import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  Button,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { createAlert, getWallet, payCash, getPaymentCharges } from "actions";
import { validations, validator } from "helpers";
import { useHistory } from "react-router-dom";
import { checkAmountBeforeSubmit, fixedValue ,isNumeric,replaceLeadingZeros } from "helpers"
import {display} from "helpers"

import { payStyle } from "styles";

const Pay = () => {
  const classes = payStyle();

  const dispatch = useDispatch();
  const { wallet, _loader } = useSelector(({ transaction }) => transaction);
  const {payment} = useSelector((state)=>state.transaction)
  const paymentCharges = (Number(payment.gift_cash_charge))
  console.log("payment charges", payment, paymentCharges)

  const history = useHistory();
  const giftId = history?.location?.giftId;

  const [message, setMessage] = useState("")

  const [amount, setAmount] = useState("")
  const [error, setError] = useState("")

  useEffect(() => {
    const getwallet = async () => {
      await dispatch(getWallet());
    };
    getwallet();
  }, []);

  useEffect(()=>{
    const getData =()=>{
      dispatch(getPaymentCharges())
    }
    getData()
  
  }, [])

  const handleAmount = (e) => {
    let num = e.target.value;
    //user can't enter more than 2 digits after decimal
    if(num.includes(".") && num.split(".")[1].length>2) return;
    num = replaceLeadingZeros(num);

    //only numeric values are allowed
    if (isNumeric(num)) {
      setAmount(num);
      setError("");
    } 
    else {
      if (num.length === 0) {
        clear();
        setError("");
      } 
      else setError("invalid amount");
    }
  };
  const handleChange = (e) => {
    console.log(e.target.value);
    setMessage(e.target.value)
  }
  const clear = () => {
    setAmount("");
    setMessage("")
  };

  const handlePay = () => {
    if (amount.length === 0 || error) {
      setError("please enter a amount");
    } else {
      const amt = Number(amount)
      const checkAmount = checkAmountBeforeSubmit(amt);
      if(checkAmount) {
        setError(checkAmount);
        return;
      }
      dispatch(payCash( {amount, message, giftId}, history, paymentCharges));   
      clear();
    } 
  };

  return (
    <>
      <Box fontFamily="CormorantBold" className={classes.payHeading}>
        Send Money
      </Box>
      <div className={classes.payContainer}>
        <div className={classes.main}>
          <div className={classes.walletAmount}>{`WALLET BALANCE: $${
            wallet?.amount ? display(wallet?.amount) : 0
          }`}</div>
          <div className={classes.styling}>
            <TextField
              error={error}
              autoComplete="off"
              id="standard-basic"
              // type="number"
              name="amount"
              label="Amount (in $)"
              variant="outlined"
              value={amount}
              onChange={(e) => handleAmount(e)}
              InputProps={{ inputProps: { min: 1 } }}
            />
             {error && (
              <div className={classes.Row} style={{ color: "red" }}>
                {error}
              </div>
            )}
            {amount && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  paddingTop: "10px",
                }}
              >
                <Typography variant="caption" display="block" gutterBottom>
                {`You will be charged ${paymentCharges}% extra`}
                </Typography>
                <div title="Here, extra charges include 1% gift_cash_charge">
                  <InfoOutlined fontSize="small" />
                </div>
              </div>
            )} 
          </div>
          {/* //*Messsage
          <div className={classes.styling}>
              <TextField
              id="outlined-textarea"
              label="Message"
              placeholder="Type here..."
              multiline
              variant="outlined"
              value={message}
              onChange={handleChange}
              inputProps={{ className: classes.textarea }}
            />
          </div> */}
            <Button
            style={{ marginLeft: "20px" }}
            variant="contained"
            onClick={handlePay}
            size="large"
            color="primary"
            disabled={!amount || _loader}
            >
            {_loader?"Processing...":"PAY"}
          </Button>
        
        </div>
      </div>
    </>
  );
};

export default Pay;