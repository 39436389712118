import { ACTION_TYPES } from "constant";
const INITIAL_STATE = {
  loader: false,
  _loader: false,
  dloader: false,
  guests: [],
  groups: [],
  invitation: {},
  notifications: [],
  events: [],
  //to store user's guestId
  guestIds: []
};

export default function guest(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTION_TYPES.USER_LOGGEDIN:
      return { ...state, isLoggedIn: true };

    case ACTION_TYPES.GUEST_REQUEST:
      return { ...state, loader: true };
    case ACTION_TYPES.GUEST_SUCCESS:
      return { ...state, loader: false };
    case ACTION_TYPES.GUEST_COMPLETE:
      return {
        ...state,
        loader: false,
        _loader: false,
        guests: action.payload,
      };
    case ACTION_TYPES.GUEST_FAILED:
      return { ...state, loader: false };
    case ACTION_TYPES.GUEST_GROUP_COMPLETE:
      return {
        ...state,
        _loader: false,
        loader: false,
        groups: action.payload,
      };
    case ACTION_TYPES.GUEST_INVITATION_DETAIL:
      return { ...state, invitation: action.payload, loader: false };

    case ACTION_TYPES.GUEST_EVENTS:
      return { ...state, events: action.payload, loader: false };

    case ACTION_TYPES.SAVE_GUEST_IDS:
      return {...state,guestIds: action.payload};

    case ACTION_TYPES.GUEST_GROUP_REQUEST:
      return { ...state, _loader: true };
    case ACTION_TYPES.GUEST_GROUP_FAILED:
      return { ...state, _loader: false, loader: false };
    case ACTION_TYPES.GUEST_GROUP_DEL_REQUEST:
      return { ...state, dloader: true };
    case ACTION_TYPES.GUEST_GROUP_DEL_COMPLETE:
      return { ...state, dloader: false };
    case ACTION_TYPES.GUEST_GROUP_DEL_FAILED:
      return { ...state, dloader: false };
    case ACTION_TYPES.RESET:
      return INITIAL_STATE;

    case ACTION_TYPES.LOG_OUT:
      return INITIAL_STATE;

    default:
      return state;
  }
}
