import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, Box } from "@material-ui/core";
import { eventStyle } from "styles";
import { NoRecordFound, Loader } from "components";
import { userIcon, calendarIcon, locationPinIcon } from "assets";
import { setEvent, setEventVendor } from "actions";
import Rating from "@material-ui/lab/Rating";
import { RateReviewOutlined } from "@material-ui/icons";
import { colors } from "constant";

function SearchResult({ results, searchFor, setOpen }) {
  const classes = eventStyle();

  const { loader } = useSelector(({ search }) => search);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleShowResult = async (res) => {
    if (searchFor === "Vendors") {
      dispatch(setEventVendor(res));
      history.push("/vendor-detail");
    } else if (searchFor === "Wedding Events") {
      dispatch(setEvent(res));
      history.push("/guest-event");
    }
    setOpen(false);
  };

  return (
    <>
      {loader ? (
        <div
          style={{
            widheight: "100px",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader size={50} time={5000} />
        </div>
      ) : (
        <Grid container>
          {results && results.length ? (
            results.map((result, index) => (
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={6}
                key={`${index}-event-and-vendors-list`}
                className={classes.eventVendorListMain}
              >
                <div
                  className={classes.eventFrListV}
                  style={{ width: "90%" }}
                  onClick={() => handleShowResult(result)}
                >
                  <Box fontFamily="GothamBold" className={classes.eventFrHT}>
                    {searchFor === "Vendors"
                      ? result.business_name
                      : result.event_name}
                  </Box>
                  <Box fontFamily="GothamBook" className={classes.eventFrCT}>
                    <img
                      src={userIcon}
                      alt=""
                      className={classes.eventFrIcons}
                    />
                    {searchFor === "Vendors"
                      ? result.vendor_name
                      : result.spouse_name}
                  </Box>
                  <Box fontFamily="GothamBook" className={classes.eventFrCT}>
                    {searchFor === "Vendors" ? (
                      <>
                        <RateReviewOutlined
                          fontSize="small"
                          style={{
                            color: colors.main5,
                            marginRight: "2px",
                            fontSize: "15px",
                          }}
                        />
                        <Rating
                          value={
                            result && result.avgRating ? result?.avgRating : 0
                          }
                          size="small"
                          readOnly
                          precision={0.5}
                        />
                        {result.avgRating ? result?.avgRating : 0}
                      </>
                    ) : (
                      <>
                        <img
                          src={calendarIcon}
                          alt=""
                          className={classes.eventFrIcons}
                        />
                        {result.event_date &&
                          new Date(result.event_date).toLocaleDateString()}
                      </>
                    )}
                  </Box>
                  <Box fontFamily="GothamBook" className={classes.eventFrCT}>
                    <img
                      src={locationPinIcon}
                      alt=""
                      className={classes.eventFrIcons}
                    />
                    {searchFor === "Vendors"
                      ? `${result.city} ${result.state}, ${result.zip_code}`
                      : result.event_location}
                  </Box>
                </div>
              </Grid>
            ))
          ) : (
            <NoRecordFound />
          )}
        </Grid>
      )}
    </>
  );
}

export default SearchResult;
