import { makeStyles } from "@material-ui/styles";
import { colors } from "constant";
const center = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const notificationStyle = makeStyles((theme) => ({
  main: {
    position: "absolute",
    top: 70,
    right: 30,
    minWidth: "300px",
    maxWidth: "300px",
    maxHeight: "400px",
    color: colors.black,
    backgroundColor: colors.white,
    padding: "10px",
    borderRadius: "6px",
    boxShadow: "1px 1px 5px black",
    overflowY: "auto",
    overflowX: "hidden",
    scrollbarWidth: "0",
    "&::-webkit-scrollbar": {
      scrollbarWidth: "0",
    },
  },
  header: { color: colors.main5, fontFamily: "Gotham" },
  center: {
    display: "flex",
    alignItems: "center",
    color: colors.main,
    fontFamily: "Gotham",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  btn: { width: "95%", margin: "10px", textAlign: "center" },
  date: { fontSize: "12px", color: colors.grey5 },
}),{index:1});
