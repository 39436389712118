import React,{useEffect} from "react";
import { Typography, Grid, Box, Button } from "@material-ui/core";
import { eventStyle, commonStyle, commonButtonStyle } from "styles";
import { strings } from "constant";
import { useSelector } from "react-redux";
import { setEventVendor } from "actions";
import { useDispatch } from "react-redux";
import { plusIcon, giftIcon, usersIcon } from "assets";
import { Loader } from "components"

const { events, common } = strings;
export default (props) => {
  const classes = eventStyle();
  const dispatch = useDispatch();
  const commClasses = commonStyle();
  const {
    event = {},
    categories = [],
    vendor,
    bookedVenders,
    eloader,loader
  } = useSelector(({ event }) => event);
  const { guest_count = 0 } = event;

  const { gifts } = useSelector(({ gift }) => gift);
  const { hasVendor } = useSelector(({ app }) => app);
  const onBookedVendor = (cateId) => {
    var vId = "";
    bookedVenders.forEach((item) => {
      if (item.cate === cateId) {
        vId = item.bookedVender;
      }
    });
    dispatch(setEventVendor(vendor, vId));
    props.history.push({
      pathname: "/eventvendordetail",
      state: { booked: true, from: "eventDetail" },
    });
  };
  return (
    <Grid
      container
      justifyContent="center"
      className={classes.eventVendorsMain}
    >
      <Typography component="div" className={classes.eventTV}>
        <Box fontFamily="CormorantBold" className={classes.eventT}>
          {events.Vendors}
        </Box>
      </Typography>
      <div className={classes.vendorHorV}>
        {categories.map((item, index) => (
          <div
            onClick={() => onBookedVendor(item.id)}
            className={classes.eventVendorsV}
            key={Math.round() + "-" + item.title + index + "vend-round"}
          >
            <img src={item.image} className={classes.vendorImg} alt="" />
            <Box fontFamily="Gotham" className={classes.vendorImgAlt}>
              {item.name}
            </Box>
          </div>
        ))}
        <div className={classes.eventVendorsV}>
          <div
            className={classes.roundVenV}
            onClick={() => props.history.push("/category")}
          >
            <img src={plusIcon} className={classes.plusIcon1} alt="" />
          </div>
        </div>
        <div className={commClasses.hairline} />
        <Typography component="div" className={classes.eventTV}>
          <Box fontFamily="CormorantBold" className={classes.eventT}>
            {events.Guest}
          </Box>
        </Typography>
        <div className={commClasses.center}>
          <div
            className={classes.eventStatBox}
            onClick={() => props.history.push({pathname:"/guests", state:true})}
          >
            <img src={usersIcon} alt="" />
            <Box fontFamily="GothamBook" className={classes.eventstatsT}>
              {event?.event_guests}
            </Box>
          </div>
        </div>
        <div className={commClasses.hairline} />
        <Typography component="div" className={classes.eventTV}>
          <Box fontFamily="CormorantBold" className={classes.eventT}>
            {events.Gifts}
          </Box>
        </Typography>
        <div className={commClasses.center}>
          <div
            className={classes.eventStatBox}
            onClick={() => props.history.push("/gift")}
          >
            <img src={giftIcon} alt="" />
            <Box fontFamily="GothamBook" className={classes.eventstatsT}>
              {`${gifts?.totalGifts?gifts?.totalGifts:0} ${common.outOf} ${gifts?.totalGiftedBy?gifts?.totalGiftedBy:0} gifted`}
            </Box>
          </div>
        </div>

        <div className={commClasses.hairline} />
        <div className={commClasses.space100} />
        <div className={commClasses.center}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            style={commonButtonStyle}
            onClick={() => props.history.push("/gallery")}
          >
            {common.GoToGallery}
          </Button>
        </div>
      </div>
    </Grid>
  );
};
