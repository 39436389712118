import React, { useState, useEffect } from "react";
import {
  useMediaQuery,
  DialogContent,
  Dialog,
  Grid,
  Box,
  Button,
  useTheme,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { Clear, Edit, Delete, MoreHoriz } from "@material-ui/icons";
import { giftStyle, commonStyle } from "styles";
import { useDispatch, useSelector } from "react-redux";
import { strings } from "constant";
import { giftIcon } from "assets";
import AddGift from "./addGift";
import {
  createAlert,
  fetchGifts,
  giftedBy,
  deleteGift,
  cashPayment,
} from "actions";
import { Loader, NoRecordFound } from "components";
const { gift, common, errors } = strings;

export default (props) => {
  const classes = giftStyle();
  const dispatch = useDispatch();
  const { gifts : {giftLists}, loader = false } = useSelector(({ gift }) => gift);
  const { event = {} } = useSelector(({ event }) => event)

  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [selectedBy, setSelectedBy] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null)
  const [groupEl, setAnchorGroup] = useState(null)
  useEffect(() => {
    dispatch(fetchGifts());
  }, []);
  const onClose = () => {
    setOpen(false);
  };

  const get = async (data) => {
    setSelectedBy(await dispatch(giftedBy(data.selected_by)));
  };

  const viewDetails = (data) => {
    setData(data);
    setOpen(true);
    get(data);
  };

  const [allowCashPayment, setAllowCashPayment] = useState(true);
  useEffect(() => {
    const funt = async () => {
      setAllowCashPayment(await dispatch(cashPayment()));
    };
    funt();
  }, []);

  const handleCashPayment = () => {
    if (event && event.id){
      setAllowCashPayment(
        dispatch(
          cashPayment({
            cashPayment: true,
            gift_title: "",
            gift_description: "",
            delivery_address: "",
            quantity: 1,
            selected_by: [],
          })
        )
      );
      handleClose();
    }
    else dispatch(createAlert({ message: errors.noEventFoun, type: "error" }));
  };

  const handleAddGift = () => {
    if (event && event.id)
      props.history.push({ pathname: "/addgift", state: { edit: 0 } });
    else dispatch(createAlert({ message: errors.noEventFoun, type: "error" }));
  };
  const handleMenuClick=(event)=>{
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorGroup(null);
  }

  return (
    <Grid container className={classes.giftMain}>
      <div className={classes.headV}>
        <Box fontFamily="CormorantBold" className={classes.giftT}>
          {gift.GiftList}
        </Box>
        <div className={classes.addButtonV}>
          <div style={{ display: "flex" }}>
            <div style={{ margin: "0px 10px 0px 0px" }} className ={classes.spaceH20}>
              {allowCashPayment === false && (
                <Button
                  onClick={handleCashPayment}
                  variant="outlined"
                  size="large"
                  color="primary"
                >
                  {"Allow cash payment"}
                </Button>
              )}
            </div>
            <div className = {classes.spaceH20}>
            <Button
              onClick={handleAddGift}
              variant="outlined"
              size="large"
              color="primary"
            >
              {gift.AddGift}
            </Button>
            </div>
            </div>
            </div>
            <div className={classes.addButtonMenuV}>
              <MoreHoriz
                id={"guest-group-add-menu-icon"}
                onClick={handleMenuClick}
                aria-owns={Boolean(anchorEl) ? "guest-group-add-menu-icon" : null}
          />
        </div>
        <Menu
          id="guest-group-add-menu-icon"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {allowCashPayment === false && (
          <MenuItem onClick={handleCashPayment}>{"Allow cash payment"}</MenuItem>
          )}

          <MenuItem 
            onClick={()=>{
              handleClose();
              handleAddGift();
            }}
            >
            {gift.AddGift}</MenuItem>
          <MenuItem onClick={handleClose}>{common.Close}</MenuItem>
        </Menu>
      </div>
      <div className={classes.giftListV}>
        {loader ? (
          <Loader />
        ) : (
          <Grid container>
            {giftLists && giftLists.length ? (
              giftLists.map((item, index) => (
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={4}
                  className={classes.boxWrapper}
                  key={Math.random() + index + "gift-list"}
                >
                  <div className={classes.giftBox}>
                    {item.cashPayment === false ? (
                      <>
                        <img src={giftIcon} className={classes.icon} alt="" />
                        <div className={classes.centerTV}>
                          <Box
                            fontFamily="GothamBook"
                            className={classes.giftstatsT}
                          >
                            {item?.gift_title}
                          </Box>
                          <Box
                            fontFamily="GothamBook"
                            className={classes.viewDetailT}
                            onClick={() => viewDetails(item)}
                          >
                            {common.ViewDetails}
                          </Box>
                        </div>
                        <Box fontFamily="Gotham" className={classes.giftTotalT}>
                          {item?.quantity}
                        </Box>
                      </>
                    ) : (
                      <Button onClick={() => props.history.push("/wallet")}>
                        Cash Payment
                      </Button>
                    )}
                  </div>
                </Grid>
              ))
            ) : (
              <NoRecordFound />
            )}
          </Grid>
        )}
      </div>
      <GiftDetailDialog
        open={open}
        onClose={onClose}
        data={data}
        dispatch={dispatch}
        history={props.history}
        selectedBy={selectedBy}
      />
    </Grid>
  );
};

const GiftDetailDialog = ({
  open = false,
  onClose = () => {},
  data = {},
  dispatch,
  history,
  selectedBy = [],
}) => {
  const classes = giftStyle();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {}, [JSON.stringify(selectedBy)]);

  const handleEdit = async () => {
    if (data && data.id)
      history.push({ pathname: "/addgift", state: { data, edit: 1 } });
    else dispatch(createAlert({ message: errors.noEventFoun, type: "error" }));
    console.log(data);
  };

  const handleDelete = async () => {
    dispatch(deleteGift(data.id));
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      className={classes.giftDetailDialogMain}
    >
      <div className={classes.dialogTitleV}>
        <DialogTitle
          fontFamily="CormorantBold"
          className={classes.detailModalTitleHT}
        >
          {gift.GiftDetails}
        </DialogTitle>

        <div>
          <IconButton size="small" onClick={onClose}>
            <Clear />
          </IconButton>
          <div>
            <IconButton size="small" onClick={handleEdit}>
              <Edit />
            </IconButton>
          </div>
          <div>
            <IconButton size="small" onClick={handleDelete}>
              <Delete />
            </IconButton>
          </div>
        </div>
      </div>

      <DialogContent>
        <div className={classes.giftDetailModalBodyMainV}>
          <div className={classes.contentHV}>
            <img src={giftIcon} className={classes.dialogIcon} alt="" />
            <Box fontFamily="Gotham" className={classes.giftNameT}>
              {data.gift_title}
            </Box>
          </div>
          <div className={classes.giftDetailModalBody}>
            <Box fontFamily="Gotham" className={classes.dialogTitleT}>
              {common.Quantity}
            </Box>
            <Box fontFamily="GothamBook" className={classes.dialogDetailT}>
              {data.quantity}
            </Box>
            <Box fontFamily="Gotham" className={classes.dialogTitleT}>
              {common.Description}
            </Box>
            <Box fontFamily="GothamBook" className={classes.dialogDetailT}>
              {data.gift_description}
            </Box>
            <Box fontFamily="Gotham" className={classes.dialogTitleT}>
              {common.DeliveryAddress}
            </Box>
            <Box fontFamily="GothamBook" className={classes.dialogDetailT}>
              {data.delivery_address}
            </Box>
            <Box fontFamily="Gotham" className={classes.dialogTitleT}>
              {"Gifted By"}
            </Box>
            {selectedBy &&
              selectedBy.map((el, i) => (
                <Box
                  fontFamily="GothamBook"
                  className={classes.dialogDetailT}
                  key={i}
                >
                  {el.name}
                </Box>
              ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
