export const validations = {
  email: (val) =>
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      val
    ),
  password: (val) => /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$/.test(val),
  cpassword: (confPass, pass) => pass === confPass,
  fullname: (val) => !/[^A-Za-z_ '-]/.test(val),
  phone: (val) => /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(val),
  role: (val) => val,
  check: (val) => val,
};

export const validator = (key, value) => {
  if (key && !value) return false;

  if (key.substring(key.length - 4) === "name") key = "name";

  switch (key) {
    case "email":
      return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      );
    case "name":
      return /^[a-zA-Z_ '-]+$/.test(value);
    case "phone":
      return /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(value);
    case "booking_amount":
      return /^[1-9]\d*(\.\d+)?$/.test(value);
    case "Amount":
      return /^[1-9]+$/.test(value);
    default:
      return !!value;
  }
};
