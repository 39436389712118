import React, { useRef, useState } from "react";
import {
  Marker,
  GoogleMap,
  InfoWindow,
  withScriptjs,
  withGoogleMap,
  DirectionsRenderer,
} from "react-google-maps";
import { Button } from "@material-ui/core";
import { DirectionsBike } from "@material-ui/icons";

const mapStyles = [
  {
    featureType: "all",
    elementType: "labels.text",
    stylers: [
      {
        color: "#878787",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "labels.text",
    stylers: [
      {
        weight: "1.84",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#f9f5ed",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#aee0f4",
      },
    ],
  },
];

const MAP_SETTINGS = {
  DEFAULT_MAP_OPTIONS: {
    scrollwheel: false,
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
  },
  DEFAULT_CENTER: { lat: 57, lng: 20 },
  DEFAULT_ZOOM: 15,
  MARKER_SIZE: 35,
  PIXEL_OFFSET: {
    MARKER: {
      X: 0,
      Y: -35,
    },
  },
  DIRECTIONS_OPTIONS: { suppressMarkers: true, preserveViewport: true },
};

function GoogleMapComponent({ latitude, longitude, event_location }) {
  // const
  const {
    MARKER_SIZE,
    DEFAULT_ZOOM,
    DEFAULT_CENTER,
    DEFAULT_MAP_OPTIONS,
    // PIXEL_OFFSET,
    DIRECTIONS_OPTIONS,
  } = MAP_SETTINGS;

  const [show, setshow] = useState(false);
  const handleInfo = () => {
    console.log("cliked");
    setshow((p) => !p);
  };

  const handleDirection = () => {
    window.open(
      `http://maps.google.co.uk/maps?q=${latitude + "," + longitude}`
    );
  };

  const mapRef = useRef(null);
  const Map = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        ref={mapRef}
        defaultZoom={DEFAULT_ZOOM}
        defaultCenter={{
          lat: latitude,
          lng: longitude,
        }}
        defaultOptions={{ ...DEFAULT_MAP_OPTIONS, styles: mapStyles }}
        {...props}
      >
        <Marker
          position={{
            lat: latitude,
            lng: longitude,
          }}
          onClick={handleInfo}
        />
        {show ? (
          <InfoWindow
            position={{
              lat: latitude + 0.0018,
              lng: longitude,
            }}
          >
            <p style={{}}>
              <span>{event_location}</span>
            </p>
          </InfoWindow>
        ) : null}
      </GoogleMap>
    ))
  );
  return (
    <>
      <Map
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&libraries=places`}
        loadingElement={<div style={{ height: `100%`, width: "100%" }} />}
        containerElement={<div style={{ height: "100%" }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
      {show ? (
        <Button
          onClick={handleDirection}
          variant="outlined"
          style={{ margin: "10px 0px" }}
        >
          Direction
        </Button>
      ) : null}
    </>
  );
}

export default GoogleMapComponent;
