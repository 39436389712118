
import { firestore, updateOne, sendPush, sendEmail } from "helpers";
import { createAlert, createWallet } from "actions";
import { ACTION_TYPES } from "constant";
import {getFixed, fixedValue} from "helpers";

export const getVendorOrdersDetail = () => async (dispatch, getState) => {
  try {
    const {
      event: { id = "" },
      bookedVenders,
    } = getState().event;

    const res = await firestore
      .collection("vender_orders")
      .where("event_id", "==", id)
      .get();
    const data = res.docs.map((it) => it.data());
    console.log("All payments", data);

    const paymentDetail = data?.map((booked) => {
      return {
        ...booked,
        name:
          bookedVenders.length &&
          bookedVenders?.filter((d) => booked.proposal_id === d.id)[0]
            ?.vendor_name,
      };
    });

    console.log(paymentDetail);
    dispatch({ type: ACTION_TYPES.PAYMENT_DETAILS, payload: paymentDetail });
  } catch (err) {
    console.log(err);
  }
};

export const getEventOrdersDetail =
  (Eid, cId) => async (dispatch, getState) => {
    try {
      const {
        business: { id },
      } = getState().vendor;
      
      const res = await firestore
        .collection("vender_orders")
        .where("business_id", "==", id)
        .where("event_id", "==", Eid)
        .get();
      const data = res.docs.map((it) => it.data());
      console.log("All payments", data);

      dispatch({ type: ACTION_TYPES.PAYMENT_DETAILS, payload: data });
    } catch (err) {
      console.log(err);
    }
  };


export const requestPayment =
  (eId, cId, categoryName, coupleId, message, amount) =>
  async (dispatch, getState) => {
    try {
      //Notification
      const { uid, name } = getState().user.user;

      //*Notify
      const data = {
        to: [coupleId],
        from: uid,
        title: "Payment Request",
        body: `${name} requested you to pay ${message} for ${categoryName}`,
        eventId: eId,
        category_id: cId,
        notificationType: "payment request",
        amount: amount,
      };

      console.log("data >>>>", data);
      sendPush(data);

      //*Email
      const sendMailData = {
        to: data.to,
        subject: "Payment Request",
        type: "payment_request",
        message: `${name} requested you to pay ${message} for category ${categoryName}`,
      };

      console.log("sendMail data >>>>", sendMailData);
      await sendEmail(sendMailData);
      dispatch(
        createAlert({
          message: `Successfully requested for ${message}`,
          type: "success",
          duration: 5000,
        })
      );
    } catch (error) {
      console.log("fetch event catch error ", error);
      dispatch({ type: ACTION_TYPES.TRANSACTION_FAILED });
      dispatch(createAlert({ message: error?.message?error?.message:error, type: "error" }));
    }
  };

export const payPayment = (id, paymentCharges,data) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.TRANSACTION_REQUEST });
    const { wallet = {} } = getState().transaction;
    console.log(id, data);

    const walletAmount =  wallet?.amount  //1% charge
    const charges_amount = data.amount*(paymentCharges/100)
    const fixedAmount = charges_amount + data.amount
    const calculated_amount = fixedValue(fixedAmount)
    
    if (walletAmount >= calculated_amount) {   
      // debugger;
      //*1
      await updateOne("vender_orders", id, {
        lastPaymentType: data.lastPaymentType,
        transferred_amount: data.transferred_amount,
      });

      //*2 Deducte money from wallet
      dispatch(sendMoney(data.business_id, data, calculated_amount));
      await dispatch(getVendorOrdersDetail());

      dispatch(
        createAlert({
          message: `Successfully paid ${data.lastPaymentType} Installment`,
          type: "success",
          duration: 5000,
        })
      );
      // eslint-disable-next-line no-throw-literal
    } else throw "You don’t have enough funds for this transaction please go to your wallet and add amount";
  } catch (error) {
    console.log("fetch event catch error ", error);
    dispatch({ type: ACTION_TYPES.TRANSACTION_FAILED });
    dispatch(createAlert({ message: error?.message?error.message:error, type: "error" }));
  }
};

export const sendMoney =
  (id,{ amount, userId, message }, calculated_amount) =>
  async (dispatch, getState) => {
    try {
      const vendor = await firestore
        .collection("venders")
        .where("id", "==", id)
        .get();
      const vender_detail = vendor.docs.map((d) => d.data());
      console.log("vender_detail", vender_detail)
      const { ownerId } = vender_detail[0];
      console.log("calculated_amount",calculated_amount)
      const data = {
        amount: fixedValue(amount),
        currency: "$",
        businessId: id,
        fromId: userId,
        credit:true,
        message,
      };

      console.log(data);
      //* transfer money to vendor's wallet
      await dispatch(createWallet(ownerId, data));

      //* deduct money from couple wallet
     
      await dispatch(deductAmount(userId, { ...data, ownerId }, calculated_amount));

    } catch (err) {
      console.log(err);
    }
  };

export const deductAmount = (uid, { businessId="", amount=0, ownerId="", message="" }, calculated_amount) =>
  async (dispatch, getState) => {
    try{
      console.log("calculated_amount", calculated_amount)
      const updateMyWallet = { 
        chargesAmount: -calculated_amount,
        amount:-amount,
        createdAt: new Date().getTime(),
        currency: "$",
        businessId,
        credit: false, //
        message,
        toId: ownerId,
      };
      await dispatch(createWallet(uid, updateMyWallet));
  
    }
   
    catch (err) {
      console.log(err);
    }
  };

//*GIFTS
export const payCash =
  ({ amount, message="", giftId = null }, history, paymentCharges) =>
  async (dispatch, getState) => {
    try {
      const cents = Number(amount)
      // const calculated_amount = cents+(cents*paymentCharges)/100;
      const calculated_amount = cents*(paymentCharges/100)
        const fixedAmount = cents+calculated_amount
        const chargesAmount = fixedValue(fixedAmount)
        console.log("chargesAmount", chargesAmount)

      dispatch({type: ACTION_TYPES.PAY_CASH_REQUEST});
      const { wallet = {} } = getState().transaction; 
      const walletAmount = wallet &&  wallet?.amount 
      if (walletAmount >= chargesAmount) {
        const {
          event: { owners },
        } = getState().event;

        const {
          user: { uid = "" },
        } = getState().user;

        const cents = Number(amount);
        const data = {
          amount: cents,
          currency: "$",
          fromId: uid,
          // message,
        };

        console.log(data);
        //* + transfer money to vendor's wallet
        await dispatch(createWallet(owners, data));

        //! - deduct money from wallet
        const updateMyWallet = {
          amount: -cents,
          chargesAmount: -chargesAmount,
          createdAt: new Date().getTime(),
          currency: "$",
          credit: false,
          // message,
          toId: owners,
        };
        dispatch(createWallet(uid, updateMyWallet));
        dispatch(
          createAlert({
            message: `Successfully paid $${amount}`,
            type: "success",
            duration: 2000,
          })
        );
        dispatch({type: ACTION_TYPES.PAY_CASH_COMPLETE});
      } else {
        // eslint-disable-next-line no-throw-literal
        throw "You have insufficient amount to transfer. Please go to your wallet and add amount";

      }
      history.goBack();
    } catch (error) {
      console.log("fetch event catch error ", error);
      dispatch({ type: ACTION_TYPES.TRANSACTION_FAILED });
      dispatch({type: ACTION_TYPES.PAY_CASH_FAILED});
      dispatch(createAlert({ message: error.message?error.message:error, type: "error" }));
      history.push("/wallet");
    }
  };
