import { imagePathToUrl } from "helpers";
import { ACTION_TYPES } from "constant";
import {
  uploadImages,
  uploadImagesS3,
  deleteFromFirebase,
  firestore,
} from "helpers";
import { setCategory } from "actions";

export const closeAlert = () => (dispatch) => {
  dispatch({ type: ACTION_TYPES.CLOSE_ALERT });
};

export const createAlert =
  ({ message, type, duration = 10000, extra = {} }) =>
  (dispatch) => {
    dispatch({
      type: ACTION_TYPES.CREATE_ALERT,
      payload: message,
      typ: type,
      extra,
    });
    setTimeout(() => {
      dispatch({ type: ACTION_TYPES.CLOSE_ALERT });
    }, duration);
  };

//!This is function is not used any more. we started saving images/vedios in AWS S3
//*only category images are saved in DB
export const imagesToUrl =
  (images = []) =>
  async (dispatch, getState) => {
    try {
      if (images && images.length) {
        dispatch({ type: ACTION_TYPES.IMAGE_URL_REQUEST });
        // let urls = images?.length
        //   ? images.map(async (image) => await imagePathToUrl(image))
        //   : [];
        const { event = {} } = getState().event;

        console.log("imagesToUrl event", event);

        let urls = [];
        for (let i = 0; i < images.length; i++) {
          let tempUrl = await imagePathToUrl(images[i]);
          urls.push(tempUrl);
        }
        // }
        console.log("this is url>>>>>", urls);
        dispatch({ type: ACTION_TYPES.IMAGE_URL_COMPLETE });
        // return new Promise((res) => res(urls));
        return urls;
      } else {
        return [];
      }
    } catch (error) {
      dispatch({ type: ACTION_TYPES.IMAGE_URL_FAILED });
      console.log("images url catch error ", error);
      return new Promise((res) => res([]));
    }
  };

//functionality for Theme_image is not implemented
export const filterImages = async (images, deleteImg, theme_image) => {
 try {
  let NewImages = [];
  let oldImages = [];
  images.forEach((img) => {
    if (typeof img === "string") {
      oldImages.push(img);
    } else {
      NewImages.push(img);
    }
  });

  const arr = [];
  oldImages.forEach((el) => {
    if (!deleteImg.includes(el)) {
      arr.push(el);
    }
  });

  deleteImg.forEach((el) => {
    deleteFromFirebase(el);
  });
  let imagesUrl = "";
  if (NewImages.length > 0)
    imagesUrl = await uploadImagesS3(NewImages, "venders");
  let imgs = [];
  if (imagesUrl.length > 0) imgs = imagesUrl.map((i) => i.location);

  return [...imgs, ...arr];
 } catch (err) {
  console.log("filterImage",err.message?err.message:err);
  throw new Error(err.message?err.message:err);
 }
};

export const setCategoryForProposal =
  (vendor_id) => async (dispatch, getState) => {
    try {
      // debugger;
      const snap = await firestore
        .collection("venders")
        .where("id", "==", vendor_id)
        .get();

      const payload = snap.docs.map((it) => it.data());
      console.log(payload);
      dispatch(setCategory(payload[0].categories));
    } catch (error) {
      console.log(error);
    }
  };
