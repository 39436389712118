import React, { useState, useEffect } from "react";
import { WalletStyle } from "styles";
import { useDispatch, useSelector } from "react-redux";
import AddAmount from './AddAmount'
import CreateAccount from "./CreateAcc";
import TransferToBank from "./TransferToBank"
import Wallet from "./Wallet";
import { getWallet, getHistory ,getPaymentCharges} from "actions";
import {Loader} from "components"
import {display} from "helpers"
import { Box, Button, Paper } from "@material-ui/core";

function Transaction() {
  const classes = WalletStyle();

  const dispatch = useDispatch();
  const { wallet,loader=false } = useSelector(({ transaction }) => transaction);

  const [next, setNext] = useState(null);

  useEffect(() => {
    const getwallet = async () => {
      await dispatch(getWallet());
      await dispatch(getHistory());
      await dispatch(getPaymentCharges())
    };
    getwallet();
  }, []);

  return (
    <div className={classes.walletContainer}>
      <div className={classes.walletMain}>
        <div className={classes.headV}>
          <Box fontFamily="CormorantBold" className={classes.walletT}>
            {"Wallet"}
          </Box>
        </div>
      </div>
      <div className={classes.balance}>
        <Box fontFamily="CormorantBold" className={classes.walletsT}>
          {"WALLET BALANCE"}
        </Box>
        {loader?"fetching...":<Box fontFamily="CormorantBold" className={classes.walletsT}>
          {`$ ${wallet?.amount ? display(wallet?.amount) : 0}`}
        </Box>}
      </div>
      <Component />
    </div>
  );
}

const Component = ({ setWallet }) => {
  const [addAmount, setAddAmount] = useState(true);
  const [transfer, setTransfer] = useState(false);
  const [stripeId, setStripeId] = useState("");
  const classes = WalletStyle();
  
  const {
    user: {stripe_account_id, type}
  } = useSelector(({ user }) => user);  

  const { business } = useSelector((state)=>state.vendor)

  const handleWallet = () => {
    setAddAmount(true);
    setTransfer(false);
    setStripeId("")
  };

  const handleTransfer = () => {
    setAddAmount(false);
    setTransfer(true);
    if(type === 1 || type === 2){
      setStripeId(stripe_account_id)
    }
    else if(type === 3 && business?.stripe_account_id){
      setStripeId(business.stripe_account_id)
    }
  };

  return (
    <div className={classes.componentMain}>
      <div className={classes.headV}>
        <div className={classes.section}>
          <Button
            className={addAmount ? classes.active : ""}
            onClick={handleWallet}
          >
            {"ADD AMOUNT"}
          </Button>
        </div>
        <div className={classes.section}>
          <Button
            className={transfer ? classes.active : ""}
            onClick={handleTransfer}
          >
            {"TRANSFER TO BANK"}
          </Button>
        </div>
      </div>
      {addAmount && <Wallet addAmount={addAmount} setWallet={setWallet} />}
      {stripeId ? <TransferToBank />: transfer && <CreateAccount transfer={transfer}  />}
    </div>
  );
};

export default Transaction;
