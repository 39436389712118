import { makeStyles } from "@material-ui/styles";
import { colors } from "constant";

export const serchStyle = makeStyles((theme) => ({
    searchContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "24px 0",
      width: "100%",
      height: "90%",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "80%",
      },
    },
    searchBtn: {
      backgroundColor: colors.main,
      color: colors.grey1,
      "&:hover": {
        backgroundColor: colors.main5,
        color: colors.grey1,
      },
    },
    space: {
      margin: "10px",
    },
    centerRow:{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    searchIcon:{
      color: colors.main,
      "&:hover": {
        color: colors.main5
      }
    }, 
  }),{index:1});