import React, { useState,useRef } from "react";
import { useHistory } from "react-router-dom";

import {
  TextField,
  DatePicker,
  GooglePlaces,
  PhoneInput,
  DropDown,
} from "components";
import {
  eventStyle,
  accountStyle,
  commonButtonStyle,
  WalletStyle,
} from "styles";
import {
  Grid,
  Box,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  makeStyles,
  IconButton
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { updateAccount } from "actions";
import { useDispatch,useSelector } from "react-redux";
import { strings, country } from "constant";
import { validator } from "helpers";
const { common } = strings;
const current_due_err = "The combination of city, state, and postal code cannot be found."

const useStyles = makeStyles(() => ({
  img: {
    height: 80,
    width: 80,
    borderRadius: 2,
    display: "flex",
    justifyContent: "center",
  },
}));

const getCountry = (countryName="")=>{
  if(countryName){
    const ans =  country.find((it)=> it.iso2===countryName || it.iso3===countryName);
    return ans.value?ans.value:"";
  }
  return "";
}

const currentlyDue = (name,currently_due)=>{
  const res = [];
  for(let due of currently_due){
    res.push(due.split(".").at(-1));
  }
  return res.includes(name);
}

function CreateAcc({currently_due=[],userAcc={},accountId=""}) {
  const inputRef = useRef(null)
  const wallet_classes = WalletStyle();
  const evntClasses = eventStyle();
  const classes = accountStyle();
  const classess = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const individual = userAcc?.individual;
  const DOB = new Date(`${individual?.dob?.day}/${individual?.dob?.month}/${individual?.dob?.year}`);

  const { user } = useSelector(({ user }) => user);
  const { business = {} } = useSelector((state)=>state.vendor);

  const [state, setState] = useState({
    first_name: individual?.first_name?individual.first_name:"",
    last_name:  individual?.last_name?individual.last_name:"",
    email: individual?.email?individual.email:"",
    phone: individual?.phone?individual.phone:"",
    gender: individual?.gender?individual.gender:"",
    date: DOB?DOB:new Date(),
    dob_day: individual?.dob?.day? individual?.dob?.day:"",
    dob_month: individual?.dob?.month?individual?.dob?.month:"",
    dob_year: individual?.dob?.year?individual?.dob?.year:"",
    id_number: "*** *** ***",
    routing_number: userAcc?.external_accounts?.data[0]?.routing_number?userAcc?.external_accounts?.data[0]?.routing_number:"",
    account_number: user.type!==3 ? user.account_number : business.account_number,
    url: userAcc?.business_profile?.url?userAcc.business_profile.url:"",
    address: individual?.address?.line1?individual.address.line1:"",
    city: individual?.address?.city?individual.address.city:"",
    state: individual?.address?.state?individual.address.state:"",
    country: getCountry(individual?.address?.country),
    postal_code: individual?.address?.postal_code?individual.address?.postal_code:"",
    image: currentlyDue("files",currently_due)?"":"dummy text",
  });

  const [loader, setLoader] = useState(false)
  const [image, setImage] = useState("")
  const [error, setError] = useState({
    first_name: currentlyDue("first_name",currently_due)?"Invalid first_name":"",
    last_name: currentlyDue("last_name",currently_due)?"Invalid last_name":"",
    city: currentlyDue("city",currently_due)?current_due_err:"",
    state: currentlyDue("state",currently_due)?current_due_err:"",
    phone: currentlyDue("phone",currently_due)?"Invalid phone no.":"",
    postal_code: currentlyDue("postal_code",currently_due)?current_due_err:"",
    country: "",
    address: currentlyDue("line1",currently_due)?current_due_err:"",
    email: currentlyDue("email",currently_due)?"Invalid email address":"",
    url: currentlyDue("url",currently_due)?"Incorrect business url":"",
    account_number: "",
    gender: "",
    routing_number: "",
    id_number: currentlyDue("id_number",currently_due)?current_due_err:"",
    image: currentlyDue("files",currently_due)?"upload your identity":"",
  });

  const errors = {
    first_name: strings.errors.firstname,
    last_name: strings.errors.lastname,
    city: strings.errors.city,
    state: strings.errors.state,
    country: strings.errors.country,
    phone: strings.errors.phone,
    postal_code: strings.errors.zip,
    address: strings.errors.address,
    email: strings.errors.email,
    url: strings.errors.BusinessUrl,
    account_number: strings.errors.accountNumber,
    gender: strings.errors.gender,
    routing_number: strings.errors.routingNumber,
    id_number: strings.errors.id_number,
    image: strings.errors.image
  };

  const makeErrors = () => {
    let err = {};
    const er = Object.keys(state).map((key) => {
      // if(key==="date"){
      //   var date = new Date(state[key]); // some mock date
      //   var milliseconds = date.getTime();
      // }
      const status = validator(key, state[key]);
      err = { ...err, [key]: status ? "" : errors[key] };
      return status;
    });
    setError({ ...error, ...err });
    return er;
  };

  const onSubmit = async() => {
    const errs = makeErrors();
    // console.log("erroer", errs);
    console.log(state);
    if (errs && errs.filter((er) => !er).length) return;
    else {
      setLoader(true)
      delete state.date;
      const identityImage = state.image;
      delete state.image;

      if(currentlyDue("files",currently_due)) 
      await dispatch(updateAccount(state,history,identityImage));
      else await dispatch(updateAccount(state,history));
      setLoader(false)
    }
  };
  const onChange = (k, v) => {
    if (k === "date") {
      const birth = new Date(v);
      const myDate = birth.getDate();
      const month = (birth.getMonth()+1);
      const year = birth.getFullYear();
      // const allData = `${myDate}-${month}-${year}`;
      setState({ ...state, dob_day: myDate, dob_month: month, dob_year: year , [k]: v});
    }
    else{
    setState({ ...state, [k]: v });
    setError({ ...error, [k]: validator(k, v) ? "" : errors[k] });}
  };

  const handleAddress = (format) => {
    let raw = {
      state: format.state,
      city: format.city,
      country: format.country,
      postal_code: format.zip,
      address: format.address,
    };
    setState({ ...state, ...raw });
    const err = handleAddressErrs(format, raw);
    setError({ ...error, ...err });
  };

  const handleAddressErrs = (format, raw) => {
    let err = {};
    ["state", "city", "country", "zip_code", "address"].forEach((k) => {
      err = { ...err, [k]: validator(k, raw[k]) ? "" : errors[k] };
    });
    return err;
  };

  const handleInput = () => {
    inputRef.current.click();
  };

  const imageChange = (e) => {
    const img = e.target.files[0];
    setState({...state, image: img})
    setImage(URL.createObjectURL(img));
    setError({ ...error, image: validator("image", img) ? "" : errors["image"] })
    console.log(e.target.files[0], URL.createObjectURL(e.target.files[0]));
  };

  const handleCloseClick = () => {
    setImage("");
  };

  return (
    <div className={wallet_classes.create_account_container}>
      <div style={{ width: "100%" }}>
        <Grid container>
          {/* Event Title */}
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={evntClasses.addNewEventFormGV}
          >
            <TextField
              error={error.first_name}
              required
              label={"First Name"}
              onChange={(v) => onChange("first_name", v)}
              value={state.first_name}
              // inputProps={
              //   { readOnly: !currentlyDue("first_name",currently_due) }
              // }
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={evntClasses.addNewEventFormGV}
          >
            <TextField
              error={error.last_name}
              required
              label={"Last Name"}
              onChange={(v) => onChange("last_name", v)}
              value={state.last_name}
              // disabled={!currentlyDue("last_name",currently_due)}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={evntClasses.addNewEventFormGV}
          >
            <TextField
              error={error.email}
              required
              label={"Email"}
              onChange={(v) => onChange("email", v)}
              value={state.email}
              inputProps={
                { readOnly: !currentlyDue("email",currently_due) }
              }
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={classes.personalDetailTV}
          >
            <Box>
              <FormControl fullWidth error={error.gender?true:false}>
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={state.gender}
                  required
                  label="Age"
                  onChange={(v) => onChange("gender", v.target.value)}
                  inputProps={{
                    readOnly: !currentlyDue("gender",currently_due)
                  }}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={classes.personalDetailTV}
          >
            <PhoneInput
              label={common.Phone}
              required
              error={error.phone}
              onChange={(v) => onChange("phone", v)}
              value={state.phone}
              disabled={!currentlyDue("phone",currently_due)}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={evntClasses.addNewEventFormGV}
          >
            <DatePicker
              label={"Date"}
              required
              value={state.date}
              onChange={(value) => onChange("date", value)}
              inputProps={
                { readOnly: !currentlyDue("date",currently_due) }
              }
              disabled={!currentlyDue("date",currently_due)}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={evntClasses.addNewEventFormGV}
          >
            <TextField
              error={error.id_number}
              label={"SSN (ID Number)"}
              value={state.id_number}
              required
              onChange={(value) => onChange("id_number", value)}//ssn
              // inputProps={
              //   { readOnly: !currentlyDue("id_number",currently_due) }
              // }
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={evntClasses.addNewEventFormGV}
          >
            <TextField
              error={error.routing_number}
              label={"Routing Number"}
              required
              value={state.routing_number}
              onChange={(value) => onChange("routing_number", value)}
              // inputProps={
              //   { readOnly: !currentlyDue("routing_number",currently_due) }
              // }
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={evntClasses.addNewEventFormGV}
          >
            <TextField
              error={error.account_number}
              label={"Account Number"}
              required
              value={state.account_number}
              onChange={(v) => onChange("account_number", v)}
               // inputProps={
              //   { readOnly: !currentlyDue("routing_number",currently_due) }
              // }
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={evntClasses.addNewEventFormGV}
          >
            <TextField
              error={error.url}
              label={"Business URL"}
              required
              value={state.url}
              onChange={(value) => onChange("url", value)}
              inputProps={
                { readOnly: !currentlyDue("url",currently_due) }
              }
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={evntClasses.addNewEventFormGV}
          >
            <GooglePlaces
              error={error.address}
              label={common.Address}
              value={state.address}
              required
              onChange={handleAddress}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={classes.personalDetailTV}
          >
            <TextField
              label={common.City}
              error={error.city}
              required
              onChange={(v) => onChange("city", v)}
              value={state.city}
              // disabled={!currentlyDue("city",currently_due)}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={classes.personalDetailTV}
          >
            <TextField
              label={common.State}
              error={error.state}
              required
              onChange={(v) => onChange("state", v)}
              value={state.state}
              // disabled={!currentlyDue("state",currently_due)}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={classes.personalDetailTV}
          >
            <DropDown
              label={common.Country}
              data={country}
              error={error.country}
              onChange={(v) => onChange("country", v)}
              value={state.country}
              // disabled={!currentlyDue("country",currently_due)}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={classes.personalDetailTV}
          >
            <TextField
              error={error.postal_code}
              label={"Postal code"}
              required
              value={state.postal_code}
              onChange={(v) => onChange("postal_code", v)}
              // disabled={!currentlyDue("postal_code",currently_due)}
            />
          </Grid>
          {!accountId || (currently_due.length > 0 && currentlyDue("files",currently_due)) ?
            <>
            <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={classes.personalDetailTV}
          >
            <TextField
              id="input-with-icon-textfield"
              error={error.image}
              label={"Select image"}
              disabled= {true}
              // disabled={!currentlyDue("postal_code",currently_due)}
              onClick={handleInput}
              required
              variant="outlined"
              style={{
                position: "relative",
                float: "left",
                color: "rgba(0, 0, 0, 0.54)",
              }}
            />
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              style={{ display: "none" }}
              onChange={imageChange}
            />
          </Grid>
          <Grid item sm={12}
            xs={12}
            md={6}
            lg={6}
            className={classes.personalDetailTV}

            >
            </Grid>
          <Grid item sm={12}
            xs={12}
            md={6}
            lg={6}
            className={classes.personalDetailTV}
            >
          {image && (
            <div>
              <TextField
                variant ="standard"
                disabled= {true}
                style = {{paddingBottom:"20px"}}
                  sx={{
                    ".MuiOutlinedInput-root": {
                      paddingTop: "1rem",
                      flexDirection: "column",
                    },
                    img: {
                      paddingRight: "1rem",
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <div>
                        <img src={image} className={classess.img} />
                        <IconButton
                          onClick={handleCloseClick}
                          style={{
                            position: "absolute",
                            bottom: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <Cancel />
                        </IconButton>
                      </div>
                    ),
                  }}
                />
            </div>
          )}
           </Grid>
            </>
          :null}
        </Grid>
      </div>
      <div>
        {
          loader?<Button
          variant="contained"
          size="large"
          color="primary"
          style={commonButtonStyle}
          disabled={true}
        >
          Processing...
        </Button>:
         <Button
         variant="contained"
         size="large"
         color="primary"
         style={commonButtonStyle}
         onClick={onSubmit}
       >
         {"UPDATE"}
       </Button>
        }
      </div>
    </div>
  );
}

export default CreateAcc;
