import React, { useState, useMemo, useEffect} from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { checkAmountBeforeSubmit,getFixed,isNumeric,replaceLeadingZeros } from "helpers"
import { TextField, Button, Typography } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { checkOutPayment } from "actions";
import {Loader} from "components"
import { createAlert } from "actions";
import { useDispatch, useSelector } from "react-redux";
import { WalletStyle } from "styles";

const iframeStyles = {
  invalid: {
    iconColor: "#FFC7EE",
    color: "#FFC7EE",
  },
  complete: {
    iconColor: "#cbf4c9",
  },
};
const useOptions = () => {
  const options = useMemo(
      () => ({
          style: {
              base: {
                  color: "#424770",
                  letterSpacing: "0.025em",
                  fontFamily: "Roboto, Source Code Pro, monospace, SFUIDisplay",
                  "::placeholder": {
                      color: "#aab7c4"
                  }
              },
              invalid: {
                  color: "#9e2146"
              },
          }
      }),
      []
  );
  return options;
};

export default function CheckoutForm() {
  const classes = WalletStyle();
  const stripe = useStripe();
  const dispatch = useDispatch();
  const elements = useElements();
  const options = useOptions();
  
  const {
    user: { tokenId },
  } = useSelector(({ user }) => user);

  const { payment } = useSelector((state)=>state.transaction)
  const { loader } = useSelector(({ transaction }) => transaction);
  console.log("payment", payment)
  const paymentCharges = (Number(payment.stripe))

  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  const [amount, setAmount] = useState("");

  const handleAmount = (e) => {
    let num = e.target.value;
    if(num.includes(".") && num.split(".")[1].length>2) return;
    num = replaceLeadingZeros(num);
    if (isNumeric(num)) {
      setAmount(num);
      setCheckoutError("");
    } else {
      if (num.length === 0) clear();
      else setCheckoutError("Invalid amount");
    }
  };

  const clear = () => {
    setAmount("");
  };

  const handleCardDetailsChange = (ev) => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const handleMySubmit = async (e) => {
    e.preventDefault();
    try{
        const cents = (amount*paymentCharges)/100
        const amt = Number(amount)
        const checkAmount = checkAmountBeforeSubmit(amt);
        if(checkAmount) {
          setCheckoutError(checkAmount);
          return;
        }
        const totalAmount = amt+cents
        const fixedAmount = getFixed(totalAmount);
        console.log("fixedAmount ------- ",fixedAmount);
        const data = {
          amount: fixedAmount,
          items: [{ id: new Date().getTime() }],
        }
        setProcessingTo(true);

        const cardElement = elements.getElement("card")
        try {
        const paymentMethodReq = await stripe.createPaymentMethod({
          type: "card",
          card:cardElement,
        });

        if (paymentMethodReq.error) {
          console.log("error", paymentMethodReq.error.message)
          setCheckoutError(paymentMethodReq.error.message);
          setProcessingTo(false);
          return;
        }
        cardElement.clear();
        clear();
        await dispatch(checkOutPayment(data, paymentMethodReq, stripe, Number(amount), cardElement ))
        setProcessingTo(false)
      }
      catch (error) {
        setCheckoutError(error.message)
        setProcessingTo(false);
        cardElement.clear();
        clear();
      }
    }
    catch(error){
      console.log("error: " + error)
      setProcessingTo(false);
      dispatch(
        createAlert({
          message: error.message? error.message:"something went wrong",
          type: "error",
          duration: 5000,
        })
      );
    }
  };

  const cardElementOpts = {
    iconStyle: "solid",
    style: iframeStyles,
    hidePostalCode: true,
  };

  return (
    <div className={classes.WalletComponent}>
      <div className={classes.wallet_form}>
        <div className={classes.Row}>
          <TextField
            label="Amount"
            variant="standard"
            error={checkoutError}
            onChange={handleAmount}
            value={amount}
            helperText={checkoutError}
          />
          {amount && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                paddingTop: "10px",
              }}
            >
              <Typography variant="caption" display="block" gutterBottom style={{margin: 0}}>
                {`You will be charged ${paymentCharges}% extra`}
              </Typography>
              <div title={`Here, extra charges include ${payment.stripe}% stripe charges.`} style={{marginLeft: 3}}>
                <InfoOutlined fontSize="small" />
              </div>
            </div>
          )}
        </div>
        <div className={classes.Row}>
          <CardElement
            options={cardElementOpts}
            onChange={handleCardDetailsChange}
          /> 
       </div>
        <div className={classes.center}>
          <Button
            disabled={isProcessing || !amount}
            onClick={handleMySubmit}
            variant="contained"
            className={classes.active}
          >
            {isProcessing   ? `Processing...` : `ADD AMOUNT`}
          </Button>
        </div>
      </div>
    </div>
  );
}