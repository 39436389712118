import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  Button,
  IconButton,
  FormLabel,
} from "@material-ui/core";
import { eventStyle, accountStyle, commonButtonStyle } from "styles";
import {
  TextField,
  DatePicker,
  Radio,
  FilePicker,
  PhoneInput,
  GooglePlaces,
  SearchLocation,
  DropDown,
} from "components";
import { strings, country, guests } from "constant";
import { ArrowDropDown, AddLocation } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Menus from "./popupMenu";
import { validator } from "helpers";
import { updateEvent } from "../../actions/event";
const { events, common, account } = strings;

export default (props) => {
  const dispatch = useDispatch();
  const { event = {}, eloader} = useSelector(({ event }) => event);
  const { hasEvent = false } = useSelector(({ app }) => app);
  const history = useHistory();

  // const [isExpired, setIsExpired] = useState(
  //   new Date().getTime() > event.event_date + 9600000
  // );
  const evntClasses = eventStyle();
  const classes = accountStyle();
  const [menu, setMenu] = React.useState(null);
  const [openMap, setOpenMap] = useState(false);
  
console.log(eloader)
  const handleMenuClick = (event) => {
    setMenu(event.currentTarget);
  };
  const closeMenu = () => {
    setMenu(null);
  };
  const onMenu = (ind) => {
    props.setSelected(ind);
  };
  const {
    event_date = new Date(),
    event_name = "",
    spouse_name = "",
    spouse_email = "",
    event_location = "",
    event_type = 1,
    function_type = 1,
    createdAt = "",
    modifiedAt = "",
    deleted = false,
    guest_count = "",
    images = [],
    latitude,
    longitude,
    spouse_phone,
    spouseCountryCode = "",
  } = event;
  
  const [locationData, setLocationData] = useState({
    address: event_location,
    coords: { latitude, longitude },
  });

  const [state, setState] = useState({
    event_date: event_date || new Date(),
    event_name,
    spouse_name,
    spouse_email,
    event_location,
    event_type,
    function_type,
    createdAt,
    modifiedAt,
    deleted,
    guest_count,
    images,
    latitude,
    longitude,
    spouse_phone,
    spouseCountryCode,
  });

  const [error, setError] = useState({
    event_date:"",
    event_name:"",
    spouse_name:"",
    spouse_email:"",
    event_location:"",
    event_type:"",
    function_type:"",
    createdAt:"",
    modifiedAt,
    guest_count:"",
    images:"",
    spouse_phone: ""
  });

  const makeErrors = () => {
    let err = {};
    const er = Object.keys(state).map((key) => {
      if(key === "deleted") return true;
      const status = validator(key, state[key]);
      err = { ...err, [key]: status ? "" : errors[key] };
      return status;
    });
    setError({ ...error, ...err });
    return er;
  };

  const errors = {
    event_name: strings.errors.event_name,
    event_date: strings.errors.event_date,
    spouse_name: strings.errors.spouse_name,
    event_location: strings.errors.event_location,
    event_type: strings.errors.event_type,
    function_type: strings.errors.function_type,
    createdAt: strings.errors.createdAt,
    modifiedAt: strings.errors.modifiedAt,
    guest_count: strings.errors.guest_count,
    spouse_email: strings.errors.email,
    spouse_phone: strings.errors.phone,
    images: strings.errors.image
  };

  const onChange = (k, v) => {
    console.log(k, v);
    if(k==="event_date"){
      setState({...state, [k]: v.getTime()});
    }
    else{
      setState({...state, [k]:v})
    }
    let key = k;
    if(k ==="spouse_email") key = "email";
    if(k ==="spouse_phone") key = "phone";
    setError({ ...error, [k]: validator(key, v) ? "" : errors[k] });
  };

  const [deleteImg, setDeleteImg] = useState([]);
  const onDeleteImgs = (delImgs) => {
    setDeleteImg(delImgs);
  };

  const onSubmit = () => {
    const errs = makeErrors();
    console.log("errs: ",errs && errs.filter((er) => !er).length);
    if(errs && errs.filter((er) => !er).length) return;
    else dispatch(updateEvent({ ...state, deleteImg }))
    
  };

  const handleAddress = (add) => {
    console.log(add);
    const str = ",";
    const address = add.address
      ? `${add.name ? add?.name+str :""} ${add.route ?add.route+str:""} ${add?.city ? add?.city+str:""} ${add?.state?add?.state+str:""} ${add.country ? add?.country:""}`
      : "";
    setState({
      ...state,
      event_location: address,
      latitude: add.latitude,
      longitude: add.longitude,
    });
    setError({ ...error, event_location: validator("event_location",address)? "":errors["event_location"] });
    setLocationData({
      address: address,
      coords: { latitude: add.latitude, longitude: add.longitude },
    });
  };

  useEffect(() => {
    setState({
      ...state,
      event_location: locationData.address,
      latitude: locationData.coords.latitude,
      longitude: locationData.coords.longitude,
    });
  }, [JSON.stringify(locationData)]);

  const onCancel = () => {
    setState({
      event_date,
      event_name,
      spouse_name,
      spouse_email,
      spouse_phone,
      event_location,
      event_type,
      function_type,
      createdAt,
      modifiedAt,
      deleted,
      guest_count,
      images,
      latitude,
      longitude,
    });
  };

  const handlePhoneInput = (v, code) => {
    setState({ ...state, spouse_phone: v, spouseCountryCode: code });
    setError({ ...error, spouse_phone: validator("phone", v) ? "" : errors[spouse_phone] });
  };

  const handleOpenMap  = ()=>{
    setOpenMap((p) => !p)
  }

  return (
    <div className={classes.renderMain}>
      <Box fontFamily="CormorantBold" className={classes.renderAcMainHeadT}>
        {account.WeddingDetails}
        <ArrowDropDown className={classes.dropIcon} onClick={handleMenuClick} />
        <Menus menu={menu} closeMenu={closeMenu} onMenu={onMenu} />
      </Box>
      {hasEvent === true ? (
        <>
          {/* {isExpired ? (
            <Typography
              style={{
                textAlign: "center",
                color: "red",
                margin: "20px",
              }}
              variant="h6"
            >
              Event is expired
            </Typography>
          ) : null} */}
          <Grid container>
            {/* Event Title */}
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={evntClasses.addNewEventFormGV}
            >
              {/* onChange */}
              <TextField
                error={error.event_name}
                label={"Event Title"}
                onChange={(v) => onChange("event_name", v)}
                value={state.event_name}
                // disabled={isExpired}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={evntClasses.addNewEventFormGV}
            >
              {/* onChange */}
              <TextField
                error={error.spouse_name}
                label={events.SpouseName}
                onChange={(v) => onChange("spouse_name", v)}
                value={state.spouse_name}
                // disabled={isExpired}
              />
            </Grid>
            {/* Spouse email */}
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={evntClasses.addNewEventFormGV}
            >
              <TextField
                error={error.spouse_email}
                label={common.EmailAddress}
                onChange={(v) => onChange("spouse_email", v)}
                value={state.spouse_email}
                // disabled={isExpired}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={evntClasses.addNewEventFormGV}
            >
              <PhoneInput
                label={common.Phone}
                error={error.spouse_phone}
                onChange={(v, code) => handlePhoneInput(v, code)}
                value={state.spouse_phone}
                // disabled={isExpired}
              />
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              className={evntClasses.addNewEventFormGV}
            >
              <GooglePlaces
                error={error.event_location}
                label={events.WeddingLocation}
                value={state.event_location}
                onChange={handleAddress}
                handleOpenMap={handleOpenMap}
                showMapIcon={true}
                // disabled={isExpired}
              />
              {openMap && (
                <SearchLocation
                  locationData={locationData}
                  setLocationData={setLocationData}
                />
              )}
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={evntClasses.addNewEventFormGV}
            >
              <DatePicker
                minDate={new Date()}
                label={events.event_date}
                value={state.event_date}
                onChange={(value) => onChange("event_date", value)}
                // disabled={isExpired}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={classes.personalDetailTV}
            >
              <DropDown
                error={error.guest_count}
                label={events.GuestCount}
                data={guests}
                onChange={(v) => onChange("guest_count", v)}
                value={state.guest_count}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={evntClasses.addNewEventFormGV}
            >
              <FilePicker
                multiple
                error={error.images}
                label={common.Images}
                imgs={state.images}
                onImage={(value) => onChange("images", value)}
                onDeleteImgs={onDeleteImgs}
                // disabled={isExpired}
              />
            </Grid>

            <Grid item sm={12} xs={12} md={12} lg={12}>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={6}
                className={evntClasses.addNewEventFormGV}
              >
                <FormLabel>Event Type</FormLabel>
                <Radio
                  label={"EventType"}
                  value={state.event_type === 1 ? "private" : "public"}
                  va="private"
                  vb="public"
                  onChange={(value) =>
                    onChange("event_type", value === "private" ? 1 : 2)
                  }
                  // disabled={isExpired}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={6}
                className={evntClasses.addNewEventFormGV}
              >
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Function Type
                </FormLabel>
                <Radio
                  label={events.EventType}
                  value={state.function_type === 1 ? "traditional" : "family"}
                  va="traditional"
                  vb="family"
                  onChange={(value) =>
                    onChange("function_type", value === "traditional" ? 1 : 2)
                  }
                  // disabled={isExpired}
                />
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={evntClasses.addNewEventFormGV}
            ></Grid>
            <Grid item sm={12} xs={12} md={6} lg={6} />
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={6}
              className={classes.buttonsV}
            >
              <Button
                disabled={eloader}
                variant="contained"
                size="large"
                color="primary"
                style={commonButtonStyle}
                onClick={onSubmit}
              >
                {eloader ? 'Updating...': common.Update}
              </Button>
              <Button
                // disabled={isExpired}
                variant="contained"
                size="large"
                style={commonButtonStyle}
                onClick={onCancel}
              >
                {common.Cancel}
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={() => history.push("/")}
          style={{ margin: 10 }}
        >
          Create Event
        </Button>
      )}
    </div>
  );
};
