import React, { useState, useEffect } from "react";
import { Typography, Box, Modal, Button } from "@material-ui/core";
import { promoteStyle } from "styles";
import { promote, getWallet, fetchEvent } from "actions";
import { useDispatch, useSelector } from "react-redux";
import { Confirm } from "components";
import {display} from "helpers";

export default function Promote({ open, setOpen }) {
  const [selectPlan, setSelectPlan] = useState(null);
  const classes = promoteStyle();

  const dispatch = useDispatch();
  const { wallet } = useSelector(({ transaction }) => transaction);
  const [dialog, setDailog] = useState(false);

  useEffect(() => {
    const getwallet = async () => {
      await dispatch(getWallet());
    };
    getwallet();
  }, []);

  const handleClose = () => setOpen(false);

  const handlePromotion = async () => {
    const getFun = async () => {
      let data;
      if (selectPlan === 1)
        data = {
          amountPaid: 280,
          planDuration: 7,
        };
      else if (selectPlan === 2)
        data = {
          amountPaid: 580,
          planDuration: 28,
        };
      dispatch(promote(data));
      dispatch(fetchEvent());
    };
    getFun();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.promoteHeader}>
          <div>
            <Typography
              id="modal-modal-title"
              variant="h4"
              component="h2"
              className={classes.title}
            >
              Promote Your Event
            </Typography>
            <Typography
              id="modal-modal-description"
              className={classes.subTitle}
              variant="subtitle1"
            >
              To Unlock These Features
            </Typography>
          </div>
          <div className={classes.promoteBody}>
            <Typography
              variant="subtitle1"
              className={classes.wallet}
            >{`Wallet Balance: $ ${
              wallet?.amount ? display(wallet?.amount) : 0
            }`}</Typography>
            <div className={classes.plans}>
              <div
                className={`${classes.plan1} ${
                  selectPlan && selectPlan === 1 && classes.active
                }`}
                onClick={() => setSelectPlan(1)}
              >
                <div className={classes.planTitle}>Weekly</div>
                <div className={classes.planePrice}>$ 280.00/wk</div>
              </div>
              <div
                className={`${classes.plan2} ${
                  selectPlan && selectPlan === 2 && classes.active
                }`}
                onClick={() => setSelectPlan(2)}
              >
                <div className={classes.planTitle}>Monthly</div>
                <div className={classes.planePrice}>$ 580.00/mo</div>
              </div>
            </div>
            <div className={classes.features}>
              After you promote your wedding event, WeddingBlizz users will be
              able to see Photo(s) and Video(s) related to the event.
            </div>
            <div className={classes.center}>
              <Button
                className={classes.promoteButton}
                variant="contained"
                onClick={() => setDailog(true)}
                disabled={selectPlan === null ? 1 : 0}
                // onClick={handlePromotion}
              >
                {selectPlan === null
                  ? "SELECT ONE PLAN TO PROCEED"
                  : "START PROMOTION"}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      <Confirm
        open={dialog}
        onClose={() => setDailog(false)}
        content={"Are you sure you want to take this event"}
        onClick={handlePromotion}
        button={"Ok"}
      />
    </div>
  );
}
