import { makeStyles } from "@material-ui/styles";
import { colors } from "constant";

const center = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const plan = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "2rem",
  backgroundColor: colors.white,
  color: colors.main,
  borderRadius: 10,
  cursor: "pointer",
};

export const promoteStyle = makeStyles((theme) => ({
  promoteHeader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    backgroundColor: colors.primary,
    boxShadow: 24,
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  title: {
    color: colors.white,
    textAlign: "center",
    marginTop: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    },
  },
  subTitle: {
    color: colors.white,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  promoteBody: {
    backgroundColor: colors.main6,
    padding: 10,
  },
  wallet: {
    textAlign: "left",
    color: colors.main,
  },
  plans: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    color: colors.white,
    fontFamily: "Gotham",
    margin: "10px",
  },
  plan1: {
    ...plan,
    [theme.breakpoints.down("md")]: {
      padding: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "15px",
    },
  },
  plan2: {
    ...plan,
    marginRight: "5px",
    [theme.breakpoints.down("md")]: {
      padding: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "15px",
    },
  },
  planTitle: {
    fontSize: 32,
    [theme.breakpoints.down("md")]: {
      fontSize: "24px",
    },
  },
  planePrice: {
    fontSize: 16,
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  features: {
    textAlign: "center",
    fontSize: "16px",
    margin: "20px 10%",
    color: colors.main,
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  promoteButton: {
    color: colors.black,
    backgroundColor: colors.main1,
    textAlign: "center",
    marginBottom: "20px",
    "&:hover": {
      color: colors.black,
      backgroundColor: colors.main1,
    },
  },
  center: {
    ...center,
  },
  active: {
    backgroundColor: colors.primary,
    color: colors.white,
    boxShadow: `1px 1px 4px 1px ${colors.main5}`,
    [theme.breakpoints.down("sm")]: {
      boxShadow: `0px 0px 2px 1px ${colors.main5}`,
    },
  },
  alert: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
}),{index:1});
