import React, { useEffect, useState } from "react";
import { Typography, Grid, Box } from "@material-ui/core";
import { PhotoTwoTone, AccountCircleOutlined } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { proposalStyle, eventStyle } from "styles";
import { strings,ACTION_TYPES } from "constant";
import { BreadCrumb, Loader, NoRecordFound, Chat } from "components";
import { getChats, setChat } from "actions";
import moment from "moment";
const { chat, common } = strings;
const profileImage = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png";

export default (props) => {
  const classes = proposalStyle();
  const eclasses = eventStyle();
  const [chtprop, _setChat] = useState({
    title: "",
    subTitle: "",
    visible: null,
  });
  const breads = [{ title: common.Home, path: "/" }];
  const setVisible = (type) => _setChat({ ...chtprop, visible: type });
  const dispatch = useDispatch();
  const {
    user: { type, uid = "" },
  } = useSelector(({ user }) => user);
  const { conversation = [], loader = false } = useSelector(({ chat }) => chat);
  console.log(conversation);
  useEffect(() => {
    dispatch(getChats());
  }, []);

  const handleChat = (chat) => {
    dispatch({type: ACTION_TYPES.CHAT_SUCCESS,chats:[]});
    let user2 = "";
    if (chat.participants[0] !== uid) user2 = chat.participants[0];
    else user2 = chat.participants[1];
    dispatch(
      setChat({
        ...chat,
        business_id: user2,
        id: `${user2}_${uid}`,
        id2: `${uid}_${user2}`,
      })
    );
    _setChat({
      ...chtprop,
      title: chat.name,
      subTitle: chat.message,
      visible: "active",
    });
  };
  return (
    <div className={classes.proposalMain}>
      <BreadCrumb breads={breads} current={common.Chats} />
      <div className={classes.mainBody}>
        {loader ? (
          <Loader />
        ) : conversation && conversation.length ? (
          conversation.map((propo, ind) => (
            <div
              key={ind + "conversation-list"}
              className={classes.listCardChat}
              onClick={() => handleChat(propo)}
            >
              <div>
                  <img
                    src={
                      propo.user && propo?.user?.image? propo.user.image : profileImage
                    }
                    className={classes.profileImg}
                    alt="profile1"
                  />
              </div>
              <div style={{ width: "100%" }}>
                <Box fontFamily="Gotham" className={classes.prosposalNameT}>
                  {propo?.user?.name}
                </Box>
                <Box fontFamily="GothamBook" className={classes.prosposalDateT}>
                  {propo?.modifiedAt &&
                    moment(new Date(propo?.modifiedAt)).format(
                      "MMMM Do YYYY hh:mm A"
                    )}
                </Box>
                {/* </div> */}
                <Box fontFamily="GothamBook" className={eclasses.eventFrCT}>
                  {propo.lastMessage.media ? (
                    <PhotoTwoTone />
                  ) : (
                    propo?.lastMessage?.message.replace(
                      /^(.{300}[^\s]*).*/,
                      "$1"
                    )
                  )}
                </Box>
              </div>
            </div>
          ))
        ) : (
          <NoRecordFound text={chat.NoConversFound} />
        )}
      </div>
      {(chtprop.visible === "active" || chtprop.visible === "hide") && (
        <Chat {...chtprop} setVisible={setVisible} />
      )}
    </div>
  );
};
