import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { AccountCircleOutlined } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { proposalStyle, eventStyle } from "styles";
import { strings } from "constant";
import { BreadCrumb, Loader, NoRecordFound, Chat } from "components";
import {
  getProposals,
  setProposal,
  setChat,
  setSelectedProposal,
} from "actions";
import moment from "moment";
const { proposal, common } = strings;
const profileImage = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png";
export default (props) => {
  const classes = proposalStyle();
  const eclasses = eventStyle();
  const [chtprop, setChatt] = useState({
    title: "",
    subTitle: "",
    visible: null,
  });
  const breads = [{ title: common.Home, path: "/" }];
  const setVisible = (type) => setChatt({ ...chtprop, visible: type });
  const dispatch = useDispatch();
  const {
    user: { type, uid = "" },
  } = useSelector(({ user }) => user);
  const { proposals = [], loader = false } = useSelector(
    ({ proposal }) => proposal
  );
  useEffect(() => {
    dispatch(setProposal([]));
    dispatch(getProposals());
  }, []);

  const handleProposal = (propose) => {
    console.log("propose", propose);
    dispatch(setProposal(propose.proposals));
    dispatch(setSelectedProposal({}));
    const user = {
      business_id: propose.user_id,
      id: propose.id,
    };
    dispatch(setChat(user));
    setChatt({
      ...chtprop,
      title: type === 1 ? propose?.user?.business_name : propose?.user?.name,
      subTitle: propose?.message,
      visible: "active",
    });
  };

  return (
    <div className={classes.proposalMain}>
      <BreadCrumb breads={breads} current={common.Proposals} />
      <div className={classes.mainBody}>
        {loader ? (
          <Loader />
        ) : proposals && proposals.length ? (
          proposals.map((propo, i) => (
            <div
              key={i}
              className={classes.listCardChat}
              onClick={() => handleProposal(propo)}
            >
              <div>
                {type === 1 && (
                  <img
                    src={
                      propo?.user?.images ? propo?.user?.images : profileImage
                    }
                    alt="profile"
                    className={classes.profileImg}
                  />
                )}
              </div>
              <div>
                {type === 3 && (
                  <img
                    src={propo.user.image ? propo.user.image : profileImage}
                    alt="profile"
                    className={classes.profileImg}
                  />
                )}
              </div>
              <div>
                <Box fontFamily="Gotham" className={classes.prosposalNameT}>
                  {type === 1 ? propo.user.business_name : propo.user.name}
                </Box>
                <Box fontFamily="GothamBook" className={classes.prosposalDateT}>
                  {propo?.modifiedAt &&
                    moment(new Date(propo?.modifiedAt)).format(
                      "MMMM Do YYYY hh:mm A"
                    )}
                </Box>
                <Box fontFamily="GothamBook" className={eclasses.eventFrCT}>
                  {propo?.message?.replace(/^(.{300}[^\s]*).*/, "$1")}
                </Box>
              </div>
            </div>
          ))
        ) : (
          <NoRecordFound text={proposal.NoProposalFound} />
        )}
      </div>
      {(chtprop.visible === "active" || chtprop.visible === "hide") && (
        <Chat {...chtprop} proposal setVisible={setVisible} />
      )}
    </div>
  );
};
