import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid, Box } from "@material-ui/core";
import { eventStyle, primaryLoaderStyle } from "styles";
import { ACTION_TYPES, strings } from "constant";
import { userIcon, calendarIcon, locationPinIcon } from "assets";
import { BreadCrumb, Loader, NoRecordFound } from "components";
import { fetchVendors, setEventVendor } from "actions";
import Rating from "@material-ui/lab/Rating";
import { RateReviewOutlined } from "@material-ui/icons";

const { events, common } = strings;

export default (props) => {
  const classes = eventStyle();
  const breads = [
    { title: common.Home, path: "/" },
    { title: events.WeddingEvent, path: "/eventdetail" },
    { title: common.Category, path: "/category" },
  ];
  const dispatch = useDispatch();
  const { loader = false } = useSelector(({ event }) => event);
  const [vendors, setVendors] = useState([]);
  console.log("vendors", vendors);
  useEffect(() => {
    const get = async () => {
      setVendors(await dispatch(fetchVendors()));
    };
    get();
  }, []);
  const onVendor = (vendor) => {
    dispatch(setEventVendor(vendor));
    props.history.push("/eventvendordetail");
  };
  return (
    <Grid container className={classes.eventMain}>
      <BreadCrumb breads={breads} current={events.Vendors} />
      <Typography component="div" className={classes.eventTV}>
        <Box fontFamily="CormorantBold" className={classes.eventT}>
          {events.Vendors}
        </Box>
      </Typography>
      {loader? (
        <Loader style={primaryLoaderStyle} time={5000} />
      ) : (
        <Grid container>
          {vendors && vendors.length ? (
            vendors.map((vendor, index) => (
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={4}
                key={`${index}-event-fair-vendors-list`}
                className={classes.eventVendorListMain}
              >
                <div
                  className={classes.eventFrListV}
                  onClick={() => onVendor(vendor)}
                >
                  <Box fontFamily="GothamBold" className={classes.eventFrHT}>
                    {vendor.business_name}
                  </Box>
                  <Box fontFamily="GothamBook" className={classes.eventFrCT}>
                    <img
                      src={userIcon}
                      alt=""
                      className={classes.eventFrIcons}
                    />
                    {vendor.vendor_name}
                  </Box>
                  <Box
                    fontFamily="GothamBook"
                    className={classes.eventVendDetailHT}
                  >
                    <RateReviewOutlined fontSize="small" />
                    <Rating
                      precision={0.5}
                      value={vendor && vendor.avgRating ? vendor?.avgRating : 0}
                      size="small"
                      readOnly
                    />
                    {vendor.avgRating ? vendor?.avgRating : 0}
                  </Box>
                  <Box
                    noWrap
                    fontFamily="GothamBook"
                    className={classes.eventFrCT}
                  >
                    <img
                      src={locationPinIcon}
                      alt=""
                      className={classes.eventFrIcons}
                    />
                    {`${vendor.city} ${vendor.state}, ${vendor.zip_code}`}
                  </Box>
                </div>
              </Grid>
            ))
          ) : (
            <NoRecordFound />
          )}
        </Grid>
      )}
    </Grid>
  );
};
