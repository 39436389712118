import React, { useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { commonStyle, loaderStyle } from "styles";

export default ({ size = 30, style = {}, color = "white", time = 5000 }) => {
  const classes = commonStyle();

  const [state, setState] = useState(true);

  useEffect(() => {
    const intervalCode = setTimeout(() => {
      setState(false);
    }, time);

    return () => {
      clearTimeout(intervalCode);
    };
  }, []);

  return (
    <>
      {state && (
        <div className={classes.loaderV}>
          <CircularProgress size={size} style={{ ...loaderStyle, ...style }} />
        </div>
      )}
    </>
  );
};
