import { auth, firestore, insert, updateOne } from "helpers";
import { history } from "../App";
import { ACTION_TYPES, strings, routes } from "constant";
import { createAlert, filterImages, Notify } from "actions";
import { deleteFromFirebase, findByIn } from "../helpers/firebase";
const { success, errors } = strings;

//get current vender business
export const fetchVendorBusiness = () => async (dispatch, getState) => {
  try {
    const { uid = "" } = getState().user?.user;

    dispatch({ type: ACTION_TYPES.BUSINESS_REQUEST });
    const snap = await firestore
      .collection("venders")
      .where("ownerId", "==", uid)
      .get();
    const business = await snap.docs.map((doc) => doc.data());

    if (business[0]) dispatch(fetchMyReviews(business[0].id));
    const payload = business &&
      business[0] && {
        ...business[0],
      };
    dispatch({ type: ACTION_TYPES.BUSINESS_SUCCESS, payload });
    // dispatch(Notify());
    console.log(payload);
    return new Promise((res) => res(business && business[0]));
  } catch (error) {
    console.log("addGift error ", error);
    dispatch({ type: ACTION_TYPES.BUSINESS_FAILED });
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
    return new Promise((res) => res(null));
  }
};

//updateCategory
//This option is for vendor to update category
export const updateCategory = (category) => async (dispatch, getState) => {
  try {
    const { uid = "" } = getState().user?.user;
    dispatch({ type: ACTION_TYPES.BUSINESS_ADD_REQUEST });

    const business = getState().vendor?.business || {};
    const upateCategory = category.map((ct) => ct?.id);

    const snap = await firestore
      .collection("venders")
      .where("ownerId", "==", uid)
      .get();
    const fetchedData = await snap.docs.map((doc) => doc.data());

    await updateOne("venders", business.id, {
      ...fetchedData[0],
      categories: upateCategory,
    });
    dispatch(fetchVendorBusiness());
    dispatch({ type: ACTION_TYPES.BUSINESS_ADD_COMPLETE });
    dispatch(
      createAlert({
        message: success.businessUpdated,
        type: "success",
      })
    );
    dispatch(fetchVendorBusiness());
    history.push("/businessdetails");
  } catch (error) {
    console.log("vendor error ", error);
    dispatch({ type: ACTION_TYPES.BUSINESS_ADD_FAILED });
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
  }
};

//add business
export const addBusiness =
  ({ images = [], deleteImg = [], ...data }) =>
  async (dispatch, getState) => {
    try {
      const { uid = "" } = getState().user?.user;
      const { id = null } = getState().vendor?.business || {};
      dispatch({ type: ACTION_TYPES.BUSINESS_ADD_REQUEST });
      const imgs = await filterImages(images, deleteImg);
      delete data.imagesRef;
      delete data.themeImgref;
      delete data.imgRef;
      if (id)
        await updateOne("venders", id, {
          ...data,
          images: imgs,
          ownerId: uid,
          keywords: data.business_name.toLowerCase().split(" "),
        });
      else
        await insert("venders", {
          ...data,
          images: imgs,
          ownerId: uid,
          keywords: data.business_name.toLowerCase().split(" "),
        });
      dispatch(fetchVendorBusiness());
      dispatch({ type: ACTION_TYPES.BUSINESS_ADD_COMPLETE });
      dispatch(
        createAlert({
          message: id ? success.businessUpdated : success.businessAdded,
          type: "success",
        })
      );
      dispatch(fetchVendorBusiness());
      if (!id) history.push("/vendor");
    } catch (error) {
      console.log("vendor error ", error);
      dispatch({ type: ACTION_TYPES.BUSINESS_ADD_FAILED });
      dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
    }
  };

const userImage = (payload, events) => {
  return new Promise(async (resolve, reject) => {
    const ids = events.map((id) => id.owners);
    const userSnap = await firestore
      .collection("users")
      .where("userId", "in", ids)
      .get();
    const users = userSnap.docs.map((user) => user.data());
    console.log(users);

    const eventsResults = payload.map((booked, index) => {
      const event = events.find((event) => event.id === booked.event_id);
      const user = users.find((prof) => prof.userId === event.owners);
      event.user = user;
      booked.event = event;
      return booked;
    });

    console.log(eventsResults);
    resolve(eventsResults);
  });
};
const getEvents = (temp)=>{
  return new Promise(async(resolve, reject)=>{
    try {
      const eventsSnap = await firestore
      .collection("events")
      .where("id","in",temp)
      .get();
      const events = eventsSnap.docs.map((event) => event.data());
      resolve(events); 
    } catch (error) {
      console.log("getEvents",error);
      reject(error)
    }
  })
}

// const userImage = (payload, temp) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const events = await getEvents(temp);
//     const ids = events.map((id)
//  => id.owners);
//     const userSnap = await firestore
//       .collection("users")
//       .where("userId", "in", ids)
//       .get();
//     const users = userSnap.docs.map((user) => user.data());
//     console.log(users);

//     const eventsResults = payload.map((booked) => {
//       const event = events.find((event) => event.id === booked.event_id);
//       const user = users.find((prof) => prof.userId === event.owners);
//       event.user = user;
//       booked.event = event;
//       return booked;
//     });

//     console.log(eventsResults);
//     resolve(await Promise.all(eventsResults));
//     } catch (error) {
//       console.log(error);
//     }
//   });
// };

const getUsers = (temp) => {
  return new Promise(async (resolve, reject) => {
    try {
    const userSnap = await firestore
      .collection("users")
      .where("userId", "in", temp)
      .get();
    const users = userSnap.docs.map((user) => user.data());
    resolve(await Promise.all(users));
    } catch (error) {
      console.log(error);
    }
  });
};

const getBookingEvents = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let events = [];
      let users = [];
      let temp = [];

      for (let i of payload) {
        temp.push(i.event_id);
        if (temp.length === 10) {
          console.log(temp);
          events.push(...(await getEvents(temp)));
          temp = [];
        }
      }

      if (temp.length) {
        events.push(...(await getEvents(temp)));
        temp = [];
      }

      for (let i of events) {
        temp.push(i.owners);
        if (temp.length === 10) {
          console.log(temp);
          users.push(...(await getUsers(temp)));
          temp = [];
        }
      }

      if (temp.length) {
        users.push(...(await getUsers(temp)));
        temp = [];
      }

      const eventsResults = payload.map((booked) => {
        const event = events.find((event) => event.id === booked.event_id);
        const user = users.find((prof) => prof.userId === event.owners);
        event.user = user;
        booked.event = event;
        return booked;
      });
      resolve(eventsResults);
    } catch (error) {
      reject(error);
    }
  });
};


export const getBooking = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.VENDOR_BOOKING_REQUEST });
    const { id = "" } = getState().vendor?.business ;
    firestore
      .collection("proposals")
      .where("business_id", "==", id)
      .where("isBooked", "==", true)
      .onSnapshot(async (snap) => {
        //payload booked proposals
        const payload = snap.docs.map((quote) => quote.data());
        console.log("payload", payload);
        if (payload && payload.length) {
          const data = await getBookingEvents(payload);
          console.log("propso  okkinggg  ", payload, data);
          dispatch({
            type: ACTION_TYPES.VENDOR_BOOKING_SUCCESS,
            payload: data,
          });
        } else
          dispatch({ type: ACTION_TYPES.VENDOR_BOOKING_SUCCESS, payload: [] });
      });
    setTimeout(() => {
      dispatch({ type: ACTION_TYPES.VENDOR_BOOKING_COMPLETE });
    }, 6000);
  } catch (error) {
    console.log("business catche error ", error);
    dispatch({ type: ACTION_TYPES.VENDOR_BOOKING_FAILED });
  }
};

const getData = (collection, field, arr) => {
  return new Promise(async (resolve, reject) => {
    const snap = await firestore
      .collection(collection)
      .where(field, "in", arr)
      .get();
    let users = snap.docs.map((it) => it.data());

    resolve(users);
  });
};

const fetchUserProfile = (proposals, type) => {
  return new Promise(async (resolve, reject) => {
    try {
      let profiles = [];
      let temp = [];
      await Promise.all(
        proposals.map(async (it, index) => {
          if (
            type === 3
              ? !temp.includes(it.user_id)
              : !temp.includes(it.business_id)
          ) {
            type === 3 ? temp.push(it.user_id) : temp.push(it.business_id);
          }

          if (proposals.length - 1 === index || temp.length === 10) {
            if (type === 1) {
              let users = [];
              if (temp.length < 11)
                users = await getData("venders", "id", temp);
              console.log("type == 1", users);
              profiles.push(...users);
            } else {
              let users = [];
              if (temp.length < 11)
                users = await getData("users", "userId", temp);
              profiles.push(...users);
            }
            temp = [];
          }
        })
      );
      resolve(profiles);
    } catch (error) {
      reject(error);
    }
  });
};

//sort and group by vendor and category
//sort and group by event and category
const call = (proposals, type) => {
  return new Promise(async (resolve, reject) => {
    proposals.sort((a, b) => b.modifiedAt - a.modifiedAt);
    const users = await fetchUserProfile(proposals, type);
    console.log("log", users, users.length);

    const newArr = [];
    proposals.forEach(async (item, index) => {
      if (newArr.length === 0) {
        newArr.push({
          id: item?.id,
          business_id: item.business_id,
          category_id: item.category_id,
          message: item.message,
          modifiedAt: item.modifiedAt,
          event_id: item.event_id,
          name: item.name,
          user_id: item.user_id,
          proposals: [item],
          user: users.find((it) =>
            type === 1 ? it.id === item.business_id : it.userId === item.user_id
          ),
        });
        return;
      }
      let len = newArr.length;
      let flag = false;
      for (let i = 0; i < len; i++) {
        if (
          type === 1 &&
          newArr[i].business_id === item.business_id &&
          newArr[i].category_id === item.category_id
        ) {
          newArr[i].proposals.push(item);
          newArr[i].message = item.message;
          if (newArr[i].modifiedAt < item.modifiedAt) {
            newArr[i].modifiedAt = item.modifiedAt;
            newArr[i].message = item.message;
          }
          newArr[i].name =
            item.isProposal || item.isBooked ? item.name : newArr[i].name;
          flag = true;
          break;
        }
        if (
          type === 3 &&
          newArr[i].event_id === item.event_id &&
          newArr[i].category_id === item.category_id
        ) {
          newArr[i].proposals.push(item);
          if (newArr[i].modifiedAt < item.modifiedAt) {
            newArr[i].modifiedAt = item.modifiedAt;
            newArr[i].message = item.message;
          }
          // item.user = users.find((it) => it.userId === item.user_id);
          newArr[i].name = item.isQuote ? item.name : newArr[i].name;
          flag = true;
          newArr[i].proposals.sort((a, b) => b.modifiedAt - a.modifiedAt);
          break;
        }
      }
      if (!flag) {
        newArr.push({
          id: item?.id,
          business_id: item.business_id,
          event_id: item.event_id,
          category_id: item.category_id,
          message: item.message,
          modifiedAt: item.modifiedAt,
          name: item.name,
          user_id: item.user_id,
          proposals: [item],
          user: users.find((it) =>
            type === 1 ? it.id === item.business_id : it.userId === item.user_id
          ),
        });
      }
    });
    // newArr.sort((a, b) => a.modifiedAt - b.modifiedAt);
    resolve(newArr);
  });
};

export const getProposals = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.PROPOSAL_REQUEST });
    const business = getState().vendor?.business;
    const { type = "", uid = "" } = getState().user?.user;

    if (type === 3 && business && !business.id) return;
    firestore
      .collection("proposals")
      .where(
        type === 3 ? "business_id" : "user_id",
        "==",
        type === 3 ? business?.id : uid
      )
      .onSnapshot(async (snap) => {
        const payload = snap.docs.map((quote) => quote.data());
        console.log("before sorting", payload);
        if (payload && payload.length) {
          //*vendor_id and category_id
          console.log("fetch proposal", await call(payload, type));
          dispatch({
            type: ACTION_TYPES.PROPOSAL_SUCCESS,
            payload: await call(payload, type),
          });
        } else dispatch({ type: ACTION_TYPES.PROPOSAL_SUCCESS, payload: [] });
      });
    setTimeout(() => {
      dispatch({ type: ACTION_TYPES.PROPOSAL_COMPLETE });
    }, 6000);
  } catch (error) {
    console.log("business catche error ", error);
    dispatch({ type: ACTION_TYPES.PROPOSAL_FAILED });
  }
};
//proposal listing proposal/index
export const setProposal = (payload) => async (dispatch) => {
  dispatch({ type: ACTION_TYPES.SET_PROPOSAL, payload });
};

//proposal listing proposal/proposalDetail
export const setSelectedProposal = (payload) => async (dispatch) => {
  dispatch({ type: ACTION_TYPES.SET_SELECTED_PROPOSAL, payload });
};

//! Not required any more --> now we are combining proposal by (vender and category wise)/(event and category wise)
const checkPayload = (data) => {
  //*old Code
  let duplicates = [];
  data.forEach((el, i) => {
    //Execute only if
    //*isQuote===true --> from quote
    if (!el.isProposal && !el.isBooked) {
      data.forEach((element, index) => {
        let proposed = null;
        if (i === index) return null;
        if (
          element.event_id === el.event_id &&
          element.category_id === el.category_id &&
          element.business_id === el.business_id
        ) {
          if ((element.isProposal || element.isBooked) && !element.isQuote) {
            proposed = element;
            if (!duplicates.includes(el))
              duplicates = [...duplicates, { ...el, proposed }];
          }
        }
      });
    }
  });

  const single = data.filter((original) => {
    const ar = duplicates.map((d) => {
      if (
        original.event_id === d.event_id &&
        original.category_id === d.category_id &&
        original.business_id === d.business_id
      )
        return original.id;
      else return d.id;
    });
    if (ar && ar.length) return ar.indexOf(original.id) < 0;
  });
  const array = [...duplicates, ...single];
  return array && array.length
    ? array.sort((a, b) => a.createdAt < b.createdAt)
    : data;
};

export const getVendorList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.PROPOSAL_REQUEST });
    const { id = "" } = getState().vendor?.business;
    firestore
      .collection("proposals")
      .where("business_id", "==", id)
      .where("isBooked", "==", true)
      .onSnapshot(async (snap) => {
        const payload = snap.docs.map((b) => b.data());
        console.log("booking >>> ", payload);
        dispatch({ type: ACTION_TYPES.PROPOSAL_COMPLETE });
        return new Promise((r) => r(payload));
      });
  } catch (error) {
    dispatch({ type: ACTION_TYPES.PROPOSAL_FAILED });
    return new Promise((r) => r(null));
  }
};

export const addReview =
  (rating, reviewText, oldId = null) =>
  async (dispatch, getState) => {
    try {
      const { id } = getState().event?.vendor || {};
      const { uid = "" } = getState().user?.user;
      const event = getState().event?.event;
      dispatch({ type: ACTION_TYPES.REVIEW_REQUEST });
      const data = {
        rating,
        reviewText,
        businessId: id,
        senderId: uid,
        eventId: event.id,
      };
      if (oldId) await updateOne("vendor_review", oldId, data);
      else await insert("vendor_review", data);
      dispatch({ type: ACTION_TYPES.REVIEW_SUCCESS });
      dispatch(
        createAlert({
          message: oldId ? success.ReviewUpdated : success.ReviewAdded,
          type: "success",
        })
      );
      return true;
    } catch (error) {
      dispatch({ type: ACTION_TYPES.REVIEW_FAILED });
      dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
      return false;
    }
  };

export const getReview = () => async (dispatch, getState) => {
  try {
    const { id } = getState().event?.vendor || {};
    const { uid = "" } = getState().user?.user;
    const event = getState().event?.event;
    dispatch({ type: ACTION_TYPES.REVIEW_REQUEST });
    const snap = await firestore
      .collection("vendor_review")
      .where("businessId", "==", id)
      .where("eventId", "==", event.id)
      .where("senderId", "==", uid)
      .get();
    const rev = await snap.docs.map((doc) => doc.data());
    dispatch({ type: ACTION_TYPES.REVIEW_SUCCESS });
    return rev[0];
  } catch (error) {
    dispatch({ type: ACTION_TYPES.REVIEW_FAILED });
    return null;
  }
};

export const fetchMyReviews = (bid) => async (dispatch, getState) => {
  try {
    let id = bid;
    dispatch({ type: ACTION_TYPES.REVIEW_REQUEST });
    const snap = await firestore
      .collection("vendor_review")
      .where("businessId", "==", id)
      .get();
    const rev = await snap.docs.map((doc) => doc.data());

    let payload = { reviews: [], avgRating: 0, totalReviews: 0 };
    let reviews = [];
    let users = [];
    let temp = [];
    if (rev.length) {
      const ids = rev.map((r) => r.senderId);
      for(let id of ids){
        temp.push(id);
        if(temp.length === 10){
          const us = await firestore
          .collection("users")
          .where("userId", "in", temp)
          .get();
         users.push(...us.docs.map((doc) => doc.data()))
         temp = [];
        }
      }

      if(temp.length){
        const us = await firestore
        .collection("users")
        .where("userId", "in", temp)
        .get();
        users.push(...us.docs.map((doc) => doc.data()))       
      }

      console.log("users", users);

      //users = [{...u,id: A},{...u,id: B}] and r = {...r,senderId: A}
      reviews = rev.map((r) => ({
        ...r,
        user: users.filter((u) => u.userId === r.senderId),
      }));
      let count = 0.0;
      reviews.forEach((e) => {
        count += e.rating;
      });
      const avgRating = count / reviews.length;
      const totalReviews = reviews.length;
      payload = { reviews, avgRating, totalReviews };
    }
    dispatch({
      type: ACTION_TYPES.REVIEW_SUCCESS,
      payload: payload,
    });
    return reviews;
  } catch (error) {
    dispatch({ type: ACTION_TYPES.REVIEW_FAILED });
    return null;
  }
};

export const fetchVenderRatings = (bid) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.RATING_REQUEST });
    const snap = await firestore
      .collection("vendor_review")
      .where("businessId", "==", bid)
      .get();
    const rev = await snap.docs.map((doc) => doc.data());

    let count = 0.0;
    rev.forEach((user) => {
      count += user.rating;
    });

    const payload = {
      reviews: rev,
      avgRating: count / rev.length,
      totalReviews: rev.length,
    };

    dispatch({
      type: ACTION_TYPES.REVIEW_SUCCESS,
      payload: payload,
    });
  } catch (error) {
    dispatch({ type: ACTION_TYPES.REVIEW_FAILED });
    return null;
  }
};
