import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Rating from "@material-ui/lab/Rating";
import { Typography, Grid, Box } from "@material-ui/core";
import {
  eventStyle,
  commonStyle,
  primaryLoaderStyle,
  btSmallIcon,
} from "styles";
import { strings,ACTION_TYPES } from "constant";
import Carousel from "react-material-ui-carousel";
import {
  BreadCrumb,
  Loader,
  QuoteModal,
  ReviewModal,
  Chat as ChatComponent,
} from "components";
import { Reviews } from "containers";
import {useHistory} from "react-router-dom"

import { Chat, Assignment } from "@material-ui/icons";
import { setChat, fetchVenderRatings,fetchVenderReviews,clearVendor } from "actions";
const { events, common } = strings;

export default (props) => {
  const classes = eventStyle();
  const commClasses = commonStyle();
  const [chtprop, setChatt] = useState({
    title: "",
    subTitle: "",
    visible: null,
  });
  const history = useHistory();

  const [quote, showQuote] = useState(false);
  const [review, showReview] = useState(false);

  const [booked, updateBooked] = useState(
    props?.location?.state ? props?.location?.state?.booked : false
  );
  const breads = [
    { title: common.Home, path: "/" },
    { title: events.WeddingEvent, path: "/eventdetail" },
    { title: common.Category, path: "/category" },
    { title: events.Vendors, path: "/eventvendorlist" },
  ];
  if (props?.location?.state?.from === "eventDetail") {
    breads.splice(1, 3);
  }

  const dispatch = useDispatch();
  const {
    vendor = {},
    eloader = false,
    reviews = {},
  } = useSelector(({ event }) => event);
  console.log(vendor);

  const setVisible = (type) =>
    setChatt({
      ...chtprop,
      visible: type,
      title: vendor?.business_name,
      subTitle: "",
    });
  console.log("Vendor", vendor, reviews);
  const { uid } = useSelector(({ user }) => user.user);
  const { categories = [] } = useSelector(({ app }) => app);
  console.log(categories);
  useEffect(() => {
    dispatch({type: ACTION_TYPES.CHAT_SUCCESS,chats:[]});
    // setTimeout(() => {
    //   if (!vendor || (vendor && !vendor.id) || !vendor.id)
    //     props.history.push("/");
    // }, 2000);

    const user = {
      business_id: vendor?.ownerId,
      id: `${vendor?.ownerId}_${uid}`,
      id2: `${uid}_${vendor?.ownerId}`,
      user: { name: vendor?.name },
    };
    dispatch(setChat(user));
  }, []);
  useEffect(() => {
    dispatch({type: ACTION_TYPES.CHAT_SUCCESS,chats:[]});
    const fetchReview = async () => {
      await dispatch(fetchVenderRatings(vendor?.id));
    };
    eloader && fetchReview();
    const user = {
      business_id: vendor?.ownerId,
      id: `${vendor?.ownerId}_${uid}`,
      id2: `${uid}_${vendor?.ownerId}`,
      user: { name: vendor?.name },
    };
    dispatch(setChat(user));
  }, [JSON.stringify(vendor)]);
  console.log("props.locations", props.location);

  const handleChatOpen = () => {
    setChatt({ ...chtprop, visible: "active" });
  };

  const handleQuaoteReview = () => {
    if (booked) {
      showReview(true);
      showQuote(false);
    } else {
      showQuote(true);
      showReview(false);
    }
  };
  const showReviews = ()=>{
    //*Next milestone just unComment
    // console.log(reviews,vendor);
    if(reviews?.totalReviews > 0) {
      dispatch(fetchVenderReviews(reviews.reviews));
      props.history.push("/reviews")
    }
  }
  return (
    <Grid container justifyContent="center" className={classes.eventMain}>
      <BreadCrumb breads={breads} current={events.VendorDetail} />
      <Typography component="div" className={classes.eventTV}>
        <Box fontFamily="CormorantBold" className={classes.eventT}>
          {events.VendorDetail}
        </Box>
      </Typography>
      {eloader ? (
        <Loader style={primaryLoaderStyle} time={5000}/>
      ) : (
        <div className={classes.eventVendorDetailMain}>
          <div className={classes.eventVendorInfoVendV}>
            <Grid container justifyContent="center">
              <Grid item sm={12} xs={10} md={12} className={classes.crImageV}>
                <Carousel
                  navButtonsAlwaysInvisible={false}
                  indicators={true}
                  autoPlay={true}
                  timeout={500}
                  animation="slide"
                >
                  <img
                    src={vendor?.images[0]}
                    className={classes.eventVendDetailsCRImge}
                    alt=""
                  />
                  {/* {vendor?.images &&
                    vendor?.images.length &&
                    vendor?.images.map((image, i) => (
                      <img
                        key={Math.random() + "image-vend-detail" + i}
                        src={image}
                        className={classes.eventVendDetailsCRImge}
                        alt=""
                      />
                    ))} */}
                </Carousel>
              </Grid>
              <Grid item sm={12} xs={12} md={8} className={classes.eventInfoGV}>
                <Typography
                  noWrap
                  component="div"
                  className={classes.eventInfoTV}
                >
                  <Box fontFamily="Gotham" className={classes.eventInfoHT}>
                    {events?.VendorName}
                  </Box>
                  <Box fontFamily="GothamBook" className={classes.eventInfoIT}>
                    {vendor ? vendor?.business_name : "Loading..."}
                  </Box>
                </Typography>
              </Grid>
              <Grid item sm={12} xs={12} md={4} className={classes.eventInfoGV}>
                <Typography
                  noWrap
                  component="div"
                  className={classes.eventInfoTV}
                  onClick={showReviews}
                >
                  <Box fontFamily="Gotham" className={classes.eventInfoHT}>
                    {common.Rating}
                  </Box>
                  <Box
                    fontFamily="GothamBook"
                    className={classes.eventVendDetailHT}
                  >
                    <Rating
                      value={
                        reviews && reviews?.avgRating ? reviews?.avgRating : 0
                      }
                      size="small"
                      precision={0.5}
                      readOnly
                    />
                    {reviews && reviews?.avgRating ? reviews?.avgRating : 0}
                    <div className={classes.smallDot} />
                    {reviews && reviews?.totalReviews
                      ? reviews?.totalReviews + " Reviews"
                      : 0}
                  </Box>
                </Typography>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                className={classes.eventInfoGV}
              >
                <Typography
                  noWrap
                  component="div"
                  className={classes.eventInfoTV}
                >
                  <Box fontFamily="Gotham" className={classes.eventInfoHT}>
                    {common.Address}
                  </Box>
                  <Box fontFamily="GothamBook" className={classes.eventInfoIT}>
                    {`${vendor?.city ? vendor?.city: "city..."} ${vendor?.state ? vendor?.state: "state..."}, ${vendor?.zip_code ? vendor?.zip_code: "zip code..."}`}
                  </Box>
                </Typography>
              </Grid>
              <div className={commClasses.hairline} />
              <Grid item sm={12} xs={12} md={12}>
                <Typography component="div" className={classes.eventSubV}>
                  <Box fontFamily="CormorantBold" className={classes.eventSubT}>
                    {events.VendingServicesOffered}
                  </Box>
                </Typography>

                <div className={classes.vendorCategoryV}>
                  {categories
                    .filter(
                      (it) =>
                        vendor?.categories &&
                        vendor?.categories.length &&
                        vendor?.categories.includes(it.id)
                    )
                    .map((item, index) => (
                      <div
                        className={classes.eventVendorsV}
                        key={
                          Math.round() + "-" + item.title + index + "vend-round"
                        }
                      >
                        <img
                          src={item.icon}
                          className={classes.vendorImg}
                          alt=""
                        />
                        <Box
                          fontFamily="Gotham"
                          className={classes.vendorImgAlt}
                        >
                          {item.name}
                        </Box>
                      </div>
                    ))}
                </div>
              </Grid>
              <div className={commClasses.hairline} />
              <Grid
                item
                sm={12}
                xs={12}
                md={5}
                className={classes.vendorDetailBLV}
              >
                <div className={classes.chatButtonV} onClick={handleChatOpen}>
                  <Box
                    fontFamily="GothamBook"
                    className={classes.vendorDetButT}
                  >
                    <Chat style={btSmallIcon} />
                    {common.Chat}
                  </Box>
                </div>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                className={classes.vendorDetailBRV}
              >
                <div
                  className={classes.chatButtonV}
                  onClick={handleQuaoteReview}
                >
                  <Box
                    fontFamily="GothamBook"
                    className={classes.vendorDetButT}
                  >
                    <Assignment style={btSmallIcon} />
                    {booked ? common.WriteReview : common.GetAQuote}
                  </Box>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
      {quote && <QuoteModal modal={quote} onClose={() => showQuote(false)} />}
      {chtprop.visible === "active" && (
        <ChatComponent {...chtprop} setVisible={setVisible} />
      )}
      {review && (
        <ReviewModal
          modal={review}
          onClose={() => showReview(false)}
          vendor={vendor}
        />
      )}
    </Grid>
  );
};
