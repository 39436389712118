import React, { useState } from "react";
import { Typography, Grid, Box, Button } from "@material-ui/core";
import { eventStyle, commonButtonStyle } from "styles";
import { TextField, GooglePlaces, TextArea, Loader } from "components";
import { strings, giftTErrors } from "constant";
import { addGift, updateGift } from "actions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const { gift, common } = strings;

export default (props) => {
  const classes = eventStyle();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    gift_title = "",
    gift_description = "",
    quantity = 1,
    delivery_address = "",
  } = location.state.edit && location.state.data;
  console.log(location.state.data);

  const { loader = false } = useSelector(({ gift }) => gift);
  const [state, setState] = useState({
    cashPayment: false,
    gift_title,
    gift_description,
    delivery_address,
    quantity,
    selected_by: [],
  });
  console.log(state);
  const [error, setError] = useState({
    gift_title: "",
    gift_description: "",
    delivery_address: "",
    quantity: "",
  });
  const submit = () => {
    if (
      state.gift_title &&
      state.gift_description &&
      state.delivery_address &&
      typeof state.quantity === "number" &&
      state.quantity > 0
    ) {
      if (!location.state.edit) dispatch(addGift(state));
      else {
        const id = location.state.data.id;
        const oldData = location.state.data;
        const updatedData = { ...oldData, ...state };
        dispatch(updateGift({ id, ...updatedData }, 1, 1));
      }
    } else makeErrors();
  };
  const makeErrors = () => {
    let errors = {};
    ["gift_title", "gift_description", "delivery_address", "quantity"].forEach(
      (item) =>
        (errors = { ...errors, [item]: state[item] ? "" : giftTErrors[item] })
    );
    setError(errors);
  };
  const onChange = (k, v) => {
    setState({ ...state, [k]: v });
    if (
      (!v && (k === "gift_title" || k === "gift_description")) ||
      (k === "quantity" && v < 1)
    )
      setError({ ...error, [k]: giftTErrors[k] });
    else setError({ ...error, [k]: "" });
  };
  return (
    <Grid container className={classes.eventMain}>
      <Typography component="div" className={classes.eventTV}>
        <Box fontFamily="CormorantBold" className={classes.eventT}>
          {gift.AddNewGift}
        </Box>
      </Typography>
      <form className={classes.addNewEventFormV} noValidate autoComplete="off">
        <Grid container>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={classes.addNewEventFormGV}
          >
            <TextField
              error={error.gift_title}
              label={gift.GiftTitle}
              onChange={(v) => onChange("gift_title", v)}
              value={state.gift_title}
              maxLength={100}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={classes.addNewEventFormGV}
          >
            <TextField
              label={common.Quantity}
              error={error.quantity}
              onChange={(v) => onChange("quantity", Number(v))}
              value={state.quantity}
              type="number"
              inputProps={{min:1}}
            />
          </Grid>

          <Grid item sm={12} xs={12} className={classes.addNewEventFormGV}>
            <TextArea
              label={common.Description}
              error={error.gift_description}
              onChange={(v) => onChange("gift_description", v)}
              value={state.gift_description}
            />
          </Grid>

          <Grid item sm={12} xs={12} className={classes.addNewEventFormGV}>
            {/* <TextArea label={common.Address} error={error.delivery_address} onChange={v=>onChange('delivery_address',v)} value={state.delivery_address}/> */}
            <GooglePlaces
              label={common.Address}
              error={error.delivery_address}
              onChange={(add) => onChange("delivery_address", add.address)}
              value={state.delivery_address}
            />
          </Grid>
          {/* <Grid item sm={12} xs={12} md={6} lg={6} className={classes.addNewEventFormGV}>
                        <TextField label={common.City} onChange={v=>onChange('city',v)} value={state.city}/>
                    </Grid>
                    <Grid item sm={12} xs={12} md={6} lg={6} className={classes.addNewEventFormGV}>
                        <DropDown label={common.State} onChange={v=>onChange('state',v)} value={state.state}/>
                    </Grid>

                    <Grid item sm={12} xs={12} md={6} lg={6} className={classes.addNewEventFormGV}>
                        <DropDown label={common.Country} onChange={v=>onChange('country',v)} value={state.country}/>
                    </Grid>
                    <Grid item sm={12} xs={12} md={6} lg={6} className={classes.addNewEventFormGV}>
                        <TextField label={common.Zipcode} onChange={v=>onChange('zip',v)} value={state.zip} />
                    </Grid>
                    <Grid item sm={12} xs={12} md={6} lg={6} className={classes.addNewEventFormGV}/> */}

          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={classes.addNewEventFormGBtV}
          >
            <Button
              disabled={loader}
              variant="contained"
              size="large"
              color="primary"
              style={commonButtonStyle}
              onClick={submit}
            >
              {loader ? (
                <Loader />
              ) : location.state.edit ? (
                common.Update
              ) : (
                common.Submit
              )}
            </Button>
            <Button
              disabled={loader}
              variant="contained"
              size="large"
              style={commonButtonStyle}
              onClick={() => props.history.goBack()}
            >
              {common.Cancel}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};
