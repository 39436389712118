import { makeStyles } from "@material-ui/styles";
import { colors } from "constant";

const center = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
export const galleryStyle = makeStyles((theme) => ({
  container: {
    paddingTop: 80,
    paddingBottom: 80,
    // ...center,
    // justifyContent: 'flex-start',
    [theme.breakpoints.down("md")]: {
      paddingTop: 90,
      paddingBottom: 90,
    },
  },
  main: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  fileMenu: {
    // display: 'flex',
    // alignItems: 'center'
    // ...center
  },
  buttonV: {
    padding: 15,
    paddingTop: 5,
  },
  
  imagesSV: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },

  imagesThumb: {
    height: 200,
    width: 250,
    marginTop: 20,
    borderRadius: 5,
    cursor: "pointer",
  },
  gallery: {
    display: "flex",
    flexDirection: "column",
    margin: "20px",
    padding: "10px",
    boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.1)",
    "&:hover": {
      boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    },
    borderRadius: "5px",
  },
  lAc: {
    display: "flex",
    justifyContent: "space-between",
    // border: "1px solid rgb(0, 0, 0)",
  },
  icon: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    // width: "50%",
  },
  uploadBtn:{
    // margin: 10,
    width: 150,
    height: 54,
    marginTop:5,
    [theme.breakpoints.down("md")]:{
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
   }
  },
  uploadBttn:{
    display: "flex",
    [theme.breakpoints.down("md")]:{
      display: "flex",
      justifyContent: "center",
    }
  },
  img:{
    height: 80,
    width: 80,
    borderRadius: 2,
    display: "flex",
    justifyContent: "center",
  }

}),{index:1});
