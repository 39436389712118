import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid, Box, Button } from "@material-ui/core";
import { eventStyle, commonButtonStyle,accountStyle, primaryLoaderStyle } from "styles";
import {
  TextField,
  DatePicker,
  DropDown,
  Radio,
  FilePicker,
  BreadCrumb,
  Loader,
  GooglePlaces,
  SearchLocation,
  PhoneInput,
} from "components";
import { validator } from "helpers";
import { Room, AddLocation } from "@material-ui/icons";
import { strings, country, guests } from "constant";
import { addEvent, fetchEvent } from "actions";
import Ava from "../../components/common/Avatar";
const { events, common } = strings;

const initialState = {
  event_name: "",
  event_date: new Date().getTime(),
  spouse_name: "",
  spouse_email: "",
  spouse_phone: "",
  event_location: "",
  event_type: 1,
  function_type: 1,
  createdAt: new Date().getTime(),
  modifiedAt: new Date().getTime(),
  deleted: false,
  guest_count: "",
  images:"",
  // theme: "",
  latitude: "",
  longitude: "",
  spouseCountryCode: "",
};

export default (props) => {
  const classes = eventStyle();
  const classess = accountStyle();
  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();
  const { loader = false, _loader = false } = useSelector(({ event }) => event);
  const {
    user: { type },
  } = useSelector(({ user }) => user);
  const breads = [{ title: common.Home, path: "/" }];

  const [openMap, setOpenMap] = useState(false);

  const [locationData, setLocationData] = useState({
    address: "",
    coords: { latitude: "", longitude: "" },
  });

  const changeState = useCallback(
    (key, value) => {
      if(key==="event_date") setState({...state, [key]: value.getTime()});
      else setState({...state, [key]:value})

      //Error
      let k = key;
      if(key==="spouse_email"){
        k="email"
      }
      setError({ ...error, [key]: validator(k, value) ? "" : errors[key] })
      },

    [state]
  );

  const makeErrors = () => {
    let err = {};
    const er = Object.keys(state).map((key)=> {
      console.log(key, state[key]);
      if(key ==="deleted") return true;
      else{
        const status = validator(key, state[key]);
        err = { ...err, [key]: status ? "" : errors[key] };
        return status;
      }
      
    });
    setError({ ...error, ...err });
    return er;
  };
  const [error, setError] = useState({
    event_date:"",
    event_name:"",
    spouse_name:"",
    spouse_email:"",
    event_location:"",
    event_type:"",
    function_type:"",
    createdAt:"",
    modifiedAt:"",
    guest_count:"",
    images:"",
    spouse_phone:""
  });

  const errors = {
    event_name:strings.errors.event_name,
    event_date: strings.errors.event_date,
    spouse_name: strings.errors.spouse_name,
    event_location: strings.errors.event_location,
    event_type: strings.errors.event_type,
    function_type: strings.errors.function_type,
    createdAt: strings.errors.createdAt,
    modifiedAt: strings.errors.modifiedAt,
    deleted: strings.errors.deleted,
    guest_count: strings.errors.guest_count,
    spouse_email:strings.errors.email,
    spouse_phone:strings.errors.phone,
    images: strings.errors.image
  };

  const onSubmit = () => {
    const errs = makeErrors();
    console.log("erro", errs)
    if (errs && errs.filter((er) => !er).length) return;
    else{
      // console.log(state);
      dispatch(addEvent(state));
    }
  };

  let val;

  const onChangeValue = () => {
    val = type;
  };

  const fun = useCallback(() => {
    onChangeValue();
  }, [type]);

  useEffect(() => {
    const get = async () => {
      if (type === 3) return props.history.push("/vendor");
      const event = await dispatch(fetchEvent());
      console.log("event event ", event);
      if (event) props.history.push("/");
    };
    get();
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        console.log("pos ", coords);
      },
      (error) => {
        console.log("position error ", error);
      }
    );
  }, [val]);

  const handleAddress = (add) => {
    const str = ","
    console.log(add);
    const address = add.address
    ?`${add.name ? add?.name+str :""} ${add.route ?add.route+str:""} ${add?.city ? add?.city+str:""} ${add?.state?add?.state+str:""} ${add.country ? add?.country:""}`
    : "";
    setState({
      ...state,
      event_location: address,
      latitude: add.latitude,
      longitude: add.longitude,
    });
    setLocationData({
      address: address,
      coords: { latitude: add.latitude, longitude: add.longitude },
    });
    setError({ ...error, event_location: validator("event_location",address)? "":errors["event_location"] });
  };

  useEffect(() => {
    setState({
      ...state,
      event_location: locationData.address,
      latitude: locationData.coords.latitude,
      longitude: locationData.coords.longitude,
    });
  }, [JSON.stringify(locationData)]);

  const onChange = useCallback(
    (key, value) => {
      setState({ ...state, [key]: value });
    },
    [state]
  );

  const handlePhoneInput = (phone, spouseCountryCode) => {
    setState({ ...state,spouse_phone: phone, spouseCountryCode });
    setError({ ...error, spouse_phone: validator("phone", phone) ? "" : errors["spouse_phone"] });
  };

  return (
    <Grid container className={classes.eventMain}>
      <BreadCrumb breads={breads} current={events.WeddingEvent} />
      <Typography component="div" className={classes.eventTV}>
        <Box fontFamily="CormorantBold" className={classes.eventT}>
          {events.NewWeddingEvent}
        </Box>
      </Typography>
      {loader ? (
        <Loader />
      ) : (
        <form
          className={classes.addNewEventFormV}
          noValidate
          autoComplete="off"
        >
          <Grid container>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={classes.addNewEventFormGV}
            >
              <TextField
                label={"Event Title"}
                error={error.event_name}
                onChange={(v) => changeState("event_name", v)}
                value={state.event_name}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={classes.addNewEventFormGV}
            >
              <TextField
                label={events.SpouseName}
                error= {error.spouse_name}
                value={state.spouse_name}
                onChange={(value) => changeState("spouse_name", value)}
              />
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={classes.addNewEventFormGV}
            >
              <TextField
                error={error.spouse_email}
                label={common.EmailAddress}
                onChange={(v) => changeState("spouse_email", v)}
                value={state.spouse_email}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={classess.personalDetailTV}
            >
              <PhoneInput
                label={common.Phone}
                error={error.spouse_phone}
                onChange={(v, code) => handlePhoneInput(v, code)}
                value={state.spouse_phone}
              />
            </Grid>
            <Grid item sm={12} xs={12} className={classes.addNewEventFormGV}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <GooglePlaces
                  error={error.event_location}
                  label={events.WeddingLocation}
                  value={state.event_location}
                  onChange={handleAddress}
                />
                <AddLocation onClick={() => setOpenMap((p) => !p)} />
              </div>
              {openMap && (
                <SearchLocation
                  locationData={locationData}
                  setLocationData={setLocationData}
                />
              )}
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={classes.addNewEventFormGV}
            >
              <DatePicker
                minDate={new Date()}
                label={events.WeddingDate}
                value={state.event_date}
                onChange={(value) => changeState("event_date", value)}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={classes.addNewEventFormGV}
            >
              <DropDown
                error={error.guest_count}
                label={events.GuestCount}
                data={guests}
                onChange={(v) => onChange("guest_count", v)}
                value={state.guest_count}
              />
              {/* <TextField
                label={events.GuestCount}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                value={state.guest_count}
                type="number"
                onChange={(value) => changeState("guest_count", value)}
              /> */}
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={classes.addNewEventFormGV}
            />
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={classes.addNewEventFormGV}
            >
              <FilePicker
                multiple
                label={common.Images}
                onImage={(value) => changeState("images", value)}
                error={error.images}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={classes.addNewEventFormGV}
            >
              <Radio
                label={events.EventType}
                value={state.event_type === 1 ? "private" : "public"}
                va="private"
                vb="public"
                onChange={(value) =>
                  onChange("event_type", value === "private" ? 1 : 2)
                }
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={classes.addNewEventFormGV}
            >
              <Radio
                label={events.WeddingFunction}
                value={state.function_type === 1 ? "traditional" : "family"}
                va="traditional"
                vb="family"
                onChange={(value) =>
                  onChange("function_type", value === "traditional" ? 1 : 2)
                }
              />
            </Grid>
            <Grid
              item
              sm={6}
              xs={6}
              md={6}
              lg={6}
              className={classes.addNewEventFormGBtV}
            >
              <Button
                variant="contained"
                disabled={_loader}
                size="large"
                color="primary"
                style={commonButtonStyle}
                onClick={onSubmit}
              >
                {_loader ? (
                  <Loader style={primaryLoaderStyle} size={15} />
                ) : (
                  common.Submit
                )}
              </Button>
              <Button
                variant="contained"
                size="large"
                style={commonButtonStyle}
                disabled={_loader}
                onClick={() => props.history.push("/")}
              >
                {common.Cancel}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Grid>
  );
};