import { makeStyles } from "@material-ui/core";
import { colors } from "constant";

const center = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const PaymentStyle = makeStyles((theme) => ({
  paymentContainer: {
    marginTop: 100,
  },
  paymentHeading: {
    fontSize: 36,
    color: colors.main,
    padding: 10,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
    },
  },
  walletAmount: {
    fontSize: 24,
    color: colors.main,
    padding: 10,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paymentList: {
    margin: "0 10%",
    [theme.breakpoints.down("sm")]: {
      margin: "0 5%",
    },
  },
}),{index:1});

const flexColumn = {
  display: "flex",
  flexDirection: "column",
};

export const PaymentListStyle = makeStyles((theme) => ({
  root: {
    margin: "20px 0 20px 0",
    width: "100%",
  },
  center: {
    ...center,
  },
  chips: {
    marginLeft: "5px",
  },

  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    color: colors.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  subHeading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    color: colors.main5,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  paymentHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  left: {
    ...flexColumn,
  },
  paymentStage: {
    ...flexColumn,
    width: "100%",
  },
  accordionDetails: {
    display: "flex",
    justifyContent: "space-between",
    padding: 10,
  },
  paymentButton: {
    color: "white",
    backgroundColor: colors.main,
    "&:hover": {
      backgroundColor: colors.main5,
    },
  },
}),{index:1});

export const payStyle = makeStyles((theme) => ({
  payContainer: {
    height: "400px",
    width: "70%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    padding: "10px",
  },
  payHeading: {
    fontSize: 36,
    color: colors.main,
    padding: 10,
    marginTop: "100px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
    },
  },
  walletAmount: {
    fontSize: 24,
    color: colors.main,
  },
  styling: {
    margin: "20px",
  },
}),{index:1});
