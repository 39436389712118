import React, { useState, useEffect } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { commonStyle } from "styles";

export default ({
  value = null,
  onChange = () => {},
  label,
  maxLength,
  error,
  secure = false,
  disabled = false,
  ...tprops
}) => {
  const classes = commonStyle();
  const [val, setValue] = useState("");
  useEffect(() => setValue(value), [value]);
  const onDateChange = (v) => {
    setValue(v);
    onChange(v);
  };
  // console.log("date",val);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.dateFieldsV}>
        {/* <Typography component={'span'}>
                    <Box className={classes.inputFLabelT}>{label}</Box>
                </Typography> */}
        <KeyboardDatePicker
          className={classes.inputFields}
          id={label}
          label={label}
          value={val}
          format="MM/dd/yyyy"
          variant="inline"
          inputVariant="outlined"
          {...tprops}
          onChange={onDateChange}
          height={60}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          InputProps={{ readOnly: disabled}}
          // TextFieldComponent={TextFieldComponent}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};
