import { makeStyles } from '@material-ui/styles';
import { colors } from 'constant';

const center = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}
export const guestStyle = makeStyles((theme) => ({
    guestMain :{
        padding : 50,
        paddingTop : 80,
        paddingBottom : 80,
        ...center,
        [theme.breakpoints.down("md")]: {
            // padding : 30
        }
    },
    headV:{
        ...center,
        width : '100%',
        position : 'relative'
    },
    spaceH20:{
        paddingLeft:10,
        paddingRight  : 10
    },
    addButtonV:{
        position : 'absolute',
        display : 'flex',
        right : 50,
        [theme.breakpoints.down("md")]: {
            // right:10,
            display : 'none'
        }
    },
    addButtonMenuV : {
        display : 'none',
        [theme.breakpoints.down("md")]: {
            display: 'block',
            right: 150,
        }
    },
    addButtonMenuVert : {
        // display : 'none',
        // display:"block",
        position : 'absolute',
        top:"15%",
        left:"20%",
        [theme.breakpoints.down("md")]: {
            display: 'block',
            right: 150,
        }
    },
    
    giftHeadingT:{
        fontSize : 35,
        color : colors.main,
        padding:10,
        [theme.breakpoints.down("md")]: {
            fontSize : 16,
        }
    },
    listV:{
        background : colors.main4,
        width : '70%',
        borderRadius : 5,
        padding : 20,
    },
    listHT : {
        fontSize : 16,
        display : 'flex',
        paddingBottom : 5,
        alignItems : 'center',
        [theme.breakpoints.down("md")]: {
            fontSize : 11
        }
    },
    guestListV:{
        ...center,
        justifyContent: 'flex-start',
        cursor : 'pointer',
        paddingTop : 5,
        // paddingBottom : 5
    },
    guestNameT:{
        fontSize : 14,
        paddingLeft : 10,
        display : 'flex',
        alignItems : 'center',
        width : '100%',
        [theme.breakpoints.down("md")]: {
            fontSize : 10
        }
    },
    guestInviteButtonV:{
        width : '100%',
        padding : 20,
        minHeight : 100,
        ...center
    },
    groupActionIconsV : {
        display : 'flex',
        flex:1,
        justifyContent: 'flex-end',
    },
    loaderV:{
        minHeight:400,
        ...center
    },
    guestActionButtonsV:{
        ...center
    },
    EditMenu:{
        position:"absolute", 
        top:"15%", 
        left:"28%",
        [theme.breakpoints.down("md")]:{
            position:"absolute",
            top:"10%",
            left:"20%"
        }
    }

}),{index:1})