import React, { useState, useEffect } from "react";
import { Typography, Box, TextField, FormHelperText } from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import { commonStyle } from "styles";
import { colors } from "constant";

export default ({
  value = "",
  onChange = () => {},
  label,
  maxLength = 100,
  error,
  secure = false,
  disabled,
  ...tprops
}) => {
  const classes = commonStyle();
  const [val, setValue] = useState("");
  useEffect(() => setValue(value), [value]);
  const onTextChange = (no, country) => {
    console.log("code", no, country);
    onChange(no, country)
  };
  
  return (
    <div
      className={
        !!error ? classes.phoneInputFieldVErr : classes.phoneInputFieldV
      }
    >
      <PhoneInput
        label={label}
        color={colors.primary}
        inputClass={
          !!error ? classes.phoneInputFieldEr : classes.phoneInputField
        }
        // onlyCountries={['us', 'es']}
        enableSearch={true}
        error={error}
        country={"us"}
        value={val}
        onChange={(value, country) => onTextChange(value, country.dialCode)}
        inputProps={
          { readOnly: disabled }
        }
      />
      {error && (
        <FormHelperText className={classes.phoneInputError}>
          {error}
        </FormHelperText>
      )}
    </div>
  );
};
