import React, { useState } from "react";
import { accountStyle } from "styles";
import Sidemenu from "./sidemenu";
import PersonalDetail from "./personalDetail";
import WeddingDetail from "./weddingDetail";
import AccountManagement from "./accountManagement";
import EmailPreference from "./emailPreference";
import BusinessInformation from "./businessInformation";
import { useSelector } from "react-redux";

export default () => {
  const classes = accountStyle();
  const [selected, setSelected] = useState(0);
  const { user } = useSelector(({ user }) => user);
  let components;
  if (user && user.uid && user.type === 3)
    components = [
      (props) => <PersonalDetail {...props} />,
      //   (props) => <WeddingDetail {...props} />,
      (props) => <BusinessInformation {...props} />,
      (props) => <AccountManagement {...props} />,
      //   (props) => <EmailPreference {...props} />,
    ];
  else if (user && user.uid && user.type === 1)
    components = [
      (props) => <PersonalDetail {...props} />,
      (props) => <WeddingDetail {...props} />,
      (props) => <AccountManagement {...props} />,
      //   (props) => <EmailPreference {...props} />,
    ];
  else
    components = [
      (props) => <PersonalDetail {...props} />,
      (props) => <AccountManagement {...props} />,
    ];
  return (
    <div className={classes.accountMain}>
      <Sidemenu selected={selected} onSelect={setSelected} />
      {components[selected] && components[selected]({ setSelected })}
    </div>
  );
};
