import S3 from "react-aws-s3";
import { insert } from "helpers";

const {
  REACT_APP_S3_BUCKET_NAME,
  REACT_APP_S3_REGION,
  REACT_APP_S3_ACCESS_KEY,
  REACT_APP_S3_SECRET_KEY,
} = process.env;

export const s3Upload = async (
  images = [],
  Key = "event_gallery/",
  eventId,
  senderId,
  event_type
) => {
  const config = {
    bucketName: REACT_APP_S3_BUCKET_NAME,
    dirName: Key,
    region: REACT_APP_S3_REGION,
    accessKeyId: REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: REACT_APP_S3_SECRET_KEY,
    // s3Url: REACT_APP_S3_URL,
  };
  const ReactS3Client = new S3(config);
  try {
    const s3Res = await Promise.all(
      images.map(async (image) => {
        let type = image.type
          ? image.type.split("/")[0] === "image"
            ? 0
            : 1
          : 0;
        console.log("image", image);
        let path = await ReactS3Client.uploadFile(image, String(Date.now()));
        console.log("path=>", path);
        await insert("event_gallery", {
          path: path.location,
          type,
          eventId,
          senderId,
          likes: [],
          eventType: event_type,
        });
        console.log("path path path", path);
      })
    );
    console.log("s3Res s3Res ", s3Res, images);
    //return s3Res;
  } catch (error) {
    console.log(" s3 image upload catch error ", error);
  }
};

//UPLOADING PROFILE IMAGE
export const s3UploadPorfImg = async (image = "", Key, contentType = "") => {
  const config = {
    bucketName: REACT_APP_S3_BUCKET_NAME,
    dirName: Key,
    region: REACT_APP_S3_REGION,
    accessKeyId: REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: REACT_APP_S3_SECRET_KEY,
  };

  try {
    console.log("S3.js", image);
    const ReactS3Client = new S3(config);
    const path = await ReactS3Client.uploadFile(image, String(Date.now()));
    console.log(path);

    return path.location;
  } catch (err) {
    console.log("S3 profile image upload", err);
  }
};

export const uploadImagesS3 = async (images = "", Key, contentType = "") => {
  const config = {
    bucketName: REACT_APP_S3_BUCKET_NAME,
    dirName: Key,
    region: REACT_APP_S3_REGION,
    accessKeyId: REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: REACT_APP_S3_SECRET_KEY,
  };

  try {
    console.log("S3.js", images);
    const ReactS3Client = new S3(config);
    if (images.length > 0) {
      const path = images.map(
        async (img) => await ReactS3Client.uploadFile(img)
      );
      console.log(path);

      return await Promise.all(path);
    } else {
      const ReactS3Client = new S3(config);
      const path = await ReactS3Client.uploadFile(images, String(Date.now()));

      return path.location;
    }
  } catch (err) {
    console.log("S3 images upload", err.message?err.message:err);
  }
};

//*DELETE PROFILE IMAGE
export const s3Delete = async (filename = "", Key) => {
  const config = {
    bucketName: REACT_APP_S3_BUCKET_NAME,
    dirName: Key,
    region: REACT_APP_S3_REGION,
    accessKeyId: REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: REACT_APP_S3_SECRET_KEY,
  };

  try {
    console.log(filename, Key);
    const ReactS3Client = new S3(config);
    ReactS3Client.deleteFile(filename)
      .then((response) => console.log("response: ", response))
      .catch((err) => console.log(err));
  } catch (err) {
    console.log("S3 delete image upload", err);
  }
};
