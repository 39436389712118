import { makeStyles } from "@material-ui/styles";
import { colors } from "constant";

const center = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const headerStyle = makeStyles((theme) => ({
  headerView: {
    background: colors.white,
    display: "flex",
    justifyContent: "space-between",
  },
  toolbar: {
    background: colors.white,
    display: "flex",
    justifyContent: "space-between",
  },
  headerLinks: {
    fontFamily: "Gotham",
    fontSize: 13,
    cursor: "pointer",
    color: colors.dark,
    textTransform: "capitalize",
  },
  button: {
    height: 38,
    width: 100,
    margin: "0 20px",
    minWidth: "200px",
  },
  space20: {
    marginRight: "20px",
  },
  logoView: {
    width: "40%",
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  linksView: {
    width: "40%",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "10px",
    color: colors.dark,
    textTransform: "capitalize",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginRight: 0
    },
  },
  buttonView: {
    width: "15%",
    display: "flex",
    textAlign:"center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  list: {
    display: "flex",
    width: "100%",
  },
  widthp100: {
    width: "100%",
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    overflowX: "auto",
    [theme.breakpoints.down("md")]: {
      padding: "0px",
    },
  },
  drawerView: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      // display: "block",
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  fullList: {
    width: "auto",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  logo1: {
    width: 45,
    height: 40,
    [theme.breakpoints.down("md")]: {
      width: 30,
      height: 20,
    },
  },
  logo2: {
    width: 227,
    height: 33,
    marginTop: 10,
    [theme.breakpoints.down("md")]: {
      width: 100,
      height: 22,
      marginTop: 0,
    },
  },
  profileMenuV: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  profileMenuNameT: {
    fontSize: 13,
    color: colors.main,
    marginLeft: 10,
    marginRight: 10,
    textTransform: "capitalize",
  },
  popoverT: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 10,
    paddingBottom: 10,
    cursor: "pointer",
    fontSize: 13,
    borderBottom: `1px solid ${colors.grey7}`,
  },
  colorMain: {
    color: colors.main,
  },
  center: {
    ...center,
  },
  notifyIcon: {
    ...center,
    position: "relative",
    marginRight: 20,
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      justifyContent: "flex-end",
    },
  },
  notifyCount9: {
    position: "absolute",
    fontSize: 12,
    top: "10px",
    right: "10px",
    color: colors.main5,
    size: 4,
  },
  notifyCount10: {
    position: "absolute",
    fontSize: 12,
    top: "10px",
    right: "6px",
    color: colors.main5,
    size: 4,
  },
}),{index:1});
export const headerProfielIcon = {
  color: colors.main,
  fontSize: 16,
};
export const menuIcon = {
  color: colors.main,
  fontSize: 17,
};
export const popupMenuItems = {
  paddingLeft: 40,
  paddingRight: 40,
};
export const loggedInMenu = {
  top: "0 !important",
  right: "0 !important",
};
