import React, { useState, useEffect } from "react";
import { Grid, Box, Button, IconButton } from "@material-ui/core";
import {
  accountStyle,
  commonButtonStyle,
  primaryLoaderStyle,
  eventStyle,
} from "styles";
import { useSelector, useDispatch } from "react-redux";
import {
  TextField,
  FilePicker,
  DropDown,
  Loader,
  GooglePlaces,
  PhoneInput,
  SearchLocation,
} from "components";
import { ArrowDropDown, AddLocation } from "@material-ui/icons";
import Menus from "./popupMenu";
import BusinessStats from "./businessStats";
import { strings, country } from "constant";
import { addBusiness } from "actions";
import { validator } from "helpers";
import { useHistory } from "react-router-dom";
const { account, common, vendors } = strings;

export default (props) => {
  const classes = eventStyle();
  const acclasses = accountStyle();
  const dispatch = useDispatch();
  const { category = [] } = useSelector(({ event }) => event);
  const [menu, setMenu] = useState(null);
  const [openMap, setOpenMap] = useState(false);

  const history = useHistory();

  const {
    business = {},
    loader = false,
    _loader = false,
  } = useSelector(({ vendor }) => vendor);
  console.log(business);

  const {
    email = "",
    business_name = "",
    phone = "",
    city = "",
    zip_code = "",
    address = "",
    images = [],
    latitude,
    longitude,
    categories = [],
    countryCode = "",
  } = business ? business : {};

  const [locationData, setLocationData] = useState({
    address,
    city,
    state: business?.state,
    country: business?.country,
    zip_code,
    latitude,
    longitude,
  });

  const [state, setState] = useState({
    name: business_name,
    categories,
    email,
    phone,
    address,
    city,
    state: business?.state,
    country: business?.country,
    zip_code,
    latitude,
    longitude,
    images: images,
    countryCode,
  });

  const handleAddress = (format) => {
    console.log("format==>", format);

    let raw = {
      state: format.state,
      city: format.city,
      country: format.country,
      zip_code: format.zip,
      address: format.address,
    };    
    setState({
      ...state,
      ...raw,
      longitude: format.longitude,
      latitude: format.latitude,
    });
    setLocationData({
      ...raw,
      longitude: format.longitude,
      latitude: format.latitude,
    });
    const err = handleAddressErrs(format, raw);
    setError({ ...error,...err})
  };
  const handleAddressErrs = (raw) => {
    let err = {};
    ["state", "city", "country", "zip_code", "address"].forEach((k) => {
      err = { ...err, [k]: validator(k, raw[k]) ? "" : errors[k] };
    });
    return err;
  };

  useEffect(() => {
    setState({
      ...state,
      state: locationData.state,
      city: locationData.city,
      country: locationData.country,
      zip_code: locationData.zip_code,
      address: locationData.address,
      latitude: locationData.latitude,
      longitude: locationData.longitude,
    });
  }, [JSON.stringify(locationData)]);

  const handleMenuClick = (event) => {
    setMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setMenu(null);
  };

  const onMenu = (ind) => {
    props.setSelected(ind);
  };

  const [error, setError] = useState({
    name: "",
    email: "",
    city: "",
    state: "",
    phone: "",
    zip_code: "",
    country: "",
    address: "",
  });

  const errors = {
    name: strings.errors.guestName,
    email: strings.errors.email,
    city: strings.errors.city,
    state: strings.errors.state,
    country: strings.errors.country,
    phone: strings.errors.phone,
    zip_code: strings.errors.zip,
    address: strings.errors.address,
  };

  const onChange = (k, v) => {
    setState({ ...state, [k]: v });
    setError({ ...error, [k]: validator(k, v) ? "" : errors[k] });
  };

  const handlOnfiles = (images = []) => {
    setState({ ...state, images: images });
  };

  const makeErrors = () => {
    let err = {};
    const er = Object.keys(errors).map((key) => {
      const status = validator(key, state[key]);
      err = { ...err, [key]: status ? "" : errors[key] };
      return status;
    });
    setError({ ...error, ...err });
    return er;
  };
  const [deleteImg, setDeleteImg] = useState([]);
  const onDeleteImgs = (delImgs) => {
    console.log(deleteImg);
    setDeleteImg(delImgs);
  };

  const handleCancel = () => {
    setState({
      email,
      business_name,
      phone,
      city,
      zip_code,
      address,
      images,
      latitude,
      longitude,
      categories,
      countryCode,
    });
  };

  const handlePhoneInput = (value, code) => {
    setState({ ...state, phone: value, countryCode: code });
  };

  const onSubmit = () => {
    console.log(state);
    const { displayImages, ...data } = state;
    const errs = makeErrors();
    if (errs && errs.filter((er) => !er).length) return;
    else
      dispatch(
        addBusiness({
          ...data,
          id: business.id,
          categories:
            category && category.length > 0
              ? category.map((it) => it.id)
              : state.categories,
          deleteImg,
          business_name: state.name,
        })
      );
  };

  const handleOpenMap = ()=>{
    setOpenMap((p) => !p)
  }
  return (
    <div className={acclasses.renderMain}>
      <Box fontFamily="CormorantBold" className={acclasses.renderAcMainHeadT}>
        {account.BusinessInformation}
        <ArrowDropDown
          className={acclasses.dropIcon}
          onClick={handleMenuClick}
        />
        <Menus menu={menu} closeMenu={closeMenu} onMenu={onMenu} />
      </Box>
      <BusinessStats />
      {business.id ? (
        <div className={acclasses.myBusinessFormV}>
          <Grid container>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={classes.addNewEventFormGV}
            >
              <TextField
                label={vendors.BusinessName}
                error={error.name}
                value={state.name}
                onChange={(value) => onChange("name", value)}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={classes.addNewEventFormGV}
            >
              <PhoneInput
                label={common.Phone}
                value={state.phone}
                error={error.phone}
                onChange={(value, code) => handlePhoneInput(value, code)}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              className={classes.addNewEventFormGV}
            >
              <TextField
                label={common.EmailAddress}
                value={state.email}
                error={error.email}
                onChange={(value) => onChange("email", value)}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              className={classes.addNewEventFormGV}
            >
              <GooglePlaces
                label={common.Address}
                value={state.address}
                error={error.address}
                onChange={handleAddress}
                handleOpenMap={handleOpenMap}
                showMapIcon={true}
              />
              {openMap && (
                <SearchLocation
                  locationData={locationData}
                  setLocationData={setLocationData}
                  business={true}
                />
              )}
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={classes.addNewEventFormGV}
            >
              <TextField
                label={common.City}
                value={state.city}
                error={error.city}
                onChange={(value) => onChange("city", value)}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={classes.addNewEventFormGV}
            >
              <TextField
                label={common.State}
                value={state.state}
                error={error.state}
                onChange={(value) => onChange("state", value)}
              />
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={classes.addNewEventFormGV}
            >
              <DropDown
                data={country}
                label={common.Country}
                error={error.country}
                value={state.country}
                onChange={(value) => onChange("country", value)}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={classes.addNewEventFormGV}
            >
              <TextField
                label={common.Zipcode}
                value={state.zip_code}
                error={error.zip_code}
                onChange={(value) => onChange("zip_code", value)}
              />
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={classes.addNewEventFormGV}
            >
              <FilePicker
                multiple
                label={common.Images}
                value={state.images}
                imgs={state.images}
                onImage={handlOnfiles}
                onDeleteImgs={onDeleteImgs}
                // type={type}
              />
            </Grid>
            <Grid item sm={6} xs={6} md={6} lg={6} />

            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={classes.addNewEventFormGBtV}
            >
              <Button
                variant="contained"
                size="large"
                disabled={0}
                color="primary"
                style={commonButtonStyle}
                onClick={onSubmit}
              >
                {loader ? (
                  <Loader style={primaryLoaderStyle} size={15} />
                ) : (
                  common.Update
                )}
              </Button>
              <Button
                variant="contained"
                size="large"
                style={commonButtonStyle}
                disabled={_loader}
                onClick={handleCancel}
              >
                {common.Cancel}
              </Button>
            </Grid>
          </Grid>
        </div>
      ) : (
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={() => history.push("/vendor")}
          style={{ margin: 10 }}
        >
          Create Business
        </Button>
      )}
    </div>
  );
};
