import React, { useState,useRef } from "react";
import { useHistory } from "react-router-dom";

import {
  TextField,
  DatePicker,
  GooglePlaces,
  PhoneInput,
  DropDown,
} from "components";
import {
  eventStyle,
  accountStyle,
  commonButtonStyle,
  WalletStyle,
} from "styles";
import {
  Grid,
  Box,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  makeStyles,
  IconButton
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { createAccount } from "actions";
import { useDispatch } from "react-redux";
import { strings, country } from "constant";
import { validator } from "helpers";
const { common } = strings;

const useStyles = makeStyles(() => ({
  img: {
    height: 80,
    width: 80,
    borderRadius: 2,
    display: "flex",
    justifyContent: "center",
  },
}));

const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  gender: "",
  date: new Date(),
  dob_day: new Date().getDate(),
  dob_month: new Date().getMonth() + 1,
  dob_year: new Date().getFullYear(),
  id_number: "",
  routing_number: "",
  account_number: "",
  url: "",
  address: "",
  city: "",
  state: "",
  country: "",
  postal_code: "",
  image: ""
};

function CreateAcc() {
  const inputRef = useRef(null)
  const wallet_classes = WalletStyle();
  const evntClasses = eventStyle();
  const classes = accountStyle();
  const classess = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [state, setState] = useState(initialState);
  const [loader, setLoader] = useState(false)
  const [image, setImage] = useState("")
  const [error, setError] = useState({
    first_name: "",
    last_name: "",
    city: "",
    state: "",
    phone: "",
    postal_code:"",
    country: "",
    address: "",
    email: "",
    url: "",
    account_number: "",
    gender: "",
    routing_number: "",
    id_number: "",
    image: ""
  });

  const errors = {
    first_name: strings.errors.firstname,
    last_name: strings.errors.lastname,
    city: strings.errors.city,
    state: strings.errors.state,
    country: strings.errors.country,
    phone: strings.errors.phone,
    postal_code: strings.errors.zip,
    address: strings.errors.address,
    email: strings.errors.email,
    url: strings.errors.BusinessUrl,
    account_number: strings.errors.accountNumber,
    gender: strings.errors.gender,
    routing_number: strings.errors.routingNumber,
    id_number: strings.errors.id_number,
    image: strings.errors.image
  };

  const makeErrors = () => {
    let err = {};
    const er = Object.keys(state).map((key) => {
      console.log(key, state[key]);
      const status = validator(key, state[key]);
      err = { ...err, [key]: status ? "" : errors[key] };
      return status;
    });
    setError({ ...error, ...err });
    return er;
  };

  const onSubmit = async() => {
    const errs = makeErrors();
    console.log("erroer", errs);
    if (errs && errs.filter((er) => !er).length) return;
    else {
      setLoader(true)
      delete state.date;
      const identityImage = state.image;
      delete state.image;
      await dispatch(createAccount(state,identityImage,history));
      setLoader(false)
    }
  };
  const onChange = (k, v) => {
    if (k === "date") {
      const birth = new Date(v);
      const myDate = birth.getDate();
      const month = (birth.getMonth()+1);
      const year = birth.getFullYear();
      // const allData = `${myDate}-${month}-${year}`;
      setState({ ...state, dob_day: myDate, dob_month: month, dob_year: year , [k]: v});
    }
    else{
    setState({ ...state, [k]: v });
    setError({ ...error, [k]: validator(k, v) ? "" : errors[k] });}
  };
  console.log(state)

  const handleAddress = (format) => {
    let raw = {
      state: format.state,
      city: format.city,
      country: format.country,
      postal_code: format.zip,
      address: format.address,
    };
    setState({ ...state, ...raw });
    const err = handleAddressErrs(format, raw);
    setError({ ...error, ...err });
  };

  const handleAddressErrs = (format, raw) => {
    let err = {};
    ["state", "city", "country", "zip_code", "address"].forEach((k) => {
      err = { ...err, [k]: validator(k, raw[k]) ? "" : errors[k] };
    });
    return err;
  };

  const handleInput = () => {
    inputRef.current.click();
  };

  const imageChange = (e) => {
    const img = e.target.files[0];
    setState({...state, image:e.target.files[0] })
    setImage(URL.createObjectURL(e.target.files[0]));
    setError({ ...error, image: validator("image", img) ? "" : errors["image"] })
    console.log(e.target.files[0], URL.createObjectURL(e.target.files[0]));
  };

  const handleCloseClick = () => {
    setImage("");
  };

  return (
    <div className={wallet_classes.create_account_container}>
      <div style={{ width: "100%" }}>
        <Grid container>
          {/* Event Title */}
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={evntClasses.addNewEventFormGV}
          >
            {/* onChange */}
            <TextField
              error={error.first_name}
              required
              label={"First Name"}
              onChange={(v) => onChange("first_name", v)}
              value={state.first_name}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={evntClasses.addNewEventFormGV}
          >
            <TextField
              error={error.last_name}
              required
              label={"Last Name"}
              onChange={(v) => onChange("last_name", v)}
              value={state.last_name}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={evntClasses.addNewEventFormGV}
          >
            <TextField
              error={error.email}
              required
              label={"Email"}
              onChange={(v) => onChange("email", v)}
              value={state.email}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={classes.personalDetailTV}
          >
            <Box>
              <FormControl fullWidth error={error.gender}>
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={state.gender}
                  required
                  label="Age"
                  onChange={(v) => onChange("gender", v.target.value)}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={classes.personalDetailTV}
          >
            <PhoneInput
              label={common.Phone}
              required
              error={error.phone}
              onChange={(v) => onChange("phone", v)}
              value={state.phone}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={evntClasses.addNewEventFormGV}
          >
            <DatePicker
              // minDate={new Date()}
              label={"Date Of Birth"}
              required
              value={state.date}
              onChange={(value) => onChange("date", value)}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={evntClasses.addNewEventFormGV}
          >
            <TextField
              error={error.id_number}
              label={"SSN (ID Number)"}
              value={state.id_number}
              required
              onChange={(value) => onChange("id_number", value)}
            />
          </Grid>

          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={evntClasses.addNewEventFormGV}
          >
            <TextField
              error={error.routing_number}
              label={"Routing Number"}
              required
              // InputProps={{ inputProps: { min: 0 } }}
              value={state.routing_number}
              onChange={(value) => onChange("routing_number", value)}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={evntClasses.addNewEventFormGV}
          >
            <TextField
              error={error.account_number}
              label={"Account Number"}
              required
              value={state.account_number}
              onChange={(v) => onChange("account_number", v)}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={evntClasses.addNewEventFormGV}
          >
            <TextField
              error={error.url}
              label={"Business URL"}
              required
              value={state.url}
              onChange={(value) => onChange("url", value)}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={evntClasses.addNewEventFormGV}
          >
            <GooglePlaces
              error={error.address}
              label={common.Address}
              value={state.address}
              required
              onChange={handleAddress}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={classes.personalDetailTV}
          >
            <TextField
              label={common.City}
              required
              error={error.city}
              onChange={(v) => onChange("city", v)}
              value={state.city}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={classes.personalDetailTV}
          >
            <TextField
              label={common.State}
              required
              error={error.state}
              onChange={(v) => onChange("state", v)}
              value={state.state}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={classes.personalDetailTV}
          >
            <DropDown
              label={common.Country}
              data={country}
              error={error.country}
              onChange={(v) => onChange("country", v)}
              value={state.country}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={classes.personalDetailTV}
          >
            <TextField
              error={error.postal_code}
              required
              label={"Postal code"}
              onChange={(v) => onChange("postal_code", v)}
              value={state.postal_code}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={classes.personalDetailTV}
          >
            <TextField
              id="input-with-icon-textfield"
              label={"Upload Document"}
              error={error.image}
              disabled= {true}
              required
              onClick={handleInput}
              variant="outlined"
              style={{
                position: "relative",
                float: "left",
                color: "rgba(0, 0, 0, 0.54)",
              }}
            />
            {
              !error.image && <span style = {{fontSize:"11px"}}>Please verify your identity by uploading any government ID proof.</span>
            }
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              style={{ display: "none" }}
              onChange={imageChange}
            />
          </Grid>
          <Grid item sm={12}
            xs={12}
            md={6}
            lg={6}
            className={classes.personalDetailTV}
            >
            </Grid>
          <Grid item sm={12}
            xs={12}
            md={6}
            lg={6}
            className={classes.personalDetailTV}
            >
          {image && (
            <div>
              <TextField
                variant ="standard"
                disabled= {true}

                style = {{paddingBottom:"20px"}}
                  sx={{
                    ".MuiOutlinedInput-root": {
                      paddingTop: "1rem",
                      flexDirection: "column",
                    },
                    img: {
                      paddingRight: "1rem",
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <div>
                        <img src={image} className={classess.img} />
                        <IconButton
                          onClick={handleCloseClick}
                          style={{
                            position: "absolute",
                            bottom: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <Cancel />
                        </IconButton>
                      </div>
                    ),
                  }}
                />
            </div>
          )}
           </Grid>
        </Grid>
      </div>
      <div>
        {
          loader?<Button
          variant="contained"
          size="large"
          color="primary"
          style={commonButtonStyle}
          disabled={true}
        >
          Processing...
        </Button>:
         <Button
         variant="contained"
         size="large"
         color="primary"
         style={commonButtonStyle}
         onClick={onSubmit}
       >
         {common.Submit}
       </Button>
        }
      </div>
    </div>
  );
}

export default CreateAcc;
