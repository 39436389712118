import React, { useState, useEffect } from "react";
import { Dialog, Typography, Box, Button } from "@material-ui/core";
import { authModalStyle, clearIconStyle, whiteLoaderStyle } from "styles";
import { Clear } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { strings } from "constant";
import { Loader } from "components";
import Inputs from "components/auth/input";
import { addVendorProposal } from "actions";
import { validator,isNumeric , replaceLeadingZeros} from "helpers";

const { common, events } = strings;
const error = {
  name: strings.errors.fullname,
  booking_amount: strings.errors.bookinAmount,
  message: strings.errors.message,
};

export default (props) => {
  const classes = authModalStyle();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    name: "",
    booking_amount: "",
    message: "",
  });
  const [errors, setError] = useState({
    name: "",
    booking_amount: "",
    message: "",
  });
  const { name, uid } = useSelector(({ user }) => user.user);

  const { event ={}} = useSelector(({ event }) => event);
  const event_id = event?.id;
  console.log("event", event)
  const { business = {} } = useSelector(({ vendor }) => vendor);
  const { selectProposal: proposal, loader = false } = useSelector(
    ({ proposal }) => proposal
  );

  useEffect(() => {
    setState({ ...state, name });
  }, [name]);
  useEffect(() => {
    if (proposal.business_id)
      setState({
        ...state,
        name: business.name,
        message: proposal?.message,
        booking_amount: proposal?.booking_amount,
      });
  }, [proposal && JSON.stringify(proposal)]);

  
  const { modal = false, onClose = () => {}, category_id } = props;
  const submit = () => {
    //*on send update or send proposal
    let data;

    setTimeout(() => {
      onClose(false);
    }, 2000);
    //*Direct proposal from vendor
    //In this case category is not selected
    if (category_id) {
      data = {
        ...state,
        category_id,
        event_id,
        business_id: business?.id,
        servicesDate: event.event_date,
        user_id: event.owners,
        sender_id: uid,
        isProposal: true,
        isBooked: false,
        isQuote: false,
      };
      dispatch(addVendorProposal(data, null));
      //quote doc category is already selected
    } else {
      const { modifiedAt, createdAt, isDeleted, event, id, ...propsal } =
        proposal;
      data = {
        ...propsal,
        ...state,
        sender_id: uid,
        isProposal: true,
        isBooked: false,
        isQuote: false,
      };
      if (proposal.sender_id === proposal.user_id) {
        //for quote sending proposal
        dispatch(addVendorProposal(data, id));
        console.log("id", data, id)
      } else {
        //for update sending proposal
        dispatch(addVendorProposal(data, id, "update"));
      }
    }
    console.log("data data data data ", data);
  };
  const onChange = (k, v) => {
    if(k==="booking_amount" && v.includes(".") && v.split(".")[1].length>2) return;
    setState({ ...state, [k]: v });
    setError({ ...errors, [k]: validator(k, v) ? "" : error[k] });
  };
  const makeErrors = () => {
    let err = {};
    const er = Object.keys(errors).map((key) => {
      const status = validator(key, state[key]);
      err = { ...err, [key]: status ? "" : error[key] };
      return status;
    });
    setError({ ...errors, ...err });
    return er;
  };
  const onSubmit = () => {
    const errs = makeErrors();
    if (errs && errs.filter((er) => !er).length) return;
    else submit();
  };

  const fields = [
    {
      label: common.Name,
      key: "name",
      maxLength: 200,
      type: "textfield",
      value: state.name,
    },
    {
      label: common.BookingAmount,
      key: "booking_amount",
      maxLength: 100,
      type: "textfield",
      value: state.booking_amount,
    },
    {
      label: common.Message,
      key: "message",
      maxLength: 2000,
      type: "textarea",
      value: state.message,
    },
  ];

  return (
    <Dialog open={modal} onClose={onClose} className={classes.modal}>
      <div className={classes.modalBody}>
        <div className={classes.headerIconV}>
          <Clear style={clearIconStyle} onClick={onClose} />
        </div>
        <Typography component={"span"} className={classes.headingV}>
          <Box fontFamily="CormorantBold" className={classes.modalHeadingT}>
            {strings.proposal.AddProposal}
          </Box>
        </Typography>
        <Inputs
          fields={fields}
          state={state}
          errors={errors}
          onChange={onChange}
        />
        <div className={classes.buttonV}>
          <Button
            onClick={onSubmit}
            variant="contained"
            size="large"
            color="primary"
            className={classes.button}
          >
            {loader ? (
              <Loader style={whiteLoaderStyle} size={15} />
            ) : (
              common.Submit
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
