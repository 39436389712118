import { firestore, getLocation } from "helpers";
import { ACTION_TYPES, strings } from "constant";
import { createAlert } from "actions";

const { errors } = strings;

const getCollection = (searchFor) => {
  return searchFor === "Vendors" ? "venders" : "events";
};

//get vendor's user_name and rating
const getUserNameAndRating = async (result, dispatch) =>
  result.map(async (v) => {
    const vn = await firestore
      .collection("users")
      .where("userId", "==", v.ownerId)
      .get();
    let vendor_name = vn.docs.map((doc) => doc.data());
    vendor_name = vendor_name[0]?.name;
    console.log(vendor_name);

    const snap = await firestore
      .collection("vendor_review")
      .where("businessId", "==", v.id)
      .get();
    const rev = await snap.docs.map((doc) => doc.data());

    let count = 0;
    rev.forEach((e) => {
      count += e.rating;
    });
    const avgRating = rev.length?count / rev.length:0;
    const ans = { ...v, vendor_name, avgRating,totalReviews: rev.length,reviews: rev};
    return ans;
  });

const getEventDetails = (result, dispatch) =>
  result.map(async (v) => {
    const promoteSnap = await firestore
      .collection("promotions")
      .where("eventId", "==", v.id)
      .get();

    let promoted = 0;
    let expiredDate = v.event_date;
    let planDuration = 0;
    const currentDate = new Date().getTime();

    const promote = promoteSnap.docs.map((it) => it.data());

    if (promote.length) {
      planDuration = promote[0].planDuration;
      expiredDate =
        promote[0]?.startDate + promote[0]?.planDuration * 24 * 60 * 60 * 1000;
      if (currentDate > expiredDate) promoted = 0;
      else promoted = 1;
    }

    const ans = { ...v, promoted, expiredDate, planDuration };
    console.log(ans);
    return ans;
  });

// ------------------------------------------------------------------- NAME
export const searchForName =
  (searchFor, valName) => async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.SEARCH_START});
      //if type==3 and invitated then only show private events on couple
      const {type} = getState().user.user;
      const {guestIds = [],events=[]} = getState().guest;

      const arr = valName.toLowerCase().split(" ");
      let results = [];
      let includeEvents = [];

      // return searchFor === "Vendors" ? "venders" : "events";
      if(searchFor === "Vendors"){
        const snap = await firestore
        .collection("venders")
        .where("keywords", "array-contains-any", arr)
        .get();
        results = snap.docs.map((it) => it.data());
        console.log("names", results);
      }
      //!purposely did type===4, type 4 does not exist
      else if(type===4){
        const eventIds = events.map((e)=>e.id);
        // for(let id of eventIds){
          // const res = await firestore.collection("event_invite")
          //   .where("eventId", "==",id)
          //   .where("guestId","in",guestIds)
          //   .where("accepted","==",true).get();
          // const isAccepted = res.docs.map((it)=>it.data());
          // if(isAccepted.length && isAccepted[0].accepted){
          //   includeEvents.push(id);
          // }
        // }
          console.time("for loop");
          //* "in" 10 by 10
        await Promise.all(eventIds.map(async (id)=>{
          const res = await firestore.collection("event_invite")
            .where("eventId", "==",id)
            .where("guestId","in",guestIds)
            .where("accepted","==",true).get();
          const isAccepted = res.docs.map((it)=>it.data());
          if(isAccepted.length && isAccepted[0].accepted){
            includeEvents.push(id);
          }
        }));
        console.timeEnd("for loop");
        console.log("includeEvents",includeEvents);
        const snap1 = await firestore.collection("events")
          .where("id", "in",includeEvents)
          .get();
        
        const guestResult = snap1.docs.map((it)=>it.data());
        //get based on keywords
        const withKeywords = guestResult.filter((it)=>{
          const present = it.keywords.filter((key)=>arr.includes(key))
          if(present.length) return true;
          return false
        })
        results.push(...withKeywords);
        
        const snap2 = await firestore
          .collection("events")
          .where("keywords", "array-contains-any", arr)
          .where("event_type", "==",2)
          .get();
        const guestResult2 = snap2.docs.map((it) => it.data());
        //if event is public then snap2 will also fetch same event
        //*to avoid this we have to remove duplicates from array

        if(guestResult2.length) results.push(...guestResult2)
      }
      else{
        let query = firestore
        .collection("events")
        .where("keywords", "array-contains-any", arr);
        query = query.where("event_type","==",2);
        const snap = await query.get();
        results = snap.docs.map((it) => it.data());
        console.log("names", results);
      }
      // dispatch({ type: "SEARCH_END" });
      let ans = [];
      if (searchFor === "Vendors") ans = await Promise.all(await getUserNameAndRating(results, dispatch));
      else ans = Promise.all(await getEventDetails(results, dispatch));
      dispatch({ type: ACTION_TYPES.SEARCH_END});
      return ans;
    } catch (error) {
      console.log("Search failed ", error);
      dispatch({ type: "SEARCH_END" });
      dispatch(
        createAlert({
          message: "Search using name failed",
          type: "error",
          duration: 4000
        })
      );
    }
  };

// ------------------------------------------------------------------- Category
export const searchForVendorsCategory =
  (searchFor, valName) => async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.SEARCH_START});
      dispatch({ type: ACTION_TYPES.SET_EVENT_CATEGORY, payload: valName });
      const collection_name = getCollection(searchFor);
      console.log("categories", valName);
      const snap = await firestore
        .collection(collection_name)
        .where("categories", "array-contains-any", [valName])
        .get();
      const result = snap.docs.map((it) => it.data());

      const ans = await Promise.all(await getUserNameAndRating(result, dispatch));
      dispatch({ type: ACTION_TYPES.SEARCH_END});
      return ans;
    } catch (error) {
      console.log("Search failed ", error);
      dispatch({ type: "SEARCH_END" });
      dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
    }
  };

// ------------------------------------------------------------------- Location
export const searchForLocation =
  (
    searchFor,
    range = 10,
    latitude = 30.712238429363858,
    longitude = 76.71354188360594
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.SEARCH_START});
      const collection_name = getCollection(searchFor);
      const {type} = getState().user.user;
      dispatch({ type: ACTION_TYPES.SET_EVENT_CATEGORY, payload: "" });
      const tableName = searchFor === "Vendors" ? "geo_business" : "geo_event";
      const center = [latitude, longitude];
      const radius = range;
      let result = await getLocation(tableName, { center, radius });
      result = new Set(result)
      result = [...result]

      //! return searchFor === "Vendors" ? "venders" : "events";

      let res = [];
      let temp = [];
      if (result.length) {
        if(searchFor === "Vendors"){
          for(let vendor of result) {
            temp.push(vendor);
            if(temp.length===10){
              const snap = await firestore
              .collection("venders")
              .where("id", "in", temp)
              .get();
              res.push(...snap.docs.map((it) => it.data()));
              temp = [];
            }
          }
          if(temp.length){
            const snap = await firestore
            .collection("venders")
            .where("id", "in", temp)
            .get();
            res.push(...snap.docs.map((it) => it.data()));
          }
          const ans = await Promise.all(await getUserNameAndRating(res, dispatch));
          dispatch({ type: ACTION_TYPES.SEARCH_END});
          return ans;
        }
        else{
          for(let evt of result) {
            temp.push(evt);
            if(temp.length===10){
              const snap = await firestore
              .collection("events")
              .where("id", "in", temp)
              .where("event_type","==",2)
              .get();
              res.push(...snap.docs.map((it) => it.data()));
              temp = [];
            }
          }
          if(temp.length){
            const snap = await firestore
            .collection("events")
            .where("id", "in", temp)
            .where("event_type","==",2)
            .get();
            res.push(...snap.docs.map((it) => it.data()));
          }
          const ans = await Promise.all(await getEventDetails(res, dispatch));
          dispatch({ type: ACTION_TYPES.SEARCH_END});
          return ans;
        }
      } else {
        dispatch({ type: "SEARCH_END" });
        return res;
      }
    } catch (error) {
      console.log("Search failed ", error);
      dispatch({ type: "SEARCH_END" });
      dispatch(
        createAlert({ message: "Search using location failed", type: "error" })
      );
    }
  };

// -------------------------------------------------------------------------------- Date
export const searchForWeddingDate = (date) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.SEARCH_START});
    const start = new Date(date.startDate).getTime();
    const end = new Date(
      date.endDate ? date.endDate : date.startDate
    )?.getTime();
    const snap = await firestore
      .collection("events")
      .where("event_date", ">", start)
      .where("event_date", "<", end)
      .where("event_type", "==",2)
      .get();

    let results = snap.docs.map((it) => it.data());
    const ans = await Promise.all(await getEventDetails(results, dispatch));
    dispatch({ type: ACTION_TYPES.SEARCH_END});
    return ans;
  } catch (error) {
    console.log("Search failed ", error);
    dispatch({ type: "SEARCH_END" });
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
  }
};

export const getCategory = () => async (dispatch, getState) => {
  try {
    const snap = await firestore.collection("categories").get();
    const categories = snap.docs.map((it) => it.data());
    return categories;
  } catch (err) {
    console.log("getCategory Error", err);
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
  }
};
