import React, { useState, useEffect } from "react";
import { Typography, Grid, Box } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { commonStyle, eventStyle } from "styles";
import Carousel from "react-material-ui-carousel";
import { GoogleMap, Chat as ChatComponent } from "components";
import { setChat, fetchEvent } from "actions";
import { strings, colors, ACTION_TYPES } from "constant";
import moment from "moment";
const { events, common } = strings;

const center = {
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
};

export default (props) => {
  const classes = eventStyle();
  const commClasses = commonStyle();
  const dispatch = useDispatch();
  const { booking = [] } = useSelector(({ vendor }) => vendor);
  console.log("bookings", booking)
  const {business ={}} = useSelector(({ vendor }) => vendor);
  const { event = {} } =
    props.type === 3 ? booking[props.index] : useSelector(({ event }) => event);
    console.log("event", event, event.id)

    const {type = "" } = useSelector(({ user }) => user)?.user
     const { ownerId } =
    props.type === 3 ? useSelector(({ vendor }) => vendor.business) : 0;

  const { detail = {} } = useSelector(({ chat }) => chat);

  const {
    event_name = "",
    spouse_name = "",
    event_date = "",
    event_location = "",
    guest_count,
    id,
    latitude,
    longitude,
    owners,
  } = event;
  let images = event.images;
  let event_id = event.id
  console.log("event>>", event_id, event)
  const managePayment = () => {
    props.history.push({
      pathname: "/payment",
      state: {
        event_id: id,
        cId: props.service.id,
        uId: owners,
        categoryName: props.service.name,
      },
    });
  };
  const setVisible = (type) => setChatt({ ...chtprop, visible: type });
  const [chtprop, setChatt] = useState({
    title: "",
    subTitle: "",
    visible: null,
  });

  const handleChatOpen = () => {
    setChatt({ ...chtprop, visible: "active" });
  };

  useEffect(() => {
    dispatch({type:ACTION_TYPES.EVENT_COMPLETE, payload:event})
    console.log("payload", {payload:event})
  },[])

  useEffect(() => {
    if (ownerId) {
      const user = {
        business_id: owners,
        id: `${ownerId}_${owners}`,
        id2: `${owners}_${ownerId}`,
      };
      dispatch(setChat(user));
    }
  }, []);

  useEffect(() => {
    if (ownerId) {
      const user = {
        business_id: owners,
        id: `${ownerId}_${owners}`,
        id2: `${owners}_${ownerId}`,
      };
      dispatch(setChat(user));
    }
  }, [JSON.stringify(detail)]);

  return (
    <div className={classes.crEventMain}>
      <Grid container justifyContent="center" className={classes.eventInfoV}>
        <Grid item sm={12} xs={12} md={12} className={classes.crImageV}>
          <Carousel
            navButtonsAlwaysVisible={false}
            indicators={true}
            autoPlay={true}
            timeout={500}
            animation="slide"
            swipe={true}
          >
            {images &&
              images.length &&
              images?.map((image, i) => (
                <img
                  key={Math.random() + "image-vend" + i}
                  src={image}
                  className={classes.eventDetailsCRImge}
                  alt=""
                />
              ))}
          </Carousel>
        </Grid>
        <Grid item sm={12} xs={12} md={6} className={classes.eventInfoGV}>
          <Typography noWrap component="div" className={classes.eventInfoTV}>
            <Box fontFamily="Gotham" className={classes.eventInfoHT}>
              {"Event Name"}
            </Box>
            <Box fontFamily="GothamBook" className={classes.eventInfoIT}>
              {event_name}
            </Box>
          </Typography>
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          // md={props?.type === 3 ? 4 : 8}
          md={6}
          className={classes.eventInfoGV}
        >
          <Typography noWrap component="div" className={classes.eventInfoTV}>
            <Box fontFamily="Gotham" className={classes.eventInfoHT}>
              {events.CoupleName}
            </Box>
            <Box fontFamily="GothamBook" className={classes.eventInfoIT}>
              {spouse_name}
            </Box>
          </Typography>
        </Grid>
        <Grid item sm={12} xs={12} md={6} className={classes.eventInfoGV}>
          <Typography component="div" className={classes.imageGridTV}>
            <Box fontFamily="Gotham" className={classes.eventInfoHT}>
              {events.EventDate}
            </Box>
            <Box fontFamily="GothamBook" className={classes.eventInfoIT}>
              {event_date && moment(event_date).format("DD MMM YYYY")}
            </Box>
          </Typography>
        </Grid>
        {/* {props.type === 3 && ( */}
        <Grid item sm={12} xs={12} md={6} className={classes.eventInfoGV}>
          <Typography component="div" className={classes.imageGridTV}>
            <Box fontFamily="Gotham" className={classes.eventInfoHT}>
              {events.GuestCount}
            </Box>
            <Box fontFamily="GothamBook" className={classes.eventInfoIT}>
              {guest_count ? guest_count : "0"}
            </Box>
          </Typography>
        </Grid>
        {/* )} */}
        <Grid item sm={12} xs={12} md={12} className={classes.eventInfoGV}>
          <Typography wrap="true" component="div" className={classes.imageGridTV}>
            <Box fontFamily="Gotham" className={classes.eventInfoHT}>
              {events.WeddingLocation}
            </Box>
            <Box fontFamily="GothamBook" className={classes.eventInfoIT}>
              {event_location}
            </Box>
            {props.type === 3 && (
              <GoogleMap
                latitude={latitude}
                longitude={longitude}
                event_location={event_location}
              />
            )}
          </Typography>
        </Grid>

        <div className={commClasses.hairline} />
        {props.type === 3 && (
          <>
            <Typography component="div" className={classes.eventTV}>
              <Box fontFamily="CormorantBold" className={classes.eventT}>
                {"Your service"}
              </Box>
            </Typography>
            <div
              className={classes.eventVendorsV}
              style={{ ...center, paddingRight: "0" }}
              // key={Math.round() + "-" + item.title + index + "vend-round"}
            >
              <img
                src={props.service.icon}
                className={classes.vendorImg}
                alt=""
              />
              <Box fontFamily="Gotham" className={classes.vendorImgAlt}>
                {props.service.name}
              </Box>
            </div>

            <div className={commClasses.hairline} />

            <Grid
              item
              sm={12}
              xs={12}
              md={5}
              className={classes.vendorDetailBLV}
              style={center}
            >
              <div
                className={classes.chatButtonV}
                onClick={managePayment}
                style={{ margin: "10px" }}
              >
                <Box fontFamily="GothamBook" className={classes.vendorDetButT}>
                  Manage payment
                </Box>
              </div>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={5}
              className={classes.vendorDetailBLV}
              style={center}
            >
               {business?.id && type === 3? (
              <>
                <div className={classes.chatButtonV}   
                  onClick={() =>
                    props.history.push({
                      pathname: "/category",
                      state: {
                        from_eventDetail: true,
                        multi: false,
                        categories: business?.categories,
                        event_id : event.id
                      },
                    })
                  }>
                <Box fontFamily="GothamBook" className={classes.vendorDetButT} >
                  {"Send Proposal"}
                </Box>
                </div>
              </>
            ) : null}
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};
