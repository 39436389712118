import React, { useState,useEffect} from "react";
import {useHistory} from 'react-router-dom'
import {createAlert} from 'actions'
import {TextField,Typography,Button} from "@material-ui/core";
import {Loader} from "components"
import {WalletStyle} from "styles";
import { InfoOutlined } from "@material-ui/icons";
import { transferToBank,fetchStripeAccountStatus } from "actions";
import { useDispatch, useSelector } from "react-redux";
import { checkAmountBeforeSubmit,getFixed,isNumeric,replaceLeadingZeros } from "helpers"

function AddAmount() {
  const classes = WalletStyle();
  const dispatch = useDispatch();
  const history = useHistory();

  const { user } = useSelector(({ user }) => user);
  const { loader } = useSelector(({ transaction }) => transaction);
  
  const { business = {} } = useSelector((state)=>state.vendor)
  const {payment} = useSelector((state)=>state.transaction)
  const bank_transfer_charge = Number(payment.bank_transfer_charge);
  const platform_charge = Number(payment.platform);

  const [error, setError] = useState()
  const [amount, setAmount] = useState("")
  const [isProcessing,setIsProcessing] = useState(false);
  const [stripeAccountStatus,setStripeAccountStatus] = useState("");

  useEffect(() => {
    const accountId = user.type!==3 ? user.stripe_account_id : business.stripe_account_id
    const invokeStatus =async()=>{
      setStripeAccountStatus(await dispatch(fetchStripeAccountStatus(accountId)));
    }
    invokeStatus();
  },[])

 const handleAmount = (e) =>{
  let num = e.target.value;

  //if digits after decimal point is of length>2 ignore 
  //eg. 1.12345 --> 1.12 (ignore 345)
  if(num.includes(".") && num.split(".")[1].length>2) return;
  num = replaceLeadingZeros(num);
  if(isNumeric(num)){
    setAmount(num)
    setError("")
  }else{
    if(num.length===0){
      clear();
      setError("")
    }
    else setError("Invalid amount")
  }
 }

  const clear = ()=>{
    setAmount("")
  }

  const onSubmit = async() => {
    //*1% Bank transfer charge
    //*1% platform_charge charges
    let amt = Number(amount)
    const checkAmount = checkAmountBeforeSubmit(amt);
    if(checkAmount) {
      setError(checkAmount);
      return;
    }
    const extraCharges = bank_transfer_charge+platform_charge;
    const chargeAmount = amt*(extraCharges/100);
    const enteredAmount = amt
    const totalAmount = amt+chargeAmount
    let inCents = getFixed(totalAmount);
    const data = {
      amount: inCents,
      account_id:user.type!==3 ? user.stripe_account_id : business.stripe_account_id
    }
    try{
      setIsProcessing(true)
      if(amount.length !== 0 || !error){
        
      await dispatch(transferToBank(data,enteredAmount,history))
      clear();
      setIsProcessing(false)
      
      }else
        setError("please enter a amount");
    }
    catch(error){
      setIsProcessing(false)
      dispatch(
        createAlert({
          message: "please enter valid amount before sending",
          type: "error",
          duration: 10000,
        })
      );
    }
  };

  return (
    <div className={classes.transfer_form}>
        <div className={classes.Row}>
          <TextField
            variant="outlined"
            error={error}
            label="Amount"
            onChange={handleAmount}
            value={amount}
            helperText={error}
          />
          {amount && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                paddingTop: "10px",
              }}
            >
              <Typography variant="caption" display="block" gutterBottom style={{margin: 0}}>
                {`You will be charged ${bank_transfer_charge+platform_charge}% extra`}
              </Typography>
              <div title={`Here, extra charges include bank transfer charge fees and  platform fee which is ${bank_transfer_charge}% and ${platform_charge}.`} style={{marginLeft: 3}}>
                <InfoOutlined fontSize="small" />
              </div>
            </div>
          )}
        </div>
        <div className={classes.Row}>
        <TextField
          label={"Account Number"}
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          value={user.type!==3? `**** **** **** ${user.account_number.slice(-4)}`:`**** **** **** ${business.account_number.slice(-4)}`}
        />
        </div>
        <div className={classes.center}>
          <Button
            onClick={onSubmit}
            variant="contained"
            className={classes.active}
            disabled={isProcessing || !amount}
          >
            {isProcessing  || loader ? `Processing...` : `TRANSFER MONEY`  }
          </Button>
        </div>
      </div>
  );
}

export default AddAmount;