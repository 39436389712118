import {
  firestore,
  insert,
  imagePathToUrl,
  sendPush,
  updateOne,
  uploadImagesS3,
  sendEmail,
} from "helpers";
import { history } from "../App";
import { ACTION_TYPES, strings } from "constant";
import { createAlert, fetchMyReviews, filterImages } from "actions";

export const addEvent =
  ({ images, theme_image, ...state }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.EVENT_SERVICE_REQUEST });
      const {
        user: { uid = "" },
      } = getState().user;
      const imagesUrl = await uploadImagesS3(images, "events");
      const imgs = imagesUrl?.map((i) => i.location);

      let lat, lng;
      if (!state.latitude) {
        navigator.geolocation.getCurrentPosition(
          ({ coords: { latitude, longitude } }) => {
            lat = latitude;
            lng = longitude;
          }
        );
      } else {
        lat = state.latitude;
        lng = state.longitude;
      }

      const insertData = {
        ...state,
        owners: uid,
        images: imgs,
        keywords: state.event_name.toLowerCase().split(" "),
        lat: lat,
        lng: lng,
      };
      const event = await insert("events", insertData);
      dispatch({ type: ACTION_TYPES.EVENT_SERVICE_SUCCESS });
      dispatch(
        createAlert({
          message: strings.success.EventAddedSuccussful,
          type: "success",
        })
      );
      history.push("/");
    } catch (error) {
      console.log("add event catch error ", error);
      dispatch({ type: ACTION_TYPES.EVENT_SERVICE_FAILED });
      dispatch(createAlert({ message: error.message, type: "error" }));
    }
};

export const updateEvent =
  ({ images, theme_image, deleteImg, ...state }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.EVENT_SERVICE_REQUEST });
      const {
        user: { uid = "" },
      } = getState().user;

      const { event } = getState().event;
      if (!event) {
        history.push("/");
        throw new Error("You don't have any wedding event to update.");
      }
      const {
        event: { id = "" },
      } = getState().event;

      const imgs = await filterImages(images, deleteImg);

      let lat = state.latitude,
        lng = state.longitude;
      const insertData = {
        ...state,
        owners: uid,
        images: imgs ? imgs : [],
        keywords: state.event_name.toLowerCase().split(" "),
        latitude: lat,
        longitude: lng,
      };
      const update = await updateOne("events", id, insertData);
      dispatch({ type: "ACTION_TYPES.EVENT_UPDATE_SUCCESS" });
      dispatch(
        createAlert({
          message: strings.success.EventUpdatedSuccussful,
          type: "success",
        })
      );
      history.push("/");
    } catch (error) {
      console.log("add event catch error ", error.message?error.message: error);
      dispatch(createAlert({ message: error.message?error.message: error, type: "error" }));
      dispatch({ type: "ACTION_TYPES.EVENT_UPDATE_FAILED" });
    }
};

export const fetchEvent = (ownerId) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.EVENT_SERVICE_REQUEST });
    let uid;
    // debugger;
    if (ownerId) {
      uid = ownerId;
    } else {
      const { user } = getState().user;
      uid = user.uid;
    }

    let {
      user: { uid: userId },
    } = getState().user;
    const snap = await firestore
      .collection("events")
      .where("owners", "==", uid)
      .get();

    const data = snap.docs.map((it) => it.data());

    let eId = data[0]?.id;

    let promoted = 0;
    let expiredDate = 0;
    let planDuration = 0;

    let totalGuests, invitedGuests;

    if (eId) {
      const gf = await firestore
        .collection("gifts")
        .where("event_id", "==", eId)
        .where("cashPayment", "==", false)
        .get();

      const promoteSnap = await firestore
        .collection("promotions")
        .where("eventId", "==", eId)
        .orderBy("createdAt", "desc")
        .get();

      const guestsSnap = await firestore
        .collection("guest_users")
        .where("coupleId", "==", userId)
        .get();

      const invitedGuestsSnap = await firestore
        .collection("event_invite")
        .where("eventId", "==", eId)
        .get();

      totalGuests = guestsSnap.docs.map((it) => it.data());
      invitedGuests = invitedGuestsSnap.docs.map((it) => it.data());

      console.log(totalGuests.length, invitedGuests.length);

      const promote = promoteSnap.docs.map((it) => it.data());

      if (promote.length) {
        const currentDate = new Date().getTime();
        planDuration = promote[0].planDuration;
        expiredDate =
          promote[0]?.startDate +
          promote[0]?.planDuration * 24 * 60 * 60 * 1000;
        if (currentDate > expiredDate) promoted = 0;
        else promoted = 1;
      }

      const gifts = gf.docs.map((it) => it.data());
      let totalGifts = 0,
        totalGiftedBy = 0;
      gifts.forEach((it) => {
        totalGifts += Number(it.quantity);
        totalGiftedBy += it.selected_by.length;
      });

      dispatch({
        type: ACTION_TYPES.GIFTS_COMPLETE,
        payload: { ...gifts, totalGifts, totalGiftedBy },
      });
    }
    // dispatch({ type: ACTION_TYPES.EVENT_SUCCESS });
    if (data.length !== 0)
      dispatch({
        type: ACTION_TYPES.EVENT_COMPLETE,
        payload: {
          ...data[0],
          promoted,
          expiredDate,
          planDuration,
          event_guests: `${totalGuests.length} out of ${invitedGuests.length} invited`,
        },
      });
    dispatch({ type: ACTION_TYPES.EVENT_SERVICE_SUCCESS });
    // dispatch(Notify());
    return data[0];
  } catch (error) {
    console.log("fetch event catch error ", error);
    // dispatch({ type: ACTION_TYPES.EVENT_FAILED });
    dispatch({ type: ACTION_TYPES.EVENT_SERVICE_FAILED });
    dispatch(createAlert({ message: error.message, type: "error" }));
    return null;
  }
};

export const fetchCategory = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.EVENT_REQUEST });
    const category = await firestore.collection("categories").get();
    const data = await Promise.all(
      category.docs.map(async (doc) => {
        const detail = doc.data();
        const { icon } = detail;
        const image = await imagePathToUrl(icon);
        return { ...detail, id: doc.id, icon: image };
      })
    );
    dispatch({ type: ACTION_TYPES.EVENT_SUCCESS });
    dispatch({ type: ACTION_TYPES.CATEGORIES, payload: data });
    return data;
  } catch (error) {
    console.log("fetchCategory event catch error ", error);
    dispatch({ type: ACTION_TYPES.EVENT_FAILED });
    dispatch(createAlert({ message: error.message, type: "error" }));
    return null;
  }
};

export const setCategory = (payload) => (dispatch) => {
  dispatch({ type: ACTION_TYPES.SET_EVENT_CATEGORY, payload });
};

export const fetchVendors = () => async (dispatch, getState) => {
  try {
    const { category = null } = getState().event;
    dispatch({ type: ACTION_TYPES.EVENT_REQUEST });
    const snap = await firestore
      .collection("venders")
      .where("categories", "array-contains", category.id)
      .get();
    const vendors = snap.docs.map((doc) => doc.data());
    const data =  await Promise.all(
      vendors.map(async (v) => {
        const vn = await firestore
          .collection("users")
          .where("userId", "==", v.ownerId)
          .get();
        let vendor_name = vn.docs.map((doc) => doc.data());
        vendor_name = vendor_name[0]?.name;

        const snap = await firestore
          .collection("vendor_review")
          .where("businessId", "==", v.id)
          .get();
        const rev = await snap.docs.map((doc) => doc.data());

        let count = 0;
        rev.forEach((e) => {
          count += e.rating;
        });
        const avgRating = count / rev.length;
        return { ...v, vendor_name, avgRating,totalReviews: rev.length,reviews: rev};
      })
    );
    dispatch({ type: ACTION_TYPES.EVENT_SUCCESS });
    return data;
  } catch (error) {
    console.log("fetchVendors event catch error ", error);
    dispatch({ type: ACTION_TYPES.EVENT_FAILED });
    dispatch(createAlert({ message: error.message, type: "error" }));
    return null;
  }
};

export const setVender = async (payload, dispatch) => {
  try {
    if(!payload?.avgRating && !payload?.totalReviews && !payload?.reviews)
    await dispatch(fetchMyReviews(payload.id));
    else
    dispatch({
      type: ACTION_TYPES.REVIEW_SUCCESS,
      payload:{ avgRating: payload.avgRating, totalReviews: payload.totalReviews,reviews: payload.reviews},
    });
    dispatch({
      type: ACTION_TYPES.EVENT_VENDOR_DETAIL,
      payload: { ...payload },
    });
    dispatch({ type: ACTION_TYPES.EVENT_SERVICE_SUCCESS });
  } catch (err) {
    console.log("setVender", err);
  }
};

export const setEventVendor = (payload, id=null) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.EVENT_SERVICE_REQUEST });
    const {
      event: { id: Eid },
      category,
    } = getState().event;
    let res = "";

    if (category && Eid) {
      const snap = await firestore
        .collection("proposals")
        .where("event_id", "==", Eid)
        .where("category_id", "==", category)
        .where("isBooked", "==", true)
        .get();
      const snapData = snap.docs.map((it) => it.data());
      dispatch({ type: "BOOKED_VENDER", payload: snapData });
    }

    if (id) {
      const vender = await firestore
        .collection("venders")
        .where("id", "==", id)
        .get();
      res = vender.docs.map((it) => it.data());
      if (res && res[0]) setVender(res[0], dispatch);
    } else {
      setVender(payload, dispatch);
    }
    dispatch({ type: ACTION_TYPES.EVENT_SERVICE_SUCCESS });
  } catch (err) {
    console.log(err);
  }
};

//Quote
export const addProposal =
  (data, id = null) =>
  async (dispatch) => {
    try {
      dispatch({ type: ACTION_TYPES.EVENT_SERVICE_REQUEST });

      if (id) await updateOne("proposals", id, data);
      else await insert("proposals", data);
      setTimeout(() => {
        dispatch({ type: ACTION_TYPES.EVENT_SERVICE_SUCCESS });
        dispatch(
          createAlert({
            message: id
              ? strings.success.proposalUpdated
              : strings.success.proposalAdded,
            type: "success",
          })
        );
      }, 500);
      setTimeout(() => {
        history.push("/");
        dispatch(sendNotification(id));
      }, 1000);
    } catch (error) {
      console.log("add proposal ", error);
      dispatch(createAlert({ message: error.message, type: "error" }));
      dispatch({ type: ACTION_TYPES.EVENT_SERVICE_FAILED });
    }
  };

//check if Quote is already send for this category to same selected vender
export const fetchProposal =
  (eventid, uid, category = "") =>
  async (dispatch, getState) => {
    try {
      console.log("category change");
      const { id = null } = getState().event?.vendor || {};
      dispatch({ type: ACTION_TYPES.EVENT_SERVICE_REQUEST });
      console.log("Ouery >>>>>>>>>>>>>>>>>>>>>>>>", uid, eventid, category, id);
      const snap = await firestore
        .collection("proposals")
        .where("user_id", "==", uid)
        .where("isProposal", "==", false)
        .where("isBooked", "==", false)
        .where("event_id", "==", eventid)
        .where("category_id", "==", category)
        .where("business_id", "==", id)
        .get();
      // setTimeout(() => {
      dispatch({ type: ACTION_TYPES.EVENT_SERVICE_SUCCESS });
      // }, 2000);
      const data = snap.docs.map((it) => it.data());
      return new Promise((res) => res(data && data.length ? data[0] : null));
    } catch (error) {
      console.log("fetch proposal catch error ", error);
      dispatch(createAlert({ message: error.message, type: "error" }));
      dispatch({ type: ACTION_TYPES.EVENT_SERVICE_FAILED });
      return new Promise((res) => res(null));
    }
  };

//This notification handler will trigger when Quote is sent from Couple to Vendor
//*Vendor will get a push notification
export const sendNotification = (update) => async (dispatch, getState) => {
  try {
    const vendor = getState().event?.vendor;
    const {
      user: { uid },
    } = getState().user;
    if (vendor && vendor.ownerId) {
      const { name = "" } = getState().user?.user;
      const title = update
        ? strings.notifications.UpdateQuote
        : strings.notifications.NewQuote;
      const body = `${
        update
          ? strings.notifications.QuoteUpdateBody
          : strings.notifications.QuoteBody
      } ${name}`;

      const notif = await sendPush({
        to: [vendor.ownerId],
        from: uid,
        title,
        body,
      });
      console.log("notification ", notif);
    }
  } catch (error) {
    console.log("notification error ", error);
  }
};

//sending email to vender and couple both at same time. When couple accepts the proposal
const sendBookingMail = async (data) => (dispatch, getState) => {
  try {
    const { categories } = getState().app;
    const { name } = getState().user?.user;
    const { venderName } = getState().event.event;

    const category = categories.filter((it) => it.id === data.category_id);

    const ForVenderEmail = {
      to: [data.sender_id],
      subject: "Proposal Accepted",
      type: "vendor_booking",
      message: `The proposal of $${data.booking_amount} sent by you for the ${category} category has been accepted by the couple \"${name}\".`,
    };

    sendEmail(ForVenderEmail);

    const ForCoupleEmail = {
      to: [data.user_id],
      subject: "Booking confirmed",
      type: "vendor_booking",
      message: `You have successfully booked the vendor \"${venderName}\" for the ${category} category for an amount of $${data.booking_amount}.`,
    };
    console.log(ForVenderEmail, ForCoupleEmail);

    sendEmail(ForCoupleEmail);
  } catch (error) {
    console.log(error.message);
  }
};

//Vendor Proposal
export const addVendorProposal =
  (data, id = null, type = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.PROPOSAL_REQUEST });
      const { name = "", uid } = getState().user?.user;
    
      const { id: event_id } = getState().event?.event;

      const { email, phone, isDeleted, ...other } = data;
      console.log(other);

      //update and accept
      if ((id && type === "update") || other.isBooked === true) {
        await updateOne("proposals", id, other);
        if (other.isBooked) {
          const snap = await firestore
            .collection("proposals")
            .where("id", "==", id)
            .get();
          const bookedDetail = snap.docs.map((it) => it.data());
          if (bookedDetail[0].isBooked) {
            await dispatch(addVendorOrder(bookedDetail[0]));
            dispatch(sendBookingMail(data));
          }
        }
      } else {
        //direct and send proposal --> quote_id and proposal data
        if (id) await updateOne("proposals", id, { isQuote: false });
        await insert("proposals", other);
        //In case of direct proposal no quote exists
      }

      handleNotification({ ...data, event_id }, type, name);

      dispatch({ type: ACTION_TYPES.PROPOSAL_COMPLETE });
      //i removed this because it is taking too long time to show some response to user
      // setTimeout(() => {
      dispatch({ type: ACTION_TYPES.SET_PROPOSAL, payload: [] }); //clear select proposal
      dispatch({
        type: ACTION_TYPES.SET_SELECTED_PROPOSAL,
        payload: {},
      });
      dispatch(
        createAlert({
          message: strings.success.proposalAdded,
          type: "success",
        })
      );
      history.goBack();
      // }, 500);
    } catch (error) {
      console.log("add proposal ", error);
      dispatch(createAlert({ message: error.message, type: "error" }));
      dispatch({ type: ACTION_TYPES.PROPOSAL_FAILED });
    }
  };

export const addVendorOrder = (data) => async (dispatch, getState) => {
  try {
    if (data.isBooked) {
      const vendor_order = {
        booked_on: data.createdAt,
        booking_amount: data.booking_amount,
        business_id: data.business_id,
        category_id: data.category_id,
        event_date: data.servicesDate,
        event_id: data.event_id,
        proposal_id: data.id,
        user_id: data.user_id,
      };
      await insert("vender_events", vendor_order);
      await insert("vender_orders", {
        ...vendor_order,
        lastPaymentType: 0,
        transferred_amount: 0,
      });
    }
  } catch (err) {
    console.log(err);
    dispatch(createAlert({ message: err.message, type: "error" }));
  }
};

//This notification sent to couple about proposals
//*Couple will receive push notification for proposals
const handleNotification = async (data, update, name) => {
  try {
    const bid = data?.business_id;
    // const business = await findById("venders", bid);
    const business = await firestore
      .collection("venders")
      .where("id", "==", bid)
      .get();
    const busines = business.docs.map((b) => b.data());

    const title = update
      ? strings.notifications.UpdateProposal
      : strings.notifications.NewProposal;
    if (data.isProposal) {
      const body = `${
        update
          ? strings.notifications.ProposalUpdatedBody
          : strings.notifications.ProposalBody
      } ${busines[0]?.business_name}`;
      const notif = await sendPush({
        to: [data?.user_id],
        from: busines[0]?.ownerId,
        eventId: data?.event_id,
        title,
        body,
      });
    }
    if (data.isBooked) {
      const body = `${strings.notifications.ProposalAcceptedBody} ${name}`;
      const notif = await sendPush({
        to: busines && busines[0] && [busines[0]?.ownerId],
        from: data?.user_id,
        eventId: data?.event_id,
        title,
        body,
      });
    }
  } catch (error) {
    console.log("handleNotification catch error ", error);
  }
};

export const setEvent = (payload) => (dispatch) =>{
  dispatch({ type: ACTION_TYPES.EVENT_COMPLETE, payload });
}
// in ISO booked getEveVendors fetched from vender_events collection
export const getEveVendors = (id) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_TYPES.EVENT_SERVICE_REQUEST });
    const snap = await firestore
      .collection("proposals")
      .where("event_id", "==", id)
      .where("isBooked", "==", true)
      .get();
    //commented on 4/2/22
    // dispatch({ type: ACTION_TYPES.SET_EVENT_CATEGORIES, payload: [] });
    const data = snap.docs.map((it) => it.data());

    //Getting bookedVender from proposal isBooked id true
    const bookedVenders =
      data && data.length
        ? data.map((id) => {
            return {
              vendor_name: id.name,
              booking_amount: id.booking_amount,
              bookedVender: id.business_id,
              id: id.id,
              cate: id.category_id,
            };
          })
        : [];
    //!Booked vender proposal must contain category
    const cats = data && data.length ? data.map((it) => it.category_id) : [];
    const removeDuplicate = [...new Set(cats)];

    dispatch({ type: "BOOKED_VENDER", payload: bookedVenders });

    if (cats && cats.length) {
      const cts = await firestore
        .collection("categories")
        .where("id", "in", removeDuplicate)
        .get();
      const categories = await Promise.all(
        cts.docs.map(async (itm) => {
          const it = itm.data();
          const image = await imagePathToUrl(it.icon);
          return { ...it, image };
        })
      );

      if (categories && categories.length)
        dispatch({
          type: ACTION_TYPES.SET_EVENT_CATEGORIES,
          payload: categories,
        });
      dispatch({ type: ACTION_TYPES.EVENT_SERVICE_SUCCESS });
      
      return new Promise((res) =>
        res({ proposals: data && data.length ? data : [], categories })
      );
    }
  } catch (error) {
    console.log("fetch proposal catch error ", error);
    dispatch(createAlert({ message: error.message, type: "error" }));
    dispatch({ type: ACTION_TYPES.EVENT_SERVICE_FAILED });
    return new Promise((res) => res(null));
  }
};

export const Notify = () => async (dispatch, getState) => {
  try {
    // dispatch({ type: ACTION_TYPES.NOTIFICATION_REQUEST });
    dispatch({ type: "NOTIFICATIONS_LIST", payload: true });
    const {
      user: { uid = "" },
    } = getState().user;

    const snap = await firestore
      .collection("notifications")
      .where("reciever", "array-contains-any", [uid])
      .orderBy("createdAt", "desc")
      .limit(10)
      .get();

    const notify = snap.docs.map((it) => it.data());
    let lastKey = 0;
    if(notify.length) lastKey = notify[notify.length - 1].createdAt;
    dispatch({ type: "NOTIFICATIONS_LIST", payload: false });
    return { notify: notify, lastKey: lastKey };
  } catch (error) {
    console.log(error);
    dispatch({ type: ACTION_TYPES.NOTIFICATION_FAILED });
    dispatch({ type: "NOTIFICATIONS_LIST", payload: false });
  }
};

export const NotifyNext = (key) => async (dispatch, getState) => {
  try {
    // dispatch({ type: ACTION_TYPES.NOTIFICATION_REQUEST });
    const {
      user: { uid = "" },
    } = getState().user;
    const snap = await firestore
      .collection("notifications")
      .where("reciever", "array-contains-any", [uid])
      .orderBy("createdAt", "desc")
      .startAfter(key)
      .limit(10)
      .get();

    const notify = snap.docs.map((it) => it.data());
    console.log("next notify", notify);

    const lastKey = notify[notify.length - 1].createdAt;

    return { notify: notify, lastKey: lastKey };
  } catch (error) {
    console.log(error);
    dispatch({ type: ACTION_TYPES.NOTIFICATION_FAILED });
  }
};

export const fetchVenderReviews =  (rev)=>async(dispatch, getState) =>{
  try {
    dispatch({ type: ACTION_TYPES.REVIEW_REQUEST });
    let payload = { reviews: [], avgRating: 0, totalReviews: 0 };
    let reviews = [];
    const ids = rev.map((r) => r.senderId);

    let temp = [];
    let users = [];
    for(let uid of ids){
      temp.push(uid)
      if(temp.length===10){
        const us = await firestore
        .collection("users")
        .where("userId", "in", temp)
        .get();
        users.push(...us.docs.map((doc) => doc.data()));
        temp = [];
      }
    }
    if(temp.length){
      const us = await firestore
      .collection("users")
      .where("userId", "in", temp)
      .get();
      users.push(...us.docs.map((doc) => doc.data()))
    }
    reviews = rev.map((r) => ({
      ...r,
      user: users.filter((u) => u.userId === r.senderId),
    }));
    let count = 0.0;
    reviews.forEach((e) => {
      count += e.rating;
    });
    const avgRating = count / reviews.length;
    const totalReviews = reviews.length;
    payload = { reviews, avgRating, totalReviews };

    dispatch({
      type: ACTION_TYPES.REVIEW_SUCCESS,
      payload: payload,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: ACTION_TYPES.REVIEW_FAILED})
  }
}