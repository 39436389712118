import React, { useState } from "react";
import { headerStyle } from "styles";
import { useSelector, useDispatch } from "react-redux";
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import moment from "moment";
import { strings } from "constant";
import { Confirm } from "components";
import { history } from "../../App";
import { Promote } from "containers";

import { logout, createAlert } from "actions";
const { auth, header, common } = strings;

export default (props) => {
  const classes = headerStyle();
  const dispatch = useDispatch();
  const { user = {}, isLoggedIn = false } = useSelector(({ user }) => user);
  const [dialog, setDailog] = useState(false);
  const onLogout = () => setDailog(true);
  const loggedIn = !!(isLoggedIn && user.uid && user.token);
  const handleLogout = () => {
    dispatch(logout());
    history.push("/");
  };

  const [promote, setPromote] = useState(false);
  const { event } = useSelector(({ event }) => event);
  const {hasEvent}  = useSelector(({ app })=> app)
  let authMenu = loggedIn
    ? [
        {
          title: common.Proposals,
          onClick: () => {
            history.push("/proposals");
          },
          isShown:
            user.type !== 2 ||
            (user.roles && user.roles.length && user.roles.indexOf(2) < 0),
        },
        {
          title: common.Guest, 
          onClick: () => {
           history.push("/guests");
          },
          isShown:
          hasEvent && loggedIn && user.type === 1,
        },

        {
          title: common.Chat,
          onClick: () => {
            history.push("/conversation");
          },
          isShown: user.type !== 2,
        },
        {
          title: "wallet",
          onClick: () => {
            history.push("/wallet");
          },
          isShown: true,
        },
        {
          title: "payment",
          onClick: () => {
            history.push("/payment");
          },
          isShown: user.type === 1,
        },
        {
          title: auth.Logout,
          onClick: () => {
            onLogout();
          },
          isShown: true,
        },
      ]
    : [
        { title: auth.Login, onClick: props.setLoginModal, isShown: true },
        { title: auth.SignUp, onClick: props.setSignupModal, isShown: true },
      ];
  return (
    <>
      <Drawer open={props.open} onClose={props.toggleDrawerHandler}>
        <div
          className={classes.list}
          role="presentation"
          onClick={props.toggleDrawerHandler}
          onKeyDown={props.toggleDrawerHandler}
        >
          <Divider />
          <List>
            {loggedIn ? (
              <ListItem button key={user.name+"1"}>
                <ListItemText
                  className={classes.profileMenuNameT}
                  primary={user.name}
                />
              </ListItem>
            ) : null}
            {loggedIn ? (
              <ListItem button onClick={()=>history.push("/account")}>
                <ListItemText primary={"My Account"} />
              </ListItem>
             
            ) : null}
            {hasEvent && loggedIn && user.type === 1 ? (
              <ListItem
                button
                key={user.name+"2"}
                onClick={
                  !event?.promoted
                    ? () => setPromote(true)
                    : () =>
                        dispatch(
                          createAlert({
                            message: `You already have an active plan. You can't promote before ${moment(
                              new Date(event.expiredDate)
                            ).format("DD MMM YYYY")}.`,
                            type: "info",
                            duration: 10000,
                          })
                        )
                }
              >
                <ListItemText
                  primary={event?.promoted ? "Promoted" : "Promote"}
                />
              </ListItem>
            ) : null}
            {authMenu.map((link, index) =>
              link.isShown ? (
                <ListItem button key={index} onClick={link.onClick}>
                  <ListItemText primary={link.title} />
                </ListItem>
              ) : null
            )}
          </List>
        </div>
      </Drawer>
      {event.id && !event?.promoted && isLoggedIn ? (
        <Promote open={promote} setOpen={setPromote} />
      ) : null}{" "}
      <Confirm
        open={dialog}
        onClose={() => setDailog(false)}
        title={auth.Logout}
        content={auth.LogoutContent}
        onClick={handleLogout}
        button={auth.Logout}
      />
    </>
  );
};
