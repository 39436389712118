import React, { useEffect } from "react";
import {
  guestLandStyle,
  eventStyle,
  commonStyle,
  giftStyle,
  commonButtonSmStyle,
  btLoaderStyle,
} from "styles";
import {
  Typography,
  Box,
  Grid,
  Button,
  CircularProgress,
} from "@material-ui/core";
import Carousel from "react-material-ui-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { strings } from "constant";
import { Loader, BreadCrumb, NoRecordFound, GoogleMap } from "components";
import moment from "moment";
import Invitation from "./invitation-detail";
import { fetchGifts, updateGift, getInvitation } from "actions";
const { events, common } = strings;

export default (props) => {
  const classes = eventStyle();
  const commClasses = commonStyle();
  const gclasses = guestLandStyle();
  const gfclasses = giftStyle();
  const dispatch = useDispatch();

  const history = useHistory();
  const { event = {} } = useSelector(({ event }) => event);
  console.log("eventDetails", event);
  const {
    uid = "",
    type = "",
    email = "",
  } = useSelector(({ user }) => user)?.user;

  const { loader = false, gifts:{giftLists} } = useSelector(({ gift }) => gift);
  // console.log("gifts", gifts)
  // const {giftLists} = gifts
  const {
    invitation = {},
    loader: { inviteLoader = false },
  } = useSelector(({ guest }) => guest);

  const { business = {} } = useSelector(({ vendor }) => vendor);
  const { hasVendor = false } = useSelector(({ app }) => app);
  const {
    images,
    spouse_name = "",
    event_date = "",
    event_location = "",
    event_name,
    latitude,
    longitude,
    guest_count,
  } = event;

  useEffect(() => {
    const call = async () => {
      dispatch(fetchGifts());
      dispatch(getInvitation(email, event.owners, event.id));
    };
    if (type === 2) {
      call();
    }
    dispatch({
      type: "GUEST_INVITATION_DETAIL",
      payload: [],
    });
  }, [JSON.stringify(event)]);

  const breads = [{ title: common.Home, path: "/" }];

  const handlePayCash = (giftId) => {
    history.push({ pathname: "/pay", giftId });
  };

  const onSelectRem = (item, status) => {
    dispatch(updateGift(item, status));
  };

  return (
    <Grid container justify="center" className={classes.eventMain}>
      <BreadCrumb breads={breads} current={common.EventDetails} />
      <Typography component="div" className={classes.eventTV}>
        <Box fontFamily="CormorantBold" className={classes.eventT}>
          {common.EventDetails}
        </Box>
      </Typography>
      {loader ? (
        <Loader />
      ) : (
        <div className={classes.crEventMain}>
          <Grid container justify="center" className={classes.eventInfoV}>
            {type === 2 && invitation?.eventId === event?.id && (
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                className={classes.eventInfoGV}
              >
                <Invitation />
              </Grid>
            )}
            <Grid item sm={12} xs={12} md={12} className={classes.crImageV}>
              <Carousel
                navButtonsAlwaysInvisible={false}
                indicators={true}
                autoPlay={true}
                timeout={500}
                animation="slide"
              >
                {images &&
                  images.map((image, i) => (
                    <img
                      key={Math.random() + "image-vend" + i}
                      src={image}
                      className={classes.eventDetailsCRImge}
                      alt=""
                    />
                  ))}
              </Carousel>
            </Grid>
            <Grid item sm={12} xs={12} md={6} className={classes.eventInfoGV}>
              <Typography Wrap component="div" className={classes.eventInfoTV}>
                <Box fontFamily="Gotham" className={classes.eventInfoHT}>
                  {"Event name"}
                </Box>
                <Box fontFamily="GothamBook" className={classes.eventInfoIT}>
                  {event_name}
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12} md={6} className={classes.eventInfoGV}>
              <Typography
                noWrap
                component="div"
                className={classes.eventInfoTV}
              >
                <Box fontFamily="Gotham" className={classes.eventInfoHT}>
                  {events.CoupleName}
                </Box>
                <Box fontFamily="GothamBook" className={classes.eventInfoIT}>
                  {spouse_name}
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12} md={6} className={classes.eventInfoGV}>
              <Typography component="div" className={classes.imageGridTV}>
                <Box fontFamily="Gotham" className={classes.eventInfoHT}>
                  {events.EventDate}
                </Box>
                <Box fontFamily="GothamBook" className={classes.eventInfoIT}>
                  {event_date && moment(event_date).format("DD MMM YYYY")}
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12} md={6} className={classes.eventInfoGV}>
              <Typography
                noWrap
                component="div"
                className={classes.imageGridTV}
              >
                <Box fontFamily="Gotham" className={classes.eventInfoHT}>
                  {events.GuestCount}
                </Box>
                <Box fontFamily="GothamBook" className={classes.eventInfoIT}>
                  {guest_count}
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12} md={12} className={classes.eventInfoGV}>
              <Typography Wrap component="div" className={classes.imageGridTV}>
                <Box fontFamily="Gotham" className={classes.eventInfoHT}>
                  {events.WeddingLocation}
                </Box>
                <Box fontFamily="GothamBook" className={classes.eventInfoIT}>
                  {event_location}
                </Box>
              </Typography>
            </Grid>

            <Grid item sm={12} xs={12} md={12}>
              <GoogleMap
                latitude={latitude}
                longitude={longitude}
                event_location={event_location}
              />
            </Grid>

            {invitation && invitation?.accepted && (
              <>
                <div className={commClasses.hairline} />
                <Typography component="div" className={classes.eventTV}>
                  <Box fontFamily="CormorantBold" className={classes.eventT}>
                    {common.Gift}
                  </Box>
                </Typography>
                {loader ? (
                  <Loader />
                ) :  giftLists && giftLists.length ? ( // <Loader />
                giftLists?.map(
                    (data, i) =>
                      data && (
                        <div className={gclasses.giftsLisCardV} key={i}>
                          {data.cashPayment === false ? (
                            <>
                              <div className={gfclasses.giftDetailModalBody}>
                                <Box
                                  fontFamily="Gotham"
                                  className={gfclasses.dialogGiftTitleT}
                                >
                                 
                                  {data?.gift_title}
                                </Box>
                                <Box
                                  fontFamily="Gotham"
                                  className={gfclasses.dialogTitleT}
                                >
                                  {common.Description}
                                </Box>
                                <Box
                                  fontFamily="GothamBook"
                                  className={gfclasses.dialogDetailT}
                                >
                                  {data?.gift_description}
                                </Box>
                                <Box
                                  fontFamily="Gotham"
                                  className={gfclasses.dialogTitleT}
                                >
                                  {common.DeliveryAddress}
                                </Box>
                                <Box
                                  fontFamily="GothamBook"
                                  className={gfclasses.dialogDetailT}
                                >
                                  {data?.delivery_address}
                                </Box>
                                {data?.quantity > data?.selected_by?.length ||
                                data?.selected_by?.indexOf(uid) > -1 ? (
                                  <Button
                                    disabled={loader}
                                    onClick={() =>
                                      onSelectRem(
                                        data,
                                        !!data?.selected_by &&
                                          data?.selected_by.length &&
                                          data.selected_by.indexOf(uid) > -1
                                      )
                                    }
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    style={commonButtonSmStyle}
                                  >
                                    {!!data?.selected_by &&
                                    data?.selected_by.length > 0 &&
                                    data?.selected_by.indexOf(uid) > -1
                                      ? common.RemoveSelection
                                      : common.Select}
                                    {loader ? (
                                      <CircularProgress
                                        size={"small"}
                                        style={btLoaderStyle}
                                      />
                                    ) : null}
                                  </Button>
                                ) : (
                                  <Box
                                    fontFamily="Gotham"
                                    className={gfclasses.dialogTitleT}
                                    style={{ fontSize: "16px" }}
                                  >
                                    {
                                      "This gift is reached at its maximum limits"
                                    }
                                  </Box>
                                )}
                              </div>
                            </>
                          ) : (
                            <Button onClick={() => handlePayCash(data.id)}>
                              Pay Cash
                            </Button>
                          )}
                        </div>
                      )
                  )
                ) : (
                  <NoRecordFound />
                )}
              </>
            )}

            {/* //*Show this option only for vendor */}
            {business?.id && type ===3? (
              <>
                <div className={commClasses.hairline} />
                <Button
                  onClick={() =>
                    props.history.push({
                      pathname: "/category",
                      state: {
                        from_eventDetail: true,
                        multi: false,
                        categories: business?.categories,
                      },
                    })
                  }
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.button}
                >
                  {"Send Proposal"}
                </Button>
              </>
            ) : null}
            {(event.promoted && event.event_type === 2) ||
            (invitation && invitation?.accepted) ? (
              <>
                <div className={commClasses.hairline} />
                <Button
                  onClick={() =>
                    props.history.push({
                      pathname: "/gallery",
                      state: { from_search_result: true },
                    })
                  }
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.button}
                >
                  {"Gallery"}
                </Button>
              </>
            ) : null}
          </Grid>
        </div>
      )}
    </Grid>
  );
};
