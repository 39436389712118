import React, { useState } from "react";
import { eventStyle } from "styles";
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  TextareaAutosize
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { colors } from "constant";
import { acceptInvitation, rejectInvitation } from "actions";
import { Loader,Confirm} from "components";

function Invitation() {
  const classes = eventStyle();
  const dispatch = useDispatch();

  const [accept, setAccept] = useState(false);
  const [code, setCode] = useState("");

  const [reject, setReject] = useState(false);
  const [reason, setReason] = useState("");

  const [info,setInfo] = useState(false);

  const [error, setError] = useState("");
  const {loader=false, invitation = {} } = useSelector(({ guest }) => guest);
  const { event = {} } = useSelector(({ event }) => event);

  const { spouse_name = "" } = event;

  const handleInfoDown = (e) => {
    e.preventDefault();
  }

  const handleAccept = () => {
    if (invitation?.inviteCode === code) {
      console.log("Accepted");
      dispatch(acceptInvitation());
    } else {
      setError("Invalid invitation code");
    }
  };

  const handleReject = () => {
      dispatch(rejectInvitation(reason));
  };

  const handleInputChange = (e) =>{
    setCode(e.target.value)
    setError("")
  }

  const handleAcceptBtn = () => {
    setAccept((p)=>!p)
    setError("")
  }

  return (
    <>
    {
    loader?<Loader />:
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {!invitation?.accepted && !invitation?.rejected ? (
        <>
          <div
            style={{
              fontFamil: "Gotham",
              color: colors.main,
              fontSize: "24px",
            }}
          >
            Invitation Pending
          </div>
          <div style={{ fontFamil: "Gotham", color: colors.main }}>
            {`You have recevied wedding invitation from ${spouse_name}`}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {!accept && !reject ? (
              <Button
                onClick={() => setAccept((p) => !p)}
                variant="contained"
                size="large"
                className={classes.accepted}
              >
                {"Accept"}
              </Button>
            ) : (
              <>
                {accept ? (
                  <div className={classes.inviteButton}>
                    <TextField
                      onChange={handleInputChange}
                      value={code}
                      placeholder={"Enter invite Code"}
                      variant="outlined"
                      error={error}
                      // className={classes.inviteButtonChild1}
                      InputProps={{
                        className:classes.inviteButton1,
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              // aria-label='toggle password visibility'
                              onClick={()=>setInfo((prevValue)=>!prevValue)}
                              onMouseDown={handleInfoDown}
                              >
                              <InfoOutlined />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      helperText={error}
                    />
                    <div className={classes.sameLine}>
                    <Button
                      variant="contained"
                      onClick={handleAccept}
                      className={classes.acceptBtn}
                    >
                      {loader ? <Loader /> : "Submit"}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleAcceptBtn}
                      className={classes.acceptBtn}
                    >
                      Cancel
                    </Button>
                    </div>
                  </div>
                ) : null}
              </>
            )}
            {!accept && !reject ? (
              <Button
                onClick={() => setReject((p) => !p)}
                variant="contained"
                size="large"
                className={classes.rejected}
              >
                {"Reject"}
              </Button>
            ) : (
              <>
                {reject ? (
                  <div className={classes.inviteButton}>
                    <TextField
                      multiline={true}
                      variant="outlined"
                      aria-label="minimum height"
                      onChange={(e) => setReason(e.target.value)}
                      value={reason}
                      placeholder="Reason ...."
                      style={{ width: 300 }}
                    />
                    {error}
                    <div className = {classes.sameLine}>
                    <Button
                      onClick={handleReject}
                      className={classes.acceptBtn}
                      variant="contained"
                    >
                      {loader ? <Loader /> : "Submit"}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => setReject((p) => !p)}
                      className={classes.acceptBtn}
                    >
                      Cancel
                    </Button>
                    </div>
                  </div>
                ) : null}
              </>
            )}
          </div>
        </>
      ) : (
        <>
          {invitation?.accepted ? (
            <Box
              fontFamily="CormorantBold"
              style={{ color: colors.green, fontSize: "24px" }}
            >
              {"Invitation Accepted"}
            </Box>
          ) : (
            <Box
              fontFamily="CormorantBold"
              style={{ color: colors.red, fontSize: "24px" }}
            >
              {"Invitation Rejected"}
            </Box>
          )}
        </>
      )}
      {
        info && <Confirm
          open={info}
          onClose={() => setInfo(false)}
          title={"Wedding Blizz"} 
          content={"Check your registratered email for invite code."} 
          action={false} 
        />
      }
      </div>
    }</>
  );
}

export default Invitation;
