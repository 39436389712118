import React, { useState, useEffect, useRef } from "react";
import { headerStyle, navButtons, headerProfielIcon, menuIcon } from "styles";
import {
  Button,
  Box,
  List,
  ListItem,
  Popover,
  IconButton,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
  AccountCircle,
  ExpandMoreOutlined,
  ExpandLessOutlined,
  NotificationsNoneOutlined,
} from "@material-ui/icons";
import moment from "moment";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { logout, createAlert } from "actions";
import { strings } from "constant";
import MenuIcon from "./menuicon";
import Drawer from "./drawer";
import Logo from "./logo";
import { Confirm, Notification } from "components";
import { SignupModal, LoginModal, ForgotModal, Search } from "components";
import { Promote } from "containers";
const { auth, common } = strings;

function useOutsideAlerter(ref, setOpenNotify) {
  console.log("clickss outside");
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenNotify(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);
}

export default (props) => {
  const classes = headerStyle();
  const [open, openDrawer] = useState(false);
  const [login, setLoginModal] = useState(false);
  const [type, setType] = useState("couple");
  const [signup, setSignupModal] = useState(false);
  const [forgot, setForgotModal] = useState(false);
  const [popup, setPopup] = useState(null);

  const { user = {}, isLoggedIn = false } = useSelector(({ user }) => user);
  const {hasEvent=false}  = useSelector(({ app })=> app)

  const [openNotify, setOpenNotify] = useState(false);

  const { history } = props;
  const onSignIn = () => {
    setSignupModal(false);
    setForgotModal(false);
    setTimeout(() => {
      setLoginModal(true);
    }, 300);
  };

  const onSignUp = () => {
    setLoginModal(false);
    setForgotModal(false);
    setTimeout(() => {
      setSignupModal(true);
    }, 300);
  };

  const onChangeLogin = () => {
    setLoginModal(false);
    setType(type === "couple" ? "vendor" : "couple");
    setTimeout(() => {
      setLoginModal(true);
    }, 300);
  };

  const onForgot = () => {
    setLoginModal(false);
    setSignupModal(false);
    setForgotModal(true);
  };

  const handleNotification = () => {
    console.log("clicked icon");
    setOpenNotify((p) => !p);
  };

  const wrapperRef = useRef(null);
  // useOutsideAlerter(wrapperRef, setOpenNotify);

  return (
    <>
      <List
        component="nav"
        className={classes.header}
        // style={{ justifyContent: "space-between" }}
      >
        <ListItem
          className={classes.header}
          // style={{ justifyContent: "space-between" }}
          component="nav"
          variant="dense"
        >
          <Logo />
          {isLoggedIn && (
            <div className={classes.linksView}>
              <Search />
            </div>
          )}
          {isLoggedIn && (
            <div className={classes.notifyIcon}>
              <IconButton onClick={handleNotification} ref={wrapperRef}>
                <NotificationsNoneOutlined className={classes.colorMain} />
              </IconButton>
            </div>
          )}
          <LoggedInUser
            setSignupModal={setSignupModal}
            setLoginModal={setLoginModal}
            open={popup}
            history={history}
          />

          {/* Menu for phone screen */}
          <div className={classes.drawerView}>
            <MenuIcon openDrawerHandler={() => openDrawer(true)} />
            <Drawer
              open={open}
              toggleDrawerHandler={() => openDrawer(false)}
              setSignupModal={setSignupModal}
              setLoginModal={setLoginModal}
            />
          </div>
        </ListItem>

        {login && (
          <LoginModal
            modal={login}
            setModal={setLoginModal}
            onSignUp={onSignUp}
            type={type}
            onChangeLogin={onChangeLogin}
            onForgot={onForgot}
          />
        )}
        {signup && (
          <SignupModal
            modal={signup}
            setModal={setSignupModal}
            onSignIn={onSignIn}
          />
        )}
        {forgot && (
          <ForgotModal
            modal={forgot}
            setModal={setForgotModal}
            onSignIn={onSignIn}
          />
        )}
      </List>
      {openNotify && isLoggedIn && (
        <Notification setOpenNotify={setOpenNotify} openNotify={openNotify} />
      )}
    </>
  );
};

//*Menu for PC screen
const LoggedInUser = ({
  setLoginModal = () => {},
  setSignupModal = () => {},
  open = false,
  history,
}) => {
  const classes = headerStyle();
  const { user = {}, isLoggedIn = false } = useSelector(({ user }) => user);
  const {hasEvent=false}  = useSelector(({ app })=> app)

  const { event } = useSelector(({ event }) => event);
  const [dialog, setDailog] = useState(false);
  const [promote, setPromote] = useState(false);
  const dispatch = useDispatch();
  const onLogout = () => setDailog(true);

  const setRoute = (route) => {
    history.push(route);
  };
  const handleLogout = () => {
    console.log("heerrerre");
    dispatch(logout());
    history.push("/");
  };
  const handlePopClick = (popupState, route) => {
    popupState.close();
    setRoute(route);
  };
  return (
    <>
      {isLoggedIn && user.uid && user.token ? (
        <PopupState variant="popover" popupId="demo-popup-popover">
          {(popupState) => (
            <div>
              <div
                className={classes.profileMenuV}
                {...bindTrigger(popupState)}
              >
                <AccountCircle size="small" style={headerProfielIcon} />
                <Box
                  variant="button"
                  fontFamily="Gotham"
                  className={`${classes.profileMenuNameT}`}
                >
                  {user.name}
                </Box>
                {!open ? (
                  <ExpandMoreOutlined style={menuIcon} />
                ) : (
                  <ExpandLessOutlined style={menuIcon} />
                )}
              </div>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <div className={classes.popoverV}>
                  <Box
                    variant="button"
                    fontFamily="Gotham"
                    className={classes.popoverT}
                    onClick={() => handlePopClick(popupState, "/account")}
                  >
                    {auth.MyAccount}
                  </Box>
                  {hasEvent && user.type === 1 && (
                    <Box
                      variant="button"
                      onClick={
                        !event?.promoted
                          ? () => setPromote(true)
                          : () =>
                              dispatch(
                                createAlert({
                                  message: `You already have an active plan. You can't promote before ${moment(
                                    new Date(event.expiredDate)
                                  ).format("DD MMM YYYY")}.`,
                                  type: "info",
                                  duration: 10000,
                                })
                              )
                      }
                      fontFamily="Gotham"
                      className={classes.popoverT}
                    >
                      {/* Promote */}
                      {event?.promoted ? "Promoted" : "Promote"}
                    </Box>
                  )}
                  {(user.type !== 2 ||
                    (user.roles &&
                      user.roles.length &&
                      user.roles.indexOf(2) < 0)) && (
                    <Box
                      variant="button"
                      fontFamily="Gotham"
                      className={classes.popoverT}
                      onClick={() => handlePopClick(popupState, "/proposals")}
                    >
                      {common.Proposals}
                    </Box>
                  )}
                    {
                      hasEvent &&  user.type === 1 && (
                      <Box
                        variant="button"
                        fontFamily="Gotham"
                        className={classes.popoverT}
                        onClick={()=>handlePopClick(popupState, "/guests")}
                        >
                           {common.Guest}
                        </Box>
                      )}
                      
                  {user.type === 2 &&
                    user.roles &&
                    user.roles.length &&
                    user.roles.indexOf(2) > -1 &&
                    user.roles.indexOf(1) > -1 && (
                      <Box
                        variant="button"
                        fontFamily="Gotham"
                        className={classes.popoverT}
                        onClick={() =>
                          handlePopClick(popupState, "/eventdetail")
                        }
                      >
                        {common.MyEvent}
                      </Box>
                    )}
                  {user.type !== 2 ? (
                    <Box
                      variant="button"
                      fontFamily="Gotham"
                      className={classes.popoverT}
                      onClick={() =>
                        handlePopClick(popupState, "/conversation")
                      }
                    >
                      {common.Chat}
                    </Box>
                  ) : null}
                  {user.type === 2 &&
                    user.roles &&
                    user.roles.length &&
                    user.roles.indexOf(2) > -1 &&
                    user.roles.indexOf(3) > -1 && (
                      <Box
                        variant="button"
                        fontFamily="Gotham"
                        className={classes.popoverT}
                        onClick={() => handlePopClick(popupState, "/vendor")}
                      >
                        {common.Vendor}
                      </Box>
                    )}
                  {user.type === 3 && (
                    <Box
                      variant="button"
                      fontFamily="Gotham"
                      className={classes.popoverT}
                      onClick={() => handlePopClick(popupState, "/reviews")}
                    >
                      {common.Reviews}
                    </Box>
                  )}
                  <Box
                    variant="button"
                    onClick={() => handlePopClick(popupState, "/wallet")}
                    fontFamily="Gotham"
                    className={classes.popoverT}
                  >
                    Wallet
                  </Box>
                  {user.type === 1 && (
                    <Box
                      variant="button"
                      onClick={() => handlePopClick(popupState, "/payment")}
                      fontFamily="Gotham"
                      className={classes.popoverT}
                    >
                      Payment
                    </Box>
                  )}

                  <Box
                    variant="button"
                    onClick={onLogout}
                    fontFamily="Gotham"
                    className={classes.popoverT}
                  >
                    {auth.Logout}
                  </Box>
                </div>
              </Popover>
            </div>
          )}
        </PopupState>
      ) : (
        <div className={classes.buttonView}>
          <Button
            onClick={() => setLoginModal(true)}
            variant="outlined"
            size="small"
            color="primary"
            style={navButtons}
            mr={4}
          >
            {auth.Login}
          </Button>
          <Button
            onClick={() => setSignupModal(true)}
            variant="contained"
            size="small"
            color="primary"
            style={navButtons}
            mr={4}
          >
            {auth.SignUp}
          </Button>
        </div>
      )}
      {event.id && !event?.promoted && isLoggedIn ? (
        <Promote open={promote} setOpen={setPromote} />
      ) : null}

      <Confirm
        open={dialog}
        onClose={() => setDailog(false)}
        title={auth.Logout}
        content={auth.LogoutContent}
        onClick={handleLogout}
        button={auth.Logout}
      />
    </>
  );
};
