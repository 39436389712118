import React, { useState, useEffect, useRef } from "react";
import {
  chatStyle,
  navIconStyle,
  proposalStyle,
  msgIconStyle,
  sendIconStyle,
  sendIconActiveStyle,
  leftSideBubble,
  rightSideBubble,
  proposeDetailStyle,
} from "styles";
import {
  CloseRounded,
  RemoveRounded,
  QuestionAnswerRounded,
  Send,
  AddPhotoAlternateOutlined,
} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import { useLocation } from "react-router-dom";
import { InputBase, Box, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { ProposalDetail } from "containers";
import { strings } from "constant";
import { NoRecordFound } from "components";
import { getChat, sendMessage } from "actions";
import { uploadImagesS3 } from "helpers";
import moment from "moment";
const { common } = strings;

export default (props) => {
  const location = useLocation();
  const classes = chatStyle();
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  let inputVRef = null;
  const {
    chats = [],
    _loader = false,
    detail = {},
  } = useSelector(({ chat }) => chat);
  console.log(detail);

  const {
    title,
    subTitle,
    visible = "",
    proposal = false,
    setVisible = () => {},
  } = props;
  const [message, setMessage] = useState("");

  useEffect(() => {
    dispatch(getChat());
  }, [detail.id]);

  useEffect(() => scrollBottom(), [inputVRef, JSON.stringify(chats)]);

  const handleMessage = (e) => {
    e.preventDefault();
    if (message) {
      dispatch(sendMessage(message));
      setMessage("");
    }
  };
  const scrollBottom = () => {
    if (!proposal)
      inputVRef &&
        inputVRef.scrollIntoView({ behavior: "smooth", alignToTop: false });
  };
  const handleChatMessage = (value, key, target) => {
    console.log("kkkk ", target.key, target.value);
    setMessage(value);
  };

  const onChangeFile = async (e) => {
    var file = e.target.files[0];
    console.log(file);
    const imageUrl = await uploadImagesS3(file, "conversations");

    if (imageUrl) {
      console.log(imageUrl);
      dispatch(sendMessage(imageUrl, true));
    } else {
      console.error("some error occurred");
    }
  };

  return (
    <>
      {visible === "hide" ? (
        <CloseIcon handleClick={() => setVisible("active")} />
      ) : visible === "active" ? (
        <div className={classes.chatMain}>
          <div className={classes.chatHeaderMain}>
            <div className={classes.closeMain}>
              <RemoveRounded
                style={navIconStyle}
                onClick={() => setVisible("hide")}
              />
              <CloseRounded
                style={navIconStyle}
                onClick={() => setVisible("close")}
              />
            </div>
            <div className={classes.chatHeaderV}>
              <Box fontFamily="GothamBook" className={classes.chatHeaderTT}>
                {title || detail?.user?.name}
              </Box>
              {/* <Box fontFamily="GothamBook" className={classes.chatHeaderST}>
                {subTitle}
              </Box> */}
            </div>
          </div>
          <div className={classes.chatBodyMain}>
            {proposal && <ProposalDetail chat={true} />}
            {!proposal && <Chats chats={chats} />}
            {!proposal && <div ref={(ref) => (inputVRef = ref)} />}
          </div>
          {location.pathname !== "/proposals" ? (
            <form onSubmit={handleMessage}>
              <div className={classes.inputV}>
                <InputBase
                  className={classes.input}
                  placeholder={common.TypeMessage}
                  value={message.type ? "" : message}
                  onChange={({ target, ...key }) =>
                    handleChatMessage(target.value, key, target)
                  }
                />
                <IconButton>
                  <AddPhotoAlternateOutlined
                    onClick={() => inputFile.current.click()}
                  />
                </IconButton>
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                  onChange={onChangeFile}
                />

                <Send
                  type="submit"
                  style={message ? sendIconActiveStyle : sendIconStyle}
                  className={classes.messageSendBtn}
                  onClick={handleMessage}
                />
              </div>
            </form>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

const CloseIcon = ({ handleClick = () => {} }) => {
  const classes = chatStyle();
  return (
    <div onClick={handleClick} className={classes.chatCloseMain}>
      <QuestionAnswerRounded style={msgIconStyle} />
    </div>
  );
};

const Chats = ({ chats = [], loader = false }) => {
  const classes = proposalStyle();
  const { uid = "" } = useSelector(({ user }) => user)?.user;
  console.log("chats", chats, uid);

  return (
    <div>
      {chats && chats.length ? (
        chats.map((chat, index) => (
          <div
            className={
              chat ? classes.mainDetailBodyCHV : classes.mainDetailBodyCHV
            }
            key={`${index}-chat-list`}
            style={
              chat.sender_id === uid || chat.senderId === uid
                ? rightSideBubble
                : leftSideBubble
            }
          >
            <div className={classes.chatlistCard} style={proposeDetailStyle}>
              {chat.isMedia || chat.media ? (
                <img
                  style={{ height: "90%", width: "90%" }}
                  src={chat.message}
                  alt="media"
                />
              ) : (
                <Typography Wrap component="div">
                  <Box fontFamily="GothamBook" className={classes.proposeMT}>
                    {chat?.message}
                  </Box>
                </Typography>
              )}
            </div>
            <Box fontFamily="GothamBook" className={classes.prosposalDateT}>
              {chat?.createdAt
                ? moment(new Date(chat.createdAt)).format(
                    "MMMM Do YYYY hh:mm A"
                  )
                : ""}
            </Box>
          </div>
        ))
      ) : (
        <NoRecordFound text={common.NoChatRecord} />
      )}
    </div>
  );
};
