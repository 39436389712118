import { ACTION_TYPES } from "constant";
const INITIAL_STATE = {
  loader: false,
  _loader: false,
  images: [],
  users: [],
  coments: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.GALLERY_REQUEST:
      return { ...state, loader: true };
    case ACTION_TYPES.GALLERY_COMPLETE:
      return { ...state, loader: false };
    case ACTION_TYPES.GALLERY_SUCCESS:
      return { ...state, loader: false, images: action.payload };
    case ACTION_TYPES.GALLERY_FAILED:
      return { ...state, loader: false };
    case ACTION_TYPES.GALLERY_COMMENTS_REQUEST:
      return { ...state, _loader: true };
    case ACTION_TYPES.GALLERY_COMMENTS_SUCCESS:
      return { ...state, coments: action.payload, _loader: false };
    case ACTION_TYPES.GALLERY_COMMENTS_FAILED:
      return { ...state, _loader: false };

    case ACTION_TYPES.RESET:
      return INITIAL_STATE;
    case ACTION_TYPES.LOG_OUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};
