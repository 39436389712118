import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Rating from "@material-ui/lab/Rating";
import { Typography, Grid, Box, Button } from "@material-ui/core";
import {
  eventStyle,
  commonStyle,
  primaryLoaderStyle,
  btSmallIcon,
} from "styles";
import { strings,ACTION_TYPES } from "constant";
import Carousel from "react-material-ui-carousel";
import {
  BreadCrumb,
  Loader,
  QuoteModal,
  ReviewModal,
  Chat as ChatComponent,
} from "components";

import { Chat, Assignment } from "@material-ui/icons";
import { setChat, fetchVenderRatings,fetchVenderReviews } from "actions";
const { events, common } = strings;

const NoImageFound = "https://www.salonlfc.com/wp-content/uploads/2018/01/image-not-found-scaled-1150x647.png"
export default (props) => {
  const classes = eventStyle();
  const commClasses = commonStyle();
  const [chtprop, setChatt] = useState({
    title: "",
    subTitle: "",
    visible: null,
  });

  const setVisible = (type) => setChatt({ ...chtprop, visible: type });
  const [quote, showQuote] = useState(false);
  const [review, showReview] = useState(false);

  const breads = [{ title: common.Home, path: "/" }];
  const {
    vendor = {},
    loader = false,
    _loader = false,
    eloader = false,
    reviews = {},
    event,
    bookedVenders = [],
  } = useSelector(({ event }) => event)

  const [booked, updateBooked] = useState(false);
  const dispatch = useDispatch();

  const { business = {} } = useSelector(({ vendor }) => vendor);
  const { type, uid } = useSelector(({ user }) => user.user);
  const { categories = [], hasEvent = false } = useSelector(({ app }) => app);

  console.log(hasEvent);

  console.log("display", business, uid, vendor);
  useEffect(() => {
    dispatch({type: ACTION_TYPES.CHAT_SUCCESS,chats:[]});
    const user = {
      business_id: vendor?.ownerId,
      id: `${vendor?.ownerId}_${uid}`,
      id2: `${uid}_${vendor?.ownerId}`,
      user: { name: vendor?.name },
    };
    dispatch(setChat(user));
  }, []);

  useEffect(() => {
    dispatch({type: ACTION_TYPES.CHAT_SUCCESS,chats:[]});
    const user = {
      business_id: vendor?.ownerId,
      id: `${vendor?.ownerId}_${uid}`,
      id2: `${uid}_${vendor?.ownerId}`,
      user: { name: vendor?.vendor_name? vendor?.vendor_name: vendor?.business_name },
    };
    dispatch(setChat(user));
    updateBooked(
      bookedVenders.map((id) => id.business_id).includes(vendor?.id)
    );
  }, [JSON.stringify(vendor)]);
  console.log("props.locations");

  const handleChatOpen = () => {
    const obj = { 
      ...chtprop, title: vendor.vendor_name?vendor.vendor_name:vendor.business_name,visible: "active" 
    }
    console.log("chats >>",obj,vendor);
    setChatt({ ...chtprop, title: vendor.name,visible: "active" });
  };

  const handleQuaoteReview = () => {
    if (booked) {
      showReview(true);
      showQuote(false);
    } else {
      showQuote(true);
      props.history.push({
        pathname: "/category",
        state: {
          from_eventDetail: true,
          multi: false,
          categories: vendor.categories,
        },
      });
      showReview(false);
    }
  };

  const showReviews = ()=>{
    //*Next milestone just unComment
    if(reviews?.totalReviews > 0) {
      dispatch(fetchVenderReviews(reviews.reviews));
      props.history.push("/reviews")
    }
  }

  return (
    <Grid container justifyContent="center" className={classes.eventMain}>
      <BreadCrumb breads={breads} current={events.VendorDetail} />
      <Typography component="div" className={classes.eventTV}>
        <Box fontFamily="CormorantBold" className={classes.eventT}>
          {events.VendorDetail}
        </Box>
      </Typography>
      {eloader || _loader? (
        <Loader style={primaryLoaderStyle} time={5000}/>
      ) : (
        <div className={classes.eventVendorDetailMain}>
          <div className={classes.eventVendorInfoVendV}>
            <Grid container justifyContent="center">
              <Grid item sm={12} xs={10} md={12} className={classes.crImageV}>
                <Carousel
                  navButtonsAlwaysInvisible={false}
                  indicators={true}
                  autoPlay={true}
                  timeout={500}
                  animation="slide"
                >
                  <img
                    src={vendor?.images && vendor?.images.length ? vendor?.images[0]:NoImageFound}
                    className={classes.eventVendDetailsCRImge}
                    alt=""
                  />
                  {/* {vendor?.images &&
                    vendor?.images.length &&
                    vendor?.images.map((image, i) => (
                                        <img
                    key={Math.random() + "image-vend-detail" + i}
                    src={vendor?.images[0]}
                    className={classes.eventVendDetailsCRImge}
                    alt=""
                  />
                    ))} */}
                </Carousel>
              </Grid>
              <Grid item sm={12} xs={12} md={8} className={classes.eventInfoGV}>
                <Typography
                  noWrap
                  component="div"
                  className={classes.eventInfoTV}
                >
                  <Box fontFamily="Gotham" className={classes.eventInfoHT}>
                    {events?.VendorName}
                  </Box>
                  <Box fontFamily="GothamBook" className={classes.eventInfoIT}>
                    {vendor ? vendor?.business_name : "Loading..."}
                  </Box>
                </Typography>
              </Grid>
              <Grid item sm={12} xs={12} md={4} className={classes.eventInfoGV}>
                <Typography
                  noWrap
                  component="div"
                  className={classes.eventInfoTV}
                  onClick={()=>showReviews(vendor.id)}
                  style={{cursor:"pointer"}}
                >
                  <Box fontFamily="Gotham" className={classes.eventInfoHT}>
                    {common.Rating}
                  </Box>
                  <Box
                    fontFamily="GothamBook"
                    className={classes.eventVendDetailHT}
                  >
                    <Rating
                      value={
                        reviews && reviews?.avgRating ? reviews?.avgRating : 0
                      }
                      precision={0.5}
                      readOnly
                      size="small"
                    />
                    {reviews && reviews?.avgRating ? reviews?.avgRating : 0}
                    <div className={classes.smallDot} />
                    {reviews && reviews?.totalReviews
                      ? reviews?.totalReviews+" Reviews"
                      : 0+" Reviews"}
                  </Box>
                </Typography>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                className={classes.eventInfoGV}
              >
                <Typography
                  noWrap
                  component="div"
                  className={classes.eventInfoTV}
                >
                  <Box fontFamily="Gotham" className={classes.eventInfoHT}>
                    {common.Address}
                  </Box>
                  <Box fontFamily="GothamBook" className={classes.eventInfoIT}>
                    {`${vendor?.city} ${vendor?.state}, ${vendor?.zip_code}`}
                  </Box>
                </Typography>
              </Grid>
              <div className={commClasses.hairline} />
              <Grid item sm={12} xs={12} md={12}>
                <Typography component="div" className={classes.eventSubV}>
                  <Box fontFamily="CormorantBold" className={classes.eventSubT}>
                    {events.VendingServicesOffered}
                  </Box>
                </Typography>

                <div className={classes.vendorCategoryV}>
                  {categories
                    .filter(
                      (it) =>
                        vendor?.categories &&
                        vendor?.categories.length &&
                        vendor?.categories.includes(it.id)
                    )
                    .map((item, index) => (
                      <div
                        className={classes.eventVendorsV}
                        key={
                          Math.round() +
                          "-" +
                          item?.title +
                          index +
                          "vend-round"
                        }
                      >
                        <img
                          src={item?.icon}
                          className={classes.vendorImg}
                          alt=""
                        />
                        <Box
                          fontFamily="Gotham"
                          className={classes.vendorImgAlt}
                        >
                          {item?.name}
                        </Box>
                      </div>
                    ))}
                </div>
              </Grid>
              {type === 1 && uid !== vendor?.ownerId && hasEvent === true && (
                <>
                  <div className={commClasses.hairline} />
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    className={classes.vendorDetailBLV}
                  >
                    <div
                      className={classes.chatButtonV}
                      onClick={handleChatOpen}
                    >
                      <Box
                        fontFamily="GothamBook"
                        className={classes.vendorDetButT}
                      >
                        <Chat style={btSmallIcon} />
                        {common.Chat}
                      </Box>
                    </div>
                  </Grid>
                  {!business && (
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      className={classes.vendorDetailBRV}
                    >
                      <div
                        className={classes.chatButtonV}
                        onClick={handleQuaoteReview}
                      >
                        <Box
                          fontFamily="GothamBook"
                          className={classes.vendorDetButT}
                        >
                          <Assignment style={btSmallIcon} />
                          {event
                            ? booked
                              ? common.WriteReview
                              : common.GetAQuote
                            : null}
                        </Box>
                      </div>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </div>
        </div>
      )}
      {chtprop.visible === "active" && (
        <ChatComponent {...chtprop} setVisible={setVisible} />
      )}
      {review && (
        <ReviewModal
          modal={review}
          onClose={() => showReview(false)}
          vendor={vendor}
        />
      )}
      {/* {quote && <QuoteModal modal={quote} onClose={() => showQuote(false)} />} */}
    </Grid>
  );
};
