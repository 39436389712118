import { firestore, insert, incrementWalletAmount, updateOne } from "helpers";
import axios from "axios";
import { createAlert } from "actions";
import { ACTION_TYPES } from "constant";
import { sha256 } from "js-sha256";
const url = process.env.REACT_APP_STRIPE_URL;

const getFixed = (num)=>{
  const str = String(num);
  let fixed = "";
  if(str.includes(".")){
    const start = str.split(".")[0].length;
    fixed = str.split(".")[1].length>=2 ? start+3:start+2;
    let val = Number(str.slice(0,fixed))
    return val
  }
  else{
    return Number(str)
  }
}

export const getWallet = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.TRANSACTION_SUCCESS });
    const {
      user: { uid = "" },
    } = getState().user;
    const wallet = await firestore
      .collection("wallet")
      .where("userId", "==", uid)
      .get();
    const wdata = wallet.docs.map((d) => d.data());
    if (wdata.length) {
      dispatch({ type: "WALLET", payload: {...wdata[0],amount: getFixed(wdata[0].amount)} });
      return wdata[0];
    }
  } catch (err) {
    console.log(err);
    dispatch({ type: ACTION_TYPES.TRANSACTION_FAILED });
    dispatch(createAlert({ message: err.message ? err.message:err, type: "error" }));
  }
};

export const createWallet = (oId, data, card) => async (dispatch, getState) => {
  try {
    let {
      user: { uid = "" },
    } = getState().user;

    if (oId) uid = oId;

    const wall = await firestore
      .collection("wallet")
      .where("userId", "==", uid)
      .get();
    const wdata = wall.docs.map((d) => d.data());
    
    //CASE: 1 --> if wallet already exists
    if (wdata.length) {
      const totalAmount = wdata[0].amount + data.chargesAmount? data.chargesAmount:data.amount; 
      const walletData = {
        id: wdata[0].id,
        userId: uid,
        delete: false,
        modifiedAt: data.createdAt ? data.createdAt : new Date().getTime(),
      };
      console.log("wallet data: " + walletData)
      
      

      // console.log("dataaaa", data);
      //2. update total amount and modified time
      await firestore
        .doc(`wallet/${wdata[0]?.id}`)
        .set({ ...wdata[0], ...walletData, amount:getFixed(wdata[0].amount)});

      const resp = await incrementWalletAmount(
        wdata[0].id,
        data.chargesAmount? data.chargesAmount:data.amount,
        data.created
      );
      console.log("resp", resp)
      //1 amount added into wallet, 2 paid/received, 3 trasfer to bank
      let typeId;
      if(data.type) typeId = data.type;//3
      else typeId = data?.id ? 1 : 2;

      const transactionData = {
        amount: data.chargesAmount ? Math.abs(data.chargesAmount):  Math.abs(data.amount),
        createAt: data.createdAt ? data.createdAt : new Date().getTime(),
        modifiedAt: new Date().getTime(),
        amountUnit: "$",
        businessId: data.businessId ? data.businessId : null,
        credit: data.fromId || data.card ? true: false,
        deleted: false,
        fromId: data.fromId ?  data.fromId:data.card?`**** **** **** ${data.card.last4}` : null,
        message: data.message ? data.message : "",
        toId: data.toId ? data.toId : null,
        transactionId: data.id ? data.id : null,
        //data.id --> transaction ID
        type: typeId,
      };
      console.log("transacrionData>>", transactionData);

      const { id } = firestore
        .collection("wallet")
        .doc(wdata[0]?.id)
        .collection("transactions")
        .doc();

      await firestore
        .collection("wallet")
        .doc(wdata[0]?.id)
        .collection("transactions")
        .doc(id)
        .set(transactionData);
    } else {
      //CASE: 2 --> if wallet does not exists for a user

      const amount = data.chargesAmount ? data.chargesAmount : data.amount;
      const walletData = {
        amount: amount,
        userId: uid,
        deleted: false,
        createAt: data.created ? data?.created : new Date().getTime(),
      };
      const { id: wallet_id } = await insert("wallet", walletData);

      const transactionData = {
        amount: data.chargesAmount ? Math.abs(data.chargesAmount):  Math.abs(data.amount),
        amountUnit: "$",
        businessId: data.businessId ? data.businessId : null,
        credit: data.fromId || data.card ? true: false,
        deleted: false,
        fromId: data.fromId ?  data.fromId:data.card?`**** **** **** ${data.card.last4}` : null,
        message: data.message ? data.message : "",
        toId: data.toId ? data.toId : null,
        transactionId: data.id ? data.id : null,
        createAt: new Date().getTime(),
        modifiedAt: new Date().getTime(),
        type: data.id ? 1 : 2,
      };
      console.log("transacrionData", transactionData);
      const { id = "" } = firestore
        .collection("wallet")
        .doc(wallet_id)
        .collection("transactions")
        .doc();

      await firestore
        .collection("wallet")
        .doc(wallet_id)
        .collection("transactions")
        .doc(id)
        .set(transactionData);
    }
    dispatch(getWallet());
  } catch (err) {
    console.log(err);
  }
};

export const getPaymentCharges = () => async (dispatch, getState) => {
  try{
    const data = await firestore
    .collection("payment_charges")
    .get();
    const showData = data.docs.map((val)=>val.data())
    const allData = showData.find((value)=> value)
    dispatch({type:"PAYMENT_CHARGES", payload:allData})
  }
  catch(err){
    console.log(err);
  }
};

export const checkOutPayment = (data, paymentMethodReq, stripe, totalAmount, cardElement)=> async(dispatch, getState) => {
  console.log("data", data, paymentMethodReq, totalAmount) 
  const {paymentMethod:{card}} = paymentMethodReq
  try{
    const {tokenId} = getState().user.user
    console.log("tokenId", tokenId)
    const {data:clientSecret} = await axios.post(
      "https://wbapi.devronins.com/create-payment-intent",
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenId}`, 
          Checksum: sha256(JSON.stringify(data)),
        },
      }
    );
      console.log("clientSecret", {data:clientSecret}, data, clientSecret)
      if(clientSecret.status ==="failure"){
        dispatch(createAlert({message: clientSecret.message? clientSecret.message:"something went wrong"}));
      }
    else{
      try{
        const confirmedCardPayment = await stripe.confirmCardPayment(clientSecret.clientSecret, {
            payment_method: paymentMethodReq.paymentMethod.id
        });
        console.log("confirmedCardPayment: ", confirmedCardPayment);
      if(confirmedCardPayment.error){
        console.log("confirmedCardPayment.error", confirmedCardPayment.error)
        dispatch(createAlert({message:confirmedCardPayment.error ? confirmedCardPayment.error : "something went wrong", type:"error"}))
        dispatch(createTransasction(confirmedCardPayment, card, totalAmount))
      }
      else{
        dispatch(createTransasction(confirmedCardPayment.paymentIntent, card, totalAmount))
      }
      } 
      catch(error){
        cardElement.clear();
        dispatch(
        createAlert({
        message: error.message === "paymentMethodReq.paymentMethod is undefined"?"Please fill your card details" : error?.message,
        type: "error",
        duration: 5000,
      }));
      }
    }
   
  }
  catch(err){
    console.log(err);
    dispatch(createAlert({message:err.message?err.message:err, type:"error"}))
  }
}

export const createTransasction =
  (data, card, totalAmount) => async (dispatch, getState) => {
    const { uid } = getState().user.user;
    try {
      dispatch({ type: ACTION_TYPES.TRANSACTION_REQUEST });
      const { uid } = getState().user.user;
      const noDigits = Math.floor(Math.log10(data.created)) + 1;
      data.created = noDigits >= 13 ? data.created : data.created * 1000;
      //payment -ve
      const payload = {
        amount: data.amount? data.amount:null,
        createdAt: data.created?data.created:null,
        currency: data.currency? data.currency:null,
        paymentMethodReq: {
          card: {
            brand: card.brand?card.brand:null ,
            country: card.country? card.country:null,
            funding: card.funding? card.funding:null,
            lastDigits: card.last4?`**** **** **** ${card.last4}`:null,
          },
          createdAt: data.created? data.created:null,
          id: data.payment_method? data.payment_method :null,
          type: data.payment_method_types?data.payment_method_types[0]:null,
        },
        paymentMethodTypes: data.payment_method_types? data.payment_method_types:null,
        status: data.status? data.status:null,
        transactionId: data.id? data.id:null,
        userId: uid,
      };
      
      if(data.error){
        console.log("paymentMethod>>", payload);
        await insert("stripe_transaction", payload);
      }
      else{
        dispatch(
          createWallet(null, { ...data, amount: totalAmount, card })
        );
        dispatch({ type: ACTION_TYPES.TRANSACTION_SUCCESS });
        dispatch(
          createAlert({
            message: `Sucessfull Payment`,
            type: "success",
            duration: 10000,
          })
        );
      }
    } catch (err) {
      console.log(err);
      dispatch({ type: ACTION_TYPES.TRANSACTION_FAILED });
    }
  };

const fun = (id) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("users")
      .where("userId", "==", id)
      .get()
      .then((data) => {
        const s = data.docs.map((it) => it.data());
        resolve(s);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const walletDetail = (id) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("wallet")
      .where("userId", "==", id)
      .get()
      .then((data) => {
        const s = data.docs.map((it) => it.data());
        resolve(s);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getHistory = () => async (dispatch, getState) => {
  try {
    const {
      user: { uid = "", type },
    } = getState().user;
    dispatch({
      type: ACTION_TYPES.HISTORY_REQUEST,
    });

    const data = await walletDetail(uid);
    if (data[0]?.id) {
      firestore
        .collection("wallet")
        .doc(data[0]?.id)
        .collection("transactions")
        .orderBy("createAt")
        .onSnapshot(async (value) => {
          const history = value.docs.map((v) => v.data());
          const len = history.length;
          //Reversing array
          //* fetch user name using toId and fromId

          //userDetail --> History history + userDetail
          let userDetail = await Promise.all(
            history.map(async (hist) => {
              if (hist.fromId && hist.fromId !== "1") {
                const data = await fun(hist.fromId);
                // const data = snap.docs.map((it) => it.data());
                const name = data.length && data[0]?.name ? data[0].name: "";
                return { ...hist, name};
              } else if (hist.toId && hist.toId !== "1") {
                const data = await fun(hist.toId);
                const name = data.length && data[0]?.name ? data[0].name: "";
                return { ...hist,name };
              } else return { ...hist };
            })
          );
          if (len === 1)
            dispatch({
              type: ACTION_TYPES.HISTORY_SUCCESS,
              payload: userDetail,
            });
          else {
            for (let i = 0; i < len / 2; i++) {
              let temp = userDetail[i];
              userDetail[i] = userDetail[len - i - 1];
              userDetail[len - 1 - i] = temp;
            }
            dispatch({
              type: ACTION_TYPES.HISTORY_SUCCESS,
              payload: userDetail,
            });
          }
        });
    } else {
      dispatch({
        type: ACTION_TYPES.HISTORY_SUCCESS,
        payload: [],
      });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: ACTION_TYPES.HISTORY_FAILED,
    });
  }
};

export const promote = (body) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.EVENT_REQUEST });

    const { id: event_id } = getState().event.event;
    const { uid } = getState().user.user;
    const { wallet = {} } = getState().transaction;
    if (wallet?.amount >= body.amountPaid) {
      const data = {
        ...body,
        eventId: event_id,
        startDate: new Date().getTime(),
        invited: false,
        amountUnit: "$",
      };
      const res = await insert("promotions", data);

      //*To pay
      // const platform = {
      //     amount: body.amountPaid * 100,
      //     createdAt: new Date().getTime(),
      //     currency: "$",
      //     credit: false,
      //     fromId: uid,
      //     message: "Money debited for Event Promotion",
      //     type: 2,
      // };
      // await dispatch(createWallet(uid, data));

      /*
      TODO: NOTE!
      *toId: "1", Dumy id. At this moment we don't know anything about weddingBlizz bussinessId and userId
      *Also notification is not working. notification require toId or fromId
      */

      //*Update deductAmount
      const updateMyWallet = {
        amount: -body.amountPaid,
        createdAt: new Date().getTime(),
        currency: "$",
        credit: false,
        businessId: "1", //!Doubt
        toId: "1", //!Dumy id
        message: "Money debited for Event Promotion",
        type: 2,
      };

      dispatch(createWallet(uid, updateMyWallet));
      dispatch(
        createAlert({
          message: "You have successfully activated the Event promotion",
          type: "success",
        })
      );
      // eslint-disable-next-line no-throw-literal
    } else throw "You don’t have enough funds for this transaction";

    dispatch({ type: ACTION_TYPES.EVENT_SUCCESS });
  } catch (err) {
    console.log(err);
    dispatch(createAlert({ message: err.message?err.message:err, type: "error" }));
  }
};

export const fetchStripeAccountStatus  = (accountId="",history) => async(dispatch,getState) => {
  try {
    const {tokenId } = getState().user.user;

    const response = await axios.get(
      `https://wbapi.devronins.com/fetch-account-detail/${accountId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );
    console.log("response",response);
    if(response.data.status==="success"){
      return response;
    }
  } catch (error) {
    console.error(" - ",error.message?error.message: error);
    dispatch(createAlert({ message: error.message?error.message:error, type: "error" }));
    throw "Something went wrong. Please try again later";
  }
}

export const createAccount = (data, image,history) => async (dispatch, getState) => {
  try {
      const { user } = getState().user;
      const { business } = getState().vendor || {};

    const response = await axios.post(
      "https://wbapi.devronins.com/create-vendor-account",
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Checksum: sha256(JSON.stringify(data)),
          Authorization: `Bearer ${user.tokenId}`,
        },
      }
    );
    console.log("response", response);
    if(response.data.status === "failure"){  
      dispatch(createAlert({message:response.data.message.code === "account_number_invalid" ? 
      "Invalid account number, Please enter valid account number."  
      :response.data.message.param === "individual[id_number]" ?
      "Please enter valid SSN Id (Social Security Number)." : response.data.message.raw.message , type: "error"})) 
    }
    else{
      const updateAccount = {
        account_number: `${data.account_number}`,
        stripe_account_id: response.data.account_info,
      }
      if ((user.type === 1 || user.type === 2) &&  user.uid){
        dispatch({type: ACTION_TYPES.AUTH_COMPLETE,payload: {...user,...updateAccount}});
        updateOne("users", user.uid, updateAccount)}
      else if(user.type === 3){
        dispatch({type: ACTION_TYPES.BUSINESS_SUCCESS, payload: {...business,...updateAccount}});
        updateOne("venders", business.id, updateAccount);
      }
      dispatch(createAlert({message: "You have successfully created account",type: "success" }));
      history.push("/");

      //*Upload verification image
      await dispatch(uploadVerification(response.data.account_info,image));
    }
  } catch (err) {
    console.log(err);
    dispatch(createAlert({ message: err.message?err.message:err, type: "error" }));
  }
};

const uploadVerification = (account_id,image) => async (dispatch, getState) => {
  try {
    const { user } = getState().user;

    //https://stackoverflow.com/questions/41878838/how-do-i-set-multipart-in-axios-with-react
    const formData = new FormData();
    formData.append("image",image)
    const resp = await axios.post(
      `${url}/upload-account-image/${account_id}`,
      formData,
      {
        headers: {
          "content-type": 'multipart/form-data',
          Authorization: `Bearer ${user.tokenId}`,
        },
      }
      );
  } catch (error) {
    console.log(error);
  }
}

export const updateAccount = (data,history,image="")=> async(dispatch, getState) =>{
  try {
    const { user } = getState().user;
    const { business } = getState().vendor || {};

    const account_id = user.type!==3 ? user.stripe_account_id: business.stripe_account_id;
    data.account_id = account_id;
    const resp = await axios.put(
      `${url}/update-vender-account`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Checksum: sha256(JSON.stringify(data)),
          Authorization: `Bearer ${user.tokenId}`,
        },
      }
    );
    console.log(resp);

    if(image){
      await dispatch(uploadVerification(account_id,image))
    }
    dispatch(createAlert({message: "You have successfully updated your stripe account",type: "success" }));
    history.push("/");
  } catch (err) {
    console.log(err);
    dispatch(createAlert({ message: err.message?err.message:err, type: "error" }));
  }
}

export const transferToBank = (data={},amount=0,history) =>async(dispatch, getState)=>{
  try{
    const {tokenId, uid} = getState().user.user;
    const { id = null } = getState().vendor?.business || {};
    let {bank_transfer_charge,platform} = getState().transaction.payment;
    bank_transfer_charge = Number(bank_transfer_charge);
    let platform_charge = Number(platform);

    let { wallet: {amount: balance} } = getState().transaction;
    const charges = bank_transfer_charge + platform_charge;
    const calculated_amount = amount+(amount*charges)/100;
    const currAmount =  getFixed(calculated_amount)

    
    console.log(currAmount)
    if(balance < currAmount){
      dispatch(createAlert({message:"You have insufficient amount to transfer. Please go to your wallet and add amount." ,type:"error"}));
      history.push("/wallet");
      return;
    }

    const response = await axios.post(
      "https://wbapi.devronins.com/transfer-fund-vendor",
      data, {
        headers: {
          "Content-Type": "application/json",
          Checksum: sha256(JSON.stringify(data)),
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );
    console.log("response>>", response);
    const resp = response?.data.data;
    const createdAt = resp.created?resp.created:new Date().getTime();
    const payload = {         
      amount: resp.amount?resp.amount:0,
      createdAt: createdAt,
      modifiedAt: createdAt,
      currency: resp.currency?resp.currency:null,
      fundTransfer:{
        sourceType: resp.source_type?resp.source_type:null,
        destination: resp.destination?resp.destination:null,
        balanceTransaction: resp.balance_transaction?resp.balance_transaction:null,
        destinationPayment: resp.destination_payment?resp.destination_payment:null,
        amountReversed: resp?.amount_reversed? resp.amount_reversed:0,
        object: resp.object?resp.object:null,
        reversed: resp?.reversed? resp.reversed:false,
        reversals: {
          ...resp.reversals,
          hasMore: resp?.reversals.has_more? resp.reversals.has_more:false,
          totalCount: resp.reversals.total_count?resp.reversals.total_count:0,
        },
      },
      status: response?.data.status === "success"?"succeeded":response?.data.status? response?.data.status:"failure",
      transactionId: resp.id?resp.id:null,
      userId: uid
    }
    delete payload.fundTransfer.reversals.has_more;
    delete payload.fundTransfer.reversals.total_count;
    if(response.data.status==="failure") {
      await insert("stripe_transaction", payload);
      dispatch(createAlert({message:response.data.message.type,type:  "error"})) 
    }
    else{
      // dispatch(createAlert({message: response?.data.message,type: "success" }));
      try{
        const resp = response?.data.data;
        if(resp){
          //  await saveInDb(resp,response,amount,data)
          //*1. Deduct amount from wallet
          const noDigits = Math.floor(Math.log10(resp.created)) + 1;
          resp.created = noDigits >= 13 ? resp.created : resp.created * 1000;
          const updateMyWallet = {
            amount: -amount,
            chargesAmount:-currAmount,
            createdAt: resp.created?resp.created:new Date().getTime(),
            currency: "$",
            credit: false,
            toId: data.account_id,//we don't need this
            type: 3,
            id: resp.id?resp.id:null
          };
          await dispatch(createWallet(uid, updateMyWallet));         
          await insert("stripe_transaction", payload);
          // dispatch(createAlert({message: response?.data.message,type: "success" }));
        }
      }finally{
        dispatch(createAlert({message: response?.data.message,type: "success" }));
      }
    }
    }catch (err) {
    console.log(err.message)
    dispatch(createAlert({ message: err.message?err.message:err, type: "error" }));
  }
}


//!test this function is working proporly
// const saveInDb = (resp,response,amount,data) => async (dispatch,getState)=>{
//   const {tokenId, uid} = getState().user.user;
//   //*1. Deduct amount from wallet
//   const noDigits = Math.floor(Math.log10(resp.created)) + 1;
//   resp.created = noDigits >= 13 ? resp.created : resp.created * 1000;
//   const updateMyWallet = {
//     amount: -amount,
//     createdAt: resp.created?resp.created:new Date().getTime(),
//     currency: "$",
//     credit: false,
//     toId: data.account_id,//we don't need this
//     type: 3,
//     id: resp.id?resp.id:null
//   };
//   await dispatch(createWallet(uid, updateMyWallet));

//   //*2. Create an entry into stripe_transaction
//   const createdAt = resp.created?resp.created:new Date().getTime();
//   const payload = {         
//     amount: resp.amount?resp.amount:0,
//     createdAt: createdAt,
//     modifiedAt: createdAt,
//     currency: resp.currency?resp.currency:null,
//     fundTransfer:{
//       sourceType: resp.source_type?resp.source_type:null,
//       destination: resp.destination?resp.destination:null,
//       balanceTransaction: resp.balance_transaction?resp.balance_transaction:null,
//       destinationPayment: resp.destination_payment?resp.destination_payment:null,
//       amountReversed: resp.amount_reversed,
//       object: resp.object?resp.object:null,
//       reversed: resp.reversed,
//       reversals: {
//         ...resp.reversals,
//         hasMore: resp.reversals.has_more,
//         totalCount: resp.reversals.total_count,
//       },
//     },
//     status: response?.data.status === "success"?"succeeded":response?.data.status,
//     transactionId: resp.id?resp.id:null,
//     userId: uid
//   }
//   delete payload.fundTransfer.reversals.has_more;
//   delete payload.fundTransfer.reversals.total_count;
//   await insert("stripe_transaction", payload);
//   dispatch(createAlert({message: response?.data.message,type: "success" }));
// }