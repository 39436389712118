import {
  auth,
  createProfile,
  resendVerificationEmail,
  handleFCM,
  notification,
  updateOne,
  firestore,
  s3UploadPorfImg,
} from "helpers";
import { history } from "../App";
import { ACTION_TYPES, strings } from "constant";
import { createAlert } from "actions";

export const signUp =
  ({ email, password, phone, role, fullname , countryCode}) =>
  async (dispatch, getState) => {
    try {
      let roles = [role];
      let data = getState().user;
      let userData = data ? data.user : {};
      dispatch({ type: ACTION_TYPES.AUTH_REQUEST });
      const { user = {} } = await auth.createUserWithEmailAndPassword(
        email,
        password
      );

      const { uid } = user;

      const snap = await firestore
        .collection("users")
        .where("email", "==", email)
        .get();
      const guest = snap.docs.map((it) => it.data());
      if (guest && guest.length) roles = [...new Set([...roles, 2])];
      await createProfile(uid, {
        userId: uid,
        phone,                                            
        type: role,
        roles,
        displayName: fullname,
        name: fullname,
        email,
        countryCode
      });
      notification(uid);
      dispatch({ type: ACTION_TYPES.SIGNUP });
      dispatch(
        createAlert({
          message: strings.success.SignupSuccess,
          type: "success",
          duration: 10000,
        })
      );
      return true;
    } catch (error) {
      console.log("signup catch error ", error);
      dispatch(
        createAlert({ message: error.message, type: "error", duration: 10000 })
      );
      dispatch({ type: ACTION_TYPES.AUTH_FAILED });
      return true;
    }
  };

const resendEmailVerification = () => async (dispatch) => {
  try {
    await resendVerificationEmail();
    dispatch(
      createAlert({
        message: strings.success.verificationEmailSent,
        type: "success",
      })
    );
  } catch (error) {
    dispatch(createAlert({ message: error.message, type: "error" }));
  }
};

export const login =
  ({ email, password }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.AUTH_REQUEST });

      const { user = {} } = await auth.signInWithEmailAndPassword(
        email,
        password
      );

      console.log("user ", user);

      const { uid, refreshToken, emailVerified } = user;

      const snap = await firestore
        .collection("users")
        .where("userId", "==", uid)
        .get();

      if (uid && refreshToken) {
        if (emailVerified) {
          const tokenId = await user.getIdToken(true);
          let userData = snap.docs.map((it) => it.data());
          userData = userData[0];
          console.log("userData",userData);
          notification(uid);
          history.push("/");
          dispatch({
            type: ACTION_TYPES.AUTH_COMPLETE,
            payload: {
              ...userData,
              name: userData?.name? userData.name: "",
              email: userData.email,
              uid,
              token: refreshToken,
              tokenId,
            },
          });
        } else {
          dispatch({ type: ACTION_TYPES.AUTH_FAILED });
          dispatch(
            createAlert({
              message: strings.errors.emailNotVerified,
              type: "error",
              duration: 25000,
              extra: {
                label: strings.common.Resend,
                action: () => dispatch(resendEmailVerification()),
              },
            })
          );
        }
        dispatch(getPosition());
      } else dispatch({ type: ACTION_TYPES.AUTH_FAILED });
      return true;
    } catch (error) {
      console.log("login catch error ", error);
      dispatch({ type: ACTION_TYPES.AUTH_FAILED });
      dispatch(createAlert({ message: error.message, type: "error" }));
      return true;
    }
  };

export const getPosition = () => (dispatch) => {
  navigator.geolocation.getCurrentPosition(
    ({ coords }) => {
      dispatch({ type: ACTION_TYPES.SET_POSITION, payload: coords });
    },
    (error) => {
      console.log("position error ", error);
    }
  );
};

export const logout = () => async (dispatch, getState) => {
  const {
    user: { uid },
    fcm = "",
  } = getState().user;
  console.log("laagout");

  dispatch({ type: ACTION_TYPES.RESET });
  setTimeout(() => {
    console.log("dadadadadsd");
    handleFCM(fcm, uid, "remove");
  }, 500);
  setTimeout(async () => {
    auth.signOut();
  }, 1000);
};

export const onForgotPassword = (email) => async (dispatch) => {
  try {
    auth.sendPasswordResetEmail(email);
    dispatch(
      createAlert({ message: strings.success.ForgotPassword, type: "success" })
    );
  } catch (error) {
    dispatch(createAlert({ message: error.message, type: "error" }));
    console.log("forgot catch error ", error);
  }
};

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const uploadProImg =
  (image, contentType) => async (dispatch, getState) => {
    try {
      const file = dataURLtoFile(image, "profile");
      // const file = image;
      if (file) {
        const {
          user: { uid = "" },
        } = getState().user;
        console.log(uid);
        const data = await s3UploadPorfImg(file, `users/${uid}`, contentType);
        console.log(data);
        return data;
      }
    } catch (err) {
      console.log("uploadProImg", err);
    }
  };

export const updateUser = (state) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.ACCOUNT_UPDATE_REQUEST });
    const {
      user: { uid = "" },
    } = getState().user;
    console.log("gjkg");
    const update = await updateOne("users", uid, state);
    dispatch(fetchUser());
    dispatch({ type: ACTION_TYPES.ACCOUNT_UPDATE_COMPLETE });
    dispatch(
      createAlert({
        message: strings.success.profileUpdateSuccess,
        type: "success",
      })
    );
  } catch (error) {
    console.log("add event catch error ", error);
    dispatch({ type: ACTION_TYPES.ACCOUNT_UPDATE_FAILED });
    dispatch(createAlert({ message: error.message?error.message:error, type: "error" }));
  }
};

const fetchUser = () => async (dispatch, getState) => {
  const { user = {} } = getState().user;
  const data = await firestore
    .collection("users")
    .where("userId", "==", user.uid)
    .get();
  const obj =
    data && data.docs && data.docs.length
      ? data.docs.map((item) => item.data())
      : [];
  let payload = obj && obj.length ? obj[0] : {};
  dispatch({
    type: ACTION_TYPES.AUTH_COMPLETE,
    payload: { ...user, ...payload },
  });
};

export const deleteUser = (history) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.ACCOUNT_UPDATE_REQUEST });
    const user = auth.currentUser;

    const {
      user: { uid },
    } = getState().user;

    //*2 Logout (clearing local storage) and redirect to "/"
    dispatch(logout());

    user
      .delete()
      .then(async () => {
        //*1 Deleting from Users collections firestore
        var UserSnap = await firestore
          .collection("users")
          .where("userId", "==", uid);

        UserSnap.get().then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            doc.ref.delete();
          });
        });

        //*3 Deleting from firebase auth
        dispatch(
          createAlert({
            message: "Your account has been deleted Successfully",
            type: "success",
          })
        );
        history.push("/");
      })
      .catch((error) => {
        dispatch(createAlert({ message: error, type: "error" }));
      });

    dispatch({ type: ACTION_TYPES.ACCOUNT_UPDATE_COMPLETE });
  } catch (error) {
    console.log(error);
    dispatch(createAlert({ message: "Failed to delete", type: "error" }));
  }
};
