import React, { useState } from "react";
import { Menu, MenuItem, Grid, Box, Button } from "@material-ui/core";
import { strings } from "constant";
import { useSelector } from "react-redux";

const { account } = strings;

export default (props) => {
  const { user = {} } = useSelector(({ user }) => user);
  console.log();
  let sides = [];
  if (user && user.type === 3)
    sides = [
      account.PersonalDetails,
      account.BusinessInformation,
      account.AccountManagement,
    ];
  else if (user && user.type === 1)
    sides = [
      account.PersonalDetails,
      account.WeddingDetails,
      account.AccountManagement,
    ];
  else sides = [account.PersonalDetails, account.AccountManagement];
  return (
    <Menu
      id="simple-menu"
      anchorEl={props.menu}
      keepMounted
      open={Boolean(props.menu)}
      onClose={props.closeMenu}
    >
      {sides.map((text, ind) => (
        <MenuItem
          onClick={() => props.onMenu(ind)}
          key={`${ind}-opop-account-menu`}
        >
          {text}
        </MenuItem>
      ))}
    </Menu>
  );
};
