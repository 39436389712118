const format = ["_REQUEST", "_COMPLETE", "_SUCCESS", "_FAILED"];
const types = [
  "AUTH",
  "EVENT",
  "EVENT_SERVICE",
  "GIFT",
  "GUEST",
  "GUEST_GROUP",
  "GUEST_GROUP_DEL",
  "ACCOUNT_UPDATE",
  "BUSINESS_ADD",
  "BUSINESS",
  "IMAGE_URL",
  "VENDOR_BOOKING",
  "PROPOSAL",
  "CHAT",
  "CONVERSATION",
  "GALLERY",
  "REVIEW",
  "NOTIFICATION",
  "TRANSACTION",
  "HISTORY",
  "WALLET",
  "GALLERY_COMMENTS",
  "RATING",
  "PAY_CASH"
];

const manuals = {
  RESET: "RESET",
  LOG_OUT: "LOG_OUT",
  USER_LOGGEDIN: "USER_LOGGEDIN",
  CLOSE_ALERT: "CLOSE_ALERT",
  CREATE_ALERT: "CREATE_ALERT",
  SIGNUP: "SIGNUP",
  SET_EVENT_CATEGORY: "SET_EVENT_CATEGORY",
  SET_EVENT_CATEGORIES: "SET_EVENT_CATEGORIES",
  EVENT_VENDOR_DETAIL: "EVENT_VENDOR_DETAIL",
  DEVICE_TOKEN: "DEVICE_TOKEN",
  GIFTS_COMPLETE: "GIFTS_COMPLETE",
  GUEST_GROUP_COMPLETE: "GUEST_GROUP_COMPLETE",
  SET_POSITION: "SET_POSITION",
  CATEGORIES: "CATEGORIES",
  VENDOR_BOOKING: "VENDOR_BOOKING",
  SET_PROPOSAL: "SET_PROPOSAL",
  SET_SELECTED_PROPOSAL: "SET_SELECTED_PROPOSAL",
  SET_CHAT: "SET_CHAT",
  SET_CHAT_DETAIL: "SET_CHAT_DETAIL",
  COMMENTS: "COMMENTS",
  GUEST_EVENTS: "GUEST_EVENTS",
  PAYMENT_DETAILS: "PAYMENT_DETAILS",
  HAS_EVENT: "HAS_EVENT",
  HAS_VENDOR: "HAS_VENDOR",
  GUEST_INVITATION_DETAIL: "GUEST_INVITATION_DETAIL",
  PAYMENT_CHARGES: "PAYMENT_CHARGES",
  SAVE_GUEST_IDS: "SAVE_GUEST_IDS",
  SEARCH_START: "SEARCH_START",
  SEARCH_END: "SEARCH_END",
  GIFT_DETAIL_COMPLETE: "GIFT_DETAIL_COMPLETE"
};

export default {
  ...types
    .map((t) => format.map((i) => `${t}${i}`))
    .flat(1)
    .reduce((obj, v) => {
      obj[v] = v;
      return obj;
    }, {}),
  ...manuals,
};
