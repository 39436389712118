import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid, Box } from "@material-ui/core";
import { eventStyle, proposalStyle, primaryLoaderStyle } from "styles";
import { strings } from "constant";
import Rating from "@material-ui/lab/Rating";
import { BreadCrumb, Loader, NoRecordFound } from "components";
import moment from "moment";
const { common } = strings;

const imageStyle = {width: "60px",margin: "10px", borderRadius: "50%"}

const image = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png";
export default (props) => {
  const classes = eventStyle();
  const pclasses = proposalStyle();
  const {
    reviews: { reviews },
    rloader = false,
  } = useSelector(({ event }) => event);

  const breads = [{ title: common.Home, path: "/" }];

  return (
    <Grid container justify="center" className={classes.eventMain}>
      <BreadCrumb breads={breads} current={common.Reviews} />
      <Typography component="div" className={classes.eventTV}>
        <Box fontFamily="CormorantBold" className={classes.eventT}>
          {"Reviews"}
        </Box>
      </Typography>
      {rloader ? (
        <Loader style={primaryLoaderStyle} />
      ) : reviews && reviews.length ? (
        reviews.map((it) => (
          <div className={classes.reviewListV}>
            <div className={pclasses.listCardReview}>
            <div><img src={it.user[0]?.image ? it.user[0]?.image:image} style={imageStyle}/></div>
            <div>
              <Box fontFamily="Gotham" className={pclasses.prosposalNameT}>
                  {it?.user[0]?.name}
                </Box>
                <Box fontFamily="GothamBook" className={pclasses.prosposalDateT}>
                  {it?.modifiedAt
                    ? moment(new Date(it?.modifiedAt)).format("DD MMM YYYY")
                    : ""}
                </Box>
                <Rating value={it.rating} size="small" readOnly precision={0.5} />
                <Box fontFamily="GothamBook" className={classes.eventFrCT}>{it?.reviewText}</Box>
            </div>
            </div>
          </div>
        ))
      ) : (
        <NoRecordFound />
      )}
    </Grid>
  );
};
