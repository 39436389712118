import { firestore, insert, s3Upload, updateOne, s3Delete } from "helpers";
import { ACTION_TYPES, strings } from "constant";
import { createAlert } from "actions";
import { history } from "../App"

const { success, errors, common } = strings;

export const addImage = (images, key) => async (dispatch, getState) => {
  const { id = "", event_type = 1 } = getState().event?.event;
  const uid = getState().user?.user?.uid;
  try {   
      dispatch({ type: ACTION_TYPES.GALLERY_REQUEST });
      await s3Upload(images, key, id, uid, event_type);
      history.goBack();
      dispatch(fetchImages());
      dispatch(createAlert({ message: success.FileUploaded, type: "success" }));
      return true;
      
  } catch (error) {
    console.log("addGift error ", error);
    dispatch({ type: ACTION_TYPES.GALLERY_FAILED });
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
    return true;
  }
};

const getLoginedUser = () => async (dispatch, getState) => {
  const { uid } = getState().user.user;
  return uid;
};

const payloadPromise = (data, uid, dispatch) => {
  return new Promise(async (resolve, rej) => {
    const profile = await getPromise(uid);

    const userId = await dispatch(getLoginedUser());

    const user = {
      displayName: profile[0].displayName
        ? profile[0].displayName
        : profile[0].name,
      profilePic: profile[0].image,
    };

    const newData = data.map((i) => ({
      src: i.path,
      type: i.type,
      id: i.id,
      likes: i.likes,
      createdAt: i.createdAt,
      islike: i.likes && i.likes.filter((el) => el === userId),
      profile: user,
    }));
    resolve(newData);
  });
};

export const fetchImages = (userId) => async (dispatch, getState) => {
  try {
    const { id = "" } = getState().event?.event;
    let uid = getState().user?.user?.uid;

    dispatch({ type: ACTION_TYPES.GALLERY_REQUEST });
    const snap = await firestore
      .collection("event_gallery")
      .where("eventId", "==", id)
      .where("senderId", "==", userId ? userId : uid)
      .get();
    const images = snap.docs.map((doc) => doc.data());
    const res = await payloadPromise(images, userId ? userId : uid, dispatch);
    console.log(res);

    dispatch({
      type: ACTION_TYPES.GALLERY_SUCCESS,
      payload: res,
    });
  } catch (error) {
    console.log("addGift error ", error);
    dispatch({ type: ACTION_TYPES.GALLERY_FAILED });
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
  }
};

export const like = (id) => async (dispatch, getState) => {
  try {
    const images = getState().gallery?.images;

    const { uid, displayName, name } = getState().user?.user;
    const snap = await firestore
      .collection("event_gallery")
      .where("id", "==", id)
      .get();
    let data = snap.docs.map((doc) => doc.data());
    data = data[0];

    const filterdlikes = data.likes.filter((el) => el !== uid);

    let updatedLikes = [];
    if (filterdlikes.length === data.likes.length) {
      updatedLikes = [...filterdlikes, uid];
    } else updatedLikes = filterdlikes;

    const newImages = images.map((el) => {
      return {
        ...el,
        likes: el.id === id ? updatedLikes : el.likes,
        islike:
          el.id === id ? updatedLikes.filter((el) => el === uid) : el.islike,
      };
    });

    dispatch({
      type: ACTION_TYPES.GALLERY_SUCCESS,
      payload: newImages,
    });

    await updateOne("event_gallery", id, {
      ...data,
      likes: updatedLikes,
    });
    dispatch(fetchImages(data?.senderId));
    dispatch({ type: ACTION_TYPES.GALLERY_COMPLETE });
  } catch (error) {
    console.log("addGift error ", error);
    dispatch({ type: ACTION_TYPES.GALLERY_FAILED });
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
  }
};

//COMMENT
export const postComments = (mid, comment) => async (dispatch, getState) => {
  try {
    const { event } = getState().event;
    const user = getState().user?.user;
    const comments = getState().gallery?.coments;
    // const { gallery } = getState().gallery;

    dispatch({ type: ACTION_TYPES.GALLERY_REQUEST });

    //Genetor Id
    let { id = "" } = await firestore.collection("mediaComment").doc();
    comments.unshift({
      id,
      comment,
      eventId: event.id,
      mediaId: mid,
      userId: user.uid,
      displayName: user.displayName,
      createdAt: new Date().getTime(),
      profilePic: user.image,
    });
    dispatch({
      type: ACTION_TYPES.COMMENTS,
      payload: comments,
    });
    dispatch({ type: ACTION_TYPES.GALLERY_COMPLETE });

    await insert("mediaComment", {
      id,
      comment,
      eventId: event.id,
      mediaId: mid,
      userId: user.uid,
    });
  } catch (error) {
    console.log("addGift error ", error);
    dispatch({ type: ACTION_TYPES.GALLERY_FAILED });
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
  }
};

const getComments = (id) => {
  return new Promise(function (resolve, reject) {
    firestore
      .collection("mediaComment")
      .where("mediaId", "==", id)
      .orderBy("createdAt", "desc")
      .onSnapshot((value) => {
        const comments = value.docs.map((v) => v.data());
        resolve(comments);
      });
  });
};

const getPromise = (id) => {
  return new Promise(async (resolve, reject) => {
    const snap = await firestore
      .collection("users")
      .where("userId", "==", id)
      .get();
    const user = snap.docs.map((it) => it.data());
    resolve(user);
  });
};

const getPromise2 = (ids = []) => {
  return new Promise(async (resolve, reject) => {
    const snap = await firestore
      .collection("users")
      .where("userId", "in", ids)
      .get();
    const users = snap.docs.map((it) => it.data());
    resolve(users);
  });
};

const getUserDetails2 = async (data) => {
  return new Promise(async (resolve, reject) => {
    let users = [];
    let temp = [];
    for (let a of data) {
      temp.push(a.userId);
      if (temp.length === 10) {
        console.log(temp);
        users.push(...(await getPromise2(temp)));
        temp = [];
      }
    }
    if (temp.length) {
      users.push(...(await getPromise2(temp)));
      temp = [];
    }
    resolve(users);
  });
};

export const fetchComments = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.GALLERY_COMMENTS_REQUEST });
    const data = await getComments(id);
    let users = await getUserDetails2(data);
    console.log(data, users);

    dispatch({
      type: ACTION_TYPES.GALLERY_COMMENTS_SUCCESS,
      payload: data.map((it) => {
        const user = users.find((usr) => it.userId === usr.userId);
        return {
          id: it.id,
          displayName: user.displayName ? user.displayName : user.name,
          profilePic: user.image,
          comment: it.comment,
          createdAt: it.createdAt,
          userId: user.userId,
        };
      }),
    });

    return users;
  } catch (error) {
    console.log("addGift error ", error);
    dispatch({ type: ACTION_TYPES.GALLERY_COMMENTS_FAILED });
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
  }
};

//DELETE IMAGES
export const deleteImages = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.GALLERY_REQUEST });
    const images = getState().gallery?.images;
    const { event } = getState().event;

    const delImg = images.filter((el) => el.id === id);
    // console.log(delImg);

    dispatch({
      type: ACTION_TYPES.GALLERY_SUCCESS,
      payload: images.filter((el) => el.id !== id),
    });
    const mediaRef = await firestore
      .collection("event_gallery")
      .where("id", "==", id);
    mediaRef.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        doc.ref.delete();
      });
    });

    await firestore
      .collection("mediaComment")
      .where("mediaId", "==", id)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          doc.ref.delete();
        });
      });

    const data = await s3Delete(
      delImg[0].src.substr(-17),
      `event_gallery/${event.id}`
    );
    dispatch({ type: ACTION_TYPES.GALLERY_COMPLETE });
    dispatch(createAlert({ message: common.GalleryDeleted, type: "success" }));
  } catch (error) {
    console.log("addGift error ", error);
    dispatch({ type: ACTION_TYPES.GALLERY_FAILED });
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
  }
};

//DELETE comments
export const deleteComment = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.GALLERY_COMMENTS_REQUEST });

    //Changed data in local storage --> instant deletion
    const comments = getState().gallery?.coments;

    dispatch({
      type: ACTION_TYPES.GALLERY_COMMENTS_SUCCESS,
      payload: comments.filter((el) => el.id !== id),
    });

    const mediaRef = await firestore
      .collection("mediaComment")
      .where("id", "==", id);
    mediaRef.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        doc.ref.delete();
      });
    });
    dispatch({ type: ACTION_TYPES.GALLERY_COMPLETE });
  } catch (error) {
    console.log("addGift error ", error);
    dispatch({ type: ACTION_TYPES.GALLERY_COMMENTS_FAILED });
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
  }
};
