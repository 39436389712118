import React, { useEffect, useMemo, useState } from "react";
import {
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Typography,
  Button,
  Divider,
  Chip,
  
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector, useDispatch } from "react-redux";
import { ChipComp } from "components";
import { display } from "helpers";
import { PaymentListStyle } from "styles";
import { payPayment, requestPayment, getPaymentCharges } from "actions";

const props = {
  label: "PAID",
};

export default function SimpleAccordion({
  proposal,
  type = 1,
  eventId,
  cId,
  coupleId,
}) {
  const classes = PaymentListStyle();
  const dispatch = useDispatch();
  const [loader_charges, setLoader_charges]= useState(false)

  useEffect(()=>{
    const getData =async()=>{
      setLoader_charges(true)
      await dispatch(getPaymentCharges())
      setLoader_charges(false)
    }
    getData();
  }, [])

  const { payment} = useSelector((state)=>state.transaction)
  const paymentCharges = (Number(payment.platform))
  console.log("payment>>", payment, paymentCharges)
  const { categories = [] } = useSelector(({ app }) => app);

  let categoryName = useMemo(
    () => categories.filter((i) => i.id === cId),
    [categories]
  );
  
  categoryName = categoryName.length && categoryName[0].name;
  console.log(categoryName);

  const onPaymentHandle = async () => {
    const lastPaymentType = proposal?.lastPaymentType;
    if (type === 1) {
      if (lastPaymentType === 0 || lastPaymentType === 1) {
          const amount = (proposal.booking_amount * 30) / 100;
        await dispatch(
          payPayment(proposal.id, paymentCharges, {
            amount,
            lastPaymentType: lastPaymentType + 1,
            transferred_amount: amount + proposal.transferred_amount,
            business_id: proposal.business_id,
            userId: proposal.user_id,
            message:
              lastPaymentType === 0 ? "Initial Payment" : "Second Payment",
          })
        );
      } else if (lastPaymentType === 2) {
        const amount = (proposal.booking_amount * 40) / 100;
        await dispatch(
          payPayment(proposal.id, paymentCharges, {
            amount,
            lastPaymentType: 3,
            transferred_amount: amount + proposal.transferred_amount,
            business_id: proposal.business_id,
            userId: proposal.user_id,
            message: "Final Payment",
          })
        );
      }
    } else if (type === 3) {
      let message = "";
      let amount = "";
      if (lastPaymentType === 0) {
        message = "Initial Payment";
        amount = (proposal.booking_amount * 30) / 100;
      } else if (lastPaymentType === 1) {
        message = "Second Payment";
        amount = (proposal.booking_amount * 30) / 100;
      } else {
        message = "Final Payment";
        amount = (proposal.booking_amount * 40) / 100;
      }
      dispatch(
        requestPayment(eventId, cId, categoryName, coupleId, message, amount)
      );
    }
  };

  return (
 <>
    <div className={classes.root}>
      <Accordion style={{boxShadow: "0px 1px 4px 1px #d4d4d4"}}>
        
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
         
          <div className={classes.paymentHeader}>
            <div className={classes.left}>
              <Typography className={classes.heading}>
                {categories.map((cate) =>
                  cate.id === proposal.category_id ? cate.name : null
                )}
              </Typography>
              <Typography className={classes.subHeading}>
                {type === 1 && `by ${proposal.name}`}
              </Typography>
            </div>
            <div className={classes.left}>
              <Typography
                className={classes.heading}
              >{`$ ${display(proposal.booking_amount)}`}</Typography>
              <Typography className={classes.subHeading}>{`${
                proposal.lastPaymentType === 3 ? "Fully Paid" : ""
              }`}</Typography>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.paymentStage}>
            <div className={classes.accordionDetails}>
              <Typography className={classes.subHeading}>
                Initial Payment(30%)
              </Typography>
              <div className={classes.center}>
                <Typography className={classes.subHeading}>{`$ ${
                  display((proposal.booking_amount * 30) / 100)
                }`}</Typography>
                {proposal.lastPaymentType >= 1 ? <ChipComp {...props} /> : null}
              </div>
            </div>
            <div className={classes.accordionDetails}>
              <Typography className={classes.subHeading}>
                Second Payment(30%)
              </Typography>
              <div className={classes.center}>
                <Typography className={classes.subHeading}>{`$ ${
                  display((proposal.booking_amount * 30) / 100)
                }`}</Typography>
                {proposal.lastPaymentType >= 2 ? <ChipComp {...props} /> : null}
              </div>
            </div>
            <div className={classes.accordionDetails}>
              <Typography className={classes.subHeading}>
                Final Payment(40%)
              </Typography>
              <div className={classes.center}>
                <Typography className={classes.subHeading}>{`$ ${
                  display((proposal.booking_amount * 40) / 100)
                }`}</Typography>
                {proposal.lastPaymentType >= 3 ? <ChipComp {...props} /> : null}
              </div>
            </div>
            <Divider />
            <div className={classes.accordionDetails}>
              <Typography className={classes.heading}>Total Payment</Typography>
              <Typography
                className={classes.heading}
              >{`$ ${display(proposal.booking_amount)}`}</Typography>
            </div>
            {proposal?.lastPaymentType !== 3 && (
              <Button
                className={classes.paymentButton}
                onClick={onPaymentHandle}
              >
                {`${
                  (proposal?.lastPaymentType === 0 &&
                    (type === 1
                      ? "PAY FIRST INSTALLMENT"
                      : "REQUEST FIRST INSTALLMENT")) ||
                  (proposal?.lastPaymentType === 1 &&
                    (type === 1
                      ? "PAY SECOND INSTALLMENT"
                      : "REQUEST SECOND INSTALLMENT")) ||
                  (proposal?.lastPaymentType === 2 &&
                    (type === 1
                      ? "PAY FINAL INSTALLMENT"
                      : "REQUEST FINAL INSTALLMENT"))
                }`}
              </Button>
              
            )}
            {proposal?.lastPaymentType!==3 && type === 1 &&(
            loader_charges ? <span style={{marginLeft:"auto", paddingTop: "10px"}}>Loading...</span> :
                <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  paddingTop: "10px",
                }}
                >
                <Typography variant="caption" display="block" gutterBottom style={{marginLeft: "auto", color:"grey"}}>
                  {`You will be charged ${paymentCharges}% extra`}
                </Typography>
                <div title={`Here, extra charges include ${payment.platform}% platform charges.`} style={{marginLeft: 3}}>
                  <InfoOutlined  style={{color:"grey", fontSize:16}}/>
                </div>
                </div>
               )}
           
          </div>
        </AccordionDetails>

      </Accordion>

    </div>

    </>                   
  );
}
