import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  TextField,
  FormControl,
  OutlinedInput,
  InputLabel,
  FormHelperText,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { VisibilityOff, Visibility } from "@material-ui/icons";
import { commonStyle } from "styles";

export default ({
  value = "",
  onChange = () => {},
  label,
  maxLength = 100,
  error,
  secure = false,
  ...tprops
}) => {
  const classes = commonStyle();
  const [val, setValue] = useState("");
  useEffect(() =>{
    setValue(value)
  }, [value,val]);
  const onTextChange = (v) => {
    setValue(v);
    onChange(v);
  };

  const [showPassword, setShowPassword] = useState(secure);

  const handleClickShowPassword = () => {
    setShowPassword((p) => !p);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {secure ? (
        <FormControl className={classes.inputFields} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
          <OutlinedInput
            label={label}
            id={label}
            // id="outlined-adornment-password"
            type={showPassword ? "password" : "text"}
            value={val}
            error={!!error}
            autoComplete="off"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            inputProps={{
              maxLength,
            }}
            onChange={({ target: { value } }) => onTextChange(value)}
            {...tprops}
          />
          <FormHelperText>{error}</FormHelperText>
        </FormControl>
      ) : (
        // this is old code and this does not handle the hide/show password
        <div className={classes.inputFieldsV}>
          <TextField
            className={classes.inputFields}
            error={!!error}
            type={secure ? "password" : "text"}
            id={label}
            label={label}
            value={val}
            helperText={error}
            variant="outlined"
            autoComplete="off"
            inputProps={{
              maxLength,
            }}
            onChange={({ target: { value } }) => onTextChange(value)}
            {...tprops}
          />
        </div>
      )}
    </>
  );
};
