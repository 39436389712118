export default {
  main: "#866436",
  primary: "#866436",
  main1: "#DBB562",
  main2: "#FCECCB",
  main3: "#FCF5EB",
  main4: "#F7EFE5",
  main5: "#5E460A",
  main6: "#EFD7AB",
  main7: "#F5E5CA",

  white: "#FFFFFF",

  dark: "#333333",
  dark1: "#373737",

  black: "#000000",

  grey: "#999999",
  grey1: "#EEEEEE",
  grey2: "#AAAEAE",
  grey3: "#73736B",
  grey4: "#707070",
  grey5: "#666666",
  grey6: "#D4D4D4",
  grey7: "#DBDBDB",

  blue1: "#666B7E",
  blue2: "#7F8293",
  blue3: "#0146AD",
  blue4: "#157CD9",

  red: "#FF0303",
  green: "#0C9C06",
};
