import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Modal,
  Box,
  MenuItem,
  Divider,
  IconButton,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  searchForName,
  getCategory,
  searchForVendorsCategory,
  searchForWeddingDate,
  searchForLocation,
  setCategory as setOneCategory,
  setProposal,
} from "actions";
import {serchStyle} from "styles"
import { withStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { Close,SearchSharp} from "@material-ui/icons";
import { Filter, SearchResult, SearchLocation, GooglePlaces } from "components";
import DateRan from "./DateRange";
import Slider from "./slider";
import { colors, months } from "constant";

const AppThemeColor = withStyles({
  root: {
    color: colors.main,
  },
})(Typography);

const style = {
  minWidth: "80%",
  maxHeight: "100%",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Search = () => {
  const dispatch = useDispatch();
  const match = useMediaQuery('(min-width:500px)') // add style
  const {
    user: { type },
  } = useSelector(({ user }) => user);

  const ModelRef = React.createRef();
  const DateRef = React.createRef();

  const classes = serchStyle();

  //Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [search, setSearch] = useState("");
  const [results, setResult] = useState([]);

  const [filter, setFilter] = useState({
    sf: type === 1 ? "Vendors" : "Wedding Events",
    su: "Name",
  });

  const [defaultValue, setDefaultValue] = useState({
    SearchFor: filter.sf,
    SearchUsing: filter.su,
  });

  const [selectDate, setSelectDate] = useState(null);
  const [openDate, setOpenDate] = useState(false);

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("Photography");

  const [locationData, setLocationData] = useState({
    address: "",
    coords: { latitude: 0, longitude: 0 },
  });
  const [openMap, setOpenMap] = useState(false);
  const handleAddress = (add) => {
    setLocationData({
      address: add.address,
      coords: { latitude: add.latitude, longitude: add.longitude },
    });
  };
  //5 - 200 Km
  const [range, setRange] = useState(5);

  useEffect(() => {
    const get = async () => {
      setCategories(await dispatch(getCategory()));
      dispatch(setOneCategory(null));
      dispatch(setProposal([]));
    };
    get();
  }, []);

  // useEffect(() => {
  //   console.log("DATA",filter.sf, search);
  //   const handleEsc = (e) => {
  //     if (e.keyCode === 13){
  //       console.log("Enter");
  //       handleSearch();
  //     }
  //   };
  //   window.addEventListener("keydown", handleEsc);
  //   return () => {
  //     window.removeEventListener("keydown", handleEsc);
  //   };
  // })

  useEffect(() => {
    setDefaultValue({
      SearchFor: filter.sf,
      SearchUsing: filter.su,
    });
    setResult([]);
    setSearch("");
  }, [JSON.stringify(filter)]);

  const handleSearch = async () => {
    switch (filter.su) {
      case "Name":
        setResult(await dispatch(searchForName(filter.sf, search)));
        break;
      case "Location":
        setResult(
          await dispatch(
            searchForLocation(
              filter.sf,
              range,
              locationData.coords.latitude,
              locationData.coords.longitude
            )
          )
        );
        break;
      case "Vendors Category":
        setResult(await dispatch(searchForVendorsCategory(filter.sf, search)));
        break;
      case "Wedding Date":
        setResult(await dispatch(searchForWeddingDate(selectDate)));
        break;
      default:
        break;
    }
  };

  const handleOpenMap = ()=>{
    setOpenMap((p) => !p)
  }

  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <>
      {
      match ?
      <Button
        variant="contained"
        onClick={handleOpen}
        className={classes.searchBtn}
      >
        Search
      </Button>: 
        <IconButton onClick={handleOpen} >
          <SearchSharp className = {classes.searchIcon} />
        </IconButton>
      }
      <Modal
        open={open}
        onClose={handleClose}
        ref={ModelRef}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ maxHeight: "100%" }}
      >
        <Box sx={style}>
          <div className={classes.centerRow}>
          <AppThemeColor variant="h6">{`Search for: ${
            filter.sf === "Wedding Events" ? "Events" : "Vendors"
          }`}</AppThemeColor>
          <IconButton onClick={handleClose}><Close/></IconButton>
          </div>
          <div className={classes.searchContainer}>
            {filter.su === "Name" && (
              <TextField
                value={search}
                id="outlined-search"
                label={"Enter keyword"}
                type="search"
                style={{ flexGrow: "1" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            )}
            {filter.su === "Vendors Category" && (
              <TextField
                select
                style={{ flexGrow: "1",height: "100%"}}
                label="Select"
                onChange={(e) => {
                  setCategory(e.target.value);
                  categories.forEach((el) => {
                    if (e.target.value === el) {
                      setSearch(el.id);
                    }
                  });
                }}
                sx={{
                  maxWidth: "100%",
                }}
                helperText="Vendor Category"
              >
                {categories.length &&
                  categories.map((cate) => (
                    <MenuItem key={cate.id} value={cate}>
                      {cate.name}
                    </MenuItem>
                  ))}
              </TextField>
            )}
            {filter.su === "Wedding Date" && (
              <>
                <Button variant="contained" onClick={() => setOpenDate(true)}>
                  DATE RANGE
                </Button>
                {selectDate !== null && (
                  <div>{`Between ${new Date(selectDate.startDate).getDate()} ${
                    months[new Date(selectDate.startDate).getMonth()]
                  } - ${new Date(selectDate.endDate).getDate()} ${
                    months[new Date(selectDate.endDate).getMonth()]
                  }`}</div>
                )}
                <Modal
                  open={openDate}
                  ref={ModelRef}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <DateRan
                    onClose={handleClose}
                    open={openDate}
                    ref={DateRef}
                    setOpenDate={setOpenDate}
                    setSelectDate={setSelectDate}
                  />
                </Modal>
              </>
            )}
            {filter.su === "Location" && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    flex: 1,
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <GooglePlaces
                      value={locationData.address}
                      onChange={handleAddress}
                      handleOpenMap={handleOpenMap}
                      showMapIcon={true}
                    />
                    {/* <IconButton
                      size="large"
                      title="MAP"
                      onClick={() => setOpenMap((p) => !p)}
                    >
                      <AddLocation />
                    </IconButton> */}
                  </div>
                  {openMap && (
                    <SearchLocation
                      locationData={locationData}
                      setLocationData={setLocationData}
                    />
                  )}
                  <Slider setRange={setRange} />
                </div>
              </>
            )}
            <Button
              variant="contained"
              className={`${classes.space} ${classes.searchBtn}`}
              onClick={handleSearch}
              disabled={search || locationData.address || selectDate ? false : true}
            >
              Search
            </Button>
            <Filter setFilter={setFilter} defaultVal={defaultValue} />
          </div>
          <Divider />
          <SearchResult
            searchFor={filter.sf}
            results={results}
            setOpen={setOpen}
          />
        </Box>
      </Modal>
    </>
  );
};

export default Search;
