export default {
  success: {
    SignupSuccess: "please check your email - we have sent a confirmation link",
    EventUpdatedSuccussful: "Event updated successfully",
    ForgotPassword: "A reset link is sent to the email",
    EventAddedSuccussful: "Event added successfully",
    proposalAdded: "Proposal added successfully",
    proposalUpdated: "Proposal updated successfully",
    verificationEmailSent:
      "We have sent an email with a confirmation link to your email address",
    GiftAdded: "New Gift added successfully",
    FileUploaded: "File Uploaded successfully",
    guestSuccess: "Guest added successfully",
    guestGroupSuccess: "Group addedd successfully",
    guestGroupUpdateSuccess: "Group updated successfully",
    profileUpdateSuccess: "Profile updated successfully",
    businessAdded: "Business information added successfully",
    businessUpdated: "Business information updated successfully",
    InvitationSending:
      "Event Invitation in being sent, Guest will recieve invitation in mail",
    ReviewAdded: "Review added successfully",
    ReviewUpdated: "Review updated successfully",
    DeleteGroupSuccess: "Group deleted successfully",
    CashPayment:"Cash Payment enabled successfully",
  },
  errors: {
    firstname:"Please enter valid first name",
    lastname:"Please enter valid last name",
    fullname: "Please enter valid full name",
    accountNumber:"please enter account number",
    ssn:"please enter SSN (ID Number)",
    gender: "Please enter your gender",
    BusinessUrl: "Please enter a business URL",
    routingNumber: "Please enter a routing number",
    id_number: "Please enter a ID number",
    email: "Please enter valid email",
    phone: "Please enter valid phone no",
    password: "Please enter a valid password with min 8 alphanumeric chars.",
    cpassword: "Password does not match",
    role: "Please select a role",
    emailLogin: "Enter email address",
    passwordLogin: "Enter password",
    NoUid: "No UID found.",
    emailNotVerified:
      "Email address is not verified, click on Resend to resend verification email again",
    commandKeysMissing: "Command keys are missing",
    noEventFoun: "No event found! Please add a new event and try again",
    giftTitleRequired: "Please enter gift title",
    giftDescRequired: "Please enter gift description",
    giftAddRequired: "Please enter address",
    giftQuantityRequired: "Please enter a valid quantity",
    CommonApiError: "Oops! Something went wrong, Please try again later",
    guestName: "Please enter valid guest name",
    guestGroup: "Please select a group",
    address: "Please enter valid address",
    city: "Please enter valid city",
    state: "Please enter valid state",
    zip: "Please enter valid zip code",
    country: "Please select country",
    nameExist: "This name already exist. Please type a new name",
    NoGroupAdded: "Please add atleast one group to continue",
    MaxGuestCountExceed: "Max no of guests are already added",
    bookinAmount: "Please enter a valid booking amount",
    message: "Please enter a valid message",
    write_review: "Pleaser write a review",
    event_date    :"Please enter a valid event date",
    spouse_name:  "please enter valid spouse_name",
    event_location: "please enter valid event location",
    event_type: "Please enter a valid event type",
    function_type:"please enter a valid function type",
    createdAt:  "createdAt",
    modifiedAt: "modifiedAt",
    deleted: "deleted",
    guest_count: "Please select one of the above options",
    event_name: "Please enter a valid event name",
    name: "Please enter a valid business name",
    image: "Please provide atleast one image"
  },
  auth: {
    Login: "Login",
    SignUp: "Sign Up",
    Logout: "Logout",
    MyProfile: "My Profile",
    MyAccount: "My Account",
    FullName: "Full Name",
    EmailAddress: "Email Address",
    Phone: "Phone",
    Password: "Password",
    ConfirmPassword: "Confirm Password",
    AlreadyHaveAC: "Already have an account?",
    SignIn: "Sign In",
    DontHaveAc: "Don't have an account?",
    ForgotPassword: "Forgot Password?",
    VendorLogin: "Vendor Login",
    CoupleLogin: "Couple Login",
    LogoutContent: "Are you sure want to logout?",
    SelectYouRole: "Select Your Role",
    Couple: "Couple",
    Guest: "Guest",
    Vendor: "Vendor",
    ForgotLinkMessage: "A reset link will be sent to this email",
  },
  header: {
    Planning: "Planning",
    LocalVendors: "Local Vendors",
    WeddingWebsites: "Wedding Websites",
    Invitations: "Invitations",
    RingsPlDresses: "Rings + Dresses",
  },
  landing: {
    WeddingPlanner: "Wedding Planner",
    Wedding: "Wedding",
    Planner: "Planner",
    DreamComeTrue: "Your Dream Come True",
    WeddingPlanninDecor: "Wedding planning and decor",
    WPDContent:
      "Lorem ipsum dolor sit amet, con ctetur adipiscing elit. Donec pha etra mi vestibulum sapien lacinia non tincidunt augue congue eget Vivamus sit amet neque fringilla vestibulum ultrices eget a eros.",
    WeddingVision: "Wedding Vision",
    WeddingVisionMessage:
      "Lorem ipsum dolor sit ametest conse ctetur adipiscing elit.",
    GuestList: "Guest List",
    GuestListMessage:
      "Lorem ipsum dolor sit ametest conse ctetur adipiscing elit.",
    Budget: "Budget",
    BudgetMessage:
      "Lorem ipsum dolor sit ametest conse ctetur adipiscing elit.",
    Checklist: "Checklist",
    ChecklistMessage:
      "Lorem ipsum dolor sit ametest conse ctetur adipiscing elit.",
    VendorManager: "Vendor Manager",
    VendorManagerMessage:
      "Lorem ipsum dolor sit ametest conse ctetur adipiscing elit.",
    WedingWebsite: "Weding Website",
    WedingWebsiteMessage:
      "Lorem ipsum dolor sit ametest conse ctetur adipiscing elit.",

    LetsPlanHeading: "Let's Plan Your Perfect Wedding",
    LetsPlanMessage1: "Lorem ipsum dolor sit ametest",
    LetsPlanMessage2:
      "Donec phar etra mi vestibulum sapien lacinia, non tincidunt augue congue. Vivamus sit amet neque fringilla nisi vestibulum ultrices eget a eros. Aenean bibendum justo leo, nec dapibus ipsum vulputate id. Curabitur eget ante dui.",
    LetsPlanMessage3:
      "Cras turpis augue, elementum pellentesque porttitor suscipit, consequat ac neque. Nullam condimentum tellus a tortor gravida lacinia. Aliquam finibus augue vitae posuere mattis.",

    IdeasNTips: "Ideas and Tips",
    IdeasNTipsMssg:
      "Lorem ipsum dolor sit ametest conse ctetur adipiscing elit.",
    PlanningBasics: "Planning Basics",
    FamilyNFriends: "Family & Friends",
    WeddingTheme: "Wedding Theme",
    WeddingFashion: "Wedding Fashion",
    WeddingReception: "Wedding Reception",
    WeddingServices: "Wedding Services",

    EasyFindBestWS: "Easily find the best wedding services",
    WeddingVendors: "Wedding Vendors",
    WeddingVendorInfo:
      "Donec phar etra mi vestibulum sapien lacinia, non tincidunt augue congue. Vivamus sit amet neque fringilla nisi vestibulum ultrices eget a eros. Aenean bibendum justo leo, nec dapibus ipsum vulputate id. Curabitur eget ante dui.",
    Photography: "Photography",
    BeautyNHealth: "Beauty & Health",
    Catering: "Catering",
    Videography: "Videography",
    Invitation: "Invitation",

    PlanWeddingH1: "Plan Any Wedding From Anywhere,",
    PlanWeddingH2: "With Wedding Blizz App",
  },
  footer: {
    CopyRight: "© 2020 Wedding Blizz. All Rights Reserved",
    Links: "Links",
    SocialMedia: "Social Media",
    ContactUs: "Contact Us",
    Planning: "Planning",
    LocalVendors: "Local Vendors",
    WeddingWebsite: "Wedding Website",
    Invitations: "Invitations",
    RingsNDresses: "Rings + Dresses",
    AboutUs: "About Us",
    Contact: "Contact",
    CovidTerms: "Our COVID-19 Policy",
    TnC: "Terms & Conditions",
    PnP: "Privacy Policy",
    Facebook: "Facebook",
    Instagram: "Instagram",
    LinkedIn: "LinkedIn",
    Twitter: "Twitter",
    YouTube: "YouTube",
    FooterEmail: "info@weddingblizz.com",
    FooterPhone: "123 456 7890",
    FooterAddrss: "63, Adekunle Fajuyi, Ikeja, Lagos, Nigeria 542684",
  },
  vendors: {
    MeetWeddingTeam: "Meet Your Wedding Vendor Team",
    SelectCategory: "Select Category",
    SelectCountry: "Select Country",
    SelectCity: "Select City",

    TopVenCategory: "Top Wedding Vendor Categories",
    ReceptionVenue: "Reception Venue",
    WeddingPhotographers: "Wedding Photographers",
    Videographers: "Videographers",
    BridalSalons: "Bridal Salons",
    Beauty: "Beauty",
    DJs: "DJs",
    WedingBands: "Wending Bands",
    WeddingPlanners: "Wedding Planners",
    WeddingCakes: "Wedding Cakes",

    FindingaVendorH: "Finding a Wedding Vendor",
    FindingaVendorSubH:
      "Lorem ipsum dolor sit ametest conse ctetur adipiscing elit.",
    StartYourStyle: "Start with Your Style",
    StartYourStyleInfo:
      "Lorem ipsum dolor sit ametest conse ctetur adipiscing elit. Donec phar etra mi vestibulum sapien lacinia, non tincidunt augue congue. Vivamus sit amet neque fringilla nisi vestibulum ultrices eget a eros. ",
    UnderstandOptions: "Understand the Options",
    UnderstandOptionsInfo:
      "Lorem ipsum dolor sit ametest conse ctetur adipiscing elit. Donec phar etra mi vestibulum sapien lacinia, non tincidunt augue congue. Vivamus sit amet neque fringilla nisi vestibulum ultrices eget a eros. ",
    StartConversation: "Start Conversation",
    StartConversationInfo:
      "Lorem ipsum dolor sit ametest conse ctetur adipiscing elit. Donec phar etra mi vestibulum sapien lacinia, non tincidunt augue congue. Vivamus sit amet neque fringilla nisi vestibulum ultrices eget a eros. ",

    AllVendorCategories: "All Wedding Vendor Categories",
    AllVendorCategoriesSub:
      "Lorem ipsum dolor sit ametest conse ctetur adipiscing elit.",

    Venue: "Venue",
    Catering: "Catering",
    Photography: "Photography",
    Invitation: "Invitation",
    WeddingGifts: "Wedding Gifts",
    Transportation: "Transportation",

    WeddingVendorsState: "Wedding Vendors by State",
    AbiaWeddings: "Abia Weddings",

    CreateBusiness: "Create Business",
    VendorBusiness: "Vendor Business",
    BusinessName: "Business Name",
    Booking: "Booking",
    Bookings: "Bookings",
    NoBookingAvail: "No Bookings available",
  },
  about: {
    AboutCompany: "About Company",
    ProvidesWeddingServices: "Provides wedding services",
    ProvidesWeddingServicesS1: "Lorem ipsum dolor sit amet.",
    ProvidesWeddingServicesS2:
      "Donec phar etra mi vestibulum sapien lacinia, non tincidunt augue congue. Vivamus sit amet neque fringilla nisi vestibulum ultrices eget a eros. Aenean bibendum justo leo, nec dapibus ipsum vulputate id. Curabitur eget ante dui.",
    ProvidesWeddingServicesS3:
      "Cras turpis augue, elementum pellentesque porttitor suscipit, consequat ac neque. Nullam condimentum tellus a tortor gravida lacinia. Aliquam finibus augue vitae posuere mattis.",
    ProvidesWeddingServicesS3:
      "Vivamus sit amet neque fringilla nisi vestibulum ultrices eget a eros. Aenean bibendum justo leo, nec dapibus ipsum vulputate id.",
    TotalUsers: "Total Users",
    TotalVendors: "Total Vendors",
    TotalReviews: "Total Reviews",

    PlannBookWedding: "Plan And Book Your Wedding",
    PlannBookWeddingS1: "Lorem ipsum dolor sit amet.",
    PlannBookWeddingS2:
      "Donec phar etra mi vestibulum sapien lacinia, non tincidunt augue congue. Vivamus sit amet neque fringilla nisi vestibulum ultrices eget a eros. Aenean bibendum justo leo, nec dapibus ipsum vulputate id. Curabitur eget ante dui.",
    PlannBookWeddingS3:
      "Cras turpis augue, elementum pellentesque porttitor suscipit, consequat ac neque. Nullam condimentum tellus a tortor gravida lacinia. Aliquam finibus augue vitae posuere mattis.",
    PlannBookWeddingS3:
      "Vivamus sit amet neque fringilla nisi vestibulum ultrices eget a eros. Aenean bibendum justo leo, nec dapibus ipsum vulputate id.",
    Attention2Detail: "Attention to Detail",
    Attention2DetailS1: "Lorem ipsum dolor sit amet.",
    Attention2DetailS2:
      "Donec phar etra mi vestibulum sapien lacinia, non tincidunt augue congue. Vivamus sit amet neque fringilla nisi vestibulum ultrices eget a eros. Aenean bibendum justo leo, nec dapibus ipsum vulputate id. Curabitur eget ante dui.",
    InvestorRelations: "Investor Relations",
    InvestorRelationsS1: "Lorem ipsum dolor sit amet.",
    InvestorRelationsS2:
      "Donec phar etra mi vestibulum sapien lacinia, non tincidunt augue congue. Vivamus sit amet neque fringilla nisi vestibulum ultrices eget a eros. Aenean bibendum justo leo, nec dapibus ipsum vulputate id. Curabitur eget ante dui.",
    AmazingValue: "Amazing Value",
    AmazingValueS1: "Lorem ipsum dolor sit amet.",
    AmazingValueS2:
      "Donec phar etra mi vestibulum sapien lacinia, non tincidunt augue congue. Vivamus sit amet neque fringilla nisi vestibulum ultrices eget a eros. Aenean bibendum justo leo, nec dapibus ipsum vulputate id. Curabitur eget ante dui.",
    AmazingValueS3:
      "Vivamus sit amet neque fringilla nisi vestibulum ultrices eget a eros. Aenean bibendum justo leo, nec dapibus ipsum vulputate id.",
  },
  events: {
    WeddingEvent: "Wedding Event",
    VendorDetail: "Vendor Detail",
    VendorName: "Vendor Name",
    AddUrWeddingEvent: "Add your Wedding Event",
    NewWeddingEvent: "New Wedding Event",
    SpouseName: "Spouse Name",
    WeddingDate: "Wedding Date",
    WeddingLocation: "Wedding Location",
    GuestCount: "Guest Count",
    EventType: "Event Type",
    WeddingFunction: "Wedding Function",
    WeddingFunction: "Wedding Function",
    WeddingTheme: "Wedding Theme",
    CoupleName: "Couple Name",
    EventDate: "Event Date",
    Vendors: "Vendors",
    Guests: "Guests",
    Guest: "Guest",
    Gifts: "Gifts",
    TheEventFairy: "The Event Fairy",
    VendingServicesOffered: "Vending Services Offered",
    PrefContactMethod: "Preferred Contact Method",
  },
  guest: {
    AddNGuest: "Add New Guest",
    GuestName: "Guest Name",
    SelectGroup: "Select Group",
    GuestList: "Guest List",
    AddNGroup: "Add New Group",
    AddGroup: "Add Group",
    AddGuestList: "Add Guest List",
    GroupName: "Group Name",
    EditGroup: "Edit Group",
    EditGuest: "Edit Guest",
    DeleteGuest: "Delete Guest",
    DeleteGroup: "Delete Group",
    DeleteGroupContent:
      "This will delete all the guests in this group, Please confirm to proceed",
    DeleteGuestContent: "Are you sure want to delete this guest?",
    CoupleOfTheMoment: "Couple of the Moment",
  },
  gift: {
    GiftList: "Gift List",
    AddGift: "Add Gift",
    GiftDetails: "Gift Details",
    Giftedby: "Gifted by",
    AddNewGift: "Add New Gift",
    GiftTitle: "Gift Title",
  },
  account: {
    AccountSettings: "Account Settings",
    PersonalDetails: "Personal Details",
    WeddingDetails: "Wedding Details",
    AccountManagement: "Account Management",
    EmailPreferences: "Email Preferences",
    BusinessInformation: "Business Information",
    EditEmail: "Edit Email",
    UpdateYourPassword: "Update Your Password",
    CurrentPassword: "Current Password",
    NewPassword: "New Password",
    ConfirmNewPassword: "Confirm New Password",
    DeleteYourAccount: "Delete Your Account",
    BusinessName: "Business Name",
  },
  privacy: {
    PrivacyPolicy: "Privacy Policy",
    PrivacyPolicyH: "WEDDINGBLIZZ TERMS AND CONDITIONS",
    PrivacyPolicyC:
      "Consetetur sadipscing elitr, sed diam nons umy eirmod tempor invidunt ut labore set dolore magna aliquyam erat, sed diameset voluptua. At vero eos et jus tortduo dolores et ea rebumtet clita kasd gubergren. Eea takimata sanctus est Lorem ipsum sit dolor sit amet, consetetur sadipscing elitr, sed diam nonu my eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accu ert sam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata erdt sanctus est. Consetetur sadipscing elitr, sed diam nons umy eirmod tempor invidunt ut labore set dolore magna aliquyam erat, sed diameset voluptua. At vero eos et jus tortduo dolores et ea rebumtet clita kasd gubergren. Eea takimata sanctus est Lorem ipsum sit dolor sit amet, consetetur sadipscing elitr, sed diam nonu my eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accu ert sam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata erdt sanctus est.",
  },
  tnc: {
    TermsNConditions: "Terms and Conditions",
    WBTermsNConditions: "WeddingBlizz Terms and Conditions",
    General: "General",
    GeneralTerms:
      "Please read the following Terms and Conditions of Use carefully before continuing. By using this site, you signify your acceptance of these Terms.  These Terms govern your use of the WeddingBlizz “the App” and its website, which provides an easy-to-use, comprehensive online marriage and wedding planning activities (the “Service”). Additional terms and conditions of use applicable to specific areas of the Service may also be posted in such areas and, together with these Terms, govern your use of those areas. These Terms, together with any such additional terms and conditions, are referred to as the “Terms”.  You must read and agree to our Terms of Use (the “Agreement”) because it forms the binding contract between you and our “Company” – Trustexpats International LLC. However, we’ve provided this short summary for your convenience (with capitalized terms defined in the Agreement).",
    Acceptance: "Acceptance",
    AcceptanceTerms:
      "By using the Service, you agree to this Agreement, our Privacy Policy and Safety Tips, and any terms that apply to in app purchase you make.",
    Eligibility: "Eligibility",
    EligibilityTerms:
      "You must be at least 18 years to use the Service and not prohibited by law from using the Service. You promise to follow the law and that you’re not a convicted felon or sex offender.",
    YourAccount: "Your Account.",
    YourAccountTerms:
      "If you use Facebook to access WeddingBlizz, you must authorize us to access certain information from Facebook to use WeddingBlizz. You agree to keep your account secure and confidential.  You are responsible for maintaining the confidentiality of the password and account and are fully responsible for all activities that occur under your password or account. You agree to (a) immediately notify the Company of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you exit from your account at the end of each session. You are responsible for logging out if your computer is accessible to others. This prevents unauthorized access. The Company cannot and will not be liable for any loss or damage arising from your failure to comply with this Section.",
    ModifyingServiceAndtermnats: "Modifying the services and Termination.",
    ModifyingServiceAndtermnatsTerms:
      "We may modify the Services at any time or discontinue them altogether. You can terminate your account in Settings, and we reserve the right to terminate it for you if you violate this Agreement or if we deem your use of the Service to be inappropriate.  Weddingblizz reserves the right, in its sole discretion, to change, modify, add, or remove portions of these Terms at any time, effective immediately upon notice published on the Site. We suggest that you check these terms periodically for changes. Your continued use of the Service constitutes your binding acceptance of these terms and conditions, including any changes or modifications made. If at any time these Terms are no longer acceptable to you, you should immediately cease all use of the Service.",
    RegistrationObligations: "Registration Obligations",
    RegistrationObligationsTerms:
      "To use this App, you must first submit a complete available registration form. When submitting a registration form, you agree to: (a) provide true, accurate, current and complete information about yourself, your wedding plan, your company and services as a vendor and (b) maintain and promptly update the Registration Data to keep it true, accurate, current and complete.  If you provide any information that is untrue, inaccurate, not current or incomplete, or the App has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, the App has the right to suspend or terminate your account and refuse any and all current or future use of the Service (or any portion thereof) by you",
    Safety: "Safety.",
    SafetyTerms:
      "WeddingBlizz is not responsible for the actions of its users or your interactions with them, and we don’t conduct background checks. Be careful and be sure to read and follow our Safety Tips on interacting with people on or off of WeddingBlizz.",
    Rights: "Rights.",
    RightsTerms:
      "WeddingBlizz grants you the right to use our Service as authorized and permitted by this Agreement. See the Agreement for a full list of actions you agree not to take.  You grant WeddingBlizz the right to display your profile and Content for the limited purpose of WeddingBlizz operating the Service and researching and developing new ones.",
    RulesConductForPosting: "Rules of Conduct fot Posting Content",
    RulesConductForPostingTerms:
      "See the Agreement for a list of the rules you agree to abide by when you use WeddingBlizz, such as not soliciting money from other users, harassing other users or using the Service for any illegal purposes",
    RulesConductForPostingTerms1: "You shall not post any content that",
    RulesConductForPostingTerms2:
      "(a) is libelous, defamatory, obscene, pornographic, abusive, harassing or threatening;",
    RulesConductForPostingTerms3:
      "(b) contains viruses or other contaminating or destructive features;",
    RulesConductForPostingTerms4:
      "(c) violates the rights of others, such as content that infringes any copyright, trademark, patent, trade secret or violates any right of privacy or publicity; or (d) otherwise violates any applicable law. You may not post any links to any external Internet sites that are obscene or pornographic.",
    RulesConductForPostingTerms5:
      "(d) otherwise violates any applicable law. You may not post any links to any external Internet sites that are obscene or pornographic.",
    InAppPurchases: "In App Purchases",
    InAppPurchasesTerms:
      "WeddingBlizz may offer services for purchase through mobile platforms such as iTunes, Google Play and independent marketers. Those purchases are governed by the terms of the platforms. Most purchases are not refundable and certain services only grant you a specified license, as further described in the Agreement.",
    CopyRight: "CopyRight",
    CopyRightTerms:
      "If you believe your work has been posted on the Service in violation of your copyright, please send a notice to our Copyright Agent following instructions in the Agreement.",
    Disclaimers: "Disclaimers",
    DisclaimersTerms:
      "Our Service is provided “as is,” and we disclaim legal liability for the quality, safety, or reliability of our Service.",
    LimitationOfLiability: "Limitation of Liability.",
    LimitationOfLiabilityTerms:
      "To the extent allowed by law, we limit our liability to you for certain types of damages for claims relating to your use of the Service, the conduct of other users and unauthorized access or use of your Content. Our aggregate liability will not exceed the fees you pay us.",
    Changes: "Changes",
    ChangesTerms:
      "We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons",
    Arbitration: "Arbitration",
    ArbitrationTerms:
      "Our Agreement contains a mandatory arbitration clause. You agree that any disputes will be settled by arbitration, and you waive your right to a trial by jury or to participate in a class action.",
    Indemnity: "Indemnity",
    IndemnityTerms:
      "You agree to indemnify us for actions arising out of your use of the Service, your Content or your violation of this Agreement.  You also agree to indemnify and hold harmless the Company and its employees, officers, directors, owners, agents, information providers and licensors, to the fullest extent lawful, from and against all loss, liability, claim, damage, cost, judgment, penalty and expense (including attorneys’ fees), promptly as incurred, directly or indirectly based upon, or arising out of or in connection with (1) your violation of the Terms or (2) any use or alleged use of the app under your password by you or any person, whether or not authorized by you. The Company reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, and in such case, you agree to cooperate with the Company’s defense of such claim and to reimburse the Company for the costs and expenses of such defense.",
    DisclaimerWarrantiesLimitationLiability:
      "Disclaimer of Warranties; Limitation of Liability",
    DisclaimerWarrantiesLimitationLiabilityTerms:
      "The services and the contents in the App are available on “as is” basis. Neither the Company nor any provider of third party content, nor any of their respective agents",
    DisclaimerWarrantiesLimitationLiabilityTerms1:
      "(i) warrants that the service will be uninterrupted or error free;",
    DisclaimerWarrantiesLimitationLiabilityTerms2:
      "(ii) makes any warranty as to the results to be obtained from use of the Service or the Content, ",
    DisclaimerWarrantiesLimitationLiabilityTerms3:
      "(iii) makes any warranties of any kind, either express or implied, including, without limitation, warranties of title or implied warranties of merchantability or fitness for a particular purpose, with respect to the service, any content or any products or services sold through the App or ",
    DisclaimerWarrantiesLimitationLiabilityTerms4:
      "(iv) warrants that any files available for downloading through the Service will be free of viruses or similar contamination or destructive features. You expressly agree that the entire risk as to the quality and performance of the Service and solely you assume the accuracy or completeness of the Content. Neither the Company nor any third party service provider, nor any of their respective agents shall be liable for any direct, indirect, incidental, special or consequential damages arising out of the use of or inability to use the service, even if such party has been advised of the possibility of such damages.",
    GoverningLawVenue: "Governing Law; Venue.",
    GoverningLawVenueTerms:
      "California law applies to this Agreement. Actions for disputes relating to this Agreement must be brought in Los Angeles, California, except for users residing in the EU, who may bring claims in their country of residence.   These Terms shall be construed in accordance with the laws of the State of California, and the parties irrevocably consent to bring any action to enforce these Terms in the federal or state courts of California. These Terms constitute the entire agreement between you and us with respect to the subject matter hereof. The rights and obligations provided herein may not be assigned or delegated. If any inconsistency exists between the terms of these Terms and any additional terms and conditions posted on the Service, such terms shall be interpreted as to eliminate any inconsistency, if possible, and otherwise, the additional terms and conditions shall control.",
  },
  common: {
    Guest:"Guest List",
    Send:"Send",
    Search: "Search",
    ViewAll: "View All",
    City: "City",
    State: "State",
    Country: "Country",
    Zipcode: "Zipcode",
    PhoneNumber: "Phone Number",
    EmailAddress: "Email Address",
    Email: "Email",
    Address: "Address",
    Private: "Private",
    Public: "Public",
    Traditional: "Traditional",
    Family: "Family",
    Images: "Images",
    Image: "Image",
    Browse: "Browse",
    Submit: "Submit",
    Cancel: "Cancel",
    Map: "Map",
    outOf: "out of",
    ViewDetails: "View Details",
    Quantity: "Quantity",
    Description: "Description",
    DeliveryAddress: "Delivery Address",
    FirstName: "First Name",
    LastName: "Last Name",
    Phone: "Phone",
    Update: "Update",
    Delete: "Delete",
    Photos: "Photos",
    Edit: "Edit",
    Discussions: "Discussions",
    Events: "Events",
    Home: "Home",
    Category: "Category",
    notFoundN: "404",
    notFoundT: "The Page you are looking for does not exist.",
    NoRecordFound: "No Record Found",
    Rating: "Rating",
    Chat: "Chat",
    GetAQuote: "Get a Quote",
    Name: "Name",
    Message: "Message",
    Resend: "Resend",
    Invite: "Invite",
    Close: "Close",
    ServiceOffered: "Service Offered",
    Proposals: "Proposals",
    ProposalDetail: "Proposal Detail",
    BookingAmount: "Booking Amount",
    Accept: "Accept",
    WeddingInvitation: "Wedding Invitation",
    TypeMessage: "Type message ...",
    Invitation: "Invitation",
    Invitations: "Invitations",
    EventDetails: "Event Details",
    Gift: "Gift",
    Select: "Select",
    RemoveSelection: "Remove Selection",
    MyEvent: "My Event",
    Vendor: "Vendor",
    Chats: "Chats",
    NoChatRecord: "No chat record found",
    Gallery: "Gallery",
    GoToGallery: "Go to Gallery",
    DeleteCommentContent: "Are you sure want to delete this comment?",
    DeleteImage: "Delete Image",
    DeleteComment: "Delete Comment",
    DeleteFile: "Delete File",
    DeleteImageContent: "Are you sure want to delete this image?",
    DeleteFileContent: "Are you sure want to delete this File?",
    Upload: "Upload",
    Media: "Media",
    WriteReview: "Write Review",
    AddReview: "Add Review",
    StartRating: "Start Rating",
    WriteYourReview: "Write Your Review",
    Reviews: "Reviews",
    MyReviews: "My Reviews",
    GalleryDeleted: "Gallery Image is deleted successfully",
  },
  notifications: {
    Proposal: "Proposal",
    NewProposal: "New Proposal",
    UpdateProposal: "Proposal Update",
    NewQuote: "New Quote",
    UpdateQuote: "Quote Update",
    ProposalBody: "You have a new Proposal by",
    ProposalUpdatedBody: "You have a new updated Proposal by",
    ProposalAcceptedBody: "Your Proposal is accepted by",
    QuoteBody: "You have a quote on your service by ",
    QuoteUpdateBody: "You have new quote updated on your service by",
  },
  proposal: {
    NoProposalFound: "No proposals found",
    SendProposal: "Send Proposal",
    AddProposal: "Add Proposal",
    UppdateProposal: "Uppdate Proposal",
    ProposalAccepted: "Proposal Accepted",
  },
  chat: {
    NoConversFound: "No chat record found",
  },
};
