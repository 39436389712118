import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Close, FilterList } from "@material-ui/icons";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({ setFilter, defaultVal }) {
  const {
    user: { type },
  } = useSelector(({ user }) => user);

  const [open, setOpen] = useState(false);

  //searching for vendor or event
  const [searchingFor, setSearhingFor] = useState(
    defaultVal.SearchFor === "Vendors" ? 1 : 0
  );

  //searching using name,location,date,category
  const [state, setState] = useState("Name");

  const handleApply = () => {
    const sf = searchingFor === 0 ? "Wedding Events" : "Vendors";
    console.log("handleApply",sf, state);
    let searchUsing = state;
    if(searchingFor === 0 && state === "Vendors Category"){
      searchUsing = "Wedding Date";
    }else if(searchingFor === 1 && state === "Wedding Date"){
      searchUsing = "Vendors Category"
    }
    setState(searchUsing);
    setFilter({
      sf: sf,
      su: searchUsing,
    });
    handleClose();
  };

  const handleReset = () => {
    setState("Name");
    setFilter({
      sf: type === 1 ? "Vendors" : "Wedding Events",
      su: "Name",
    });
    setSearhingFor([]);
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        <FilterList title="Filter" />
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Filters
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom>SEARCHING FOR</Typography> */}
          <FormControl component="fieldset">
            <FormLabel component="legend">SEARCHING FOR</FormLabel>
            <RadioGroup
              aria-label="gender"
              defaultValue={defaultVal.SearchFor}
              name="radio-buttons-group"
              // value={defaultVal.SearchFor}
            >
              <FormControlLabel
                value="Wedding Events"
                control={<Radio />}
                label="Wedding Events"
                onChange={() => setSearhingFor(0)}
              />
              <FormControlLabel
                value="Vendors"
                control={<Radio />}
                label="Vendors"
                onChange={() => setSearhingFor(1)}
              />
            </RadioGroup>
          </FormControl>
          <div style={{ marginTop: "24px" }}>
            <FormControl component="fieldset">
              <FormLabel component="legend">SEARCHING USING</FormLabel>
              <RadioGroup
                aria-label="SEARCHING USING"
                defaultValue={defaultVal.SearchUsing}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="Name"
                  control={<Radio />}
                  label="Name"
                  onChange={() => setState("Name")}
                />
                <FormControlLabel
                  value="Location"
                  control={<Radio />}
                  label="Location"
                  onChange={() => setState("Location")}
                />
                <FormControlLabel
                  value={searchingFor ? "Vendors Category" : "Wedding Date"}
                  control={<Radio />}
                  label={searchingFor ? "Vendors Category" : "Wedding Date"}
                  onChange={() =>
                    setState(searchingFor ? "Vendors Category" : "Wedding Date")
                  }
                />
              </RadioGroup>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleReset}>
            Reset
          </Button>
          <Button autoFocus onClick={handleApply}>
            Apply
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
