import React, { useState, useEffect } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import { Button } from "@material-ui/core";

const DateRan = ({ open, setOpenDate, setSelectDate }) => {
  const date = new Date();
  const endDate = date.setDate(date.getDate() + 1);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: endDate,
      key: "selection",
    },
  ]);

  useEffect(() => {
    console.log(state);
  }, [JSON.stringify(state)]);

  const handleDate = () => {
    let startDate = state[0].startDate;
    let endDate = state[0].endDate;
    if (!endDate) {
      endDate = "RR";
    }
    console.log(startDate, endDate);
    setSelectDate({ startDate, endDate });
    setOpenDate(false);
  };

  return (
    <div style={{ margin: "10px" }}>
      {open && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DateRange
            editableDateInputs={true}
            onChange={(item) => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="contained"
              onClick={handleDate}
              style={{ margin: "10px" }}
            >
              SAVE
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenDate(false)}
              style={{ margin: "10px" }}
            >
              CANCEL
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRan;
