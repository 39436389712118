import React, { useState } from "react";
import { Box, Typography, Slider } from "@material-ui/core";

export default function NonLinearSlider({ setRange }) {
  const [value, setValue] = useState(5);

  const handleChange = (event, newValue) => {
    if (typeof newValue === "number") {
      setValue(newValue);
    }
    setRange(newValue);
  };

  return (
    <Box sx={{ width: 250 }}>
      <Typography id="input-slider" gutterBottom>
        Radius: {`${value} KM`}
      </Typography>
      <Slider
        value={value}
        min={5}
        step={5}
        max={200}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-label="pretto slider"
      />
    </Box>
  );
}
