import { ACTION_TYPES } from "constant";
const INITIAL_STATE = {
  show: false,
  message: "",
  type: "error",
  extra: {},
  categories: [],
  imgloader: false,
  notifications: [],
  notificationsList: false,
  hasEvent: false,
  hasVendor: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.CLOSE_ALERT:
      return { ...state, show: false, message: "" };
    case ACTION_TYPES.CREATE_ALERT:
      return {
        ...state,
        show: true,
        message: action.payload,
        type: action.typ,
        extra: action.extra,
      };
    case ACTION_TYPES.SET_POSITION:
      return { ...state, position: action.payload };
    case ACTION_TYPES.CATEGORIES:
      return { ...state, categories: action.payload };
    case ACTION_TYPES.HAS_EVENT:
      return { ...state, hasEvent: action.payload };
    case ACTION_TYPES.HAS_VENDOR:
      return { ...state, hasVendor: action.payload };

    case ACTION_TYPES.NOTIFICATION_REQUEST:
      return { ...state, loader: true };
    case ACTION_TYPES.NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
        loader: false,
      };
    case ACTION_TYPES.NOTIFICATION_COMPLETE:
      return { ...state, loader: false };
    case ACTION_TYPES.NOTIFICATION_FAILED:
      return { ...state, loader: false };

    case ACTION_TYPES.IMAGE_URL_REQUEST:
      return { ...state, imgloader: true };
    case ACTION_TYPES.IMAGE_URL_COMPLETE:
      return { ...state, imgloader: false };
    case ACTION_TYPES.IMAGE_URL_FAILED:
      return { ...state, imgloader: false };
    case ACTION_TYPES.RESET:
      return INITIAL_STATE;
    case ACTION_TYPES.LOG_OUT:
      return INITIAL_STATE;
    case "NOTIFICATIONS_LIST":
      return { ...state, notificationsList: action.payload };

    default:
      return state;
  }
};
