import { combineReducers } from "redux";
import user from "./user";
import vendor from "./vendor";
import event from "./event";
import gift from "./gift";
import guest from "./guest";
import app from "./app";
import proposal from "./proposal";
import chat from "./chat";
import gallery from "./gallery";
import transaction from "./transaction";
import search from "./search";

export default combineReducers({
  user,
  vendor,
  event,
  gift,
  guest,
  app,
  proposal,
  chat,
  gallery,
  transaction,
  search,
});
