import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { accountStyle } from "styles";
import { strings } from "constant";
import Menus from "./popupMenu";
import { useSelector, useDispatch } from "react-redux";
import { getProposals } from "actions";

const { account, common } = strings;
export default (props) => {
  const dispatch = useDispatch();
  const classes = accountStyle();
  const { business, booking = [] } = useSelector(({ vendor }) => vendor);

  const { proposals = [], loader = false } = useSelector(
    ({ proposal }) => proposal
  );

  const dummy = [
    { title: common.Discussions, value: proposals?.length },
    { title: common.Events, value: booking.length },
    {
      title: common.Photos,
      value: business && business.images ? business.images.length : 0,
    },
  ];

  useEffect(() => {
    dispatch(getProposals());
  }, []);

  return (
    <div className={classes.businessStatV}>
      {dummy.map((item) => (
        <div className={classes.businessStatInV}>
          <Box fontFamily="GothamLight" className={classes.busStatsValuT}>
            {item.value}
          </Box>
          <Box fontFamily="Gotham" className={classes.busStatsTitleT}>
            {item.title}
          </Box>
        </div>
      ))}
    </div>
  );
};
