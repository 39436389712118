import React, { useState, useEffect } from "react";
import { Player } from "video-react";
import {
  DialogContent,
  Dialog,
  TextField,
  Button,
  IconButton,
  Divider,
} from "@material-ui/core";
import TimeAgo from "react-timeago";
import { useDispatch, useSelector } from "react-redux";
import { commonStyle, clearVideoModal } from "styles";
import { postComments, deleteComment, fetchComments } from "actions";
import { Clear } from "@material-ui/icons";
import { primaryLoaderStyle } from "styles";
import { Loader, Confirm } from "components";
import { DeleteOutlineOutlined } from "@material-ui/icons";
import { strings } from "constant";
const { common } = strings;

const media = { width: "100%", padding: "0px", backgroundSize: "100%" };

export default (props) => {
  const dispatch = useDispatch();
  const classes = commonStyle();
  const [comment, setComment] = useState("");
  const [delDiag, setDelDiag] = useState(false);
  const [commentId, setCommentId] = useState(null);
  const { _loader, coments = [] } = useSelector(({ gallery }) => gallery);
  const {
    event: { owners },
  } = useSelector(({ event }) => event);
  console.log(coments);

  const { uid } = useSelector(({ user }) => user.user);

  const { open, toggleModal, url, type } = props;

  const handleComments = async (id) => {
    if (comment) {
      dispatch(postComments(id, comment));
    }
    setComment("");
  };

  useEffect(() => {
    const get = async () => {
      await dispatch(fetchComments(props.id));
    };
    get();
  }, [props.id]);

  const hanldeDelete = async (id) => {
    await dispatch(deleteComment(id));
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Dialog open={open} onClose={toggleModal}>
        <DialogContent className={classes.videoContentV}>
          <div className={classes.closeV} onClick={toggleModal}>
            <Clear style={clearVideoModal} />
          </div>
          {type === "video" || type === 1 ? (
            <Player>
              <source src={url} style={media} />
            </Player>
          ) : (
            <img src={url} style={media} alt="" />
          )}
        </DialogContent>
        <div className={classes.comments}>
          <div style={{ backgroundColor: "black", height: "1px" }}></div>
          <h3>Comments {coments && coments.length}</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <TextField
              id="standard-basic"
              label="Add a Comment"
              variant="standard"
              style={{ width: "80%" }}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <Button
              variant="outlined"
              Button
              onClick={() => handleComments(props.id)}
              disabled={comment.length ? false : true}
            >
              Post
            </Button>
          </div>
          <div style={{ height: "300px", overflowX: "hidden" }}>
            {_loader ? (
              <Loader
                style={(primaryLoaderStyle, { marginTop: "25px" })}
                size={30}
              />
            ) : coments && coments.length ? (
              coments.map((el, i) => (
                <div
                  style={{
                    marginTop: "10px",
                  }}
                  key={i}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={
                            el.profilePic
                              ? el.profilePic
                              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"
                          }
                          alt=""
                          style={{ width: "30px", marginRight: "10px" }}
                        />
                        <h3 style={{ margin: "0" }}>{el.displayName}</h3>
                      </div>
                      <p>{el.comment}</p>
                    </div>
                    <div
                      onClick={() => {
                        setDelDiag(true);
                        setCommentId(el.id);
                      }}
                    >
                      {uid === el.userId || owners === uid ? (
                        <IconButton>
                          <DeleteOutlineOutlined />
                        </IconButton>
                      ) : null}
                    </div>
                  </div>
                  <div style={{ fontSize: "12px" }}>
                    <TimeAgo date={el.createdAt} live={false} />
                  </div>
                  <Divider />
                </div>
              ))
            ) : null}
          </div>
        </div>
      </Dialog>
      <Confirm
        open={delDiag}
        onClose={() => setDelDiag(false)}
        title={common.DeleteComment}
        content={common.DeleteCommentContent}
        onClick={() => hanldeDelete(commentId)}
        button={common.Delete}
        loader={_loader}
      />
    </div>
  );
};
