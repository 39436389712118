import React, { useEffect, useState } from "react";
import { guestLandStyle, eventStyle } from "styles";
import { Typography, Box } from "@material-ui/core";
import Gallery from "react-photo-gallery";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "helpers";

import {
  weddingPlanner1,
  weddingPlanner2,
  WeddingPhotographers,
  DJs,
  WeddingPlanners,
  flowersPotDec,
  aboutCouple,
  dressingBride,
} from "assets";

import { userIcon, calendarIcon, locationPinIcon } from "assets";
import { strings } from "constant";
import { Loader, NoRecordFound } from "components";
import { getGuestEvents, setEvent, NotifyInvite } from "actions";
import moment from "moment";
const { common, guest } = strings;

const photos = [
  { src: WeddingPhotographers, height: 3, width: 5 },
  { src: DJs, height: 1, width: 2 },
  { src: WeddingPlanners, height: 3, width: 4 },
  { src: dressingBride, height: 1, width: 1 },
  { src: flowersPotDec, height: 4, width: 4 },
  { src: aboutCouple, height: 3, width: 4 },
  { src: weddingPlanner1, height: 100, width: 200 },
];
export default (props) => {
  const classes = guestLandStyle();
  const eclasses = eventStyle();
  const dispatch = useDispatch();
  const {
    user: { type, uid },
    fcm,
  } = useSelector(({ user }) => user);

  const { events: guestEvents, loader } = useSelector(({ guest }) => guest);
  console.log(guestEvents);

  useEffect(() => {
    const events = async () => {
      await dispatch(getGuestEvents());
      await dispatch(NotifyInvite());
    };
    if (type !== 2) props.history.push("/eventdetail");
    else events();
    if (!fcm || fcm === []) notification(uid);
  }, []);
  console.log("landing-guest", guestEvents);

  const handleEvent = (item) => {
    dispatch(setEvent(item));
    props.history.push("/guest-event");
  };
  return (
    <div className={classes.container}>
      <Typography component="div" className={eclasses.eventTV}>
        <Box
          fontFamily="CormorantBold"
          className={classes.guestLandingCoupleHT}
        >
          {guest.CoupleOfTheMoment}
        </Box>
      </Typography>

      <div className={classes.headerImageV}>
        <img src={weddingPlanner2} className={classes.headerImage} alt="" />
      </div>
      <div className={classes.subImagesV}>
        <Gallery photos={photos} />
      </div>
      <Typography component="div" className={eclasses.eventTV}>
        <Box fontFamily="CormorantBold" className={eclasses.eventT}>
          {common.Invitations}
        </Box>
      </Typography>
      <div className={classes.invitesV} style={{ flexWrap: "wrap" }}>
        {loader ? (
          <Loader />
        ) : guestEvents.length ? (
          guestEvents.map((item, i) => (
            <div
              className={classes.eventsLisCardV}
              key={i}
              onClick={() => handleEvent(item)}
            >
              <div
                key={"guest-events invites-" + i}
                className={classes.eventsLisCard}
              >
                <Box fontFamily="Gotham" className={classes.eventT}>
                  {item.event_name}
                </Box>

                <Box fontFamily="GothamBook" className={eclasses.eventFrCT}>
                  <img
                    src={userIcon}
                    alt=""
                    className={eclasses.eventFrIcons}
                  />
                  {/* hdkjhfkjsdf */}
                  {item.profile.name}
                </Box>
                <Box fontFamily="GothamBook" className={eclasses.eventFrCT}>
                  <img
                    src={calendarIcon}
                    alt=""
                    className={eclasses.eventFrIcons}
                  />
                  {item.event_date &&
                    moment(new Date(item.event_date)).format("MM/DD/YYYY")}
                </Box>
                <Box fontFamily="GothamBook" className={eclasses.eventFrCT}>
                  <img
                    src={locationPinIcon}
                    alt=""
                    className={eclasses.eventFrIcons}
                  />
                  {item.event_location}
                </Box>
              </div>
            </div>
          ))
        ) : (
          <NoRecordFound />
        )}
      </div>
    </div>
  );
};
