import { makeStyles } from "@material-ui/styles";
import { colors } from "constant";

const center = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const WalletStyle = makeStyles((theme) => ({
  walletContainer: {
    fontFamily: "GothamBook",
    [theme.breakpoints.down("md")]: {
      marginTop: "100px",
    },
  },
  walletMain: {
    width: "100%",
    ...center,
    paddingTop: 80,
    [theme.breakpoints.down("md")]: {
      paddingTop: 0,
    },
  },
  walletT: {
    fontSize: 36,
    color: colors.main,
    padding: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },
  
  walletsT: {
    fontSize: 24,
    color: colors.main,
    padding: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  headV: {
    ...center,
    width: "100%",
    position: "relative",
  },
  addButtonV: {
    position: "absolute",
    right: 50,

    [theme.breakpoints.down("md")]: {
      right: 10,
    },
  },
  center: {
    ...center,
  },
  balance: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  componentMain: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  active: {
    backgroundColor: colors.main,
    color: colors.grey1,
    margin: "10px",
    "&:hover": {
      backgroundColor: colors.main5,
      color: colors.grey1,
    },
  },
  wallet_form: {
    width: "30vw",
    minWidth: "300px",
    alignSelf: "center",
    boxShadow: "2px 3px 5px 1px grey",
    borderRadius: "7px",
    padding: "10px",
  },
  transfer_form: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "30vw",
    minWidth: "300px",
    alignSelf: "center",
    boxShadow: "2px 3px 5px 1px grey",
    borderRadius: "7px",
    padding: "10px",
  },
  App_container: {
    fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif",
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    height: "100vh",
    width: "100vw",
  },
  payment_element: {
    marginBottom: "24px",
  },
  Row: {
    margin: "25px",
    // width: "80%",
  },
  card: {
    margin: "15px",
    width: "80%",
  },
  w__conatiner: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  WalletComponent: {
    ...center,
    margin: "25px",
  },
  wallet_left: {
    maxHeight: "100%",
    width: "65%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  wallet_right: {
    width: "25%",
    maxHeight: "350px",
    overflow: "auto",

    [theme.breakpoints.down("md")]: {
      padding: "10px",
      width: "90%",
      margin: "20px",
    },
  },
  wallet_history_item: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
    border: `1px solid ${colors.grey2}`,
    padding: 10,
    margin: 10,
  },
  create_account_container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    margin: "25px",
  },
  
  para:{
    width:"65%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px"
    },

    [theme.breakpoints.down("xs")]:{

      fontSize:"14px",
    }
  }, 
  amount:{
        width:"12%",
        [theme.breakpoints.down("sm")]:{

          fontSize: "14px",
          margin: "1px",
          width:"35%"
        },
        
       [theme.breakpoints.down("xs")]:{

          fontSize: "12px",
          marginLeft: "8px",
          
       }
  },
}),{index:1});
