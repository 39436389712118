import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/messaging";
import "firebase/functions";
import "firebase/database";
import { GeoFire } from "geofire";

import { strings, ACTION_TYPES } from "constant";
import { store } from "store";
import { createAlert } from "actions";
const { errors } = strings;
const {
  REACT_APP_BUCKET,
  REACT_APP_FIRE_API,
  REACT_APP_FIRE_AUTH_DOMAIN,
  REACT_APP_FIRE_DB,
  REACT_APP_ID,
  REACT_APP_MEASERID,
  REACT_APP_PID,
  REACT_APP_SENDER_ID,
  REACT_APP_CONFIRMATION_EMAIL_REDIRECT
} = process.env;
const firebaseConfig = {
  apiKey: REACT_APP_FIRE_API,
  authDomain: REACT_APP_FIRE_AUTH_DOMAIN,
  databaseURL: REACT_APP_FIRE_DB,
  projectId: REACT_APP_PID,
  storageBucket: REACT_APP_BUCKET,
  messagingSenderId: REACT_APP_SENDER_ID,
  appId: REACT_APP_ID,
  measurementId: REACT_APP_MEASERID,
};
export let auth;
export let firestore;
export let messaging;
export let functions;

export const firebaseInit = () => {
  firebase.initializeApp(firebaseConfig);
  firestore = firebase.firestore();
  auth = firebase.auth();
  messaging = firebase.messaging();
  functions = firebase.functions();
  setListners();
};

export const registerServiceWorker = async () => {
  try {
    console.log("registerServiceWorker called from app.js");
    if ("serviceWorker" in navigator) {
      await navigator.serviceWorker.register("./firebase-messaging-sw.js");
    }
  } catch (error) {
    console.log("Service worker registration failed, error:", error);
  }
};

export const incrementWalletAmount = (walletId, updatedAmount, createdAt) => {
  return new Promise((resolve, reject) => {
    try {
      let walletRef = firestore.collection("wallet").doc(walletId);

      walletRef
        .update({
          amount: firebase.firestore.FieldValue.increment(updatedAmount),
          modifiedAt: createdAt ? createdAt : new Date().getTime(),
        })
        .then(() => {
          return walletRef.get();
        })
        .then((doc) => {
          console.log(doc);
          resolve(doc);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const handleFCM = async (token, uid, action = "add") => {
  console.log("device token", token);
  if (token) {
    let tokens = [];
    const user = await findById("users", uid);
    const { fcm = [] } = user.data();

    if (action === "add")
      if (fcm.indexOf(token) > -1) return;
      else tokens = [...fcm, token];
    else tokens = fcm.filter((tkn) => tkn !== token);
    updateOne("users", uid, { fcm: tokens });
  }
};

export const notification = async (uid) => {
  const { dispatch } = store;
  try {
    await Notification.requestPermission();
    const payload = await messaging.getToken();

    console.log("token: notification notification", payload);
    if (payload) {
      dispatch({ type: ACTION_TYPES.DEVICE_TOKEN, payload });
      handleFCM(payload, uid);
    }
    messaging.onMessage((notification) => {
      // debugger;
      console.log("notification", notification);
      handleMessages(notification);
    });
  } catch (error) {
    console.log("notification ", error);
    dispatch(createAlert({ message: error.message, type: "error" }));
  }
};

export const sendPush = (data) => {
  const notification = functions.httpsCallable("notifications");

  console.log("notification sending.. ");
  return notification(data);
};

export const inviteNotification = (data) => {
  const inviteNotifications = functions.httpsCallable("inviteNotifications");
  console.log("notification sending.. ");
  return inviteNotifications(data);
};

export const sendEmail = async (data) => {
  try {
    const sendEmailFun = functions.httpsCallable("sendEmail");
    const res = await sendEmailFun({
      to: data.to,
      subject: data.subject,
      type: data.type,
      message: data?.message ? data?.message : "",
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

const handleMessages = (notification) => {
  console.log("on notification ", notification);
};
let userDB = (uid) => firestore.doc(`users/${uid}`);

export const createProfile = async (uid, user) => {
  if (!uid) throw errors.NoUid;
  const createdAt = new Date().getTime();
  const snapShot = await userDB(uid).get();
  if (!snapShot.exists) userDB(uid).set({ ...user, createdAt });
  let currentUser = firebase.auth().currentUser;
  await currentUser.sendEmailVerification({
    url: REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
  });
  currentUser.updateProfile({ ...user, createdAt, modifiedAt: createdAt });
};
export const resendVerificationEmail = async () => {
  let currentUser = firebase.auth().currentUser;
  await currentUser.sendEmailVerification({
    url: REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
  });
};
export const setListners = async () => {
  if (auth)
    auth.onAuthStateChanged(async (user) => {
      if (user && user.emailVerified) {
        const { getState, dispatch } = store;
        const data = getState().user;
        const { displayName, uid, email, refreshToken, name } = user;
        const snapshot = (await userDB(uid).get()).data();
        user.getIdToken().then(function (idToken) {
          if (data.user)
            dispatch({
              type: ACTION_TYPES.AUTH_COMPLETE,
              payload: {
                ...data.user,
                uid,
                tokenId: idToken,
                name: name,
                email,
                token: refreshToken,
                ...snapshot,
              },
            });
        });
      }
    });
};

//delete images from firebase
export const deleteFromFirebase = (path) => {
  if (path) return firebase.storage().ref().child(path).delete();
};

export const getMetaData = (path) => {
  if (path)
    firebase
      .storage()
      .ref()
      .child(path)
      .getMetadata()
      .then((metadata) => {
        return metadata.name;
      });
};

export const uploadImages = (collection, images = []) => {
  try {
    let promiseArr = images.map(async (image) => {
      const name = `${new Date().getTime()}-${image.name}`;
      const ref = firebase.storage().ref(`${collection}`);
      const snapshot = await ref.child(name).put(image);
      // return await snapshot.ref.getDownloadURL();
      return `${collection}/${name}`;
    });
    return Promise.all(promiseArr);
  } catch (error) {
    console.log("upload catch error ", error);
    return [];
  }
};

export const imagePathToUrl = async (path) => {
  if (path) {
    return new Promise((resolve, reject) => {
      firebase
        .storage()
        .ref()
        .child(path)
        .getDownloadURL()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export const findByIn = async (collection = "", field = "", ids = []) => {
  return await firestore.collection(collection).where(field, "in", ids);
};
export const findById = (collection, id) => {
  return firestore.collection(collection).doc(id).get();
};

export const insert = async (collection, data) => {
  let { id = "" } = await firestore.collection(collection).doc();

  if (data.id) {
    id = data.id;
  }
  console.log("id", id);
  const createdAt = new Date().getTime();
  const modifiedAt = new Date().getTime();
  await firestore
    .collection(collection)
    .doc(id)
    .set({ ...data, id, createdAt, modifiedAt });
  const tableName = collection === "events" ? "geo_event" : "geo_business";
  if (collection === "events" || collection === "venders")
    UpdateRealTimeDb(tableName, id, data);
  return { id };
};

export const updateOne = async (collection, id, data) => {
  // eslint-disable-next-line no-throw-literal
  if (!collection || !id || !data) throw { message: errors.commandKeysMissing };
  await firestore
    .collection(collection)
    .doc(id)
    .update({ ...data, modifiedAt: new Date().getTime() });

  const tableName = collection === "events" ? "geo_event" : "geo_business";
  if (collection === "events" || collection === "venders")
    UpdateRealTimeDb(tableName, id, data);
};

export const UpdateRealTimeDb = async (tableName, id, data) => {
  try {
    var firebaseRef = firebase.database().ref(tableName);
    var geoFire = new GeoFire(firebaseRef);

    geoFire.set(id, [data.latitude, data.longitude]).then(
      function () {
        console.log("Provided key has been added to GeoFire");
      },
      function (error) {
        console.log("Error: " + error);
      }
    );
  } catch (err) {
    console.log(err);
  }
};

const getResolved = (geoQuery) => {
  return new Promise((res, rej) => {
    const promise = [];
    var onReadyRegistration = geoQuery.on(
      "key_entered",
      function (key, location, distance) {
        promise.push(key);
      }
    );

    var onReadyRegistration = geoQuery.on("ready", function () {
      console.log(
        "GeoQuery has loaded and fired all other events for initial data",
        promise
      );
      res(promise);
    });
  });
};

export const getLocation = async (tableName, data) => {
  var firebaseRef = firebase.database().ref(tableName);
  var geoFire = new GeoFire(firebaseRef);

  var geoQuery = geoFire.query({
    center: data.center,
    radius: data.radius,
  });

  //This will make sure that return only if promise is fulfilled
  return getResolved(geoQuery);
};

export const deleteGroup = async (data) => {
  const deleteGroup = functions.httpsCallable("deleteGroup");
  console.log("Deleting group.. ");
  return deleteGroup(data);
};
