import { firestore, insert, sendPush } from "helpers";
import { history } from "../App";
import { ACTION_TYPES, strings } from "constant";
import { createAlert } from "actions";
const { success, errors } = strings;

export const addGift = (state) => async (dispatch, getState) => {
  try {
    const { id = "" } = getState().event?.event;
    dispatch({ type: ACTION_TYPES.GIFT_REQUEST });
    await insert("gifts", { ...state, event_id: id });
    setTimeout(() => {
      dispatch({ type: ACTION_TYPES.GIFT_SUCCESS });
      dispatch(createAlert({ message: state.cashPayment === true ? success.CashPayment : success.GiftAdded, type: "success" }));
      history.goBack();
    }, 2000);
  } catch (error) {
    console.log("addGift error ", error);
    dispatch({ type: ACTION_TYPES.GIFT_FAILED });
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
  }
};

export const fetchGifts = () => async (dispatch, getState) => {
  try {
    const { id = "" } = getState().event?.event;
    dispatch({ type: ACTION_TYPES.GIFT_REQUEST });
    const data1 = await firestore
      .collection("gifts")
      .where("event_id", "==", id)
      .where("cashPayment", "==", true)
      .get();

    const data2 = await firestore
      .collection("gifts")
      .where("event_id", "==", id)
      .where("cashPayment", "==", false)
      .get();
    
    const payload1 = data1.docs.map((gift) => gift.data().id && gift.data());
    const payload2 = data2.docs.map((gift) => gift.data().id && gift.data());

    let totalGifts = 0,
    totalGiftedBy = 0;
    payload2.forEach((it) => {
      totalGifts += Number(it.quantity);
      totalGiftedBy += it.selected_by.length;
    });

    const payload = [...payload1, ...payload2];

    //payload: { ...gifts, totalGifts, totalGiftedBy },
    dispatch({
      type: ACTION_TYPES.GIFTS_COMPLETE,
      payload: {giftLists:payload, totalGifts, totalGiftedBy}
    });
  } catch (error) {
    console.log("addGift error ", error);
    dispatch({ type: ACTION_TYPES.GIFT_FAILED });
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
  }
};

export const giftedBy = (ids) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.GIFT_REQUEST });

    const payload = ids.map(async (id) => {
      const data = await firestore
        .collection("users")
        .where("userId", "==", id)
        .get();
      const res = data.docs.map((user) => user.data());
      return res[0];
    });
    dispatch({ type: ACTION_TYPES.GIFT_SUCCESS });
    return await Promise.all(payload);
    // console.log("gift.data() gift.data() ", payload);
  } catch (error) {
    console.log("addGift error ", error);
    dispatch({ type: ACTION_TYPES.GIFT_FAILED });
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
  }
};

export const cashPayment = (cashpayment) => async (dispatch, getState) => {
  try {
    const { id = "" } = getState().event?.event;
    const data = await firestore
      .collection("gifts")
      .where("event_id", "==", id)
      .where("cashPayment", "==", true)
      .get();
    // const data = await firestore.doc(`gifts/${id}`).get();
    const newData = data.docs.map((gift) => gift.data());
    console.log(newData);
    if (newData.length) {
      return true;
    } else if (cashpayment) {
      dispatch(addGift(cashpayment));
    } else {
      return false;
    }
  } catch (error) {
    console.log("updateGift error ", error);
    dispatch({ type: ACTION_TYPES.GIFT_FAILED });
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
  }
};

export const updateGift =
  ({ id, ...gift }, flag, edit = 0) =>
  async (dispatch, getState) => {
    try {
      const { uid = "", name } = getState().user?.user;
      const { owners } = getState().event.event;

      const { gifts:{giftLists, totalGiftedBy, totalGifts} } = getState().gift;
      // const {giftLists}= gifts
      const data = await firestore
        .collection("gifts")
        .where("id", "==", id)
        .get();
      const newData = data.docs.map((gift) => gift.data());

      // dispatch({ type: ACTION_TYPES.GIFT_REQUEST });
      //edit: It mean couple is changing details of gift
      //This "if" will trigger on couple side where else will trigger on guest selection/remove
      if (edit)
        await firestore.doc(`gifts/${id}`).update({
          ...gift,
          id,
          selected_by: [...gift.selected_by],
        });
      else {
        //"selected_by" field only for gifts where cashPayment === false
        if (!gift.cashPayment) {
          const localChanges = giftLists.map((it) => {
            if (it.id === id)
              return {
                ...it,
                selected_by:
                  gift.selected_by && gift.selected_by.length
                    ? flag
                      ? newData[0].selected_by.filter((id) => id !== uid)
                      : [...gift.selected_by, uid]
                    : [uid],
              };
            return it;
          });
         const payload={giftLists:localChanges, totalGiftedBy, totalGifts}
         console.log("payload", payload)
          dispatch({
            type: ACTION_TYPES.GIFTS_COMPLETE,
            payload: payload,
          });

          await firestore.doc(`gifts/${id}`).update({
            selected_by:
              gift.selected_by && gift.selected_by.length
                ? flag
                  ? newData[0].selected_by.filter((id) => id !== uid)
                  : [...gift.selected_by, uid]
                : [uid],
          });
        }
        if (!flag && gift.cashPayment === false) {
          //if(selection && cash_payment==false)
          //The \(giftName) have been chosen by the guest \(selectedBy) to gift you.
          const options = {
            to: [owners],
            from: uid,
            title: "Gift Selected",
            body: `The ${newData.length && newData[0].gift_title} have been chosen by the  ${name} to gift you`,
          };
          sendPush(options);
        } else if (flag && gift.cashPayment === false) {
          //if(removal && cash_payment==false)
          const options = {
            to: [owners],
            from: uid,
            title: "Gift Removed",
            body: `${name} removed gift of a ${newData.length && newData[0].gift_title}`,
          };
          sendPush(options);
        }
      }
        // dispatch(fetchGifts());
        setTimeout(() => {
        dispatch({ type: ACTION_TYPES.GIFT_SUCCESS });
        dispatch(
          createAlert({ message: "Gift updated successfully", type: "success" })
        );
        // history.goBack();
      }, 1000);
    } catch (error) {
      console.log("updateGift error ", error);
      dispatch({ type: ACTION_TYPES.GIFT_FAILED });
      dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
    }
  };

export const deleteGift = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.GIFT_REQUEST });
    await firestore.collection("gifts").doc(id).delete();
    setTimeout(() => {
      dispatch({ type: ACTION_TYPES.GIFT_SUCCESS });
      dispatch(
        createAlert({ message: "Gift Deleted successfully", type: "success" })
      );
      history.goBack();
    }, 1000);
  } catch (error) {
    console.log("addGift error ", error);
    dispatch({ type: ACTION_TYPES.GIFT_FAILED });
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
  }
};
