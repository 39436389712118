import React, { useEffect } from "react";
import Payment from "./payment";
import { Box, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getWallet,
  getVendorOrdersDetail,
  getEventOrdersDetail,
} from "actions";
import { NoRecordFound, Loader } from "components";
import {display} from "helpers"

import { PaymentStyle, proposalStyle } from "styles";

export default () => {
  const classes = PaymentStyle();
  const history = useHistory();
  const dispatch = useDispatch();

 
  const { wallet, loader = false } = useSelector(
    ({ transaction }) => transaction
  );
  
  const { paymentDetails = [] } = useSelector(({ event }) => event);

  console.log(paymentDetails);
  // debugger;
  const { type } = useSelector(({ user }) => user.user);

  const params = history?.location?.state;

  useEffect(() => {

    const getwallet = async () => {
      await dispatch(getWallet());
      if (type === 1) await dispatch(getVendorOrdersDetail());
      if (type === 3) {
        await dispatch(getEventOrdersDetail(params?.event_id, params?.cId));
      }
    };
    getwallet();
  }, []);

  return (
    <div className={classes.paymentContainer}>
      <Box fontFamily="CormorantBold" className={classes.paymentHeading}>
        Payment
      </Box>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className={classes.center}>
            <Box fontFamily="CormorantBold" className={classes.walletAmount}>
              {"WALLET BALANCE"}
            </Box>
            <Box fontFamily="CormorantBold" className={classes.walletAmount}>
              {loader ? (
                <span>Loading...</span>
              ) : (
                `${wallet?.amount ? display(wallet?.amount) : 0}$`
              )}
            </Box>
          </div>
          <div className={classes.paymentList}>
            {paymentDetails?.length ? (
              paymentDetails?.map((propo, i) => (
                <Payment
                  proposal={propo}
                  key={`payment-${i}`}
                  type={type}
                  eventId={propo?.event_id}
                  cId={propo?.category_id}
                  coupleId={propo?.user_id}
                />
              ))
            ) : (
              <NoRecordFound text={"NO RECORD FOUND"} />
            )}
          </div>
        </>
      )}
    </div>
  );
};
