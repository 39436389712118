import React, { useState, useEffect } from "react";
import Map from "./Map";

function Search_location({ locationData, setLocationData, business }) {
  const [coordinates, setCoordinates] = useState({});

  const onChangeAddress = (add) => {
    if (business) {
      setLocationData({
        state: add.state,
        city: add.city,
        country: add.country,
        zip_code: add.postalCode,
        address: add.address,
        latitude: add.newLat,
        longitude: add.newLng,
      });
    } else
      setLocationData({
        address: add.address,
        coords: { latitude: add.newLat, longitude: add.newLng },
      });
  };

  useEffect(() => {
    setCoordinates({
      lat: business ? locationData?.latitude : locationData.coords.latitude,
      lng: business ? locationData?.longitude : locationData.coords.longitude,
    });
  }, [JSON.stringify(locationData)]);

  return (
    <>
      {coordinates.lat ? (
        <div style={{ margin: "0 0 50px 0", width: "100%", height: "300px" }}>
          <Map
            center={{ lat: coordinates.lat, lng: coordinates.lng }}
            height="300px"
            zoom={15}
            onChangeAddress={onChangeAddress}
          />
        </div>
      ) : (
        <div>
          {`Required an address. 
          1. Fill address
          2. Select one of the suggestions`}
        </div>
      )}
    </>
  );
}

export default Search_location;
