import React, { useEffect, useState } from "react";
import Avatar from "react-avatar-edit";
import { useDispatch, useSelector } from "react-redux";
import { uploadProImg } from "actions";
import { accountStyle } from "styles";
import { CameraAlt } from "@material-ui/icons";
import { IconButton, useMediaQuery } from "@material-ui/core";
import {user} from "assets"
const Ava = ({ pdata, setProfileImg }) => {
  const { user } = useSelector(({ user }) => user);
  const classes = accountStyle();
  const matches = useMediaQuery("(min-width:600px)");

  const src =  user.image
  const [state, setState] = useState({ src, preview: null });
  const [url, setUrl] = useState("");
  const [toggle, setToggle] = useState(false);

  const dispatch = useDispatch();

  const onClose = () => {
    setState({ preview: null });
  };

  const onCrop = async (preview) => {
    console.log(state);
    if (typeof state.src === "string" && state.src.startsWith("https")) {
      console.log("Already exists", state);
      onClose();
    } else {
      console.log("Base64 ", preview);
      setState({ ...state, preview });
      const Dataurl = await dispatch(uploadProImg(preview));
      if (Dataurl.startsWith("https")) {
        setUrl(Dataurl);
      }
    }
  };

  useEffect(() => {
    const getFun = async () => {
      setProfileImg({ ...pdata, image: url });
    };
    console.log("url", url);
    if (url) getFun();
  }, [JSON.stringify(url)]);

  const onBeforeFileLoad = (elem) => {
    const file = elem.target.files[0];
    console.log(file);
    if (file.size > 7116822) {
      alert("File is too big!");
      elem.target.value = "";
    }
    if (typeof state.src === "string" && state.src.startsWith("https")) {
      console.log("onBeforeFileLoad");
      // setState({ preview: null });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {toggle ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Avatar
            width={matches > 375 ? 390 : 330}
            height={295}
            onCrop={onCrop}
            onClose={onClose}
            cropRadius={128}
            onBeforeFileLoad={onBeforeFileLoad}
            src={state.src}
          />
          {/* <img src={state.preview} alt="" style={{ width: "330" }} /> */}
        </div>
      ) : (
        <div className={classes.profileImg}>
          <img
            src={src ? src : '/images/profile.jpeg'}
            alt="Profile Pic"
            style={{ width: "294px", borderRadius:"50%",height:"280px"}}
          />
          <div
            style={{ position: "absolute", top: "200px", right: "1px" }}
            title="Edit"
          >
            <IconButton onClick={() => setToggle((i) => !i)}>
              <CameraAlt fontSize="large" />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default Ava;
