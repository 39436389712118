import React, { useState, useEffect } from "react";
import { Typography, Grid, Button, IconButton } from "@material-ui/core";
import {
  ThumbUpAltOutlined,
  ModeCommentOutlined,
  ThumbUpAltRounded,
  DeleteOutlineOutlined,
} from "@material-ui/icons";
import TimeAgo from "react-timeago";

import { galleryStyle, primaryLoaderStyle, commonButtonStyle } from "styles";
import { strings, colors } from "constant";
import { useSelector, useDispatch } from "react-redux";

import { Confirm, FilePicker, Loader, Media } from "components";
import { addImage, fetchImages, like, deleteImages } from "actions";
import { videoPlaceholder } from "assets";
import { createAlert } from "actions";
const { common } = strings;

export default (props) => {
  const classes = galleryStyle();
  const dispatch = useDispatch();

  const { images = [], loader = false } = useSelector(({ gallery }) => gallery);
  const { event = {} } = useSelector(({ event }) => event);
  const { user } = useSelector(({ user }) => user);

  const [imges, setImages] = useState([]);
  const [media, mediaToggle] = useState(false);
  const [mediaUrl, setMedia] = useState("");
  const [type, setType] = useState("image");
  const [uid, setUid] = useState(null);
  const [imageId, setImageId] = useState(null);

  const params = props.history?.location?.state;

  useEffect(() => {
    if (params?.from_search_result) dispatch(fetchImages(event?.owners));
    else dispatch(fetchImages(event?.owners));
  }, []);

  const [delDiag, setDelDiag] = useState(false);

  
  const onSubmit = async () => {  
      if(imges.length < 1){
        dispatch(createAlert({message:"please select an image to upload", type:"error"}));
      }
     else{
        const res =  await dispatch(addImage(imges, `event_gallery/${event.id}`));
        setImages([])
     }
  };
  
  console.log("imagess", images,imges)
 
  const viewerModal = ({ id, src, type = "" }) => {
    mediaToggle(true);
    setMedia(src);
    setType(type);
    setUid(id);
  };

  const handleToggle = () => {
    mediaToggle(false);
    setMedia("");
    setType("");
  };

  const handleDelete = async (id) => {
    await dispatch(deleteImages(id));
  };

  const handleLike = async (id) => {
    await dispatch(like(id));
  };

  

  return (
    <div className={classes.container}>
      <div className={classes.main}>
        {user.uid === event.owners && (
          <Grid container className={classes.fileMenu}>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={classes.addNewEventFormGV}
            >
              <FilePicker
                multiple
                label={common.Media}
                value={imges}
                onImage={(value) => setImages(value,[...imges])}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              className={classes.buttonV}
            >
              <div className = {classes.uploadBttn}>
              <Button
                variant="contained"
                disabled={loader}
                size="large"
                color="primary"
                className={classes.uploadBtn}
                onClick={onSubmit}
              >
                {loader ? (
                  <Loader style={primaryLoaderStyle} size={15} />
                ) : (
                  common.Upload
                )}
              </Button>
              </div>
            </Grid>
          </Grid>
        )}
        {loader ? (
          <Loader />
        ) : (
          <div className={classes.imagesSV}>
            {images.map((item) => (
              <div className={classes.gallery} key={item.id}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={
                            item.profile.profilePic
                              ? item.profile.profilePic
                              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"
                          }
                          alt=""
                          style={{ width: "25px", marginRight: "10px" }}
                        />
                        <Typography
                          variant="h6"
                          component="div"
                          style={{
                            color: colors.main,
                            fontFamily: "Gotham",
                          }}
                        >
                          {item.profile.displayName}
                        </Typography>
                      </div>
                    </div>
                    <div style={{ fontSize: "12px", color: colors.main }}>
                      <TimeAgo date={item.createdAt} live={false} />
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setDelDiag(true);
                      setImageId(item.id);
                    }}
                  >
                    {event?.owners === user?.uid && (
                      <IconButton>
                        <DeleteOutlineOutlined />
                      </IconButton>
                    )}
                  </div>
                </div>
                <div>
                  <img
                    onClick={() => viewerModal(item)}
                    src={
                      item.type === "image" || item.type === 0
                        ? item.src
                        : videoPlaceholder
                    }
                    className={classes.imagesThumb}
                    alt=""
                  />
                </div>
                <div className={classes.lAc}>
                  <div
                    className={classes.icon}
                    onClick={() => handleLike(item.id)}
                  >
                    <IconButton>
                      {item.islike?.length ? (
                        <ThumbUpAltRounded />
                      ) : (
                        <ThumbUpAltOutlined />
                      )}
                    </IconButton>
                    {`Likes (${item.likes ? item?.likes.length : 0})`}
                  </div>
                  <div
                    className={classes.icon}
                    onClick={() => {
                      viewerModal(item, 1);
                    }}
                  >
                    <IconButton>
                      <ModeCommentOutlined />
                    </IconButton>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <Media
          open={media}
          toggleModal={handleToggle}
          url={mediaUrl}
          type={type}
          id={uid}
        />
      </div>
      <Confirm
        open={delDiag}
        onClose={() => setDelDiag(false)}
        title={common.DeleteImage}
        content={common.DeleteImageContent}
        onClick={() => handleDelete(imageId)}
        button={common.Delete}
        loader={loader}
      />
    </div>
  );
};
