import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { Typography, Grid } from "@material-ui/core";
import { AccountBalance } from "@material-ui/icons";
import { WalletStyle } from "styles";
import { useSelector } from "react-redux";
import { NoRecordFound,Loader } from "components";
import moment from "moment";
import { colors } from "constant";
import { display } from "helpers";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

export default function Wallet() {
  const classes = WalletStyle();
  const { history, loader } = useSelector(({ transaction }) => transaction);
  console.log("history>>",history);
  return (
    <div className={classes.w__conatiner}>
      <div className={classes.wallet_left}>
        <Elements stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      </div>
      <div className={classes.wallet_right}>
        <Typography variant="subtitle1" style={{ textAlign: "center" }}>
          HISTORY
        </Typography>
        {loader ? (
          <Loader size={40} />
        ) : (
          <>
            {history.length ? (
              <Grid container>
                {history?.map(
                  (hist, i) => (
                    (
                      <Grid item xs={12} key={`history-${i}`}>
                        <div className={classes.wallet_history_item}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div style={{ width: "12%" }}>
                              <AccountBalance />
                            </div>
                            <div style={{ width: "65%" }} className = {classes.para}>
                              {hist.type===3 && hist.toId?<div>{"Transfered to bank"}</div>:hist?.businessId === 1 && !hist.fromId ? (
                                "Paid to weddingblizz"
                              ) : (
                                <>
                                  <div>{`${
                                   hist?.fromId && hist?.type===1
                                  ? "Added money to Wallet"
                                  :`${
                                      hist?.fromId ? `Received from` : hist?.businessId === "1" ? 
                                        "Paid to ": `Paid to` 
                                    }`

                              }`}</div>
                              <div>{`${
                                hist.fromId && hist?.type===1
                                  ?  `${hist?.fromId}`
                                  :`${
                                      hist?.fromId
                                     
                                        ? `${hist?.name}` : hist?.businessId === "1" ? 
                                          "weddingblizz"
                                        : `${hist?.name}`
                                    }`
                                  
                              }`}</div>
                                </>
                              )}
                            </div>
                            <div className = {classes.amount}
                              style={{
                                width: "30%",
                                color: `${
                                  hist?.businessId === 1
                                    ? colors.red
                                    : hist.toId || hist.fromId
                                    ? hist.toId
                                      ? colors.red
                                      : colors.green
                                    : colors.main
                                }`,
                              }}
                            >{`${
                              hist?.businessId === 1
                                ? "- "
                                : hist.toId || hist.fromId
                                ? hist.toId
                                  ? "- "
                                  : "+ "
                                : ""
                            } $${display(hist.amount)}`}</div>
                          </div>
                          <p style={{ fontSize: "12px" }}>
                            {hist?.createAt
                              ? moment(new Date(hist.createAt)).format(
                                  "DD MMM YYYY"
                                )
                              : ""}
                          </p>
                        </div>
                      </Grid>
                    )
                  )
                )}
              </Grid>
            ) : (
              <NoRecordFound />
            )}
          </>
        )}
      </div>
    </div>
  );
}
