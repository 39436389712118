export const checkAmountBeforeSubmit = (amount)=>{
    if(amount===0){
        return "Please enter some amount";
    }
    else return "";
}

export const getFixed = (num)=>{
    const str = String(num);
    let fixed = "";
    if(str.includes(".")){
      const start = str.split(".")[0].length;
      fixed = str.split(".")[1].length>=2 ? start+3:start+2;
      let val = Number(str.slice(0,fixed));
      val = val*100;
      return Number(val.toFixed(2));
    }
    return Number(str)*100;
}


export const display = (num)=>{
    const str = String(num);
    let fixed = "";
    if(str.includes(".")){
      const start = str.split(".")[0].length;
      fixed = str.split(".")[1].length>=2 ? start+3:start+2;
      let val = Number(str.slice(0,fixed))
      return val.toFixed(2)
    }
    else{
      return Number(str).toFixed(2);
    }
  }
  
  
export const fixedValue =(num)=>{
    const str = String(num);
    let fixed = "";
    if(str.includes(".")){
        const start = str.split(".")[0].length;
        fixed = str.split(".")[1].length>=2 ? start+3:start+2;
        let val = Number(str.slice(0,fixed));
        return val;
    }
    return Number(str)
}

export const isNumeric = (str) => {
    if (typeof str != "string") return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
}

export const replaceLeadingZeros = (num)=>{
    //All leading zero will be replaced by non-zero digits
    //eg. 01 --> 1
    if(num.length>=2 && num[0]==='0' && num[1]!='.'){
        num = num.replace(/^0+/, '');
        return num;
    }
    return num;
}