import {
  firestore,
  insert,
  updateOne,
  inviteNotification,
  deleteGroup as deleteGroupWithUser,
  sendPush,
} from "helpers";
import { history } from "../App";
import { ACTION_TYPES, strings } from "constant";
import { createAlert } from "actions";
const { success, errors } = strings;

export const addGroup = (data) => async (dispatch, getState) => {
  try {
    const { uid = "" } = getState().user?.user;
    dispatch({ type: ACTION_TYPES.GUEST_REQUEST });
    console.log(data);
    var id;
    if (data && data.id) {
      id = await updateOne("guest_groups", data?.id, { name: data.name });
    } else {
      id = await insert("guest_groups", { ...data, userId: uid });
    }
    dispatch({
      type: ACTION_TYPES.GUEST_GROUP_COMPLETE,
      payload: { ...data, id: id },
    });
    dispatch({ type: ACTION_TYPES.GUEST_SUCCESS });
    dispatch(
      createAlert({
        message: data?.id
          ? success.guestGroupUpdateSuccess
          : success.guestGroupSuccess,
        type: "success",
      })
    );
    dispatch(fetchGroupGuests());
  } catch (error) {
    console.log("addGroup error ", error);
    dispatch({ type: ACTION_TYPES.GUEST_FAILED });
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
  }
};

export const fetchGuestGroups = () => async (dispatch, getState) => {
  try {
    const { uid = "" } = getState().user?.user;
    dispatch({ type: ACTION_TYPES.GUEST_GROUP_REQUEST });
    const data = await firestore
      .collection("guest_groups")
      .where("userId", "==", uid)
      .get();
    dispatch({
      type: ACTION_TYPES.GUEST_GROUP_COMPLETE,
      payload: data.docs
        .map((grp) => grp.data())
        .map((it) => ({ label: it.name, value: it.id, uid: it.userId })),
    });
  } catch (error) {
    console.log("fetchGuestGroups error ", error);
    dispatch({ type: ACTION_TYPES.GUEST_GROUP_FAILED });
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
  }
};

export const addGuest =
  (data, id = null) =>
  async (dispatch, getState) => {
    try {
      const { uid = "" } = getState().user?.user;
      dispatch({ type: ACTION_TYPES.GUEST_REQUEST });

      const { guests: groups } = getState().guest;
      const guestss = [];
      groups.forEach((guest) => guestss.push(...guest.guests));

      const emailExists = guestss.map((it) => it.email);

      if (!emailExists.includes(data.email) || id !== null) {
        if (id)
 await updateOne("guest_users", id, { ...data, coupleId: uid });
        else await insert("guest_users", { ...data, coupleId: uid });

        dispatch({ type: ACTION_TYPES.GUEST_SUCCESS });
        dispatch(
          createAlert({ message: success.guestSuccess, type: "success" })
        );
      } else {
        dispatch({ type: ACTION_TYPES.GUEST_FAILED });
        dispatch(
          createAlert({
            message: `User with ${data.email} already exist in your guest list`,
            type: "error",
          })
        );
      }
      history.goBack();
    } catch (error) {
      console.log("addGuest error ", error);
      dispatch({ type: ACTION_TYPES.GUEST_FAILED });
      dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
    }
  };

const getFibasePromise = (cid, eId, gId) => {
  return new Promise(async (resolve, reject) => {
    let invited = await firestore
      .collection("event_invite")
      .where("userId", "==", cid)
      .where("eventId", "==", eId)
      .where("guestId", "==", gId)
      .get();
    const check = invited.docs.map((i) => i.data());
    console.log(check);
    resolve(check);
  });
};

const getInivitedGuests = (guests, eId, groupData) => {
  return new Promise(async (resolve, reject) => {
    const guestCheck = await Promise.all(
      guests.map(async (it) => {
        const check = await getFibasePromise(it.coupleId, eId, it.id);
        //here i used 2 check isAlreadyInvited: true, check: true
        //*2nd check:- is used in a case where couple wants to invite entire group at same time
        if (check.length) {
          return { ...it, isAlreadyInvited: true, check: true };
        }
        return { ...it, isAlreadyInvited: false, check: false };
      })
    );

    console.log(guestCheck);

    const payload = groupData.docs
      .map((grp) => grp.data())
      .map((it) => ({
        label: it.name,
        value: it.id, //In dropDown we are expecting value field/key
        guests: guestCheck.filter((g) => g.groupId === it.id),
      }));

    resolve(payload);
  });
};

export const fetchGroupGuests = () => async (dispatch, getState) => {
  try {
    const { uid = "" } = getState().user?.user;
    const { event } = getState().event;
    dispatch({ type: ACTION_TYPES.GUEST_REQUEST });
    const groupData = await firestore
      .collection("guest_groups")
      .where("userId", "==", uid)
      .get();
    const guestData = await firestore
      .collection("guest_users")
      .where("coupleId", "==", uid)
      .get();
    const guests = guestData.docs.map((g) => g.data());
    const payload = await getInivitedGuests(guests, event.id, groupData);

    console.log(".....", payload);
    dispatch({ type: ACTION_TYPES.GUEST_COMPLETE, payload });
  } catch (error) {
    console.log("fetchGroupGuests error ", error);
    dispatch({ type: ACTION_TYPES.GUEST_FAILED });
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
  }
};

export const inviteGuests = (ids) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.GUEST_REQUEST });
    const { user } = getState().user;
    const { event } = getState().event;

    const data = {
      eventId: event.id,
      userId: user.uid,
    };

    const emails = ids.map((id)=> id.email);

    for (let i of ids) {
      await insert("event_invite", {
        ...data,
        guestId: i.id,
        deleted: false,
        accepted: false,
      });
    }

    // dispatch({
    //   type: ACTION_TYPES.EVENT_COMPLETE,
    //   payload: {
    //     ...event,
    //     event_guests: `${totalGuests.length} out of ${invitedGuests.length} invited`,
    //   },
    // });

    let temp = [];
    await Promise.all(emails.map(async (email, i) => {
      temp.push(email);
      if (i === emails.length - 1 || temp.length === 10) {
        const sendNotify = {
          emails: temp,
          from: user.uid,
          title: `Wedding invitation`,
          body: `You have invitation from ${user.name}`,
          eventId: event.id,
        };

        if (temp.length <= 10) await inviteNotification(sendNotify);
        temp = [];
      }
    }))
    dispatch({ type: ACTION_TYPES.GUEST_COMPLETE });
    dispatch(
      createAlert({
        message: success.InvitationSending,
        type: "success",
      })
    );
    history && history.goBack();
  } catch (error) {
    console.log("inviteGuests error ", error);
    dispatch({ type: ACTION_TYPES.GUEST_FAILED });
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
  }
};

//invokes every time from when event-detail screen changes
export const getInvitation =
  (email, uid, eventId) => async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.GUEST_REQUEST });
      const data = await firestore
        .collection("guest_users")
        .where("coupleId", "==", uid)
        .where("email", "==", email)
        .get();
      const invitation_detail = data.docs.map((it) => it.data());

      if (invitation_detail.length && invitation_detail[0].id) {
        const snap = await firestore
          .collection("event_invite")
          .where("eventId", "==", eventId)
          .where("guestId", "==", invitation_detail[0].id)
          .get();

        const invite_guest = snap.docs.map((it) => it.data());
        console.log("event_invite", invite_guest[0]);
        dispatch({
          type: ACTION_TYPES.GUEST_INVITATION_DETAIL,
          payload: invite_guest[0],
        });
      }
    } catch (error) {
      console.log("addGift error ", error);
      dispatch({ type: ACTION_TYPES.GUEST_COMPLETE });
      dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
    }
  };

export const acceptInvitation = (uid, email) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.GUEST_REQUEST });
    let { invitation } = getState().guest;
    let { user } = getState().user;

    const updateInvitation = {
      ...invitation,
      accepted: true,
      rejected: false,
      deleted: false,
    };

    dispatch({
      type: ACTION_TYPES.GUEST_INVITATION_DETAIL,
      payload: updateInvitation,
    });

    delete invitation.isVisited;
    delete invitation.isAccepted;
    delete invitation.isDeleted;
    if (invitation.visited) delete invitation.visited;

    await insert("event_invite", {
      ...invitation,
      accepted: true,
      rejected: false,
      deleted: false,
    });
    const snap = await firestore
      .collection("event_invite")
      .where("guestId", "==", invitation.id)
      .where("coupleId", "==", invitation.userId)
      .get();

    console.log("snap", snap)
    const invite_guest = snap.docs.map((it) => it.data());
    console.log("inviteguest", invite_guest);
    dispatch(
      createAlert({ message: "Successfully accepted invitation", type: "success", duration: 4000 })
    );

    if(updateInvitation.accepted===true){
      const options ={
        to:[invitation.userId],
        from: user.uid,
        title: `Invitation Accepted`,
        body: `Invitation accepted by ${user.name}`,
      }
      await sendPush(options)
      }
    
  } catch (error) {
    console.log("addGift error ", error);
    dispatch({ type: ACTION_TYPES.GUEST_COMPLETE });
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
  }
};


export const rejectInvitation = (reason="", uid) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.GUEST_REQUEST });
    let { invitation } = getState().guest;
    let { user } = getState().user;

    const updateInvitation = {
      ...invitation,
      accepted: false,
      rejected: true,
      deleted: false,
      reason,
    };
    dispatch({ type: ACTION_TYPES.GUEST_INVITATION_DETAIL, payload: updateInvitation });

    delete invitation.isVisited;

    await insert("event_invite", {
      ...invitation,
      accepted: false,
      rejected: true,
      deleted: false,
      reason,
    });

    const snap = await firestore
      .collection("event_invite")
      .where("guestId", "==", invitation.id)
      .where("coupleId", "==", invitation.userId)
      .get();

    const invite_guest = snap.docs.map((it) => it.data());
    dispatch(
      createAlert({ message: "Rejected", type: "success", duration: 5000 })
    );

    if(updateInvitation.rejected === true) {
      const options = {
        to:[invitation.userId],
        from:user.uid,
        title:'Invitation Rejected',
        body:reason?`Invitation rejected by ${user.name}, ${reason}`:`Invitation rejected by ${user.name}`,
      }
      await sendPush(options)
    }

  } catch (error) {
    console.log("addGift error ", error);
    dispatch({ type: ACTION_TYPES.GUEST_COMPLETE });
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
  }
};

const deleteGuestsFrom_guest_users = (guest_users=[]) => {
  return new Promise(async (resolve, reject) => {
    try {
      if(guest_users.length===0){
        resolve("success");
      }
      const snapshot = await firestore
        .collection("guest_users")
        .where("id", "in", guest_users)
        .get();
      // const MAX_WRITES_PER_BATCH = 10;
      // const batches = chunk(snapshot.docs, MAX_WRITES_PER_BATCH);
      const commitBatchPromises = [];
      snapshot.docs.forEach((batch) => {
        const writeBatch = firestore.batch();
        // batch.forEach((doc) => writeBatch.delete(doc.ref));
        writeBatch.delete(batch.ref);
        commitBatchPromises.push(writeBatch.commit());
      });

      await Promise.all(commitBatchPromises);

      resolve("success");
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

const deleteGuestsFrom_invited_users = (invited_users=[]) => {
  return new Promise(async (resolve, reject) => {
    try {
      
      if(invited_users.length===0){
        resolve("success")
      }
      const snapshot = await firestore
        .collection("event_invite")
        .where("guestId", "in", invited_users)
        .get();
      // const MAX_WRITES_PER_BATCH = 10;
      // const batches = chunk(snapshot.docs, MAX_WRITES_PER_BATCH);
      const commitBatchPromises = [];

      snapshot.docs.forEach((batch) => {
        const writeBatch = firestore.batch();
        // batch.forEach((doc) => writeBatch.delete(doc.ref));
        writeBatch.delete(batch.ref);
        commitBatchPromises.push(writeBatch.commit());
      });

      await Promise.all(commitBatchPromises);

      resolve("success");
    } catch (error) {
      reject(error);
    }
  });
};

const deleteGroupWithUsers = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let guest_users = [];
      let invited_users = [];
      const groupId = data.value;

      const guests = data.guests;
      await Promise.all(
        guests.map(async (it, index) => {
          guest_users.push(it.id);
          if (it.isAlreadyInvited) invited_users.push(it.id);
          if (guest_users.length === 10 || guests.length - 1 === index) {
            if (guest_users.length && guest_users.length <= 10)
              await deleteGuestsFrom_guest_users(guest_users);
            if (invited_users.length && invited_users.length <= 10)
              await deleteGuestsFrom_invited_users(invited_users);
            guest_users = [];
            invited_users = [];
          }
        })
      );
      var groups = firestore
        .collection("guest_groups")
        .where("id", "==", groupId);
      groups.get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          doc.ref.delete();
         
        });
      });

      console.log({
        status: 200,
        message: "Group deleted successfully",
      });
      resolve("success");
      
    } catch (error) {
      reject(error);
      console.log(error);
    }
  });
};

export const deleteGroup = (id)=> async (dispatch, getState) => {
  try {
    const { guests } = getState().guest;
    const data = guests.find((it) => it.value === id);
    console.log("data", data);

    dispatch({ type: ACTION_TYPES.GUEST_GROUP_DEL_REQUEST });
    // const res = await deleteGroupWithUser(data); //http callable
    const res = await deleteGroupWithUsers(data); //normal function
    console.log("res", res);
    dispatch({ type: ACTION_TYPES.GUEST_GROUP_DEL_COMPLETE });
    dispatch(
      createAlert({ message: success.DeleteGroupSuccess, type: "success" })
    );
    history && history.goBack();
  } catch (error) {
    console.log("deleteGroup error ", error);
    dispatch({ type: ACTION_TYPES.GUEST_GROUP_DEL_FAILED });
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
  }
};

export const deleteGuest = (id)=> async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.GUEST_GROUP_DEL_REQUEST });
    await firestore.collection("guest_users").doc(id).delete();
    const invited_guest = firestore
      .collection("event_invite")
      .where("guestId", "==", id);

    invited_guest.get().then((querySnapshot) => {
      querySnapshot.forEach(function (doc) {
        doc.ref.delete();
      });
    });

    dispatch(fetchGroupGuests());
    dispatch({ type: ACTION_TYPES.GUEST_GROUP_DEL_COMPLETE });
  } catch (error) {
    console.log("deleteGuest error ", error);
    dispatch({ type: ACTION_TYPES.GUEST_GROUP_DEL_FAILED });
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
  }
};

//invokes from home screen of guest
export const getGuestEvents = (id)=> async (dispatch, getState) => {
  try {
    const { email = "" } = getState().user?.user;
    dispatch({ type: ACTION_TYPES.GUEST_REQUEST });
    //it may give you 1 or more guestss from different events/coupleId
    const guestss = await firestore
      .collection("guest_users")
      .where("email", "==", email)
      .get();
    const guests = guestss.docs.map((d) => d.data().id);
    dispatch({ type: ACTION_TYPES.SAVE_GUEST_IDS, payload: guests});

    const temp = [];
    const payload = [];
    await Promise.all(
      guests.map(async (guest, index) => {
        if (temp.length === 10 || guests.length - 1 === index) {
          let eventss = await firestore
            .collection("event_invite")
            .where("guestId", "in", guests)
            .get();
          const events = eventss.docs.map((d) => d.data().eventId);

          const eventDetailss = await firestore
            .collection("events")
            .where("id", "in", events)
            .get();
          const eventDetails = eventDetailss.docs.map((d) => d.data());

          const userss = await firestore
            .collection("users")
            .where(
              "userId",
              "in",
              eventDetails.map((e) => e.owners)
            )
            .get();
          const users = userss.docs.map((d) => d.data());

          payload.push(
            ...eventDetails.map((it) => {
              const user = users.find((user) => user.userId === it.owners);
              return {
                ...it,
                profile: user,
              };
            })
          );
        }
        temp.push(guest);
      })
    );

    //await Promise.all() will make sure that the below dispatch will invoked only when all the above promises will resolved
    dispatch({ type: ACTION_TYPES.GUEST_EVENTS, payload });
  } catch (error) {
    console.log("getGuestEvents error ", error);
    dispatch({ type: ACTION_TYPES.GUEST_FAILED });
    dispatch(createAlert({ message: errors.CommonApiError, type: "error" }));
  }
};

export const NotifyInvite = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.NOTIFICATION_REQUEST });
    const {
      user: { uid = "" },
    } = getState().user;

    const snap = await firestore
      .collection("users")
      .where("userId", "==", uid)
      .get();

    const notify = snap.docs.map((it) => it.data());
    console.log(notify);
    if (notify.length && notify[0].email) {
      const email = notify[0].email;
      const guestSnap = await firestore
        .collection("guest_users")
        .where("email", "==", email)
        .get();

      const guests = guestSnap.docs.map((it) => it.data().id);
      if (guests.length) {
        const guestIdSnap = await firestore
          .collection("notifications")
          .where("reciever", "array-contains-any", [...guests, uid])
          .orderBy("createdAt", "desc")
          .limit(12)
          .get();

        const inviteNotify = guestIdSnap.docs.map((it) => it.data());

        console.log(inviteNotify);
        dispatch({
          type: ACTION_TYPES.NOTIFICATION_SUCCESS,
          payload: inviteNotify,
        });
      }
    }
    dispatch({ type: ACTION_TYPES.NOTIFICATION_COMPLETE });
  } catch (error) {
    console.log(error);
    dispatch({ type: ACTION_TYPES.NOTIFICATION_FAILED });
  }
};