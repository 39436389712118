import React, { useState, useMemo } from "react";
import { Button, Box } from "@material-ui/core";
import { DoneAllRounded } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  proposalStyle,
  proposeDetailStyle,
  rightSideBubble,
  leftSideBubble,
  acceptedIcon,
} from "styles";
import { strings } from "constant";
import { BreadCrumb, Loader, NoRecordFound, ProposeModal } from "components";
import moment from "moment";
import { addVendorProposal, setSelectedProposal } from "actions";
const { common } = strings;

export default (props) => {
  const classes = proposalStyle();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const breads = [
    { title: common.Home, path: "/" },
    { title: common.Proposals, path: "/proposals" },
  ];
  const { chat = false } = props;
  const dispatch = useDispatch();
  const {
    user: { type, uid = "" },
  } = useSelector(({ user }) => user);
  const { proposal = {}, loader = false } = useSelector(
    ({ proposal }) => proposal
  );

  const { business } = useSelector(({ vendor }) => vendor);

  const handleSetProposal = (prop) => {
    setOpen(true);
    dispatch(setSelectedProposal(prop));
    if (!prop.category_id)
      history.push({
        pathname: "/category",
        state: {
          send_proposal: true,
          multi: false,
          categories: business.categories,
        },
      });
  };

  const onAccept = (prop) => {
    const { modifiedAt, createdAt, isDeleted, owner, event, id, ...propsal } =
      prop;
    const data = {
      ...propsal,
      isQuote: false,
      isProposal: false,
      isBooked: true,
      business_id: prop.business_id,
      user_id: prop.user_id,
    };
    // debugger;
    //*addVendorProposal(data,proposal_id)
    dispatch(addVendorProposal(data, prop && prop.id));
  };

  const handleUpdateProposal = (prop) => {
    dispatch(setSelectedProposal(prop));
    setOpen(true);
  };

  // console.log(proposaluid);
  if (proposal.length === 0)
    return (
      <div className={classes.NoRecV}>
        <NoRecordFound />
      </div>
    );
  return (
    <div className={classes.proposalMainModal}>
      {!chat && <BreadCrumb breads={breads} current={common.ProposalDetail} />}
      <div>
        {proposal.length
          ? proposal.map((prop, i) => (
              <>
                <div
                  className={
                    chat ? classes.mainDetailBodyC : classes.mainDetailBody
                  }
                  style={
                    //left  right
                    prop &&
                    prop.sender_id === prop.user_id &&
                    prop.user_id === uid
                      ? rightSideBubble
                      : leftSideBubble
                  }
                  key={i}
                >
                  {/* Don't show message BOX if u have isBooked, isQuote, and isProposal*/}
                  {/* if prop.proposed --> show quote message*/}
                  {!prop.booking_amount ? (
                    <>
                      <div
                        className={chat ? classes.listCardC : classes.listCard}
                        style={proposeDetailStyle}
                      >
                        <Box
                          fontFamily="Gotham"
                          className={classes.prosposalNameT}
                        >
                          {prop?.name}
                        </Box>
                        <Box
                          fontFamily="GothamBook"
                          className={classes.proposeMT}
                        >
                          {strings.common?.Message + " - "}
                          {prop?.message}
                        </Box>
                        <Box
                          fontFamily="GothamBook"
                          className={classes.prosposalDateT}
                        >
                          {prop?.modifiedAt
                            ? moment(new Date(prop?.modifiedAt)).format(
                                "MMMM Do YYYY hh:mm A"
                              )
                            : ""}
                        </Box>
                      </div>
                    </>
                  ) : null}
                  {prop &&
                  prop.sender_id === prop.user_id &&
                  prop.user_id !== uid &&
                  type === 3 &&
                  prop.isQuote ? (
                    <div
                      className={classes.sendProposeBV}
                      style={
                        prop && prop.sender_id === prop.user_id
                          ? leftSideBubble
                          : rightSideBubble
                      }
                    >
                      <Button
                        onClick={() => handleSetProposal(prop)}
                        variant="contained"
                        size="large"
                        color="primary"
                      >
                        {loader ? <Loader /> : strings.proposal.SendProposal}
                      </Button>
                    </div>
                  ) : null}
                </div>
                {/* //*if proposal exists */}
                {prop && (prop.isProposal || prop.isBooked) ? (
                  <div
                    className={
                      chat ? classes.mainDetailBodyC : classes.mainDetailBody
                    }
                    style={
                      prop &&
                      prop &&
                      prop.sender_id !== prop.user_id &&
                      prop.sender_id === uid
                        ? rightSideBubble
                        : leftSideBubble
                    }
                  >
                    <div
                      className={classes.listCard}
                      style={proposeDetailStyle}
                    >
                      <Box
                        fontFamily="Gotham"
                        className={classes.prosposalNameT}
                      >
                        {prop?.name}
                      </Box>
                      <Box
                        fontFamily="GothamBook"
                        className={classes.proposeMT}
                      >
                        {strings.common?.BookingAmount + " - "}
                        {prop.booking_amount}
                      </Box>
                      <Box
                        fontFamily="GothamBook"
                        className={classes.proposeMT}
                      >
                        {strings.common?.Message + " - "}
                        {prop.message}
                      </Box>
                      {prop.isBooked ? (
                        <Box
                          fontFamily="GothamBook"
                          className={classes.proposalAccepted}
                        >
                          {strings.proposal?.ProposalAccepted}
                          <DoneAllRounded style={acceptedIcon} />
                        </Box>
                      ) : null}
                      <Box
                        fontFamily="GothamBook"
                        className={classes.prosposalDateT}
                      >
                        {prop.modifiedAt
                          ? moment(new Date(prop.modifiedAt)).format(
                              "MMMM Do YYYY hh:mm A"
                            )
                          : ""}
                      </Box>
                    </div>
                    {prop &&
                    prop.isProposal &&
                    prop.sender_id !== prop.user_id &&
                    prop.sender_id === uid &&
                    prop?.isProposal &&
                    !prop?.isBooked &&
                    type === 3 ? (
                      <div
                        className={classes.sendProposeBV}
                        style={
                          proposal && prop?.sender_id === prop?.user_id
                            ? leftSideBubble
                            : rightSideBubble
                        }
                      >
                        <Button
                          onClick={() => handleUpdateProposal(prop)}
                          variant="contained"
                          size="large"
                          color="primary"
                        >
                          {loader ? (
                            <Loader />
                          ) : (
                            strings.proposal.UppdateProposal
                          )}
                        </Button>
                      </div>
                    ) : null}
                    {prop &&
                    prop?.sender_id !== proposal?.user_id &&
                    type === 1 &&
                    prop?.isProposal &&
                    !prop?.isBooked ? (
                      <div
                        className={classes.sendProposeBV}
                        style={leftSideBubble}
                      >
                        <Button
                          onClick={() => onAccept(prop)}
                          variant="contained"
                          size="large"
                          color="primary"
                        >
                          {loader ? <Loader /> : strings.common.Accept}
                        </Button>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </>
            ))
          : null}
      </div>
      {open ? (
        <ProposeModal modal={open} onClose={() => setOpen(false)} />
      ) : null}
    </div>
  );
};
