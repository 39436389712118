import { ACTION_TYPES } from "constant";
const INITIAL_STATE = {
  loader: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.SEARCH_START:
      return { ...state, loader: true };
    case ACTION_TYPES.SEARCH_END:
      return { ...state, loader: false };
    case ACTION_TYPES.LOG_OUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};
