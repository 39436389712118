import { ACTION_TYPES } from "constant";
const INITIAL_STATE = {
  wallet: {},
  loader: false,
  history: [],
  payment:{},
  _loader: false,
  stripeAccountStatus: {},
};

export default function transaction(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTION_TYPES.TRANSACTION_REQUEST:
      return { ...state, loader: true };
    case ACTION_TYPES.TRANSACTION_SUCCESS:
      return { ...state, loader: false };
    case ACTION_TYPES.TRANSACTION_FAILED:
      return { ...state, loader: false };

    case "WALLET":
      return { ...state, wallet: action.payload, loader: false };

    case ACTION_TYPES.HISTORY_REQUEST:
      return { ...state, loader: true };
    case ACTION_TYPES.HISTORY_SUCCESS:
      return { ...state, history: action.payload, loader: false };
    case ACTION_TYPES.HISTORY_COMPLETED:
      return { ...state, loader: false };
    case ACTION_TYPES.HISTORY_FAILED:
      return { ...state, loader: false };
      
    case ACTION_TYPES.PAYMENT_CHARGES:
      return {...state, payment:action.payload}

    //PAY_CASH
    case ACTION_TYPES.PAY_CASH_REQUEST:
      return {...state,_loader: true};
    case ACTION_TYPES.PAY_CASH_COMPLETE:
        return {...state,_loader: false};
    case ACTION_TYPES.PAY_CASH_FAILED:
      return {...state,_loader: false};
    default:
      return state;
  }
}
