import React from "react";
import { Chip } from "@material-ui/core";
import { PaymentListStyle } from "styles";

function ChipComp(props) {
  const classes = PaymentListStyle();
  return (
    <Chip
      label={props.label}
      color="success"
      variant="outlined"
      size="small"
      className={classes.chips}
    />
  );
}

export default ChipComp;
