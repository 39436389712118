import React, { useState,useEffect} from "react";
import { Loader } from "components";
import UpdateAcc from "./UpdateAccount";
import AddAmount from "./AddAmount";
import { useDispatch, useSelector } from "react-redux";
import { fetchStripeAccountStatus } from "actions";

export const TransferToBank = ()=> {
    const dispatch = useDispatch();
    const { user } = useSelector(({ user }) => user);
    const { business = {} } = useSelector((state)=>state.vendor);

    const [capable,setCapable] = useState("");
    const [currently_due,setCurrently_due] = useState([]);
    const [userAcc,setUserAcc] = useState("");
    const [accountId,setAccountId] = useState(user.type!==3 ? user.stripe_account_id : business.stripe_account_id);
    const [loading,setLoading] = useState(false)
    const [error,setError] = useState("");

    useEffect(() => {
        const invokeStatus = async()=>{
          setLoading(true);
          try {
            const status = await dispatch(fetchStripeAccountStatus(accountId))
            setError("");
            console.log(status);
            setUserAcc(status?.data?.account);
            setCapable(status?.data?.account?.capabilities?.transfers);
            setCurrently_due(status?.data?.account?.requirements.currently_due);
          } catch (error) {
            setError(error);
          }
          setLoading(false);
        }
        invokeStatus();
    },[])

  return (
    <>
      { loading?<Loader />:
        error?<div style={{display: "flex",justifyContent: "center"}}>{error}</div>:
        capable === "active" && currently_due.length===0 ? <AddAmount />:
        (capable === "pending" || capable === "inactive") && currently_due.length===0 ?
        <div style={{display: "flex",justifyContent: "center"}}>We are currently verifying your identity...</div>:
        <UpdateAcc currently_due={currently_due} userAcc={userAcc} accountId={accountId}/>
      }
      </>
  )
}

export default TransferToBank;