import React, { useEffect, useState, useRef } from "react";
import { Divider, Typography, Button, IconButton } from "@material-ui/core";
import { ArrowBackSharp, Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "constant";
import { NoRecordFound, Loader } from "components";
import TimeAgo from "react-timeago";
import { notificationStyle } from "styles";
import { Notify, NotifyNext } from "actions";

function useOutsideAlerter(ref, setOpenNotify) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        console.log("cliked outside");
        setOpenNotify(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);
}

export default ({ setOpenNotify }) => {
  const classes = notificationStyle();
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [posts, setPosts] = useState({ notify: [], lastKey: "" });
  const { notificationsList } = useSelector((state) => state.app);
  const getNotifications = async () => {
    setPosts(await dispatch(Notify()));
  };
  useEffect(() => {
    getNotifications();
  }, []);

  const handleBack = () => {
    getNotifications();
  };

  const handleMore = async () => {
    setPosts(await dispatch(NotifyNext(posts.lastKey)));
    setPage((p) => p + 1);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setOpenNotify);

  const handleClose = async () => {
    setOpenNotify(false);
  };

  return (
    <div className={classes.main} ref={wrapperRef}>
      {notificationsList ? (
        <Loader />
      ) : (
        <>
          {posts && posts.notify && posts.notify.length ? (
            <>
              <div className={classes.center}>
                {posts && posts.notify && posts.notify.length < 10 && page > 0 && (
                  <IconButton onClick={handleBack}>
                    <ArrowBackSharp />
                  </IconButton>
                )}

                <Typography variant="h6" style={{ margin: "auto" }}>
                  {page === 0 ? "Notifications" : "History"}
                </Typography>
                {/* <IconButton onClick={handleClose}>
                  <Close />
                </IconButton> */}
              </div>
              {posts.notify.map((notify, i) => (
                <div key={i}>
                  <div className={classes.spaceBetween}>
                    <Typography className={classes.header} variant="subtitle1">
                      {notify.title}
                    </Typography>
                    <div className={classes.date}>
                      <TimeAgo date={notify.createdAt} live={false} />
                    </div>
                  </div>
                  <Typography
                    style={{ color: colors.h5, fontFamily: "Gotham" }}
                    variant="subtitle2"
                  >
                    {notify.body}
                  </Typography>
                  <Divider />
                </div>
              ))}
              {posts && posts.notify && posts.notify.length >= 10 ? (
                <Button
                  variant="outlined"
                  onClick={handleMore}
                  className={classes.btn}
                  disabled={
                    posts && posts.notify && posts.notify.length ? 0 : 1
                  }
                >
                  {page ? "View More" : "View History"}
                </Button>
              ) : null}
            </>
          ) : (
            <NoRecordFound />
          )}
        </>
      )}
    </div>
  );
};
