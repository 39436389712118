import React, { memo } from "react";
import { Button } from "@material-ui/core";
import MapContainer from "./GoogleMapContainer";

const GoogleMap = ({ latitude, longitude, event_location }) => {
  console.log("GoogleMap rendered", latitude, longitude);

  return (
    <div style={{ margin: "0 0 50px 0", width: "100%", height: "300px" }}>
      <MapContainer
        latitude={latitude}
        longitude={longitude}
        event_location={event_location}
      />
    </div>
  );
};

export default memo(GoogleMap);
