import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid, Box } from "@material-ui/core";
import { eventStyle, primaryLoaderStyle } from "styles";
import { strings, ACTION_TYPES } from "constant";
import WedEventCarasoul from "./wedEventCarasoul";
import WedEventVendors from "./wedEventVendors";
import { BreadCrumb, Loader } from "components";
import { fetchEvent, getEveVendors } from "actions";
import { useLocation } from "react-router-dom";

const { events, common } = strings;

export default (props) => {
  const location = useLocation();
  const {
    user: { type },
  } = useSelector(({ user }) => user);
  const classes = eventStyle();
  const breads = type === 3 && [{ title: common.Home, path: "/" }];
  const dispatch = useDispatch();
  const { _loader = true, bookedVenders = [], event } = useSelector(({ event }) => event);
  let data = {};
  if (type === 3)
    data = {
      type,
      index: location.state.index,
      service: location.state.service,
    };

  useEffect(() => {
    // dispatch({type: ACTION_TYPES.EVENT_VENDOR_DETAIL, payload:})
    const get = async () => {
      if (!event) {
        dispatch({ type: ACTION_TYPES.HAS_EVENT, payload: false });
        props.history.push("/addEvent");
      } else{
        //fetch guest --> invited guest
        dispatch({ type: ACTION_TYPES.HAS_EVENT, payload: true });
        // if(categories.length===0)
        getVendors(event);
      }
    };
    if (type !== 3) get();
  }, []);

  const getVendors = async (e) => {
    await dispatch(getEveVendors(e.id));
  };

  return (
    <Grid container justifyContent="center" className={classes.eventMain}>
      <BreadCrumb breads={breads} current={events.WeddingEvent} />
      <Typography component="div" className={classes.eventTV}>
        <Box fontFamily="CormorantBold" className={classes.eventT}>
          {events.WeddingEvent}
        </Box>
      </Typography>
      {_loader ? (
        <Loader style={primaryLoaderStyle} />
      ) : (
        <>
          <WedEventCarasoul history={props.history} {...data} />
          {type !== 3 && <WedEventVendors history={props.history} />}
        </>
      )}
    </Grid>
  );
};
