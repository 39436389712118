import React, { useState } from "react";
import { useSelector } from "react-redux";
import { TextField, Button } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { commonStyle } from "styles";
import { strings } from "constant";
import { Loader } from "components";
const { common } = strings;

let uploadRef = {};
export default ({
  onImage = () => {},
  imgs = [],
  onDeleteImgs = () => {},
  multiple = true,
  label,
  error,
  disabled = false,
}) => {
  
  const classes = commonStyle();
  const { imgloader = false } = useSelector(({ app }) => app);
  const {
    user: { type },
  } = useSelector(({ user }) => user);

  const [images, setImages] = useState([]);
  const [imageCount, setImageCount] = useState(imgs.length);

  const [deleteImg, setDeleteImg] = useState([]);
  onDeleteImgs(deleteImg);

  const [oldImages, setOldImages] = useState(imgs ? imgs : []);

  const onFile = ({ target: { files = [] } }) => {
    if (type === 3 && imageCount > 0) {
      alert("maximum limit exceeded to upload image");
    } else {
      if (imageCount + files.length <= 5) {
        setImages([...images, ...files]);
        onImage([...images, ...files, ...oldImages]);
        setImageCount([...images, ...files, ...oldImages].length);
      } else {
        alert("You can upload atmost 5 images");
      }
    }
  };

 

  const onRemove = (i) => {
    let tempImgs = [...images];
    tempImgs.splice(i, 1);
    setImages(tempImgs);
    onImage([...tempImgs, ...oldImages]);
    setImageCount((el) => el - 1);
  };

  const onRemoveOld = (i) => {
    let tempImgs = [...oldImages];
    let temp = tempImgs.splice(i, 1)[0];
    setDeleteImg([...deleteImg, temp]);
    onImage([...images, ...tempImgs]);
    setOldImages(tempImgs);
    setImageCount((el) => el - 1);
  };

  return (
    <div className={classes.inputFieldsV}>
      <label>
        <div className={classes.browseBV} htmlFor="upload-photo">
          <input
            ref={(ref) => (uploadRef[label] = ref)}
            style={{ display: "none" }}
            id="upload-photo"
            name={label}
            multiple={multiple}
            accept="image/png, image/jpeg, video/mp4, video/x-m4v,video/*"
            onChange={onFile}
            type="file"
            disabled={disabled}
          />
          <TextField
            className={classes.fileInputFields}
            variant="outlined"
            label={label}
            error={!!error}
            value={imageCount}
            disabled
            helperText={error}
            onClick={() => uploadRef[label] && uploadRef[label].click()}
          />
         
          <Button
            variant="outlined"
            size="large"
            color="primary"
            className={classes.browseButton}
            onClick={() => uploadRef[label] && uploadRef[label].click()}
            disabled={disabled}
          >
            {common.Browse}
          </Button>
        </div>
      </label>
      {(images && images.length) || (imgs && imgs.length) ? (
        <Preview
          data={images}
          loader={imgloader}
          oldImages={oldImages}
          onRemoveOld={onRemoveOld}
          onRemove={onRemove}
        />
      ) : null}
    </div>
  );
};
const Preview = ({
  data = [],
  oldImages = [],
  onRemove = () => {},
  onRemoveOld = () => {},
  loader = false,
}) => {
  const classes = commonStyle();
  // var binaryData = [];
  return (
    <div className={classes.previewListV}>
      {loader ? (
        <Loader />
      ) : oldImages.length ? (
        oldImages.map((tile, ind) => (
          <div className={classes.preImageV} key={Math.random() + ind}>
            <img src={tile} alt={""} className={classes.prevImg} />
            <div className={classes.prevIcV}>
              <Cancel
                className={classes.prevTitle}
                onClick={() => onRemoveOld(ind)}
              />
            </div>
          </div>
        ))
      ) : null}
      {data.map((tile, ind) => (
        <div className={classes.preImageV} key={Math.random() + ind}>
          <img
            src={URL.createObjectURL(tile)}
            alt={""}
            className={classes.prevImg}
          />
          <div className={classes.prevIcV}>
            <Cancel
              className={classes.prevTitle}
              onClick={() => onRemove(ind)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
