import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Rating from "@material-ui/lab/Rating";
import { Typography, Grid, Box } from "@material-ui/core";
import {
  eventStyle,
  commonStyle,
  primaryLoaderStyle,
  vendorStyle,
} from "styles";
import { strings, ACTION_TYPES } from "constant";
import Carousel from "react-material-ui-carousel";
import { BreadCrumb, Loader, NoRecordFound, QuoteModal } from "components";
import { fetchVendorBusiness, getBooking, getVendorList,fetchVenderReviews } from "actions";
import { plusIcon, userIcon, calendarIcon, locationPinIcon } from "assets";
import moment from "moment";
import wedEventCarasoul from "containers/event/wedEventCarasoul";
const { events, common, vendors } = strings;

export default (props) => {
  const classes = eventStyle();
  const styles = vendorStyle();
  const commClasses = commonStyle();
  const [quote, showQuote] = useState(false);
  const breads = [{ title: common.Home, path: "/" }];
  const dispatch = useDispatch();

  const { reviews } = useSelector(({ event }) => event);

  const {
    user: { type, uid },
  } = useSelector(({ user }) => user);
  const { categories = [] } = useSelector(({ app }) => app);
  const {
    business = {},
    loader = false,
    bloader = false,
    booking = [],
  } = useSelector(({ vendor }) => vendor);
  console.log("bookingss", booking)

  useEffect(() => {
    const get = async () => {
      if (type === 3) {
        const busines = await dispatch(fetchVendorBusiness());
        if ((busines && !busines?.business_name) || !busines?.id) {
          props.history.push("/vendor");

          dispatch({ type: ACTION_TYPES.HAS_VENDOR, payload: true });
        }
      } else {
        dispatch({ type: ACTION_TYPES.HAS_VENDOR, payload: false });
        props.history.push("/event");
      }
      await dispatch(getBooking());
      const bookings = await dispatch(getVendorList());
    };
    get();
  }, [type]);

  const showReviews = ()=>{
    //*Next milestone just unComment
      dispatch(fetchVenderReviews(reviews.reviews));
      props.history.push("/reviews")
  }

  return (
    <Grid container justifyContent="center" className={classes.eventMain}>
      <BreadCrumb breads={breads} current={events.VendorDetail} />
      <Typography component="div" className={classes.eventTV}>
        <Box fontFamily="CormorantBold" className={classes.eventT}>
          {events.VendorDetail}
        </Box>
      </Typography>
      {loader ? (
        <Loader style={primaryLoaderStyle} />
      ) : (
        <div className={classes.eventVendorDetailMain}>
          <div className={classes.eventVendorInfoVendV}>
            <Grid container justifyContent="center">
              <Grid item sm={12} xs={10} md={12} className={classes.crImageV}>
                <Carousel
                  navButtonsAlwaysInvisible={false}
                  indicators={true}
                  autoPlay={true}
                  timeout={500}
                  animation="slide"
                >
                  <img
                    src={business?.images && business?.images[0]}
                    className={classes.eventVendDetailsCRImge}
                    alt=""
                  />
                </Carousel>
              </Grid>
              <Grid item sm={12} xs={12} md={8} className={classes.eventInfoGV}>
                <Typography
                  nowrap="true"
                  component="div"
                  className={classes.eventInfoTV}
                >
                  <Box fontFamily="Gotham" className={classes.eventInfoHT}>
                    {vendors.BusinessName}
                  </Box>
                  <Box fontFamily="GothamBook" className={classes.eventInfoIT}>
                    {business?.business_name}
                  </Box>
                </Typography>
              </Grid>
              <Grid item sm={12} xs={12} md={4} className={classes.eventInfoGV}>
                <Typography
                  nowrap="true"
                  component="div"
                  className={classes.eventInfoTV}
                  onClick={()=>showReviews(business?.id)}
                  style={{cursor:"pointer"}}
                >
                  <Box fontFamily="Gotham" className={classes.eventInfoHT}>
                    {common.Rating}
                  </Box>
                  <Box
                    fontFamily="GothamBook"
                    className={classes.eventVendDetailHT}
                  >
                    <Rating
                      value={
                        reviews && reviews?.avgRating ? reviews?.avgRating : 0
                      }
                      size="small"
                      precision={0.5}
                      readOnly
                    />
                    {reviews ? reviews?.avgRating : 0}
                    <div className={classes.smallDot} />
                    {reviews && reviews?.totalReviews
                      ? `${reviews?.totalReviews} Reviews`
                      : 0}
                  </Box>
                </Typography>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                className={classes.eventInfoGV}
              >
                <Typography
                  nowrap="true"
                  component="div"
                  className={classes.eventInfoTV}
                >
                  <Box fontFamily="Gotham" className={classes.eventInfoHT}>
                    {common.Address}
                  </Box>
                  <Box fontFamily="GothamBook" className={classes.eventInfoIT}>
                    {`${business?.address}, ${business?.city} ${business?.state}, ${business?.zip_code} ${business?.country}`}
                  </Box>
                </Typography>
              </Grid>
              <div className={commClasses.hairline} />
              <Grid item sm={12} xs={12} md={12}>
                <Typography component="div" className={classes.eventSubV}>
                  <Box fontFamily="CormorantBold" className={classes.eventSubT}>
                    {common.ServiceOffered}
                  </Box>
                </Typography>
                <div className={classes.vendorCategoryV}>
                  {categories
                    .filter(
                      (it) =>
                        business?.categories &&
                        business?.categories.length &&
                        business?.categories.includes(it.id)
                    )
                    .map((item, index) => (
                      <div
                        className={classes.eventVendorsV}
                        key={
                          Math.round() + "-" + item.title + index + "vend-round"
                        }
                      >
                        <img
                          src={item.icon}
                          className={classes.vendorImg}
                          alt=""
                        />
                        <Box
                          fontFamily="Gotham"
                          className={classes.vendorImgAlt}
                        >
                          {item.name}
                        </Box>
                      </div>
                    ))}
                  {/* Edit category */}
                  <div className={classes.eventVendorsV}>
                    <div
                      className={classes.roundVenV}
                      onClick={() =>
                        props.history.push({
                          pathname: "/category",
                          state: { from_businessDetail: true, multi: true },
                        })
                      }
                    >
                      <img
                        src={plusIcon}
                        className={classes.plusIcon1}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <div className={commClasses.hairline} />
              <Grid item sm={12} xs={12} md={12}>
                <Typography component="div" className={classes.eventSubV}>
                  <Box fontFamily="CormorantBold" className={classes.eventSubT}>
                    {vendors.Bookings}
                  </Box>
                </Typography>
                <div className={styles.bookedEvent}>
                  {bloader ? (
                    <Loader />
                  ) : booking?.length ? (
                    booking.map((item, index) => (
                      <BookingEvent
                        data={item}
                        categories={categories}
                        index={index}
                        key={"vendor-booking-listing" + index}
                        history={props.history}
                      />
                    ))
                  ) : (
                    <NoRecordFound text={vendors.NoBookingAvail} />
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
      {quote && <QuoteModal modal={quote} onClose={() => showQuote(false)} />}
    </Grid>
  );
};

const BookingEvent = ({ data = {}, history, index, categories }) => {
  const classes = vendorStyle();
  const eclasses = eventStyle();

  let service = "";
  categories.forEach((ct) => {
    if (data.category_id === ct.id) service = ct;
  });
  console.log("service: " + service)
  const handleBookingEvent = async (eventId) => {
    history.push("/eventdetail", { index: index, service: service });
  };

  return (
    <div
      className={classes.bookingEventCard}
      onClick={() => handleBookingEvent(data.id)}
    >
      <Box
        nowrap="true"
        fontFamily="Gotham"
        className={classes.bookingEventHT}
        style={{ position: "absolute", top: "20px", right: "20px" }}
      >
        {service.name}
      </Box>
      <div className={classes.bookingEventBody}>
        <Box
          nowrap="true"
          fontFamily="Gotham"
          className={classes.bookingEventHT}
        >
          {data?.event?.event_name}
        </Box>
        <Box fontFamily="GothamBook" className={eclasses.eventFrCT}>
          <img src={userIcon} alt="" className={eclasses.eventFrIcons} />
          {data?.event?.spouse_name}
        </Box>
        <Box fontFamily="GothamBook" className={eclasses.eventFrCT}>
          <img src={calendarIcon} alt="" className={eclasses.eventFrIcons} />
          {data && data.event && data.event.event_date
            ? moment(new Date(data.event.event_date)).format("DD MMM YYYY")
            : ""}
        </Box>
        <Box
          nowrap="true"
          fontFamily="GothamBook"
          className={eclasses.eventFrCT}
        >
          <img src={locationPinIcon} alt="" className={eclasses.eventFrIcons} />
          {data?.event?.event_location}
        </Box>
      </div>
    </div>
  );
};
