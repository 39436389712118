import { makeStyles } from "@material-ui/styles";
import { colors } from "constant";
const center = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const listCard = {
  width: "40%",
  borderRadius: 10,
  minHeight: 50,
  "box-shadow": `0px 1px 4px 1px ${colors.grey6}`,
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: 15,
  marginTop: 15,
  // marginBottom : 15,
  cursor: "pointer"
};
export const proposalStyle = makeStyles((theme) => ({
  proposalMain: {
    paddingTop: 80,
    paddingBottom: 80,
    flex: 1,
  },
  proposalMainModal: {
    flex: 1,
  },
  mainBody: {
    ...center,
    flexDirection: "column",
    paddingTop: 50,
  },
  mainDetailBody: {
    ...center,
    width: "50%",
    justifyContent: "flex-start",
    flexDirection: "column",
    paddingTop: 50,
  },
  mainDetailBodyCHV: {
    ...center,
    width: "50%",
    justifyContent: "flex-start",
    flexDirection: "column",
    paddingTop: 20,
  },
  mainDetailBodyC: {
    ...center,
    width: "100%",
    justifyContent: "flex-start",
    flexDirection: "column",
    paddingTop: 20,
  },
  mainDetailBodyCHV: {
    ...center,
    width: "100%",
    justifyContent: "flex-start",
    flexDirection: "column",
    paddingTop: 20,
  },
  listCardReview: {
    ...listCard,
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },
  listCardChat: {
    ...listCard,
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },
  listCard: {
    ...listCard,
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },
  profileImg: {
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    marginRight: "10px",
  },
  chatlistCard: {
    maxWidth: "60%",
    borderRadius: 8,
    "box-shadow": `0px 1px 4px 1px ${colors.grey6}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: 20,
    paddingBottom: 10,
    paddingTop: 5,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      maxWidth: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "60%",
      padding: "5px",
    },
  },
  listCardC: {
    width: "80%",
    borderRadius: 10,
    minHeight: 50,
    "box-shadow": `0px 1px 4px 1px ${colors.grey6}`,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: 20,
    paddingBottom: 15,
    marginTop: 5,
    // marginBottom : 15,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },
  prosposalNameT: {
    color: colors.main,
    fontSize: 16,
    textTransform: "capitalize",
    overflowWrap: "break-word",
  },
  prosposalDateT: {
    color: colors.main,
    fontSize: 10,
    marginTop: 5,
    // marginLeft: 5,
    overflowWrap: "break-word",
    display: "flex",
  },
  proposeMT: {
    fontSize: 13,
    color: colors.dark,
    paddingTop: 10,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
  },
  sendProposeBV: {
    marginTop: 5,
    width: "100%",
  },
  proposalAccepted: {
    color: colors.green,
    fontSize: 13,
    marginTop: 5,
    display: "flex",
  },
  noRecV: {
    minHeight: 200,
    ...center,
  },
}),{index:1});
export const proposeDetailStyle = {
  backgroundColor: colors.main6,
  // width : '60%'
};
export const rightSideBubble = {
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-end",
};
export const leftSideBubble = {
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-start",
};
export const acceptedIcon = {
  colors: colors.green,
  fontSize: 16,
  marginLeft: 10,
};
