import { firestore, insert } from "helpers";
import { history } from "../App";
import { ACTION_TYPES, strings, routes } from "constant";
import { createAlert } from "actions";

export const getChats = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.CONVERSATION_REQUEST });
    dispatch(getConversationListner());
  } catch (error) {
    console.log("add event catch error ", error);
    dispatch({ type: ACTION_TYPES.CONVERSATION_FAILED });
    dispatch(createAlert({ message: error.message, type: "error" }));
  }
};

export const getConversationListner = () => async (dispatch, getState) => {
  // const detail = getState().chat?.detail;
  // const { id = '', user = {} } = detail?detail:{};
  const {
    user: { uid = "" },
  } = getState().user;
  let users = [];
  firestore
    .collection("conversations")
    .where("participants", "array-contains", uid)
    // .orderBy('modifiedAt')
    .onSnapshot(async (snap) => {
      const conversation = snap.docs.map((it) => it.data());
      const ids = conversation.map(
        (it) =>
          it.participants &&
          it.participants.length &&
          it.participants.filter((it) => it !== uid)[0]
      );
      if (ids && ids.length) {
        const usnap = await firestore
          .collection("users")
          .where("userId", "in", ids)
          .get();
        users = usnap.docs.map((t) => t.data());
      }
      // console.log("conversation", conversation, ids, users);
      dispatch({
        type: ACTION_TYPES.CONVERSATION_SUCCESS,
        payload: conversation.map((c) => ({
          ...c,
          user: users.filter((n) => c.participants.indexOf(n.userId) > -1)[0],
        })),
      });
    });
};

export const getChat = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.CHAT_REQUEST });
    dispatch(getChatListner());
  } catch (error) {
    console.log("add event catch error ", error);
    dispatch({ type: ACTION_TYPES.CHAT_FAILED });
    dispatch(createAlert({ message: error.message, type: "error" }));
  }
};

//Get chat
export const getChatListner = () => async (dispatch, getState) => {
  let { id = "", id2 = "", business_id = "" } = getState().chat?.detail;

  console.log("pre getChatListner getChatListner");

  let checkId = await firestore
    .collection("conversations")
    .where("id", "in", [id, id2])
    .get();
  const data = checkId.docs.map((d) => d.data());
  if (data && data[0]?.id) {
    id = data[0].id;
  }

  firestore
    .collection("conversations")
    .doc(!id ? "zizB98Qt8XJmFq2AxyIj" : id)
    .collection("messages")
    .orderBy("createdAt")
    .onSnapshot((value) => {
      const chats = value.docs.map((v) => v.data());
      console.log("chats chats messages", chats);
      dispatch({ type: ACTION_TYPES.CHAT_SUCCESS, payload: chats });
    });
};

export const setChat = (payload) => async (dispatch, getState) => {
  dispatch({ type: ACTION_TYPES.SET_CHAT_DETAIL, payload });
};

export const sendMessage =
  (message, media = false) =>
  async (dispatch, getState) => {
    try {
      const {
        user: { uid = "" },
      } = getState().user;
      const {
        business_id: user2 = "", //*receiverId
        id = "", //send_rece
        id2, //rece_send
      } = getState().chat?.detail;
      let chatId1 = id,
        chatId2 = id2;

      if (chatId1 !== chatId2) {
        const conv = await firestore
          .collection("conversations")
          .where("id", "in", [chatId1, chatId2])
          .get();
        const cdata = conv.docs.map((d) => d.data());
        if (cdata.length) {
          const createdAt = new Date().getTime();

          const chats = {
            conversationId: cdata[0]?.id,
            message,
            receiverId: user2,
            senderId: uid,
            createdAt,
            media: media,
          };

          const { id = "" } = await firestore
            .collection("conversations")
            .doc(cdata[0]?.id)
            .collection("messages")
            .doc();
          await firestore
            .collection("conversations")
            .doc(cdata[0]?.id)
            .collection("messages")
            .doc(id)
            .set(chats);

          const conversations = {
            participants: cdata[0]?.participants,
            id: cdata[0]?.id,
            modifiedAt: new Date().getTime(),
            lastMessage: {
              ...cdata[0]?.lastMessage,
              message,
              receiverId: user2,
              senderId: uid,
              media: media,
              createdAt,
            },
          };
          console.log("cdata cdata", cdata);

          console.log("uid", uid, "user2", user2);
          await firestore
            .doc(`conversations/${cdata[0]?.id}`)
            .set(conversations);
        } else {
          const createdAt = new Date().getTime();

          const chats = {
            conversationId: chatId1,
            message,
            receiverId: user2,
            senderId: uid,
            createdAt,
            media: media,
          };
          const { id = "" } = await firestore
            .collection("conversations")
            .doc(chatId1)
            .collection("messages")
            .doc();
          await firestore
            .collection("conversations")
            .doc(chatId1)
            .collection("messages")
            .doc(id)
            .set(chats);

          const conversations = {
            participants: [uid, user2],
            id: chatId1,
            modifiedAt: new Date().getTime(),
            lastMessage: {
              conversationId: chatId1,
              media: media,
              receiverId: user2,
              senderId: uid,
              message,
              createdAt,
            },
          };
          await insert("conversations", {
            ...conversations,
            modifiedAt: new Date().getTime(),
          });

          dispatch(getChatListner());
        }
      }
    } catch (error) {
      console.log("send  message catch error ", error);
    }
  };
