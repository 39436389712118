import React, { useState } from "react";
import { Grid, Box, Button } from "@material-ui/core";
import { accountStyle, commonButtonStyle, deleteButtonStyle } from "styles";
import { useSelector, useDispatch } from "react-redux";
import { TextField } from "components";
import { strings } from "constant";
import { ArrowDropDown } from "@material-ui/icons";
import Menus from "./popupMenu";
import firebase from "firebase/app";
import "firebase/auth";
import { createAlert, deleteUser } from "actions";
import { useHistory } from "react-router-dom";

const { account, common } = strings;

export default (props) => {
  const classes = accountStyle();
  const { user = {} } = useSelector(({ user }) => user);
  const { email = "moses.chikodinaka@gmail.com" } = user;
  const [menu, setMenu] = useState(null);
  const dispatch = useDispatch();

  const history = useHistory();

  const [Password, setNewPassword] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const handleMenuClick = (event) => {
    setMenu(event.currentTarget);
  };
  const closeMenu = () => {
    setMenu(null);
  };
  const onMenu = (ind) => {
    props.setSelected(ind);
  };

  const onChange = (k, v) => {
    setNewPassword({ ...Password, [k]: v });
  };

  const handleNewPassword = (e) => {
    e.preventDefault();

    var currentUser = firebase.auth().currentUser;
    var newPassword = Password.newPassword;

    if (newPassword === Password.confirmNewPassword) {
      currentUser
        .updatePassword(newPassword)
        .then(function () {
          alert("Password is updated successfully");
        })
        .catch(function (error) {
          console.log(error);
          dispatch(createAlert({ message: "Need Re-login", type: "error" }));
        });
    } else {
      alert("Password newPassword and confirmPassword do not match");
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    // dispatch(deleteUser(history));
  };

  const handleCancel = () => {
    setNewPassword({
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    });
  };

  return (
    <div className={classes.renderMain}>
      <Box fontFamily="CormorantBold" className={classes.renderAcMainHeadT}>
        {account.AccountManagement}
        <ArrowDropDown className={classes.dropIcon} onClick={handleMenuClick} />
        <Menus menu={menu} closeMenu={closeMenu} onMenu={onMenu} />
      </Box>
      <Grid container>
        <Grid
          item
          sm={12}
          xs={12}
          md={7}
          lg={7}
          className={classes.accountManageV}
        >
          <Box fontFamily="Gotham" className={classes.acManageEmText}>
            {common.EmailAddress}
          </Box>
          <Box fontFamily="GothamBook" className={classes.acManageEmText}>
            {email}
          </Box>
        </Grid>
        <Grid
          sm={12}
          xs={12}
          md={12}
          lg={12}
          xl={12}
          item
          className={classes.updatePassV}
        >
          <Box fontFamily="CormorantBold" className={classes.updatePassT}>
            {account.UpdateYourPassword}
          </Box>
        </Grid>

        <Grid
          sm={12}
          xs={12}
          md={10}
          lg={6}
          xl={6}
          item
          className={classes.updatePassV}
        >
          {/* Current Password */}
          <TextField
            label={account.CurrentPassword}
            secure
            value={Password.currentPassword}
            onChange={(e) => onChange("currentPassword", e)}
          />
        </Grid>
        <Grid
          sm={12}
          xs={12}
          md={12}
          lg={6}
          xl={6}
          item
          className={classes.updatePassV}
        />

        <Grid
          sm={12}
          xs={12}
          md={10}
          lg={6}
          xl={6}
          item
          className={classes.updatePassV}
        >
          <TextField
            label={account.NewPassword}
            secure
            value={Password.newPassword}
            onChange={(e) => onChange("newPassword", e)}
          />
        </Grid>
        <Grid
          sm={12}
          xs={12}
          md={12}
          lg={6}
          xl={6}
          item
          className={classes.updatePassV}
        />

        <Grid
          sm={12}
          xs={12}
          md={10}
          lg={6}
          xl={6}
          item
          className={classes.updatePassV}
        >
          <TextField
            label={account.ConfirmNewPassword}
            secure
            value={Password.confirmNewPassword}
            onChange={(e) => onChange("confirmNewPassword", e)}
          />
        </Grid>
        <Grid
          sm={12}
          xs={12}
          md={10}
          lg={6}
          xl={6}
          item
          className={classes.updatePassV}
        />

        <Grid
          item
          sm={12}
          xs={12}
          md={7}
          lg={7}
          xl={12}
          className={classes.accountManageV}
        />

        {/* <Grid
          sm={12}
          xs={12}
          md={12}
          lg={12}
          xl={12}
          item
          className={classes.updatePassV}
        >
          <Box fontFamily="CormorantBold" className={classes.updatePassT}>
            {account.DeleteYourAccount}
          </Box>
          <Button
            variant="contained"
            size="large"
            style={deleteButtonStyle}
            onClick={handleDelete}
          >
            {common.Delete}
          </Button>
        </Grid> */}
        <Grid
          item
          sm={12}
          xs={12}
          md={7}
          lg={7}
          className={classes.accountManageV}
        />
        <Grid item sm={12} xs={12} md={12} lg={6} className={classes.buttonsV}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            style={commonButtonStyle}
            onClick={handleNewPassword}
          >
            {common.Update}
          </Button>
          <Button
            variant="outlined"
            size="large"
            style={commonButtonStyle}
            onClick={handleCancel}
          >
            {common.Cancel}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
