import { makeStyles } from "@material-ui/styles";
import { colors } from "constant";

const center = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
export const 
eventStyle = makeStyles((theme) => ({
  eventMain: {
    paddingTop: 80,
    paddingBottom: 80,
    width: "100%",
    ...center,
    // justifyContent: 'flex-start',
    [theme.breakpoints.down("md")]: {
      paddingTop: 90,
      paddingBottom: 90,
    },
  },
  eventTV: {
    width: "100%",
    ...center,
    paddingTop: 10,
    paddingBottom: 30,
  },
  eventT: {
    fontSize: 35,
    color: colors.main,
    padding: 10,
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },
  addNewEventV: {
    width: "100%",
    ...center,
  },
  addNewEventBox: {
    border: 1,
    borderColor: colors.grey4,
    borderStyle: "dashed",
    borderRadius: 6,
    opacity: 0.8,
    height: 150,
    width: 280,
    color: colors.grey5,
    fontSize: 15,
    ...center,
    flexDirection: "column",
    cursor: "pointer",
  },
  plusIcon: {
    height: 25,
    width: 25,
    paddingBottom: 10,
  },
  addNewEventFormV: {
    width: "60%",
    ...center,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  addNewEventFormGV: {
    position: "relative",
    paddingRight: 10,
    paddingLeft: 10,
  },
  mapTV: {
    position: "absolute",
    display: "flex",
    right: 20,
    color: colors.blue4,
    cursor: "pointer",
  },
  mapT: {
    color: colors.blue4,
    fontSize: 13,
  },
  spaceH20: {
    width: 20,
  },
  addNewEventFormGBtV: {
    width: "100%",
    display: "flex",
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  cancelButton: {
    marginLeft: 50,
  },
  eventDetailsCRImge: {
    //height: 500,
    width: 450,
    height: 300,
    borderRadius: 5,
    [theme.breakpoints.down("md")]: {
      width: 320,
      height: 250,
    },
  },
  eventVendDetailsCRImge: {
    //height: 500,
    width: 450,
    height: 300,
    borderRadius: 5,
    [theme.breakpoints.down("md")]: {
      width: 320,
      height: 250,
    },
  },
  eventInfoV: {
    paddingTop: 20,
    width: "100%",
  },
  eventInfoVendV: {
    paddingTop: 20,
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  eventVendorInfoVendV: {
    paddingTop: 20,
    width: "40%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  eventInfoHT: {
    fontSize: 14,
  },
  eventInfoIT: {
    fontSize: 14,
  },
  eventInfoGV: {
    paddingBottom: 30,
    // textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 10px",
    },
  },
  crEventMain: {
    ...center,
    width: "35%",
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  crImageV: {
    ...center,
  },
  eventVendorsV: {
    ...center,
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
    paddingRight: 25,
  },
  vendorImg: {
    height: 80,
    width: 80,
    borderRadius: "50%",
  },
  centWid100: {
    ...center,
    width: "100%",
  },
  vendorImgAlt: {
    color: colors.dark,
    fontSize: 14,
    paddingTop: 10,
    paddingBottom: 10,
  },
  vendorHorV: {
    ...center,
    flexWrap: "wrap",
    justifyContent: "flex-start",
    width: "35%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      justifyContent: "center",
    },
  },
  roundVenV: {
    width: 80,
    height: 80,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: colors.grey4,
    borderStyle: "dashed",
    opacity: 0.8,
    background: colors.main3,
    ...center,
    flexDirection: "column",
    cursor: "pointer",
    marginBottom: 25,
  },
  plusIcon1: {
    height: 25,
    width: 25,
  },
  eventStatBox: {
    width: "50%",
    height: 50,
    borderRadius: 5,
    background: colors.main4,
    cursor: "pointer",
    ...center,
  },
  eventstatsT: {
    fontSize: 14,
    paddingLeft: 10,
  },
  eventFrMain: {
    paddingTop: 100,
    paddingBottom: 100,
  },
  eventFrHeadV: {
    width: "100%",
    minHeight: 100,
    background: colors.main3,
    ...center,
  },
  eventVendorListMain: {
    ...center,
    paddingTop: 20,
  },
  eventVendorDetailMain: {
    ...center,
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  eventFrListMain: {
    ...center,
    paddingTop: 20,
    paddingBottom: 20,
  },
  eventFrListV: {
    borderRadius: 10,
    border: `1px solid ${colors.grey2}`,
    minHeight: 100,
    width: "60%",
    padding: 20,
    cursor: "pointer",
  },
  eventFrListContainer: {
    width: "85%",
  },
  eventFrHT: {
    fontSize: 15,
    color: colors.dark,
    paddingTop: 7,
  },
  eventFrCT: {
    fontSize: 13,
    color: colors.dark,
    paddingTop: 10,
    display: "flex",
  },
  eventFrIcons: {
    paddingRight: 10,
    textTransform: "capitalize",
  },
  eventFrNameHT: {
    fontSize: 14,
  },
  eventFrNameHT: {
    fontSize: 13,
    paddingLeft: 15,
    marginTop: -5,
  },
  eventFRheadingTV: {},
  eventFrReviewHT: {
    fontSize: 10,
    paddingLeft: 15,
    paddingTop: 5,
    ...center,
    justifyContent: "flex-start",
  },
  eventVendDetailHT: {
    fontSize: 10,
    paddingTop: 5,
    ...center,
    justifyContent: "flex-start",
  },
  smallDot: {
    height: 3,
    width: 3,
    background: colors.dark,
    borderRadius: 100,
    marginLeft: 5,
    marginRight: 5,
  },
  categoriesV: {
    // paddingLeft: 100,
    // paddingRight: 50,
    width: "80% !important",
    ...center,
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0 35px",
    },
  },
  accepted: {
    color: colors.white,
    backgroundColor: colors.green,
    margin: "10px",
    padding: "10px",
    border: "10px",
    "&:hover": {
      backgroundColor: colors.main5,
    },
  },
  acceptBtn: {
    color: colors.white,
    backgroundColor: colors.main,
    margin: "10px",
    padding: "10px 25px",
    border: "10px",
    "&:hover": {
      backgroundColor: colors.main5,
    },
  },
  rejected: {
    color: colors.white,
    backgroundColor: colors.red,
    border: "10px",
    margin: "10px",
    padding: "10px",
    "&:hover": {
      backgroundColor: colors.main5,
    },
  },
  inviteButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "10px",
    borderRadius: "50px",
  },
  inviteButton1:{
    borderRadius: 50,
  },
  sameLine:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  allCategoryV: {
    width: "100%",
    display: "flex",
    paddingTop: 30,
    paddingLeft: 0,
    flexDirection: "column",
  },
  disabledCategory: {
    width: "100%",
    display: "flex",
    paddingTop: 30,
    paddingLeft: 0,
  },
  allCatRoundImgV: {
    display: "flex",
    borderRadius: 100,
    justifyContent: "center",
  },
  allCatRoundImg: {
    width: 90,
    height: 90,
    borderRadius: 100,
    cursor: "pointer",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      width: 80,
      height: 80,
    },
  },
  allCatSelectedImg: {
    width: 89,
    height: 89,
    borderRadius: 100,
    cursor: "pointer",
    position: "relative",
    border: `2px solid ${colors.green}`,
    [theme.breakpoints.down("md")]: {
      width: 80,
      height: 80,
    },
  },
  allCatRoundT: {
    fontSize: 15,
    color: colors.dark,
    cursor: "pointer",
    ...center,
    paddingTop: 20,
    [theme.breakpoints.down("md")]: {
      margin: "0 auto",
      paddingTop: 10,
      fontSize: 12,
    },
  },
  categoryCheckIcon: {
    color: colors.green,
    marginTop: 53,
    marginLeft: -16,
    background: colors.white,
    borderRadius: 100,
    border: `1px solid ${colors.white}`,
    zIndex: 1,
    [theme.breakpoints.down("md")]: {
      marginTop: 53,
      marginLeft: -16,
    },
  },
  categoryButonV: {
    ...center,
    paddingTop: 50,
    width:"100%"
  },
  eventVendorDetailTV: {
    ...center,
    width: "50%",
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  detailParentV: {
    width: "70%",
    ...center,
  },
  vendorDetailParentV: {
    width: "40%",
    ...center,
  },
  eventSubV: {
    ...center,
    paddingBottom: 20,
  },
  eventSubT: {
    color: colors.main,
    fontSize: 25,
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },
  vendorDetailBLV: {
    ...center,
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  chatButtonV: {
    background: colors.main4,
    height: 60,
    width: 184,
    borderRadius: 5,
    cursor: "pointer",
    ...center,
  },
  vendorDetailBRV: {
    ...center,
    paddingLeft: 30,
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingTop: 10,
      justifyContent: "center",
    },
  },
  vendorDetButT: {
    ...center,
    color: colors.dark1,
    fontSize: 15,
  },
  vendorCategoryV: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  reviewListV: {
    width: "90%",
    ...center,
  },
}), {index: 1});
export const btSmallIcon = {
  color: colors.main,
  fontSize: 15,
  padding: 5,
  paddingBottom: 0,
};
