import React, { useState, useEffect, useMemo } from "react";
import { Typography, Grid, Box, Button } from "@material-ui/core";
import { CheckCircleRounded } from "@material-ui/icons";
import { ACTION_TYPES } from "constant";
import { eventStyle, commonButtonStyle, primaryLoaderStyle } from "styles";
import { useDispatch, useSelector } from "react-redux";
import { strings } from "constant";
import {
  BreadCrumb,
  Loader,
  NoRecordFound,
  ProposeModal,
  QuoteModal,
} from "components";
import { setCategory, addBusiness, updateCategory } from "actions";
const { events, vendors, common } = strings;

export default (props) => {
  const classes = eventStyle();
  const dispatch = useDispatch();
  const {
    user: { type },
  } = useSelector(({ user }) => user);
  let { categories = [] } = useSelector(({ app }) => app);
  const [categoriess, setCategories] = useState(categories);

  const {event = {}} = useSelector((state)=>state.event)
  console.log("event", event)
  const params = props.history?.location?.state;
  console.log("params", params)

  useEffect(() => {
    if (params?.categories && params.categories.length) {
      categories = categories.filter((cat) =>
        params.categories.includes(cat.id)
      );
      setCategories(categories);
    }
  }, [JSON.stringify(params?.categories)]);

  

  const breads =
    type === 3
      ? [
          { title: common.Home, path: "/" },
          { title: strings.vendors.VendorBusiness, path: "/vendor" },
        ]
      : [
          { title: common.Home, path: "/" },
          { title: events.WeddingEvent, path: "/eventdetail" },
        ];

  return (
    <Grid container className={classes.eventMain}>
      <BreadCrumb breads={breads} current={common.Category} />
      <Typography component="div" className={classes.eventTV}>
        <Box fontFamily="CormorantBold" className={classes.eventT}>
          {common.Category}
        </Box>
      </Typography>
      <ImagesGrids
        categories={categoriess}
        history={props.history}
        multi={params?.multi}
        BsD={params?.from_businessDetail} //from business detail --> opening category from business detail
        Ed={params?.from_eventDetail} //*from event OR vendor detail --> opening category from business detail
        send_proposal={params?.send_proposal}
      />
    </Grid>
  );
};

const ImagesGrids = ({
  categories = [],
  history,
  multi = false,
  BsD = false,
  Ed = false,
  send_proposal = false,
}) => {
  const classes = eventStyle();
  const {
    user: { type },
  } = useSelector(({ user }) => user);
  const { loader = false , event ={}} = useSelector(({ event }) => event);
  console.log("eventve", event)
  const { business = {} } = useSelector(({ vendor }) => vendor);
  const [selected, setSelected] = useState(
    BsD
      ? categories.filter((ct) => {
          if (business?.categories.includes(ct.id)) return ct;
        })
      : []
  );

  const [openModal, setOpenModal] = useState(false);
  const onClose = () => {
    setOpenModal(false);
  };

  const dispatch = useDispatch();
  const onSubmit = () => {
    if (selected && selected.length) {
      dispatch(setCategory(multi || type === 3 ? selected : selected[0]));
      // debugger;
      //BsD --> bussinessDetail/venderBusinessDetail for (if true--> it mean redirected from bussinessDetail)
      //* type === 3;
      if (BsD) {
        dispatch({ type: ACTION_TYPES.SET_SELECTED_PROPOSAL, payload: {} }); //clear previous selected proposal or quote
        dispatch(updateCategory(selected));
      }
      // else if(Ed)
      else {
        // dispatch({ type: ACTION_TYPES.SET_EVENT_CATEGORY, payload: selected });
        history.push(type === 3 ? "/addvendorbusiness" : "/eventvendorlist");
      }
    }
  };

  const handleSelection = (i, item) => {
    //item = {id,icon,...}
    if (BsD) {
      const selects =
        selected && selected.map((sel) => sel.id).includes(item.id)
          ? selected.filter((f) => f.id !== item.id)
          : [...selected, item];

      setSelected(selects);
    } else if (Ed === false && multi && type === 3) {
      //if category is already present in selected array --> remove
      //else --> add new category with previous selected category [...selected, item]
      //with category details {id,icon,...}
      const selects =
        selected && selected.map((sel) => sel.id).includes(item.id)
          ? selected.filter((f) => f.id !== item.id)
          : [...selected, item];
      setSelected(selects);
    } else {
      dispatch({
        type: ACTION_TYPES.SET_EVENT_CATEGORY,
        payload: { ...item },
      });
      setSelected([item]);
    }
    if (Ed || send_proposal) {
      setOpenModal(true);
    }
  };
  return (
    <Grid container className={classes.categoriesV}>
      {loader ? (
        <Loader style={primaryLoaderStyle} />
      ) : (
        <>
          {categories && categories.length ? (
            categories.map((item, i) => (
              <Grid
                item
                sm={4}
                xs={6}
                md={3}
                lg={3}
                className={classes.allCategoryV}
                key={item.name + i + "-category-all-cats"}
              >
                <div
                  className={classes.allCatRoundImgV}
                  onClick={() => handleSelection(i, item)}
                >
                  <img
                    src={item.icon}
                    className={
                      selected &&
                      selected.length &&
                      selected.map((s) => s.id).includes(item.id)
                        ? classes.allCatSelectedImg
                        : classes.allCatRoundImg
                    }
                    alt=""
                  />
                  {selected &&
                  selected.length &&
                  selected.map((s) => s.id).includes(item.id) ? (
                    <CheckCircleRounded className={classes.categoryCheckIcon} />
                  ) : null}
                </div>
                <Box
                  fontFamily="Gotham"
                  className={`${classes.allCatRoundT}`}
                  onClick={() => handleSelection(i, item)}
                >
                  {item.name}
                </Box>
              </Grid>
            ))
          ) : (
            <NoRecordFound />
          )}
          {Ed || send_proposal ? (
            <>
              {type === 3 && selected.length ? (
                <ProposeModal
                  modal={openModal}
                  onClose={onClose}
                  from="from_vendor_send_proposal"
                  category_id={selected[0].id}
                  
                />
              ) : null}
              {type === 1 && selected.length ? (
                <QuoteModal
                  modal={openModal}
                  category_id={selected[0].id}
                  onClose={onClose}
                />
              ) : null}
            </>
          ) : (
            <div
              className={classes.categoryButonV}
            >
              <Button
                variant="contained"
                size="large"
                color="primary"
                style={commonButtonStyle}
                onClick={onSubmit}
              >
                {common.Submit}
              </Button>
              <Button
                variant="contained"
                size="large"
                style={commonButtonStyle}
                onClick={history.goBack}
              >
                {common.Cancel}
              </Button>
            </div>
          )}
        </>
      )}
    </Grid>
  );
};
